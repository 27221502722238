import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { FormButton, FormLayout } from '../../components/Form';
import BackButton from '../../components/BackButton';

import { MarkEmailReadIcon } from '../../../../icons';

import { IEmailConfirmationPageView } from './interfaces';

import { useStyles } from './styles';

const EmailConfirmationPageView = ({
  email,
  onBackClick,
  onBackToLogin,
  t: translate,
}: IEmailConfirmationPageView) => {
  const classes = useStyles();

  return (
    <FormLayout classes={{ container: classes.root }}>
      <BackButton onClick={onBackClick} />
      <Box sx={classes.container}>
        <MarkEmailReadIcon />
        <Typography sx={classes.title}>
          {translate('confirmEmail.title')}
        </Typography>
        <Typography sx={classes.subtitle}>
          {translate('confirmEmail.subtitle1')}:{' '}
          <Typography component="span" sx={classes.email}>
            {email}
          </Typography>
        </Typography>
        <Typography sx={classes.reminder}>
          {translate('confirmEmail.subtitle2')}.{' '}
          {/*TODO uncomment when api will be ready*/}
          {/*<Typography component="span" sx={classes.resendActivation}>*/}
          {/*  {translate('confirmEmail.resendActivation')}*/}
          {/*</Typography>*/}
        </Typography>
        <FormButton onClick={onBackToLogin} sx={classes.button}>
          {translate('confirmEmail.backToLogin')}
        </FormButton>
      </Box>
    </FormLayout>
  );
};

export default withTranslation('common')(EmailConfirmationPageView);
