import React, { useEffect, useRef } from 'react';

import { IdleTimeout, VideoStream } from '@pureweb/platform-sdk-react';
import {
  Resolution,
  streamResolutionConfiguration,
} from '@pureweb/platform-sdk';

import { Box, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import PurewebLoader from './PurewebLoader';

import { useMytaverseEvent } from '../../../providers';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useConference } from '../Dolby';

import purewebLogger from './logger';

import { PurewebViewProps } from './interfaces';

import { useStyles } from './styles';

const PurewebView = ({
  videoRef,
  streamerStatus,
  inputEmitter,
  videoStream,
  clientOptions,
  enterPureweb,
  isOpenChat,
}: PurewebViewProps) => {
  const { sharingWindowFullsceen, setSharingWindowFullscreen, currentRoom } =
    useMytaverseEvent();
  const { screenMediaStreams } = useConference();

  const { logoutUser } = useMytaverse();
  const classes = useStyles(isOpenChat);
  const notificationRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!notificationRef.current && enterPureweb) {
      notificationRef.current = setTimeout(() => {
        purewebLogger.error('Connection timeout');
      }, 300000);
    }

    if (notificationRef.current && !enterPureweb) {
      clearTimeout(notificationRef.current);
    }

    return () => {
      if (notificationRef.current) {
        clearTimeout(notificationRef.current);
      }
    };
  }, [enterPureweb]);

  return (
    <Box>
      {currentRoom ? (
        <Box style={classes.root}>
          {screenMediaStreams.length ? (
            <IconButton
              sx={classes.fullscreen}
              id="gameFull"
              onClick={setSharingWindowFullscreen}
            >
              {sharingWindowFullsceen ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </IconButton>
          ) : null}

          <IdleTimeout
            Status={streamerStatus}
            WarningThreshold={1200}
            ExitThreshold={60}
            WarningCallback={() => document.exitFullscreen()}
            ExitCallback={() => {
              logoutUser();
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: 50,
            }}
          />
          {clientOptions ? (
            <VideoStream
              VideoRef={videoRef}
              Emitter={inputEmitter}
              Stream={videoStream}
              UseNativeTouchEvents={clientOptions.UseNativeTouchEvents}
              UsePointerLock={clientOptions.UsePointerLock}
              PointerLockRelease={clientOptions.PointerLockRelease}
              Resolution={streamResolutionConfiguration(
                clientOptions.Resolution ?? Resolution.fhd,
              )}
            />
          ) : null}
          <Box />
        </Box>
      ) : (
        <PurewebLoader />
      )}
    </Box>
  );
};

export default PurewebView;
