import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { getSortFunction } from '../helpers/sort';

import { HandleSortType, UseSortProps } from './interfaces';
import { SortType } from '../constants/sort';

export const useSort = <T>({
  list,
  defaultSortField,
  defaultSortType,
  defaultSortDirection = true,
}: UseSortProps<T>) => {
  const [sortField, setSortField] = useState<keyof T | null>(null);
  const [sortType, setSortType] = useState<SortType | null>(null);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortedList, setSortedList] = useState<T[]>(list);

  const isInitialSortRef = useRef(true);

  const handleSort: HandleSortType<T> = useCallback(
    ({ type, fieldName, direction }) => {
      const sortFunction = getSortFunction(type);

      setSortField(fieldName);
      setSortType(type);
      setSortDirection(direction);

      if (sortFunction) {
        const results = sortFunction({ list, fieldName, direction });

        setSortedList(results);
      }
    },
    [list],
  );

  useLayoutEffect(() => {
    if (!defaultSortType || !defaultSortField) {
      return;
    }

    handleSort({
      type: defaultSortType,
      fieldName: defaultSortField,
      direction: defaultSortDirection,
    });

    if (isInitialSortRef.current) {
      isInitialSortRef.current = false;
    }
  }, [defaultSortDirection, defaultSortField, defaultSortType, handleSort]);

  return {
    sortField,
    sortType,
    sortDirection,
    sortedList,
    handleSort,
  };
};
