export const useStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
  },
  text: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
});
