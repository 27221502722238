import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const UserIcon = (props: SvgIconProps) => (
  <SvgIcon width="22" height="24" viewBox="0 0 22 24" {...props}>
    <path
      d="M11 12C14.2812 12 17 9.32812 17 6C17 2.71875 14.2812 0 11 0C7.67188 0 5 2.71875 5 6C5 9.32812 7.67188 12 11 12ZM13.3438 14.25H8.60938C4.10938 14.25 0.5 17.9062 0.5 22.4062C0.5 23.2969 1.20312 24 2.09375 24H19.8594C20.75 24 21.5 23.2969 21.5 22.4062C21.5 17.9062 17.8438 14.25 13.3438 14.25Z"
      fill="currentColor"
    />
  </SvgIcon>
);
