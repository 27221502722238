import React from 'react';

import { Button as BaseButton } from '@mui/material';

import LoadingProgress from '../../../../../components/LoadingProgress';

import { IButton } from './interfaces';

import { useStyles } from './styles';

const Button = ({
  children,
  type = 'submit',
  loading = false,
  disabled = false,
  onClick = () => {},
  sx = {},
}: IButton) => {
  const classes = useStyles();

  const rootClass = { ...classes.root, ...sx };

  return (
    <BaseButton
      onClick={onClick}
      sx={rootClass}
      type={type}
      disabled={loading || disabled}
      variant="contained"
      disableRipple
    >
      {loading ? <LoadingProgress size={30} height="auto" /> : children}
    </BaseButton>
  );
};

export default Button;
