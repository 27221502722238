import React from 'react';
import { withTranslation } from 'react-i18next';

import { Avatar, Box, Typography } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { IUserAvatar } from './interfaces';

import { useStyles } from './styles';

const UserAvatar = ({ src, onOpenUploadModal, t: translate }: IUserAvatar) => {
  const classes = useStyles();
  const isEditMode = !!onOpenUploadModal;

  return (
    <Box sx={classes.root}>
      <Avatar src={src} />
      {isEditMode && (
        <Box onClick={onOpenUploadModal} sx={classes.updateLayout}>
          <AddAPhotoIcon />
          <Typography>{translate('userProfile.update')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default withTranslation('common')(UserAvatar);
