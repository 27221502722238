import React from 'react';

import { Button, Typography } from '@mui/material';

import { IDashboardBarButton } from './interfaces';

import { useStyles } from './styles';

const DashboardBarButton = ({
  children,
  buttonIcon,
  onClick,
  sx = {},
}: IDashboardBarButton) => {
  const classes = useStyles(!!children);
  const rootSx = { ...classes.root, ...sx };

  return (
    <Button sx={rootSx} onClick={onClick}>
      {buttonIcon && buttonIcon}
      <Typography sx={classes.typography}>{children}</Typography>
    </Button>
  );
};

export default DashboardBarButton;
