import React from 'react';

import { RouteObject } from 'react-router/lib/router';

import LoginWrapper from '../modules/auth/components/LoginWrapper';
import LoginPage from '../modules/auth/pages/LoginPage';
import EventConfirmationPage from '../modules/auth/pages/EventConfirmationPage';
import SignUpPage from '../modules/auth/pages/SignUpPage';
import EmailConfirmationPage from '../modules/auth/pages/EmailConfirmationPage';
import EmailConfirmedPage from '../modules/auth/pages/EmailConfirmedPage';
import ForgotPasswordPage from '../modules/auth/pages/ForgotPasswordPage';
import ForgotPasswordVerificationPage from '../modules/auth/pages/ForgotPasswordVerificationPage';
import PasswordRecovery from '../modules/auth/pages/PasswordRecovery';
import PrivateContainer from './PrivateContainer';
import SelectEventPage from '../modules/auth/pages/SelectEventPage';
import SelectAvatarPage from '../modules/auth/pages/SelectAvatarPage';
import SelectAvatarSkinPage from '../modules/auth/pages/SelectAvatarSkinPage';
import Home from '../modules/dashboard/pages/HomePage';
import ReadyPlayerMe from '../modules/auth/pages/ReadyPlayerMe';
import DevicesTestPage from '../modules/auth/pages/DevicesTestPage';
import PageNotFoundPage from '../modules/auth/pages/PageNotFoundPage';

import ROUTES from '../constants/routes';

const APP_ROUTES: RouteObject[] = [
  {
    path: ROUTES.LOGIN,
    element: <LoginWrapper />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
      {
        path: `${ROUTES.CONFIRM_EVENT}/:token`,
        element: <EventConfirmationPage />,
      },
      {
        path: ROUTES.REGISTER,
        element: <SignUpPage />,
      },
      {
        path: ROUTES.CONFIRM_EMAIL,
        element: <EmailConfirmationPage />,
      },
      {
        path: `${ROUTES.CONFIRMED_EMAIL}/:token`,
        element: <EmailConfirmedPage />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: <ForgotPasswordPage />,
      },
      {
        path: `${ROUTES.FORGOT_PASSWORD}/${ROUTES.FORGOT_PASSWORD_VERIFICATION}`,
        element: <ForgotPasswordVerificationPage />,
      },
      {
        path: `${ROUTES.RECOVER_PASSWORD}/:token`,
        element: <PasswordRecovery />,
      },
    ],
  },
  {
    path: ROUTES.HOME_PAGE,
    element: <PrivateContainer />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: ROUTES.SELECT_EVENT,
        element: <SelectEventPage />,
      },
      {
        path: ROUTES.AVATAR_CHOOSING,
        element: <SelectAvatarPage />,
      },
      {
        path: ROUTES.SELECT_AVATAR_SKIN,
        element: <SelectAvatarSkinPage />,
      },
      {
        path: ROUTES.READY_PLAYER,
        element: <ReadyPlayerMe />,
      },
      {
        path: ROUTES.DEVICES_TEST,
        element: <DevicesTestPage />,
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFoundPage />,
  },
];

export default APP_ROUTES;
