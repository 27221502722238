import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import CssBaseline from '@mui/material/CssBaseline';

import NotificationProvider from './providers/NotificationProvider';
import { MytaverseProvider } from './providers/MytaverseProvider';
import { MytaverseEventProvider } from './modules/dashboard/providers';
import { ConferenceProvider } from './modules/dashboard/components/DashboardContent/Dolby';

import Routes from './routes/Routes';

import i18next from './locales/i18n';

import './styles/App.scss';

const App = () => (
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <CssBaseline />
      <NotificationProvider>
        <MytaverseProvider>
          <MytaverseEventProvider>
            <ConferenceProvider>
              <Routes />
            </ConferenceProvider>
          </MytaverseEventProvider>
        </MytaverseProvider>
      </NotificationProvider>
    </BrowserRouter>
  </I18nextProvider>
);

export default App;
