import { colors } from '../../../../../constants/colors';

import { IStyles } from '../../../../../interfaces/styles';

export const classes: IStyles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  container: {
    alignSelf: 'center',
    height: 'fit-content',
    background: colors.white,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: 1,
    padding: '32px 24px',
    width: '100%',
    maxWidth: 480,
  },
};
