import { useTheme } from '@mui/material';

import { useCommonTipStyles } from '../../hooks';

import { ScreenSizes } from '../../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const commonStyles = useCommonTipStyles(breakpoints);

  return {
    ...commonStyles,
    tipImage: {
      display: 'flex',
      alignSelf: 'flex-end',
      '& img': {
        width: 358,
        [breakpoints.down(ScreenSizes.Pc)]: {
          width: 297,
        },
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          width: 168,
        },
      },
    },
  };
};
