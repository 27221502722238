import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const GoogleColoredIcon = (props: SvgIconProps) => (
  <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <g clipPath="url(#clip0_526_1072)">
      <path
        d="M24.25 12.2878C24.25 11.4774 24.17 10.6408 24.0366 9.85648H12.4867V14.4838H19.1019C18.8352 15.974 17.9549 17.2812 16.6479 18.1178L20.5957 21.1242C22.9163 19.0066 24.25 15.9217 24.25 12.2878Z"
        fill="#4280EF"
      />
      <path
        d="M12.4867 24C15.7943 24 18.5684 22.9281 20.5957 21.0981L16.6479 18.1178C15.5543 18.8498 14.1405 19.2681 12.4867 19.2681C9.28584 19.2681 6.59176 17.1505 5.60482 14.327L1.55036 17.3857C3.63094 21.438 7.84544 24 12.4867 24Z"
        fill="#34A353"
      />
      <path
        d="M5.60482 14.3008C5.09801 12.8107 5.09801 11.1898 5.60482 9.69962L1.55036 6.61471C-0.183454 10.0133 -0.183454 14.0133 1.55036 17.3857L5.60482 14.3008Z"
        fill="#F6B704"
      />
      <path
        d="M12.4867 4.75854C14.2205 4.73239 15.9277 5.38597 17.1814 6.56242L20.6757 3.11151C18.4617 1.07233 15.5276 -0.025687 12.4867 0.000456276C7.84544 0.000456276 3.63094 2.5625 1.55036 6.61471L5.60482 9.69962C6.59176 6.85 9.28584 4.75854 12.4867 4.75854Z"
        fill="#E54335"
      />
    </g>
    <defs>
      <clipPath id="clip0_526_1072">
        <rect width="24" height="24" fill="white" transform="translate(0.25)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
