import React from 'react';

import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';

import { ILoadingProgress } from './interfaces';

import { useStyles } from './styles';

const LoadingProgress = ({
  fullHeight = false,
  height = '100%',
  size = 40,
  classes: propClasses = {},
}: ILoadingProgress) => {
  const { root = {}, spinner = {} } = propClasses;

  const classes = useStyles({ fullHeight, height });
  const rootSx = { ...classes.root, ...root };
  const spinnerSx = { ...classes.spinner, ...spinner };

  return (
    <Box sx={rootSx}>
      <CircularProgress sx={spinnerSx} size={size} />
    </Box>
  );
};

export default LoadingProgress;
