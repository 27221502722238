import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { COLORS } from '../../../../constants/colors';

import { IStyles } from '../../../../interfaces/styles';

export const useStyles = (): IStyles => {
  const { appTheme } = useMytaverse();

  const assets = appTheme.backButton.assets;

  return {
    root: {
      backgroundColor: 'none',
      display: ['none', 'flex'],
      borderRadius: 0,
      alignItems: 'flex-start',
      borderBottom: '1px solid transparent',
      '&:hover': {
        background: 'none',
        borderBottom: '1px solid transparent',
      },
    },
    icon: {
      color: assets.color ? assets.color : COLORS.BLACK,
      width: 24,
      height: 24,
      pt: 0.5,
    },
    text: {
      fontSize: 20,
      color: assets.color ? assets.color : COLORS.BLACK,
      fontWeight: 700,
      textTransform: 'none',
    },
  };
};
