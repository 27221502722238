import { colors } from '../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 84px',
  },
  title: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '36px',
    color: colors.patrick,
    textAlign: 'center',
  },
  description: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '27px',
    color: colors.oxford_60,
    textAlign: 'center',
    marginTop: '16px',
  },
  footer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'flex-end',
    width: '100%',
    padding: '0px 19px',
  },
  box: {
    display: 'grid',
  },
  step: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '27px',
    color: colors.oxford_60,
  },
  nextBtn: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'center',
    columnGap: 2,
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '36px',
    color: colors.white,
    textTransform: 'capitalize',
    backgroundColor: colors.myta,
    border: `1px solid ${colors.white_40}`,
    borderRadius: '100px',
    transition: 'none',
    boxShadow: 'none',
    padding: '10px 16px',
    '&:hover': {
      transition: 'none',
      boxShadow: 'none',
      backgroundColor: colors.myta,
    },
  },
  icon: {
    width: 32,
    height: 32,
  },
  skipBtn: {
    justifySelf: 'flex-end',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '27px',
    color: colors.oxford_60,
    textTransform: 'capitalize',
    transition: 'none',
    boxShadow: 'none',
    '&:hover': {
      transition: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
});
