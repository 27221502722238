import React from 'react';
import { Outlet } from 'react-router-dom';

import LoadingProgress from '../components/LoadingProgress';

import { useCheckUniqueApplicationAccessPerAccount } from '../hooks/permissions';
import { useInitPrivateRoutesState } from './hooks';

const PrivateContainer = () => {
  useCheckUniqueApplicationAccessPerAccount();

  const initializing = useInitPrivateRoutesState();

  if (initializing) {
    return <LoadingProgress height="100%" />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default PrivateContainer;
