import { IWsConnection } from '../interfaces/profile';
import { WsConnectionClientType } from '../interfaces';

export const getHasMultipleWsWebAppConnection = (
  wsConnections: IWsConnection[],
): boolean => {
  const webappConnection = wsConnections.filter(
    (connection) => connection.clientType === WsConnectionClientType.Webapp,
  );

  return webappConnection.length > 1;
};
