import i18next from 'i18next';

import commonEs from './es/index.json';
import commonEn from './en/index.json';
import commonPt from './pt/index.json';
import commonFr from './fr/index.json';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: commonEn,
    },
    es: {
      common: commonEs,
    },
    pt: {
      common: commonPt,
    },
    fr: {
      common: commonFr,
    },
  },
});

export default i18next;
