import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

import { getCatchErrorMessage } from '../../helpers/error';

import $api from '../../http/axios';

export const useHandleApiErrors = (
  logoutUser: (withRedirection: boolean) => Promise<void>,
) => {
  const { t: translate } = useTranslation('common');

  useEffect(() => {
    $api.interceptors.response.use(
      (config: AxiosResponse) => config,
      async (error: AxiosError | Error) => {
        if (axios.isAxiosError(error)) {
          const { method, url } = error.config as AxiosRequestConfig;
          const { status, data } = (error.response as AxiosResponse) ?? {};
          const errorMessage = data?.error?.message || error.message;

          if (status === 401 || status === 403) {
            logoutUser(false);

            const message = data?.error?.errorKey
              ? translate(`apiErrors.${data.error.errorKey}`)
              : errorMessage;
            throw Error(message);
          }

          throw Error(
            `${method?.toUpperCase()} ${url} | Error ${status} ${errorMessage}`,
          );
        }

        throw Error(getCatchErrorMessage(error));
      },
    );
  }, []);
};
