import { useTheme } from '@mui/material';

import { useCommonTipStyles } from '../../hooks';

import { ScreenSizes } from '../../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const commonStyles = useCommonTipStyles(breakpoints);

  return {
    ...commonStyles,
    tipImage: {
      display: 'flex',
      alignSelf: 'center',
      '& img': {
        [breakpoints.down(ScreenSizes.Pc)]: {
          width: 271,
        },
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          width: 166,
        },
      },
    },
    imageInText: {
      '& img': {
        verticalAlign: 'top',
      },
      '& svg': {
        verticalAlign: 'top',
      },
    },
  };
};
