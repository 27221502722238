import React, { ChangeEvent, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';

import SignUpPageView from './SignUpPageView';

import { useSignUp, useSocialSignUp } from './hooks';
import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { validateForm } from '../../components/PasswordValidation';
import { getSearchParamValue } from '../../../../helpers/path';

import { RegisterSchema } from './validation';

import { IFormValues } from './interfaces';
import { IEventThemeTypes, SocialProviders } from '../../../../interfaces';

const SignUpPage = () => {
  const [openPasswordRules, setOpenPasswordRules] = useState(false);

  const location = useLocation();
  const emailParam = decodeURIComponent(
    getSearchParamValue(location.search, 'email'),
  );
  const { appTheme } = useMytaverse();
  const { loading, signUp } = useSignUp();
  const { socialLoading, socialSignUp } = useSocialSignUp();

  const onSignUp = (credentials: IFormValues) => {
    signUp(credentials);
  };

  const {
    values,
    touched,
    handleSubmit,
    handleChange,
    errors,
    setFieldTouched,
  } = useFormik<IFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: emailParam || '',
      password: '',
      passwordConfirm: '',
    },
    onSubmit: onSignUp,
    enableReinitialize: true,
    validate: (values) => validateForm(RegisterSchema, values),
  });

  const isDefaultEvent = appTheme.themeName === IEventThemeTypes.DEFAULT;

  const handleInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFieldTouched(event.target.name, false, false);
    handleChange(event);
  };

  const handleSocialBtnClick = (provider: SocialProviders) => {
    socialSignUp(provider, emailParam);
  };

  return (
    <SignUpPageView
      isDefaultEvent={isDefaultEvent}
      openPasswordRules={openPasswordRules}
      values={values}
      errors={errors}
      touched={touched}
      onChange={handleInputChange}
      onPasswordHandler={setOpenPasswordRules}
      hasInitialEmail={!!emailParam}
      loading={loading}
      onSubmit={handleSubmit}
      socialLoading={socialLoading}
      onSocialBtnClick={handleSocialBtnClick}
    />
  );
};

export default SignUpPage;
