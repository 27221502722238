import { IStyles } from '../../interfaces/styles';

export const classes: IStyles = {
  root: {
    position: 'relative',
  },
  scrollContent: {
    position: 'absolute',
    height: '100%',
    inset: 0,
    overflowY: 'auto',
    scrollbarGutter: 'auto',
    '&::-webkit-scrollbar': {
      height: '14px',
      width: '24px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#FFFFFF4D',
      border: '8px solid transparent',
      backgroundClip: 'padding-box',
      borderRadius: '18px',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent #FFFFFF4D',
    zIndex: 1,
  },
};
