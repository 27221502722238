import React from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { IBackButton } from './interfaces';

import { classes } from './styles';

const BackButton = ({ onClick, sx = {}, t: translate }: IBackButton) => {
  const rootSx = { ...classes.root, ...sx };

  return (
    <Button onClick={onClick} sx={rootSx}>
      <ArrowBackIcon sx={classes.icon} fontSize="large" />
      <Typography sx={classes.text}>{translate('back')}</Typography>
    </Button>
  );
};

export default withTranslation('common')(BackButton);
