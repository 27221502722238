import { colors } from '../../../../../constants/colors';

export const classes = {
  root: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    textAlign: 'start',
    color: colors.white,
    backgroundColor: colors.myta,
    borderRadius: '4px',
    border: 'none',
    padding: '12px 16px',
    '& p': {
      margin: 0,
    },
  },
  quotedMessage: {
    padding: '10px',
    marginBottom: 1,
    backgroundColor: colors.mytaLight,
    '& .str-chat__message-attachment-card:first-of-type': {
      margin: 0,
    },
  },
  quotedText: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: colors.white,
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  messageText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: colors.white,
  },
};
