import { ILangResolution } from './interfaces';

export const languages: ILangResolution[] = [{
  label: 'English',
  deviceId: 'EN',
}, {
  label: 'Spanish',
  deviceId: 'ES',
}, {
  label: 'Portugal',
  deviceId: 'PT',
}];
