import React, { useCallback, useState } from 'react';
import { ImageListType } from 'react-images-uploading';

import UploadImageModalView from './UploadImageModalView';

import { useConference } from '../../../modules/dashboard/components/DashboardContent/Dolby';
import { useTakePhoto } from './hooks';

import { renameFileToUploadPattern } from './helpers';

import { IUploadImageModal } from './interfaces';

const UploadImageModal = ({
  open,
  setOpen,
  setUpdImage,
}: IUploadImageModal) => {
  const [showTakePhoto, setShowTakePhoto] = useState(false);

  const { cameras } = useConference();
  const { webcamRef, generatedPhoto, setWebcamPhoto } = useTakePhoto();

  const hasCamera = !!cameras;

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const handleChangeUploadImage = (imageList: ImageListType) => {
    const file = imageList[0].file as File;
    const reader = new FileReader();
    const renamedFile = renameFileToUploadPattern(file);

    reader.onloadend = () => {
      setUpdImage(file);
    };
    reader.readAsDataURL(renamedFile);
    handleClose();
  };

  const handleTakePhoto = async () => {
    await setWebcamPhoto();
    setUpdImage(generatedPhoto);
    setShowTakePhoto(false);
    handleClose();
  };

  return (
    <UploadImageModalView
      open={open}
      onClose={handleClose}
      webcamRef={webcamRef}
      showTakePhoto={showTakePhoto}
      setShowTakePhoto={setShowTakePhoto}
      onTakePhoto={handleTakePhoto}
      hasCamera={hasCamera}
      onChangeUploadImage={handleChangeUploadImage}
    />
  );
};

export default UploadImageModal;
