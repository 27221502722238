import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CrownIcon = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M15.5 0.5L11.3333 4.44737L8 0.5L4.66667 4.44737L0.5 0.5V10.7632H15.5V0.5ZM2.16667 15.5H13.8333C14.2754 15.5 14.6993 15.3336 15.0118 15.0375C15.3244 14.7414 15.5 14.3398 15.5 13.9211V12.3421H0.5V13.9211C0.5 14.3398 0.675595 14.7414 0.988155 15.0375C1.30072 15.3336 1.72464 15.5 2.16667 15.5Z"
      fill="#FFB800"
    />
  </SvgIcon>
);
