import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import PasswordRule from './PasswordRule';

import { PASSWORD_ERROR_MESSAGES } from './constants';

import { IPasswordValidation } from './interfaces';

import { useStyles } from './styles';

const { minLength, oneLowerCase, oneUpperCase, oneNumber, match } =
  PASSWORD_ERROR_MESSAGES;

const PasswordValidation = ({
  open,
  hasPasswordValue,
  isSubmitting,
  error,
  t: translate,
}: IPasswordValidation) => {
  const hasOneLowerCase = !error.includes(oneLowerCase);
  const hasOneUpperCase = !error.includes(oneUpperCase);
  const hasOneNumber = !error.includes(oneNumber);
  const isValidMinWidth = !error.includes(minLength);
  const isMatch = hasPasswordValue && !error.includes(match);

  const hasError =
    !hasOneLowerCase ||
    !hasOneUpperCase ||
    !hasOneNumber ||
    !isValidMinWidth ||
    !isMatch;
  const isSubmittingError = isSubmitting && hasError;

  const classes = useStyles({ isSubmittingError });

  if (open || isSubmittingError) {
    return (
      <Box sx={classes.root}>
        <PasswordRule
          text={translate('passwordValidation.oneLowerCase')}
          isCompleted={hasPasswordValue && hasOneLowerCase}
          isError={isSubmitting && !hasOneLowerCase}
        />
        <PasswordRule
          text={translate('passwordValidation.oneUpperCase')}
          isCompleted={hasPasswordValue && hasOneUpperCase}
          isError={isSubmitting && !hasOneUpperCase}
        />
        <PasswordRule
          text={translate('passwordValidation.oneNumber')}
          isCompleted={hasPasswordValue && hasOneNumber}
          isError={isSubmitting && !hasOneNumber}
        />
        <PasswordRule
          text={translate('passwordValidation.minCharacter')}
          isCompleted={hasPasswordValue && isValidMinWidth}
          isError={isSubmitting && !isValidMinWidth}
        />
        <PasswordRule
          text={translate('passwordValidation.passwordMatch')}
          isCompleted={isMatch}
          isError={isSubmitting && !isMatch}
        />
      </Box>
    );
  }

  return null;
};

export default withTranslation('common')(PasswordValidation);
