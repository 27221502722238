import { IStyles } from '../../interfaces/styles';

export const classes: IStyles = {
  root: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'block',
  },
};
