import { colors } from '../../../../../../../../constants/colors';

import { IFilesHeaderStyles } from './interfaces';

export const useStyles = ({
  hasPrevPage,
  hasNextPage,
  showPagination,
}: IFilesHeaderStyles) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.gray,
    padding: '12px 16px',
  },
  searchSection: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    width: showPagination ? 'auto' : '100%',
  },
  searchField: {
    width: showPagination ? 201 : '100%',
  },
  paginationSection: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '9px',
    color: colors.white,
    textAlign: 'center',
  },
  prevIcon: {
    opacity: hasPrevPage ? 1 : 0.3,
    cursor: 'pointer',
    width: 16,
    height: 16,
  },
  page: {
    color: colors.white,
    opacity: 0.8,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    width: 10,
  },
  separator: {
    color: colors.white,
    opacity: 0.4,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
  nextIcon: {
    opacity: hasNextPage ? 1 : 0.3,
    cursor: 'pointer',
    width: 16,
    height: 16,
  },
});
