import { useTheme } from '@mui/material';

import { COLORS } from '../../../../constants/colors';
import { CHAT_DRAWER_WIDTH } from '../../../../constants';
import { DEVICES_LANDSCAPE_SIZES } from '../../../../constants/devicesSizes';

import { IChatDrawerViewStyles } from './interfaces';

export const useStyles = ({
  isLoading,
  hasScreenMediaStreams,
}: IChatDrawerViewStyles) => {
  const { breakpoints } = useTheme();

  return {
    root: {
      '& .MuiPaper-root': {
        top: hasScreenMediaStreams ? '225px' : 0,
        display: 'grid',
        gridTemplateRows: isLoading ? 'auto 1fr' : 'auto auto 1fr',
        backgroundColor: COLORS.BACKGROUND_COLOR,
        backdropFilter: 'blur(12px)',
        width: CHAT_DRAWER_WIDTH,
        height: hasScreenMediaStreams ? 'calc(100vh - 225px)' : '100%',
        [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
          gridTemplateRows: 'auto 1fr',
          width: '100%',
          zIndex: 1500,
        },
      },
      position: 'static',
      '& a': {
        color: COLORS.WHITE,
      },
    },
    tabs: {
      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
        display: 'none',
      },
    },
  };
};
