import { COLORS } from '../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '39px 83px 33px 82px',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 667,
    background:
      'radial-gradient(45.47% 122.24% at 97.04% -19.65%, rgba(45, 52, 66, 0.9) 0%, rgba(11, 11, 13, 0.9) 100%)',
    border: '1px solid rgba(140, 140, 140, 0.5)',
    backdropFilter: 'blur(4px)',
    borderRadius: 1,
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 17,
    color: '#B1B1B1',
    fontSize: 16,
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    background: COLORS.WHITE,
    backdropFilter: 'blur(6px)',
    borderRadius: 9,
    padding: 1,
    '& svg': {
      color: '#000000',
      width: 24,
      height: 24,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '36px',
    letterSpacing: '-0.25px',
    color: COLORS.WHITE,
    marginTop: '9px',
  },
  description: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '30px',
    letterSpacing: '-0.25px',
    color: '#A6B1C9',
    marginTop: '9px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '33px',
    marginTop: '44px',
  },
  button: {
    padding: '4px',
    width: 152,
    borderRadius: '8px',
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '30px',
    letterSpacing: '-0.25px',
    color: COLORS.WHITE,
  },
  stayButton: {
    border: `1px solid ${COLORS.WHITE}`,
    '&:hover': {
      backgroundColor: COLORS.WHITE,
      color: '#1C1C1C',
    },
  },
  exitButton: {
    border: '1px solid #FC5151',
    '&:hover': {
      backgroundColor: COLORS.ERROR,
      color: COLORS.WHITE,
      border: `1px solid ${COLORS.ERROR}`,
    },
  },
});
