import { colors } from '../../../../../constants/colors';

export const useStyles = (isOpenChat: boolean) => ({
  fullscreen: {
    display: 'none',
    position: 'absolute',
    top: 180,
    right: 20,
    zIndex: isOpenChat ? 1323 : 343443434334433,
    '& svg': {
      color: colors.myta,
    },
  },
});
