import React from 'react';

import { Box } from '@mui/material';

import ToolItem from './ToolItem';

import { useHandleToolClick, useTools } from './hooks';

import { useStyles } from './styles';

const Tools = () => {
  const classes = useStyles();

  const tools = useTools();
  const handleToolClick = useHandleToolClick();

  return (
    <Box sx={classes.root}>
      {tools.map(({ icon, name, description, action }) => (
        <ToolItem
          key={name}
          icon={icon}
          name={name}
          description={description}
          onClick={() => handleToolClick(action)}
        />
      ))}
    </Box>
  );
};

export default Tools;
