import React from 'react';
import { useNavigate } from 'react-router-dom';

import ReadyPlayerMeView from './ReadyPlayerMeView';

import { useSubscribeToReadyPlayerMeEvents } from './hooks';

import ROUTES from '../../../../constants/routes';

const ReadyPlayerMe = () => {
  const navigate = useNavigate();
  const frameRef = useSubscribeToReadyPlayerMeEvents();

  const handleBackBtnClick = () => {
    navigate(ROUTES.AVATAR_CHOOSING);
  };

  return (
    <ReadyPlayerMeView
      frameRef={frameRef}
      onBackBtnClick={handleBackBtnClick}
    />
  );
};

export default ReadyPlayerMe;
