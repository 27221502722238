import { COLORS } from '../../../../constants/colors';

export const classes = {
  button: {
    display: 'flex',
    justifyContent: 'center',
    mt: 0.5,
    columnGap: 2,
    '& button': {
      color: COLORS.WHITE,
      textTransform: 'capitalize',
      width: 'auto',
      height: 33,
      background: COLORS.PROFILE_BACKGROUND_GREY,
      '& svg': {
        color: COLORS.RED,
      },
    },
  },
};
