import React from 'react';

import { Box } from '@mui/material';

import AppLogo from '../AppLogo';
import EventsDropdown from './EventsDropdown';
import UserAvatarButton from '../UserAvatarButton';
import LogoutButton from '../LogoutButton';
import LanguageButton from '../LanguageButton';

import ROUTES from '../../constants/routes';

import { IAppHeader } from './interfaces';

import { useStyles } from './styles';

const AppHeader = ({
  isAuthorized = false,
  showEventsDropdown = false,
}: IAppHeader) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <Box sx={classes.box}>
        <AppLogo to={isAuthorized ? ROUTES.SELECT_EVENT : ROUTES.LOGIN} />
      </Box>
      {showEventsDropdown && (
        <EventsDropdown
          sx={classes.eventsDropdown}
          menuProps={{ MenuListProps: { sx: classes.menuList } }}
        />
      )}
      {isAuthorized ? (
        <Box sx={classes.actionsContainer}>
          <UserAvatarButton />
          <LogoutButton sx={classes.logoutButton} />
        </Box>
      ) : (
        <LanguageButton />
      )}
    </Box>
  );
};

export default AppHeader;
