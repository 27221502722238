import { create } from 'zustand';

import { IChatState } from './interfaces';
import { ChatTabs } from '../interfaces/channel';

const initialChangeEventChatState = {
  open: false,
  initialLoading: true,
  activeTab: ChatTabs.Global,
  activePrivateChannel: null,
};

const initialChatState = {
  streamClient: null,
  eventStreamChannel: null,
  privateChannels: [],
  isUnreadEventChannel: false,
  isUnreadPrivateChannel: false,
};

export const useChatState = create<IChatState>((set) => ({
  ...initialChangeEventChatState,
  ...initialChatState,
  setOpen: (open) => set({ open }),
  setStreamClient: (streamClient) => set({ streamClient }),
  setEventStreamChannel: (eventStreamChannel) => set({ eventStreamChannel }),
  setPrivateChannels: (privateChannels) => set({ privateChannels }),
  addPrivateChannel: (newPrivateChannel) =>
    set(({ privateChannels }) => {
      const isAlreadyExists = privateChannels.find(
        (prevChannel) => prevChannel.id === newPrivateChannel.id,
      );

      if (isAlreadyExists) return { privateChannels };

      return { privateChannels: [newPrivateChannel, ...privateChannels] };
    }),
  setIsUnreadEventChannel: (isUnread) =>
    set({ isUnreadEventChannel: isUnread }),
  setIsUnreadPrivateChannel: (isUnread) =>
    set({ isUnreadPrivateChannel: isUnread }),
  setInitialLoading: (initialLoading) => set({ initialLoading }),
  setActiveTab: (activeTab) => set({ activeTab }),
  setActivePrivateChannel: (privateChannel) =>
    set({ activePrivateChannel: privateChannel }),
  resetState: () =>
    set(({ streamClient }) => ({
      ...initialChatState,
      ...initialChangeEventChatState,
      streamClient,
    })),
  resetChangeEventChatState: () =>
    set((state) => ({ ...state, ...initialChangeEventChatState })),
}));
