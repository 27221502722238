import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DownloadIcon from '@mui/icons-material/Download';

import ToolboxHeader from '../../../ToolboxHeader';
import ScrollableContainer from '../../../../../../../components/ScrollableContainer';

import { useMytaverseEvent } from '../../../../../providers';

import { formatTime, getESTDate } from '../../../../../../../helpers/datetime';
import { downloadImage } from '../../../../../../../helpers/download';

import { IAgenda } from './interfaces';

import { classes } from './styles';

const Agenda = ({ context }: IAgenda) => {
  const { currentEvent } = useMytaverseEvent();
  const { t: translate } = useTranslation('common');

  const handleClick = () => {
    downloadImage(currentEvent?.agendaImage || '');
  };

  return (
    <Box sx={classes.root}>
      <ToolboxHeader {...context} />
      <Box sx={classes.header}>
        <Typography sx={classes.time}>
          <ScheduleIcon />
          {formatTime(getESTDate())} EST
        </Typography>
        <Button
          sx={classes.button}
          disableRipple
          variant="text"
          onClick={handleClick}
        >
          <DownloadIcon />
          {translate('toolbox.downloadAgenda')}
        </Button>
      </Box>
      <ScrollableContainer
        sxProps={{ root: classes.image, content: classes.imageContent }}
      >
        <img
          src={currentEvent?.agendaImage}
          alt={translate('tooltip.agenda')}
        />
      </ScrollableContainer>
    </Box>
  );
};

export default Agenda;
