import React, { SyntheticEvent } from 'react';

import ChatTabsView from './ChatTabsView';

import { useChatState } from '../../../../../hooks/context';

import { IChatTabs } from './interfaces';
import { ChatTabs as ChatTabsEnum } from '../../../../../interfaces/channel';

const ChatTabs = ({ sx = {} }: IChatTabs) => {
  const {
    activeTab,
    setActiveTab,
    isUnreadEventChannel,
    isUnreadPrivateChannel,
  } = useChatState();

  const handleChangeTab = (_: SyntheticEvent, value: ChatTabsEnum) => {
    setActiveTab(value);
  };

  return (
    <ChatTabsView
      sx={sx}
      activeTab={activeTab}
      onChangeTab={handleChangeTab}
      isUnreadEventChannel={isUnreadEventChannel}
      isUnreadPrivateChannel={isUnreadPrivateChannel}
    />
  );
};

export default ChatTabs;
