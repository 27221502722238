import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MytaverseLogoWhite = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 167 30"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.2015 9.94663H49.3481C49.5378 9.94663 49.6903 10.0991 49.6903 10.2888V11.5067C50.8457 10.15 52.4625 9.60059 53.79 9.60059C55.7255 9.60059 57.2543 10.5253 58.1204 12.0835C59.2171 10.496 61.1526 9.60059 63.1741 9.60059C66.4371 9.60059 68.4879 11.8235 68.4879 15.3758V23.6964C68.4879 23.886 68.3354 24.0385 68.1458 24.0385H66.1732C65.9835 24.0385 65.831 23.886 65.831 23.6964V15.9525C65.831 13.5556 64.6463 12.0542 62.5681 12.0542C60.8359 12.0542 58.9865 13.2389 58.9865 15.9818V23.6964C58.9865 23.886 58.834 24.0385 58.6443 24.0385H56.701C56.5114 24.0385 56.3589 23.886 56.3589 23.6964V15.9525C56.3589 13.5263 55.0881 12.0542 52.9513 12.0542C51.2484 12.0542 49.4869 13.2389 49.4869 16.1539V23.6944C49.4869 23.8841 49.3344 24.0366 49.1448 24.0366H47.2015C47.0119 24.0366 46.8594 23.8841 46.8594 23.6944V10.2868C46.8594 10.0991 47.0138 9.94663 47.2015 9.94663Z"
      fill="#F0F7F7"
    />
    <path
      d="M72.963 10.1604L77.1214 20.3715L81.1937 10.1623C81.2465 10.0333 81.3716 9.94727 81.5104 9.94727H83.616C83.8604 9.94727 84.0266 10.1956 83.9308 10.4204L76.1087 29.0988C76.0559 29.2259 75.9308 29.308 75.7939 29.308H73.7138C73.4674 29.308 73.3012 29.0558 73.401 28.829L75.2387 24.6022C75.5554 23.8769 75.5593 23.0519 75.2524 22.3226L70.2455 10.4204C70.1517 10.1956 70.3159 9.94531 70.5603 9.94531H72.6463C72.7871 9.94727 72.9122 10.0313 72.963 10.1604Z"
      fill="#F0F7F7"
    />
    <path
      d="M86.1831 9.94681V6.75229C86.1831 6.56265 86.3356 6.41016 86.5252 6.41016H88.4685C88.6582 6.41016 88.8107 6.56265 88.8107 6.75229V9.94681H92.6504C92.84 9.94681 92.9925 10.0993 92.9925 10.2889V11.9155C92.9925 12.1052 92.84 12.2577 92.6504 12.2577H88.8107V19.3329C88.8107 20.9497 89.6201 21.7298 91.1215 21.7298H93.0414C93.231 21.7298 93.3835 21.8823 93.3835 22.0719V23.6985C93.3835 23.8882 93.231 24.0407 93.0414 24.0407H90.8029C87.7413 24.0407 86.1831 22.5978 86.1831 19.5069V12.2596V9.94681V9.94681Z"
      fill="#F0F7F7"
    />
    <path
      d="M94.9492 17.0219C94.9492 12.7482 98.0382 9.60059 102.081 9.60059C104.132 9.60059 105.864 10.4373 107.106 11.7961V10.2907C107.106 10.1011 107.258 9.94858 107.448 9.94858H109.42C109.61 9.94858 109.763 10.1011 109.763 10.2907V23.6983C109.763 23.888 109.61 24.0405 109.42 24.0405H107.448C107.258 24.0405 107.106 23.888 107.106 23.6983V22.191C105.864 23.5478 104.132 24.3865 102.081 24.3865C98.0382 24.3846 94.9492 21.2956 94.9492 17.0219ZM102.312 21.843C105.084 21.843 107.106 19.8802 107.106 16.9926C107.106 14.191 105.084 12.1128 102.312 12.1128C99.6257 12.1128 97.5768 14.2497 97.5768 16.9926C97.5768 19.7648 99.6257 21.843 102.312 21.843Z"
      fill="#F0F7F7"
    />
    <path
      d="M119.726 24.0392H117.444C117.305 24.0392 117.18 23.9551 117.127 23.8261L111.714 10.4184C111.624 10.1936 111.788 9.94922 112.031 9.94922H114.263C114.404 9.94922 114.531 10.0352 114.582 10.1682L118.656 20.6628L122.731 10.1682C122.781 10.0372 122.908 9.94922 123.049 9.94922H125.166C125.409 9.94922 125.575 10.1956 125.483 10.4204L120.042 23.828C119.99 23.9551 119.864 24.0392 119.726 24.0392Z"
      fill="#F0F7F7"
    />
    <path
      d="M126.598 17.1079C126.598 12.8049 130.005 9.60059 133.904 9.60059C137.773 9.60059 140.719 12.5742 140.719 16.3865C140.719 16.6113 140.701 16.9085 140.666 17.2604C140.648 17.4344 140.502 17.5693 140.326 17.5693H129.169C129.399 20.3122 131.421 22.1324 133.992 22.1324C135.777 22.1324 137.35 21.2194 137.849 19.8391C137.898 19.7062 138.025 19.6201 138.168 19.6201H140.093C140.324 19.6201 140.492 19.845 140.42 20.0639C139.597 22.6016 137.094 24.3846 133.818 24.3846C129.571 24.3846 126.598 21.1216 126.598 17.1079ZM138.003 15.3758C137.859 13.4129 136.156 11.8821 133.904 11.8821C131.796 11.8821 129.976 13.2389 129.37 15.3758H138.003Z"
      fill="#F0F7F7"
    />
    <path
      d="M146.664 11.8534C147.473 10.6687 148.656 9.94727 150.187 9.94727H151.346C151.536 9.94727 151.688 10.0998 151.688 10.2894V11.916C151.688 12.1056 151.536 12.2581 151.346 12.2581H150.071C147.964 12.2581 146.664 13.6442 146.664 15.8964V23.697C146.664 23.8867 146.511 24.0392 146.322 24.0392H144.349C144.159 24.0392 144.007 23.8867 144.007 23.697V10.2894C144.007 10.0998 144.159 9.94727 144.349 9.94727H146.322C146.511 9.94727 146.664 10.0998 146.664 10.2894V11.8534Z"
      fill="#F0F7F7"
    />
    <path
      d="M154.181 19.7941H156.042C156.208 19.7941 156.347 19.9134 156.378 20.0757C156.637 21.4403 157.808 22.279 159.487 22.279C161.248 22.279 162.375 21.3836 162.375 20.1128C162.375 16.7052 154.058 19.0454 154.058 13.8469C154.058 11.19 156.31 9.60254 159.168 9.60254C161.991 9.60254 164.111 10.9554 164.592 13.4129C164.633 13.624 164.468 13.8195 164.255 13.8195H162.33C162.177 13.8195 162.042 13.7179 162.003 13.5713C161.7 12.4569 160.623 11.6553 159.17 11.6553C157.813 11.6553 156.543 12.2321 156.543 13.5908C156.543 16.7384 164.945 14.3122 164.945 19.8567C164.945 22.5136 162.693 24.3904 159.46 24.3904C156.402 24.3904 154.236 22.8655 153.845 20.193C153.811 19.9799 153.972 19.7941 154.181 19.7941Z"
      fill="#F0F7F7"
    />
    <path
      d="M167.628 17.1079C167.628 12.8049 171.036 9.60059 174.934 9.60059C178.803 9.60059 181.749 12.5742 181.749 16.3865C181.749 16.6113 181.732 16.9085 181.696 17.2604C181.679 17.4344 181.532 17.5693 181.356 17.5693H170.199C170.429 20.3122 172.451 22.1324 175.022 22.1324C176.807 22.1324 178.381 21.2194 178.879 19.8391C178.928 19.7062 179.055 19.6201 179.198 19.6201H181.124C181.354 19.6201 181.522 19.845 181.45 20.0639C180.627 22.6016 178.125 24.3846 174.848 24.3846C170.602 24.3846 167.628 21.1216 167.628 17.1079ZM179.034 15.3758C178.889 13.4129 177.186 11.8821 174.934 11.8821C172.826 11.8821 171.006 13.2389 170.4 15.3758H179.034Z"
      fill="#F0F7F7"
    />
    <path
      d="M9.93092 4.68215C10.9027 4.68215 11.6904 3.89438 11.6904 2.92262C11.6904 1.95085 10.9027 1.16309 9.93092 1.16309C8.95916 1.16309 8.17139 1.95085 8.17139 2.92262C8.17139 3.89438 8.95916 4.68215 9.93092 4.68215Z"
      fill="#4554E5"
    />
    <path
      d="M24.0071 4.68215C24.9789 4.68215 25.7666 3.89438 25.7666 2.92262C25.7666 1.95085 24.9789 1.16309 24.0071 1.16309C23.0353 1.16309 22.2476 1.95085 22.2476 2.92262C22.2476 3.89438 23.0353 4.68215 24.0071 4.68215Z"
      fill="#4554E5"
    />
    <path
      d="M2.89283 11.7212C3.86459 11.7212 4.65236 10.9334 4.65236 9.96168C4.65236 8.98992 3.86459 8.20215 2.89283 8.20215C1.92107 8.20215 1.1333 8.98992 1.1333 9.96168C1.1333 10.9334 1.92107 11.7212 2.89283 11.7212Z"
      fill="#4554E5"
    />
    <path
      d="M31.0452 11.7212C32.0169 11.7212 32.8047 10.9334 32.8047 9.96168C32.8047 8.98992 32.0169 8.20215 31.0452 8.20215C30.0734 8.20215 29.2856 8.98992 29.2856 9.96168C29.2856 10.9334 30.0734 11.7212 31.0452 11.7212Z"
      fill="#4554E5"
    />
    <path
      d="M16.969 19.2476C18.2107 19.2476 19.2173 18.241 19.2173 16.9993C19.2173 15.7576 18.2107 14.751 16.969 14.751C15.7273 14.751 14.7207 15.7576 14.7207 16.9993C14.7207 18.241 15.7273 19.2476 16.969 19.2476Z"
      fill="#4554E5"
    />
    <path
      d="M2.89283 25.7974C3.86459 25.7974 4.65236 25.0096 4.65236 24.0379C4.65236 23.0661 3.86459 22.2783 2.89283 22.2783C1.92107 22.2783 1.1333 23.0661 1.1333 24.0379C1.1333 25.0096 1.92107 25.7974 2.89283 25.7974Z"
      fill="#4554E5"
    />
    <path
      d="M31.0452 25.7974C32.0169 25.7974 32.8047 25.0096 32.8047 24.0379C32.8047 23.0661 32.0169 22.2783 31.0452 22.2783C30.0734 22.2783 29.2856 23.0661 29.2856 24.0379C29.2856 25.0096 30.0734 25.7974 31.0452 25.7974Z"
      fill="#4554E5"
    />
    <path
      d="M9.93092 32.8355C10.9027 32.8355 11.6904 32.0477 11.6904 31.0759C11.6904 30.1042 10.9027 29.3164 9.93092 29.3164C8.95916 29.3164 8.17139 30.1042 8.17139 31.0759C8.17139 32.0477 8.95916 32.8355 9.93092 32.8355Z"
      fill="#4554E5"
    />
    <path
      d="M24.0071 32.8355C24.9789 32.8355 25.7666 32.0477 25.7666 31.0759C25.7666 30.1042 24.9789 29.3164 24.0071 29.3164C23.0353 29.3164 22.2476 30.1042 22.2476 31.0759C22.2476 32.0477 23.0353 32.8355 24.0071 32.8355Z"
      fill="#4554E5"
    />
    <path
      d="M14.0679 24.037C14.0679 26.0566 16.0601 26.4671 16.0601 28.0605C16.0601 29.3879 14.7189 29.5756 14.7189 31.0751C14.7189 32.3166 15.7257 33.3234 16.9672 33.3234C18.2086 33.3234 19.2155 32.3166 19.2155 31.0751C19.2155 29.5756 17.8743 29.3879 17.8743 28.0605C17.8743 26.8875 19.8196 24.9422 20.9906 24.9422C22.3181 24.9422 22.5058 26.2833 24.0053 26.2833C25.2468 26.2833 26.2536 25.2765 26.2536 24.0351C26.2536 22.7936 25.2468 21.7868 24.0053 21.7868C22.5058 21.7868 22.3181 23.1279 20.9906 23.1279C19.3973 23.1279 18.9867 21.1357 16.9672 21.1357C15.366 21.1377 14.0679 22.4358 14.0679 24.037Z"
      fill="#4554E5"
    />
    <path
      d="M24.0072 19.8986C26.0268 19.8986 26.4373 17.9064 28.0307 17.9064C29.3582 17.9064 29.5458 19.2476 31.0454 19.2476C32.2868 19.2476 33.2936 18.2408 33.2936 16.9993C33.2936 15.7579 32.2868 14.751 31.0454 14.751C29.5458 14.751 29.3582 16.0922 28.0307 16.0922C26.8577 16.0922 24.9124 14.1469 24.9124 12.9758C24.9124 11.6484 26.2536 11.4607 26.2536 9.96118C26.2536 8.71973 25.2467 7.71289 24.0053 7.71289C22.7638 7.71289 21.757 8.71973 21.757 9.96118C21.757 11.4607 23.0981 11.6484 23.0981 12.9758C23.0981 14.5692 21.106 14.9798 21.106 16.9993C21.106 18.6005 22.4041 19.8986 24.0072 19.8986Z"
      fill="#4554E5"
    />
    <path
      d="M19.8683 9.96122C19.8683 7.94167 17.8762 7.53111 17.8762 5.93776C17.8762 4.61029 19.2173 4.42261 19.2173 2.92309C19.2173 1.68165 18.2105 0.674805 16.969 0.674805C15.7276 0.674805 14.7207 1.68165 14.7207 2.92309C14.7207 4.42261 16.0619 4.61029 16.0619 5.93776C16.0619 7.11078 14.1166 9.05604 12.9456 9.05604C11.6181 9.05604 11.4304 7.71488 9.93091 7.71488C8.68946 7.71488 7.68262 8.72173 7.68262 9.96317C7.68262 11.2046 8.68946 12.2115 9.93091 12.2115C11.4304 12.2115 11.6181 10.8703 12.9456 10.8703C14.5389 10.8703 14.9495 12.8625 16.969 12.8625C18.5702 12.8605 19.8683 11.5624 19.8683 9.96122Z"
      fill="#4554E5"
    />
    <path
      d="M9.93095 14.1006C7.91139 14.1006 7.50084 16.0928 5.90748 16.0928C4.58002 16.0928 4.39233 14.7516 2.89282 14.7516C1.65137 14.7516 0.644531 15.7585 0.644531 16.9999C0.644531 18.2413 1.65137 19.2482 2.89282 19.2482C4.39233 19.2482 4.58002 17.907 5.90748 17.907C7.0805 17.907 9.02577 19.8523 9.02577 21.0234C9.02577 22.3508 7.68461 22.5385 7.68461 24.038C7.68461 25.2795 8.69145 26.2863 9.9329 26.2863C11.1743 26.2863 12.1812 25.2795 12.1812 24.038C12.1812 22.5385 10.84 22.3508 10.84 21.0234C10.84 19.43 12.8322 19.0195 12.8322 16.9999C12.8303 15.3987 11.5321 14.1006 9.93095 14.1006Z"
      fill="#4554E5"
    />
    <path
      d="M181.817 8.21777H183.004V8.5384H182.59V9.96948H182.23V8.5384H181.819V8.21777H181.817ZM183.239 8.21777H183.729L184.22 9.4299L184.705 8.21777H185.18V9.96948H184.844V8.69285L184.334 9.96948H184.083L183.573 8.67721V9.96948H183.237V8.21777H183.239Z"
      fill="#0D0F33"
    />
  </SvgIcon>
);
