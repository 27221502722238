import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MytaverseLogoIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 167 30"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.1574 8.65162H44.0885C44.2591 8.65162 44.3963 8.78886 44.3963 8.95954V10.0557C45.4357 8.83461 46.8902 8.34018 48.0844 8.34018C49.8256 8.34018 51.2009 9.17244 51.98 10.5748C52.9667 9.14605 54.7078 8.34018 56.5264 8.34018C59.4618 8.34018 61.3067 10.3408 61.3067 13.5378V21.0264C61.3067 21.1971 61.1695 21.3343 60.9989 21.3343H59.2243C59.0537 21.3343 58.9165 21.1971 58.9165 21.0264V14.0569C58.9165 11.8997 57.8507 10.5484 55.9812 10.5484C54.4229 10.5484 52.7591 11.6147 52.7591 14.0833V21.0264C52.7591 21.1971 52.622 21.3343 52.4514 21.3343H50.7032C50.5326 21.3343 50.3954 21.1971 50.3954 21.0264V14.0569C50.3954 11.8733 49.2522 10.5484 47.3299 10.5484C45.798 10.5484 44.2134 11.6147 44.2134 14.2381V21.0246C44.2134 21.1953 44.0762 21.3326 43.9056 21.3326H42.1574C41.9868 21.3326 41.8496 21.1953 41.8496 21.0246V8.95778C41.8496 8.78886 41.9886 8.65162 42.1574 8.65162Z"
      fill="currentColor"
    />
    <path
      d="M65.3325 8.84342L69.0734 18.0335L72.7369 8.84518C72.7844 8.72905 72.8969 8.65163 73.0218 8.65163H74.916C75.1358 8.65163 75.2853 8.87509 75.1991 9.07744L68.1624 25.888C68.1149 26.0024 68.0023 26.0763 67.8792 26.0763H66.0079C65.7863 26.0763 65.6368 25.8493 65.7265 25.6452L67.3797 21.8411C67.6646 21.1883 67.6681 20.4458 67.392 19.7895L62.8879 9.07744C62.8034 8.87509 62.9512 8.64987 63.171 8.64987H65.0476C65.1742 8.65163 65.2868 8.72729 65.3325 8.84342Z"
      fill="currentColor"
    />
    <path
      d="M77.2251 8.65162V5.77655C77.2251 5.60587 77.3623 5.46863 77.5329 5.46863H79.2811C79.4517 5.46863 79.5889 5.60587 79.5889 5.77655V8.65162H83.0431C83.2137 8.65162 83.3508 8.78886 83.3508 8.95954V10.4235C83.3508 10.5941 83.2137 10.7314 83.0431 10.7314H79.5889V17.0991C79.5889 18.5543 80.317 19.2563 81.6677 19.2563H83.3948C83.5654 19.2563 83.7026 19.3936 83.7026 19.5642V21.0282C83.7026 21.1988 83.5654 21.3361 83.3948 21.3361H81.381C78.6268 21.3361 77.2251 20.0375 77.2251 17.2557V10.7331V8.65162Z"
      fill="currentColor"
    />
    <path
      d="M85.1113 15.0194C85.1113 11.173 87.8902 8.34018 91.5273 8.34018C93.3722 8.34018 94.9304 9.09326 96.0473 10.3161V8.96129C96.0473 8.79062 96.1844 8.65338 96.355 8.65338H98.1296C98.3002 8.65338 98.4374 8.79062 98.4374 8.96129V21.0282C98.4374 21.1988 98.3002 21.3361 98.1296 21.3361H96.355C96.1844 21.3361 96.0473 21.1988 96.0473 21.0282V19.6716C94.9304 20.8927 93.3722 21.6475 91.5273 21.6475C87.8902 21.6458 85.1113 18.8657 85.1113 15.0194ZM91.7348 19.3584C94.2287 19.3584 96.0473 17.5918 96.0473 14.993C96.0473 12.4716 94.2287 10.6012 91.7348 10.6012C89.3183 10.6012 87.4751 12.5243 87.4751 14.993C87.4751 17.488 89.3183 19.3584 91.7348 19.3584Z"
      fill="currentColor"
    />
    <path
      d="M107.4 21.3343H105.348C105.223 21.3343 105.11 21.2587 105.063 21.1425L100.193 9.07567C100.112 8.87332 100.259 8.65338 100.478 8.65338H102.486C102.613 8.65338 102.727 8.7308 102.773 8.85045L106.438 18.2956L110.103 8.85045C110.149 8.73256 110.263 8.65338 110.39 8.65338H112.295C112.513 8.65338 112.662 8.87508 112.58 9.07743L107.685 21.1443C107.637 21.2587 107.525 21.3343 107.4 21.3343Z"
      fill="currentColor"
    />
    <path
      d="M113.582 15.0968C113.582 11.2241 116.648 8.34018 120.154 8.34018C123.635 8.34018 126.286 11.0164 126.286 14.4475C126.286 14.6499 126.27 14.9173 126.238 15.234C126.222 15.3906 126.09 15.512 125.932 15.512H115.895C116.102 17.9806 117.921 19.6188 120.234 19.6188C121.839 19.6188 123.255 18.7971 123.704 17.5548C123.748 17.4352 123.862 17.3578 123.99 17.3578H125.723C125.93 17.3578 126.081 17.5601 126.016 17.7572C125.276 20.0411 123.025 21.6458 120.077 21.6458C116.257 21.6458 113.582 18.7091 113.582 15.0968ZM123.843 13.5378C123.712 11.7713 122.181 10.3936 120.154 10.3936C118.259 10.3936 116.621 11.6147 116.076 13.5378H123.843Z"
      fill="currentColor"
    />
    <path
      d="M131.634 10.3672C132.362 9.30088 133.426 8.65161 134.803 8.65161H135.846C136.017 8.65161 136.154 8.78885 136.154 8.95953V10.4235C136.154 10.5941 136.017 10.7314 135.846 10.7314H134.699C132.803 10.7314 131.634 11.9789 131.634 14.0059V21.0264C131.634 21.1971 131.497 21.3343 131.326 21.3343H129.551C129.381 21.3343 129.244 21.1971 129.244 21.0264V8.95953C129.244 8.78885 129.381 8.65161 129.551 8.65161H131.326C131.497 8.65161 131.634 8.78885 131.634 8.95953V10.3672Z"
      fill="currentColor"
    />
    <path
      d="M138.396 17.5144H140.071C140.22 17.5144 140.345 17.6217 140.373 17.7678C140.605 18.9959 141.659 19.7507 143.17 19.7507C144.754 19.7507 145.767 18.9449 145.767 17.8012C145.767 14.7343 138.285 16.8405 138.285 12.1619C138.285 9.77069 140.312 8.34195 142.883 8.34195C145.423 8.34195 147.329 9.55955 147.762 11.7713C147.799 11.9613 147.651 12.1373 147.459 12.1373H145.727C145.59 12.1373 145.468 12.0458 145.433 11.9138C145.16 10.9109 144.191 10.1895 142.885 10.1895C141.664 10.1895 140.521 10.7085 140.521 11.9314C140.521 14.7642 148.08 12.5807 148.08 17.5707C148.08 19.9619 146.054 21.651 143.145 21.651C140.394 21.651 138.446 20.2786 138.094 17.8733C138.064 17.6815 138.208 17.5144 138.396 17.5144Z"
      fill="currentColor"
    />
    <path
      d="M150.493 15.0968C150.493 11.2241 153.558 8.34018 157.065 8.34018C160.546 8.34018 163.196 11.0164 163.196 14.4475C163.196 14.6499 163.181 14.9173 163.149 15.234C163.133 15.3906 163.001 15.512 162.843 15.512H152.806C153.013 17.9806 154.832 19.6188 157.145 19.6188C158.75 19.6188 160.166 18.7971 160.615 17.5548C160.659 17.4352 160.773 17.3578 160.901 17.3578H162.634C162.841 17.3578 162.992 17.5601 162.927 17.7572C162.187 20.0411 159.936 21.6458 156.988 21.6458C153.168 21.6458 150.493 18.7091 150.493 15.0968ZM160.753 13.5378C160.623 11.7713 159.091 10.3936 157.065 10.3936C155.169 10.3936 153.532 11.6147 152.987 13.5378H160.753Z"
      fill="currentColor"
    />
    <path
      d="M8.62853 3.91496C9.50273 3.91496 10.2114 3.20597 10.2114 2.33138C10.2114 1.45679 9.50273 0.747803 8.62853 0.747803C7.75433 0.747803 7.04565 1.45679 7.04565 2.33138C7.04565 3.20597 7.75433 3.91496 8.62853 3.91496Z"
      fill="#4555E5"
    />
    <path
      d="M21.2916 3.91496C22.1658 3.91496 22.8745 3.20597 22.8745 2.33138C22.8745 1.45679 22.1658 0.747803 21.2916 0.747803C20.4174 0.747803 19.7087 1.45679 19.7087 2.33138C19.7087 3.20597 20.4174 3.91496 21.2916 3.91496Z"
      fill="#4555E5"
    />
    <path
      d="M2.29699 10.2493C3.17119 10.2493 3.87986 9.54029 3.87986 8.6657C3.87986 7.79111 3.17119 7.08212 2.29699 7.08212C1.42279 7.08212 0.714111 7.79111 0.714111 8.6657C0.714111 9.54029 1.42279 10.2493 2.29699 10.2493Z"
      fill="#4555E5"
    />
    <path
      d="M27.6229 10.2493C28.4971 10.2493 29.2058 9.54029 29.2058 8.6657C29.2058 7.79111 28.4971 7.08212 27.6229 7.08212C26.7487 7.08212 26.04 7.79111 26.04 8.6657C26.04 9.54029 26.7487 10.2493 27.6229 10.2493Z"
      fill="#4555E5"
    />
    <path
      d="M14.9601 17.0235C16.0771 17.0235 16.9826 16.1175 16.9826 15C16.9826 13.8825 16.0771 12.9765 14.9601 12.9765C13.843 12.9765 12.9375 13.8825 12.9375 15C12.9375 16.1175 13.843 17.0235 14.9601 17.0235Z"
      fill="#4555E5"
    />
    <path
      d="M2.29699 22.9179C3.17119 22.9179 3.87986 22.2089 3.87986 21.3343C3.87986 20.4597 3.17119 19.7507 2.29699 19.7507C1.42279 19.7507 0.714111 20.4597 0.714111 21.3343C0.714111 22.2089 1.42279 22.9179 2.29699 22.9179Z"
      fill="#4555E5"
    />
    <path
      d="M27.6229 22.9179C28.4971 22.9179 29.2058 22.2089 29.2058 21.3343C29.2058 20.4597 28.4971 19.7507 27.6229 19.7507C26.7487 19.7507 26.04 20.4597 26.04 21.3343C26.04 22.2089 26.7487 22.9179 27.6229 22.9179Z"
      fill="#4555E5"
    />
    <path
      d="M8.62853 29.2522C9.50273 29.2522 10.2114 28.5432 10.2114 27.6686C10.2114 26.794 9.50273 26.0851 8.62853 26.0851C7.75433 26.0851 7.04565 26.794 7.04565 27.6686C7.04565 28.5432 7.75433 29.2522 8.62853 29.2522Z"
      fill="#4555E5"
    />
    <path
      d="M21.2916 29.2522C22.1658 29.2522 22.8745 28.5432 22.8745 27.6686C22.8745 26.794 22.1658 26.0851 21.2916 26.0851C20.4174 26.0851 19.7087 26.794 19.7087 27.6686C19.7087 28.5432 20.4174 29.2522 21.2916 29.2522Z"
      fill="#4555E5"
    />
    <path
      d="M12.3501 21.3343C12.3501 23.1519 14.1423 23.5214 14.1423 24.9554C14.1423 26.1502 12.9358 26.3191 12.9358 27.6686C12.9358 28.7859 13.8415 29.6921 14.9583 29.6921C16.0751 29.6921 16.9809 28.7859 16.9809 27.6686C16.9809 26.3191 15.7744 26.1502 15.7744 24.9554C15.7744 23.8997 17.5243 22.149 18.5778 22.149C19.772 22.149 19.9409 23.356 21.2898 23.356C22.4066 23.356 23.3124 22.4499 23.3124 21.3326C23.3124 20.2153 22.4066 19.3091 21.2898 19.3091C19.9409 19.3091 19.772 20.5161 18.5778 20.5161C17.1445 20.5161 16.7751 18.7232 14.9583 18.7232C13.5179 18.7249 12.3501 19.8933 12.3501 21.3343Z"
      fill="#4555E5"
    />
    <path
      d="M21.2914 17.6094C23.1082 17.6094 23.4775 15.8164 24.9109 15.8164C26.1051 15.8164 26.2739 17.0235 27.6229 17.0235C28.7397 17.0235 29.6455 16.1173 29.6455 15C29.6455 13.8827 28.7397 12.9766 27.6229 12.9766C26.2739 12.9766 26.1051 14.1836 24.9109 14.1836C23.8556 14.1836 22.1057 12.4329 22.1057 11.3789C22.1057 10.1842 23.3122 10.0153 23.3122 8.6657C23.3122 7.5484 22.4064 6.64224 21.2896 6.64224C20.1728 6.64224 19.2671 7.5484 19.2671 8.6657C19.2671 10.0153 20.4736 10.1842 20.4736 11.3789C20.4736 12.8129 18.6814 13.1824 18.6814 15C18.6814 16.4411 19.8492 17.6094 21.2914 17.6094Z"
      fill="#4555E5"
    />
    <path
      d="M17.5683 8.66569C17.5683 6.8481 15.7761 6.4786 15.7761 5.04458C15.7761 3.84986 16.9826 3.68094 16.9826 2.33138C16.9826 1.21408 16.0768 0.307922 14.96 0.307922C13.8432 0.307922 12.9375 1.21408 12.9375 2.33138C12.9375 3.68094 14.144 3.84986 14.144 5.04458C14.144 6.1003 12.394 7.85103 11.3405 7.85103C10.1463 7.85103 9.97748 6.64399 8.62852 6.64399C7.51171 6.64399 6.60596 7.55015 6.60596 8.66745C6.60596 9.78476 7.51171 10.6909 8.62852 10.6909C9.97748 10.6909 10.1463 9.48388 11.3405 9.48388C12.7739 9.48388 13.1432 11.2768 14.96 11.2768C16.4004 11.2751 17.5683 10.1067 17.5683 8.66569Z"
      fill="#4555E5"
    />
    <path
      d="M8.62848 12.3906C6.81169 12.3906 6.44236 14.1836 5.00897 14.1836C3.81478 14.1836 3.64594 12.9765 2.29698 12.9765C1.18017 12.9765 0.274414 13.8827 0.274414 15C0.274414 16.1173 1.18017 17.0235 2.29698 17.0235C3.64594 17.0235 3.81478 15.8164 5.00897 15.8164C6.06422 15.8164 7.81418 17.5672 7.81418 18.6211C7.81418 19.8158 6.60768 19.9848 6.60768 21.3343C6.60768 22.4516 7.51344 23.3578 8.63024 23.3578C9.74705 23.3578 10.6528 22.4516 10.6528 21.3343C10.6528 19.9848 9.4463 19.8158 9.4463 18.6211C9.4463 17.1871 11.2385 16.8176 11.2385 15C11.2367 13.559 10.0689 12.3906 8.62848 12.3906Z"
      fill="#4555E5"
    />
    <path
      d="M163.258 7.09619H164.325V7.38475H163.953V8.67273H163.629V7.38475H163.26V7.09619H163.258ZM164.536 7.09619H164.978L165.419 8.1871L165.855 7.09619H166.283V8.67273H165.98V7.52376L165.521 8.67273H165.296L164.837 7.50968V8.67273H164.535V7.09619H164.536Z"
      fill="currentColor"
    />
  </SvgIcon>
);
