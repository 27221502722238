import dayjs from 'dayjs';

import { FormatDateType } from './interfaces';

const TIME_FORMAT = 'hh:mm A';
const DATE_FORMAT = 'MMM DD, YYYY';

export const formatTime: FormatDateType = (date) =>
  dayjs(date).format(TIME_FORMAT);

export const formatDate: FormatDateType = (date) =>
  dayjs(date).format(DATE_FORMAT);

export const getESTDate = (date = new Date()): Date => {
  const estDate = new Date(
    date.toLocaleString('en-US', { timeZone: 'America/New_York' }),
  );

  return estDate;
};
