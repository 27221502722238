import React from 'react';

import { Box, Button, Backdrop, Modal, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { CloseIcon } from '../../../../../icons';

import { IConfirmClearChatModalView } from './interfaces';

import { useStyles } from './styles';

const ConfirmClearChatModalView = ({
  open,
  onClose,
  onSubmit,
}: IConfirmClearChatModalView) => {
  const classes = useStyles();
  const cancelButtonSx = { ...classes.button, ...classes.cancelButton };
  const submitButtonSx = { ...classes.button, ...classes.submitButton };

  return (
    <Modal
      sx={classes.root}
      open={open}
      onClose={onClose}
      components={{ Backdrop }}
    >
      <Box sx={classes.container}>
        <CloseIcon sx={classes.closeIcon} onClick={onClose} />
        <Box sx={classes.content}>
          <Box sx={classes.iconContainer}>
            <DeleteIcon />
          </Box>
          <Typography sx={classes.title}>Clear Chat History</Typography>
          <Typography sx={classes.description}>
            You’re clearing your chat history. This action can’t be undone. Do
            you want to confirm?
          </Typography>
          <Box sx={classes.buttonContainer}>
            <Button onClick={onClose} variant="outlined" sx={cancelButtonSx}>
              Cancel
            </Button>
            <Button onClick={onSubmit} variant="outlined" sx={submitButtonSx}>
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmClearChatModalView;
