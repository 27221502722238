import { COLORS } from '../../../../constants/colors';

export const useStyles = (open: boolean) => ({
  button: {
    backgroundColor: open ? COLORS.BACKGROUND_COLOR : COLORS.DARK_GREY,
  },
  popover: {
    top: '-50px',
    '& .MuiPopover-paper': {
      backdropFilter: 'blur(12px)',
      backgroundColor: COLORS.NEW_BLACK,
    },
  },
});
