import React from 'react';

import { Box, IconButton, Tooltip } from '@mui/material';

import { IHomeBarButton } from './intefaces';

import { useStyles } from './styles';

const HomeBarButton = ({
  icon,
  handleClick,
  label,
  showPulseAnimation = false,
  disabled = false,
  sx = {},
  buttonSx = {},
}: IHomeBarButton) => {
  const classes = useStyles({ showPulseAnimation });
  const rootClass = { ...classes.root, ...sx };
  const buttonClass = { ...classes.button, ...buttonSx };

  return (
    <Tooltip title={label}>
      <Box sx={rootClass}>
        <Box sx={showPulseAnimation ? classes.pulseBox : {}} />
        <IconButton
          disabled={disabled}
          sx={buttonClass}
          onClick={handleClick}
          disableRipple
        >
          {icon}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default HomeBarButton;
