import {
  CheckIsPurewebStreamerMessageAction,
  PurewebStreamerMessageAction,
} from './interfaces';

const { PositionChanged, ParticipantPosition, ParticipantVideoPosition } =
  PurewebStreamerMessageAction;

export default class PurewebClientOptions {
  // Overridable connection options
  LaunchType?: string;

  // Launch queue configuration
  ProjectId: string;

  ModelId: string;

  Version?: string;

  EnvironmentId?: string;

  Endpoint?: string;

  // Overridable streamer options
  ForceRelay = false;

  UseNativeTouchEvents?: boolean;

  UsePointerLock?: boolean;

  PointerLockRelease?: boolean;

  regionOverride?: string;

  virtualizationProviderOverride?: string;

  Resolution?: string;

  constructor(ProjectId: string, ModelId: string) {
    this.ProjectId = ProjectId;
    this.ModelId = ModelId;
  }

  isValid(): boolean {
    if (!this.ProjectId) {
      return false;
    }

    return !!this.ModelId;
  }
}

export const checkIsPositionChangedAction: CheckIsPurewebStreamerMessageAction =
  (action) => action === PositionChanged || action === ParticipantPosition;

export const checkIsParticipantVideoPositionAction: CheckIsPurewebStreamerMessageAction =
  (action) => action === ParticipantVideoPosition;
