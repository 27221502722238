import React, { useState } from 'react';

import { InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Input from '../Input';

import { IPasswordInput } from './interfaces';

import { classes } from './styles';

const PasswordInput = ({
  name,
  value,
  errorMessage = '',
  showError,
  placeholder,
  onChange,
  enableShowPassword = false,
  ...baseInputProps
}: IPasswordInput) => {
  const [showPassword, setShowPassword] = useState(false);

  const Icon = showPassword ? VisibilityIcon : VisibilityOffIcon;
  const type = showPassword ? 'text' : 'password';

  const handleIconClick = () => setShowPassword((show) => !show);

  return (
    <Input
      type={type}
      name={name}
      value={value}
      errorMessage={errorMessage}
      showError={showError}
      placeholder={placeholder}
      onChange={onChange}
      endAdornment={
        enableShowPassword ? (
          <InputAdornment position="end">
            <Icon onClick={handleIconClick} sx={classes.icon} />
          </InputAdornment>
        ) : null
      }
      {...baseInputProps}
    />
  );
};

export default PasswordInput;
