import React from 'react';

import { Box } from '@mui/material';

import { PoweredByMytaverseIcon } from '../../icons';

import { useMytaverse } from '../../providers/MytaverseProvider';

import { IEventThemeTypes } from '../../interfaces';
import { IFooter } from './interfaces';

import { useStyles } from './styles';

const Footer = ({ children, custom = false, sx = {} }: IFooter) => {
  const classes = useStyles();
  const rootSx = { ...classes.root, ...sx };

  const { appTheme } = useMytaverse();

  const isDefaultStyle = appTheme.themeName === IEventThemeTypes.DEFAULT;

  return (
    <Box sx={rootSx}>
      <Box sx={classes.box}>
        <PoweredByMytaverseIcon custom={custom || !isDefaultStyle} />
      </Box>
      {children}
      <Box sx={classes.box} />
    </Box>
  );
};

export default Footer;
