import { useTheme } from '@mui/material';

import { useCommonTipStyles } from '../../hooks';

import { ScreenSizes } from '../../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const commonStyles = useCommonTipStyles(breakpoints);

  return {
    ...commonStyles,
    tipImage: {
      alignSelf: 'center',
      '& img': {
        [breakpoints.down(ScreenSizes.Pc)]: {
          width: 222,
        },
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          width: 177,
        },
      },
    },
  };
};
