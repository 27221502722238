import { COLORS } from '../../../../../constants/colors';
import { IStyles } from '../../../../../interfaces/styles';

export const useStyles = (): IStyles => ({
  root: {
    borderBottom: 1,
    borderColor: 'divider',
    width: '100%',
    mt: [3, 5],
  },
  totalText: {
    fontSize: 14,
    color: COLORS.WHITE,
  },
  icon: { mr: [1, 2] },
  emptyText: {
    fontSize: [20, 24],
    textAlign: 'left',
    color: COLORS.WHITE,
    mt: 3,
  },
  // buttonLink: {
  //   width: ['100vw', '41vw', '41vw', '29vw'],
  //   height: 64,
  //   textTransform: 'none',
  //   fontSize: 24,
  //   position: 'absolute',
  //   bottom: 0,
  //   left: 0,
  //   color: COLORS.WHITE,
  //   bgcolor: COLORS.BLUE,
  // } as IStyles,
  // iconLink: {
  //   mr: 3,
  // },
});
