import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AttachFileIcon = (props: SvgIconProps) => (
  <SvgIcon width="22" height="24" viewBox="0 0 22 24" {...props}>
    <path
      d="M7.625 24C3.59375 24 0.3125 20.7188 0.3125 16.6406C0.3125 14.6719 1.0625 12.7969 2.42188 11.4375L12.3125 1.54688C13.3438 0.5625 14.7969 0 16.2031 0C19.7188 0 21.6875 2.85938 21.6875 5.4375C21.6875 6.89062 21.0781 8.29688 20.0469 9.32812L11 18.375C10.2969 19.0781 9.35938 19.4062 8.46875 19.4062C5.60938 19.4062 4.85938 16.875 4.85938 15.8438C4.85938 14.9531 5.23438 14.0156 5.9375 13.3125L12.5938 6.65625C12.875 6.375 13.2031 6.28125 13.5312 6.28125C14.2344 6.28125 14.8438 6.84375 14.8438 7.59375C14.8438 7.92188 14.7031 8.25 14.4688 8.53125L7.76562 15.1875C7.57812 15.375 7.48438 15.6094 7.48438 15.8438C7.48438 16.4062 7.95312 16.8281 8.46875 16.8281C8.70312 16.8281 8.9375 16.7344 9.125 16.5469L18.2188 7.45312C18.7344 6.89062 19.0156 6.1875 19.0156 5.4375C19.0156 4.125 18.0312 2.625 16.2031 2.625C15.4531 2.625 14.7031 2.90625 14.1875 3.42188L4.29688 13.3125C3.40625 14.2031 2.9375 15.375 2.9375 16.6406C2.9375 18.8906 4.57812 21.3281 7.625 21.3281C8.89062 21.3281 10.1094 20.9062 11 20.0156L18.4531 12.5156C18.7344 12.2812 19.0625 12.1406 19.3906 12.1406C20.0938 12.1406 20.7031 12.7031 20.7031 13.4531C20.7031 13.7812 20.5625 14.1094 20.3281 14.3906L12.8281 21.8438C11.4688 23.25 9.59375 24 7.625 24Z"
      fill="currentColor"
    />
  </SvgIcon>
);
