import React, { useCallback } from 'react';

import { SelectChangeEvent } from '@mui/material';

import SingleSelect from '../../SingleSelect';

import { useMytaverse } from '../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../modules/dashboard/providers';

import { sendCustomSentryBreadcrumb } from '../../../helpers/sentry';

import { IEventsDropdown } from './intefaces';

const EventsDropdown = ({ sx = {}, menuProps }: IEventsDropdown) => {
  const { events, currentEventId, setCurrentEventId } = useMytaverse();
  const { closeLeftMenu } = useMytaverseEvent();

  const options = events.map((event) => ({
    label: event.name,
    value: event.id,
  }));

  const activeValue =
    currentEventId ||
    sessionStorage.getItem('selectedEventId') ||
    events[0].id ||
    '';

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      const event = events.find((event) => event.id === e.target.value);

      if (event) {
        sendCustomSentryBreadcrumb({
          message: `change event id=${event.id}, name=${event.name}`,
        });
        setCurrentEventId(event.id);
        closeLeftMenu();
      }
    },
    [closeLeftMenu, events, setCurrentEventId],
  );

  return (
    <SingleSelect
      options={options}
      activeValue={activeValue}
      onChange={handleChange}
      emptyPlaceholder="No available events"
      sx={sx}
      menuProps={menuProps}
    />
  );
};

export default EventsDropdown;
