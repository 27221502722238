import React from 'react';

import { StreamerStatus, InputEmitter } from '@pureweb/platform-sdk';

import PurewebClientOptions from './helpers';
import { ParticipantPosition } from '../interfaces';
import { SpatialType } from '../../../../../interfaces/webSocketConnectionInfo';

export interface PurewebViewProps {
  videoRef: React.RefObject<HTMLVideoElement>;
  streamerStatus: StreamerStatus;
  inputEmitter: InputEmitter;
  videoStream: MediaStream;
  enterPureweb: boolean;
  clientOptions: PurewebClientOptions | null;
  setEnterPureweb: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenChat: boolean;
}

export enum PurewebStreamerMessageAction {
  PositionChanged = 'POSITION_CHANGED',
  ParticipantPosition = 'PARTICIPANT_POSITION',
  ParticipantVideoPosition = 'PARTICIPANT_VIDEO_POSITION',
}

export interface PlayerPosition {
  CurrentGameSessionId: string;
  eventId: string;
  roomId: string;
  x: number;
  y: number;
  z: number;
  r: number;
}

export interface IPurewebStreamerMessage {
  action: PurewebStreamerMessageAction;
  timestamp: string;
  playerId?: string;
  playerPosition?: PlayerPosition;
  usersinscreen?: string[];
  regionName?: string;
  regionSpatialType?: SpatialType;
}

export type CheckIsPurewebStreamerMessageAction = (
  action: PurewebStreamerMessageAction,
) => boolean;

export interface IVideoPosition {
  participantId: string;
  x: string;
  y: string;
  distance: string;
}

export interface IInitMessage {
  action?: string;
  token: string;
  webSocketUrl: URL;
  participantId: string;
  changePositionInterval: number;
  eventId: string | undefined;
  avatarId: string | null;
  skinId: string | null;
  roomId: string;
  screenHeight: number;
  screenWidth: number;
  downloadAvatarUrl: string;
  isMobile: boolean;
  dolbyToken: string;
  isSpeaker: boolean;
  useLastParticipantPosition: boolean;
  lastParticipantPosition: ParticipantPosition;
}
