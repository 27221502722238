import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.40023 8L0 1.59977L1.59977 0L8 6.40023L14.4002 0L16 1.59977L9.59977 8L16 14.4002L14.4002 16L8 9.59977L1.59977 16L0 14.4002L6.40023 8Z"
      fill="currentColor"
    />
  </SvgIcon>
);
