import { COLORS } from '../../../constants/colors';

import { IStyles } from '../../../interfaces/styles';

export const classes: IStyles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '6px 15px',
    background: 'rgba(255, 255, 255, 0.05)',
    '& p': {
      color: COLORS.WHITE,
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 400,
      ml: 1.5,
    },
    '& svg': {
      color: COLORS.WHITE,
    },
  },
  iconButton: {
    p: 0,
  },
  closeIcon: {
    color: COLORS.MEDIUM_GREY,
  },
};
