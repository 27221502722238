import { useTheme } from '@mui/material';

import { colors } from '../../../../constants/colors';

import BackgroundImage from '../../../../public/images/LoadingTutorialBg.png';

import { ScreenSizes } from '../../../../interfaces';

export const useStyles = (showProgressBar: boolean) => {
  const { breakpoints } = useTheme();

  return {
    root: {
      backgroundImage: `url(${BackgroundImage})`,
      width: '100vw',
      height: '100vh',
      position: 'relative',
    },
    background: {
      backgroundImage: `url(${BackgroundImage})`,
      width: '100vw',
      height: '100vh',
    },
    container: {
      position: 'absolute',
      inset: 0,
      display: 'grid',
      gridTemplateRows: showProgressBar ? 'auto 1fr auto' : '1fr auto',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
      textAlign: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontWeight: 400,
      fontSize: 22,
      lineHeight: 1.27,
      color: colors.white,
      opacity: 0.8,
      marginBottom: '34px',
      [breakpoints.down(ScreenSizes.Pc)]: {
        fontSize: 18,
        lineHeight: 1.56,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 16,
        lineHeight: 1.75,
        marginBottom: '10px',
      },
    },
    footer: {
      width: '100vw',
      padding: '28px 48px',
      [breakpoints.down(ScreenSizes.Pc)]: {
        padding: '28px 32px',
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '22px 16px',
        '& svg': {
          width: 125,
        },
      },
    },
    footerText: {
      fontWeight: 400,
      fontSize: 22,
      lineHeight: 1.27,
      color: colors.white,
      opacity: 0.8,
      [breakpoints.down(ScreenSizes.Pc)]: {
        fontSize: 16,
        lineHeight: 1.75,
      },
    },
  };
};
