import { IRoom } from '../interfaces/rooms';

export const getInitialMapRooms = (
  rooms: IRoom[],
  roomsInfo: { dolbySpatialAudioScale: number; roomId: string }[],
): IRoom[] => {
  const mapRooms = rooms.reduce<IRoom[]>((acc, room) => {
    const roomWithScale = roomsInfo.find((r) => r.roomId === room.id);

    if (room.isInvisible) {
      return acc;
    }

    return [
      ...acc,
      {
        id: room.id,
        name: room.name,
        user: room.user,
        event: room.event,
        isDefaultRoom: room.isDefaultRoom,
        dolbySpatialAudioScale: roomWithScale
          ? roomWithScale.dolbySpatialAudioScale
          : 80,
        image: room.image,
        isInvisible: room.isInvisible,
      },
    ];
  }, []);

  return mapRooms;
};
