import React from 'react';
import { withTranslation } from 'react-i18next';

import { Tab, Tabs } from '@mui/material';

import ReadIconWrapper from '../../../../../components/ReadIconWrapper';
import ChatTabLabel from './ChatTabLabel';

import { GlobalIcon, UserIcon } from '../../../../../icons';

import { IChatTabsView } from './interfaces';
import { ChatTabs } from '../../../../../interfaces/channel';

import { useStyles } from './styles';

const ChatTabsView = ({
  sx = {},
  activeTab,
  onChangeTab,
  isUnreadEventChannel,
  isUnreadPrivateChannel,
  t: translate,
}: IChatTabsView) => {
  const classes = useStyles();
  const tabsSx = { ...classes.tabs, ...sx };

  return (
    <Tabs value={activeTab} onChange={onChangeTab} sx={tabsSx}>
      <Tab
        label={
          <ReadIconWrapper
            sx={classes.unreadChannelWrapper}
            showRedIcon={isUnreadEventChannel}
          >
            <ChatTabLabel
              icon={GlobalIcon}
              label={translate('chatDrawer.global')}
            />
          </ReadIconWrapper>
        }
        value={ChatTabs.Global}
        sx={classes.tab}
        disableRipple
      />
      <Tab
        label={
          <ReadIconWrapper
            sx={classes.unreadChannelWrapper}
            showRedIcon={isUnreadPrivateChannel}
          >
            <ChatTabLabel
              icon={UserIcon}
              label={translate('chatDrawer.private')}
            />
          </ReadIconWrapper>
        }
        value={ChatTabs.Private}
        sx={classes.tab}
        disableRipple
      />
    </Tabs>
  );
};

export default withTranslation('common')(ChatTabsView);
