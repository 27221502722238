import { COLORS } from '../../constants/colors';

import { INotificationStyles } from './interfaces';

export const useStyles = ({
  color,
  background,
  title,
}: INotificationStyles) => ({
  root: {
    padding: '27px 18px 25px',
    background,
    borderRadius: '8px',
    backdropFilter: 'blur(4px)',
    border: '1px solid rgba(140, 140, 140, 0.5)',
    width: 347,
    position: 'relative',
  },
  closeIcon: {
    fontSize: 10,
    position: 'absolute',
    top: 10,
    right: 10,
    color: COLORS.WHITE,
    cursor: 'pointer',
  },
  titleContainer: {
    display: 'flex',
    alignItems: title ? 'center' : 'flex-start',
    columnGap: 1,
  },
  icon: {
    fontSize: 21,
    color,
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: '0.1px',
    color: COLORS.WHITE,
  },
  message: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.1px',
    color: 'rgba(255, 255, 255, 0.8)',
    marginTop: '12px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    mt: 1.5,
  },
  button: {
    color: COLORS.WHITE,
    border: `1px solid ${color}`,
    textTransform: 'capitalize',
  },
});
