import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import SvgIconWrapper from '../../SvgIconWrapper';

import TipImage from '../../../../../../../public/images/Tips/Chat.png';

import { useStyles } from './styles';

const Chat = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={classes.tipImage}>
        <img src={TipImage} alt="Tip Image" />
      </Box>
      <Box sx={classes.content}>
        <Typography sx={classes.title}>
          {translate('tips.chat.title')}
        </Typography>
        <Typography sx={classes.description}>
          {translate('tips.chat.description1')}
        </Typography>
        <SvgIconWrapper>
          <ChatBubbleIcon />
        </SvgIconWrapper>
        <Typography sx={classes.description}>
          {translate('tips.chat.description2')}
        </Typography>
      </Box>
    </>
  );
};

export default withTranslation('common')(Chat);
