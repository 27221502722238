import React from 'react';
import { useNavigate } from 'react-router-dom';

import ROUTES from '../../../../../constants/routes';

import SuccessPasswordRecoveryView from './SuccessPasswordRecoveryView';

const SuccessPasswordRecovery = () => {
  const navigate = useNavigate();

  const handleBackToLogin = () => navigate(ROUTES.LOGIN);

  return <SuccessPasswordRecoveryView onBackToLogin={handleBackToLogin} />;
};

export default SuccessPasswordRecovery;
