import React, { CSSProperties } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Input from '../../../../auth/components/Input';

import { IInviteUserToEventModalView } from './interfaces';

import { useStyles } from './styles';

const InviteUserToEventModalView = ({
  open,
  onClose,
  emailValue,
  onInputChange,
  errorMessage,
  showError,
  onSubmit,
  isSubmitting,
  t: translate,
}: IInviteUserToEventModalView) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box component="div" sx={classes.modal}>
        <Typography sx={classes.title}>
          {translate('inviteUserModal.title')}
        </Typography>
        <form style={classes.form as CSSProperties} onSubmit={onSubmit}>
          <Input
            value={emailValue}
            onChange={onInputChange}
            name="email"
            type="email"
            placeholder={translate('emailScreen.placeHolder')}
            showError={showError}
            errorMessage={errorMessage}
            classes={{
              root: classes.inputRoot,
              input: classes.input,
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={classes.button}
            disableRipple
          >
            {isSubmitting ? (
              <CircularProgress size={24} sx={classes.spinner} />
            ) : (
              translate('inviteUserModal.invite')
            )}
          </Button>
        </form>
        <IconButton type="submit" onClick={onClose} sx={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default withTranslation('common')(InviteUserToEventModalView);
