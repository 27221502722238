import React from 'react';

import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import LocationOnIcon from '../../../../../../../public/images/pin.png';

import { IRoomCard } from './interfaces';

import { useStyles } from './styles';

const RoomCard = ({
  room,
  currentRoomId,
  participantCount,
  onClick,
}: IRoomCard) => {
  const classes = useStyles();

  return (
    <Box component="div" sx={classes.root} onClick={onClick}>
      <Box component="div" sx={classes.roomImageContainer}>
        {currentRoomId && currentRoomId !== room.id ? (
          <IconButton sx={classes.teleportBtn}>
            <img src={LocationOnIcon} alt="" />
            <Typography sx={classes.teleportText}>Teleport</Typography>
          </IconButton>
        ) : null}
        {room.image ? (
          <img src={room.image} className="img" alt="room" />
        ) : (
          <Skeleton variant="rectangular" width="100%" height={300} />
        )}
      </Box>
      <Box component="div" sx={classes.roomNameContainer}>
        <Typography
          sx={classes.roomName(currentRoomId === room.id)}
          component="div"
        >
          {room.name}
        </Typography>
        {participantCount ? (
          <Box component="div" sx={classes.online}>
            <CircleIcon />
            <Typography>{participantCount}</Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default RoomCard;
