import { useTheme } from '@mui/material';

import { COLORS } from '../../../../../constants/colors';
import { DEVICES_LANDSCAPE_SIZES } from '../../../../../constants/devicesSizes';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 16px 16px 24px',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '12px',
    },
    chatIcon: {
      color: COLORS.WHITE_6,
      width: 24,
      height: 24,
    },
    title: {
      color: COLORS.WHITE,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.1px',
    },
    closeButton: {
      width: 24,
      height: 24,
    },
    closeIcon: {
      color: COLORS.WHITE_6,
    },
    tabs: {
      '& *': {
        display: 'none',
        opacity: 0,
      },
      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
        '& *': {
          display: 'flex',
          opacity: 1,
        },
      },
    },
  };
};
