import React from 'react';

import { Tooltip, Typography } from '@mui/material';

import { IOverflowTitleView } from './interfaces';

import { classes } from './styles';

const OverflowTitleView = ({
  title,
  variant = 'body1',
  component = 'p',
  checkOverflow,
  isOverflow,
  onClick,
  sx = {},
}: IOverflowTitleView) => {
  const rootSx = { ...classes.root, ...sx };

  return isOverflow ? (
    <Tooltip title={title} placement="top">
      <Typography
        variant={variant}
        sx={rootSx}
        component={component}
        onClick={onClick}
      >
        {title}
      </Typography>
    </Tooltip>
  ) : (
    <Typography
      sx={rootSx}
      variant={variant}
      component={component}
      onClick={onClick}
    >
      <span ref={checkOverflow}>{title}</span>
    </Typography>
  );
};

export default OverflowTitleView;
