import React from 'react';

import { Avatar } from '@mui/material';

import UserProfileModal from '../UserProfileModal';

import { IUserAvatarButtonView } from './interfaces';

import { classes } from './styles';

const UserAvatarButtonView = ({
  profileImage = '',
  showUserSettingModal,
  setShowUserSettingModal,
}: IUserAvatarButtonView) => {
  return (
    <>
      <Avatar
        src={profileImage}
        onClick={() => setShowUserSettingModal(true)}
        sx={classes.root}
      />
      {showUserSettingModal ? (
        <UserProfileModal
          enableEditing
          onClose={() => setShowUserSettingModal(false)}
        />
      ) : null}
    </>
  );
};

export default UserAvatarButtonView;
