import { checkIsLocalhost } from '../../helpers/path';

import { customAppThemes } from '../../constants/eventStyles';

import EventsService from '../../services/EventsService';

export const getAppTheme = async () => {
  const hostname = window.location.hostname.split('.')[0];
  const isLocalhost = checkIsLocalhost();

  if (
    isLocalhost ||
    hostname.includes('demo') ||
    hostname.includes('d-world')
  ) {
    return customAppThemes.default();
  }

  const eventConfig = await EventsService.getCustomerEvent(hostname);

  if (eventConfig) {
    //@ts-ignore
    return customAppThemes[eventConfig.colorTheme.toLowerCase()](eventConfig);
  }

  return customAppThemes.default();
};
