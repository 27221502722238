import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Typography, Link, Divider } from '@mui/material';

import {
  FormButton,
  FormInput,
  FormLayout,
  FormPasswordInput,
} from '../../components/Form';
import PageTitleContent from '../../components/PageTitleContent';
import LegalNotes from '../../components/LegalNotes';
import SocialButton from '../../components/SocialButton';

import { GoogleColoredIcon, MicrosoftColoredIcon } from '../../../../icons';

import ROUTES from '../../../../constants/routes';

import { ILoginPageView } from './interfaces';
import { SocialProviders } from '../../../../interfaces';

import { useStyles } from './styles';

const LoginPageView = ({
  title,
  welcomeBackFlag,
  values,
  errors,
  touched,
  onChange,
  loading,
  onSubmit,
  socialLoading,
  onGoogleClick,
  onMicrosoftClick,
  t: translate,
}: ILoginPageView) => {
  const classes = useStyles();

  const isLoginByGoogle = socialLoading === SocialProviders.Google;
  const isLoginByMicrosoft = socialLoading === SocialProviders.Microsoft;

  return (
    <Box sx={classes.root}>
      <PageTitleContent title={title} subtitle={translate('login.subtitle')} />
      <LegalNotes>
        <FormLayout classes={{ container: classes.layoutContainer }}>
          <Typography sx={classes.formTitle}>
            {welcomeBackFlag
              ? translate('login.welcomeBack')
              : translate('login.title')}
          </Typography>
          <form onSubmit={onSubmit} noValidate>
            <FormInput
              type="email"
              name="email"
              value={values.email}
              errorMessage={errors.email ? translate(errors.email) : ''}
              showError={!loading && touched.email}
              placeholder={translate('login.emailPlaceholder')}
              onChange={onChange}
            />
            <FormPasswordInput
              name="password"
              value={values.password}
              errorMessage={errors.password ? translate(errors.password) : ''}
              showError={!loading && touched.password}
              placeholder={translate('login.passwordPlaceholder')}
              onChange={onChange}
              enableShowPassword
            />
            <Link
              href={`${ROUTES.LOGIN}/${ROUTES.FORGOT_PASSWORD}`}
              sx={classes.link}
            >
              {translate('forgotPassword.title')}
            </Link>
            <FormButton loading={loading} disabled={!!socialLoading}>
              {translate('login.signIn')}
            </FormButton>
          </form>
          <Divider sx={classes.divider}>{translate('or')}</Divider>
          <Box sx={classes.socialButtonsContainer}>
            <SocialButton
              icon={GoogleColoredIcon}
              onClick={onGoogleClick}
              loading={isLoginByGoogle}
              disabled={loading || isLoginByMicrosoft}
            >
              Google
            </SocialButton>
            <SocialButton
              icon={MicrosoftColoredIcon}
              onClick={onMicrosoftClick}
              loading={isLoginByMicrosoft}
              disabled={loading || isLoginByGoogle}
            >
              Microsoft
            </SocialButton>
          </Box>
        </FormLayout>
      </LegalNotes>
    </Box>
  );
};

export default withTranslation('common')(LoginPageView);
