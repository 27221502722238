import { colors } from '../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.oxford_40}`,
    borderRadius: 4,
    width: 391,
    height: 220,
    overflow: 'hidden',
    position: 'relative',
    marginTop: '38px',
    marginBottom: '16px',
    '& video': {
      objectFit: 'cover',
    },
  },
  selection: {
    position: 'absolute',
    content: '""',
    bottom: 0,
    width: '100%',
    backgroundColor: colors.black_60,
    backdropFilter: 'blur(11px)',
    padding: '10px 16px',
  },
  helper: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.oxford_40,
    marginBottom: '35px',
    textAlign: 'center',
  },
});
