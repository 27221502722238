import { IStyles } from '../../../interfaces/styles';

export const useStyles = (fontColor: string): IStyles => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    width: 32,
    height: 32,
    '& img': {
      width: 32,
      height: 32,
    },
  },
  text: {
    flexGrow: 1,
    fontSize: 20,
    ml: 1.5,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    maxWidth: 300,
    overflow: 'hidden',
    color: fontColor,
    fontWeight: 400,
  },
});
