import { colors } from '../../constants/colors';

export const classes = {
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
  errorText: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.1px',
    color: colors.red,
  },
  errorIcon: {
    width: 14,
    height: 20,
    color: colors.red,
  },
};
