import React from 'react';

import { Box } from '@mui/material';

import { IFloorView } from './interfaces';

import { useStyles } from './styles';

const FloorView = ({ src, isDefaultEvent }: IFloorView) => {
  const styles = useStyles(isDefaultEvent, src);

  return (
    <>
      <Box sx={styles.background} />
      <Box sx={styles.imageWrapper} />
    </>
  );
};

export default FloorView;
