import { Box } from '@mui/material';
import React from 'react';

const HomeBottomButtonOptionsCamView = () => (
  <Box component="div">
    <video
      autoPlay
      id="videoElement"
      width="520"
      height="308"
    />
  </Box>
);

export default HomeBottomButtonOptionsCamView;
