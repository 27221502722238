import $api from '../http/axios';

import { IS3File } from './interfaces';

export default class FilesService {
  static getS3Files(files: string[]): Promise<IS3File[]> {
    return $api
      .post('cms/files/retrieveFiles', { files })
      .then((res) => res.data.files);
  }
}
