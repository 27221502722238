export const parseTimer = (seconds: number): string => {
  if (seconds >= 60) {
    const minutes = Number(String(seconds / 60).split('.')[0]);
    const currentMinuteSeconds =
      seconds % 60 === 0 ? 0 : seconds - minutes * 60;

    // to preview 03:00 not 3:00
    const minutesWithZero = minutes >= 10;
    const secondsWithZero = Number(currentMinuteSeconds) < 10;
    const minutesPreview = minutesWithZero ? String(minutes) : `0${minutes}`;

    const secondsPreview = secondsWithZero
      ? `0${String(currentMinuteSeconds).split('.')[0]}`
      : String(currentMinuteSeconds).split('.')[0];

    return `${minutesPreview}:${secondsPreview}`;
  }

  const secondsWithZero = seconds < 10;

  const secondsPreview = secondsWithZero
    ? `0${String(seconds).split('.')[0]}`
    : String(seconds).split('.')[0];
  return `00:${secondsPreview}`;
};
