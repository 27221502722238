import { COLORS } from '../../../../../constants/colors';

export const classes = {
  root: {
    width: 24,
    height: 24,
  },
  icon: {
    color: COLORS.WHITE_6,
  },
};
