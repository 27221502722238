import React from 'react';

import CloseDrawerButtonView from './CloseDrawerButtonView';

import { useChatState } from '../../../../../hooks/context';

import { toggleDrawer } from '../../../../../helpers';

import { ICloseDrawerButton } from './interfaces';
import { ToggleDrawerType } from '../../../interfaces';

const CloseDrawerButton = ({ sx }: ICloseDrawerButton) => {
  const { open, setOpen } = useChatState();

  const handleToggleDrawer: ToggleDrawerType = (event) => {
    toggleDrawer(event, () => setOpen(!open));
  };

  return <CloseDrawerButtonView sx={sx} onClick={handleToggleDrawer} />;
};

export default CloseDrawerButton;
