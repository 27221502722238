import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useManualTimer } from '../../../../auth/hooks/timer';
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../components/Notification';
import { useMytaverseEvent } from '../../../providers';

import { getNotification } from '../../../../../components/Notification/helpers';

import { mytaverseWebsocketLogger } from '../../../../../providers/MytaverseProvider';

import { UseHandleUEConnectionType } from './interfaces';

export const useHandleUEConnection: UseHandleUEConnectionType = () => {
  const {
    teleportingToRoom,
    setTeleportingToRoom,
    isTeleportingToRoomByUnreal,
    setIsTeleportingToRoomByUnreal,
    setUe5WebsocketConnected,
  } = useMytaverseEvent();
  const { timer, intervalId, startTimer, clearTimer } = useManualTimer();
  const { showNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  useEffect(() => {
    if (timer < 1 && !!intervalId) {
      clearTimer();
      showNotification(
        getNotification({
          message: translate('notifications.kickOutError'),
          type: NOTIFICATION_TYPES.ERROR,
        }),
      );
    }
  }, [clearTimer, intervalId, showNotification, timer, translate]);

  const handleUe5WsConnected = useCallback(() => {
    mytaverseWebsocketLogger.log('UE5 connected');
    setUe5WebsocketConnected(true);

    const isTeleporting = !!teleportingToRoom || isTeleportingToRoomByUnreal;

    if (isTeleporting) {
      if (teleportingToRoom) {
        setTeleportingToRoom(null);
      }
      if (isTeleportingToRoomByUnreal) {
        setIsTeleportingToRoomByUnreal(false);
      }

      if (intervalId) {
        clearTimer();
      }
    }
  }, [
    teleportingToRoom,
    setTeleportingToRoom,
    isTeleportingToRoomByUnreal,
    setIsTeleportingToRoomByUnreal,
    intervalId,
    clearTimer,
  ]);

  const handleUe5WsDisconnected = useCallback(() => {
    mytaverseWebsocketLogger.error('UE5 disconnected');

    setUe5WebsocketConnected(false);

    const isTeleporting = !!teleportingToRoom || isTeleportingToRoomByUnreal;

    if (!isTeleporting) {
      startTimer();
    }
  }, [teleportingToRoom, isTeleportingToRoomByUnreal, startTimer]);

  return {
    handleUe5WsConnected,
    handleUe5WsDisconnected,
  };
};
