import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MicrosoftColoredIcon = (props: SvgIconProps) => (
  <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <path d="M11.5 0H0.25V11.25H11.5V0Z" fill="#F05125" />
    <path d="M24.25 0H13V11.25H24.25V0Z" fill="#7FBB42" />
    <path d="M24.25 12.75H13V24H24.25V12.75Z" fill="#FDB813" />
    <path d="M11.5 12.75H0.25V24H11.5V12.75Z" fill="#32A0DA" />
  </SvgIcon>
);
