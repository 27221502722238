export const getUserBrowser = (): string => {
  const userAgent = navigator.userAgent;
  let browserType;
  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserType = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserType = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserType = 'safari';
  } else if (userAgent.match(/edg/i)) {
    browserType = 'edge';
  } else {
    browserType = 'UNKNOWN';
  }

  return browserType;
};

export const showInitMessage = (message: string) => {
  const parsedMessage: {
    initMessage1?: string;
    initMessage2?: string;
    initMessage3?: string;
  } = {};

  if (message.length < 1024) {
    parsedMessage['initMessage1'] = message;
  } else if (message.length > 1024 && message.length < 2048) {
    parsedMessage['initMessage1'] = message.slice(0, 1024);
    parsedMessage['initMessage2'] = message.slice(1024, 2048);
  } else {
    parsedMessage['initMessage1'] = message.slice(0, 1024);
    parsedMessage['initMessage2'] = message.slice(1024, 2048);
    parsedMessage['initMessage3'] = message.slice(2048, 3072);
  }

  console.log({
    initMessage: JSON.parse(message),
    parsedMessage,
    stringifiedMessage: message,
  });
};
