import React from 'react';

import { Button as BaseButton } from '@mui/material';

import { IButton } from './interfaces';

import { classes } from './styles';

const Button = ({ children, onClick, type, sx = {} }: IButton) => {
  const buttonSx = { ...classes.root, ...classes[type], ...sx };

  return (
    <BaseButton onClick={onClick} variant="outlined" sx={buttonSx}>
      {children}
    </BaseButton>
  );
};

export default Button;
