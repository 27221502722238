import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { colors } from '../../../../constants/colors';

import { IEventThemeTypes } from '../../../../interfaces';

export const useStyles = () => {
  const { appTheme } = useMytaverse();
  const { themeName, loginPage } = appTheme;

  const isDarkTheme = themeName === IEventThemeTypes.DARK;
  const { helperTextColor, helperTextLinkColor } = loginPage.assets;

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '36px',
      width: '100%',
      maxWidth: 480,
    },
    text: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      textAlign: 'center',
      color: isDarkTheme ? helperTextColor : colors.oxford_60,
      width: '80%',
      alignSelf: 'center',
    },
    link: {
      color: isDarkTheme ? helperTextLinkColor : colors.cyan,
      textDecoration: 'none',
    },
  };
};
