import React from 'react';

import { Box } from '@mui/material';

import { IEventTabLabel } from './interfaces';

import { classes } from './styles';

const EventTabLabel = ({ label }: IEventTabLabel) => {
  return (
    <Box sx={classes.root} component="div">
      {label}
    </Box>
  );
};

export default EventTabLabel;
