import React from 'react';
import { withTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import {
  FormButton,
  FormLayout,
  FormPasswordInput,
} from '../../../components/Form';
import PasswordValidationWrapper from '../../../components/PasswordValidation';

import { IPasswordRecoveryView } from './interfaces';

import { classes } from './styles';

const PasswordRecoveryForm = ({
  values,
  errors,
  touched,
  onChange,
  onPasswordHandler,
  loading,
  onSubmit,
  openPasswordRules,
  t: translate,
}: IPasswordRecoveryView) => {
  return (
    <FormLayout classes={{ container: classes.form }}>
      <Typography sx={classes.title}>
        {translate('passwordRecovery.title')}
      </Typography>
      <Typography sx={classes.subtitle}>
        {translate('passwordRecovery.subtitle')}
      </Typography>
      <form noValidate onSubmit={onSubmit}>
        <PasswordValidationWrapper
          open={openPasswordRules}
          hasPasswordValue={!!values.password || !!values.passwordConfirm}
          isSubmitting={!!touched.password && !!touched.passwordConfirm}
          error={errors.password || ''}
        >
          <>
            <FormPasswordInput
              name="password"
              value={values.password}
              showError={!loading && touched.password}
              placeholder={translate('passwordRecovery.password')}
              onChange={onChange}
              onFocus={() => onPasswordHandler(true)}
              onBlur={() => onPasswordHandler(false)}
              enableShowPassword
            />
            <FormPasswordInput
              name="passwordConfirm"
              value={values.passwordConfirm}
              showError={!loading && touched.passwordConfirm}
              placeholder={translate('passwordRecovery.passwordConfirm')}
              onChange={onChange}
              onFocus={() => onPasswordHandler(true)}
              onBlur={() => onPasswordHandler(false)}
              enableShowPassword
            />
          </>
        </PasswordValidationWrapper>
        <FormButton loading={loading}>
          {translate('passwordRecovery.button')}
        </FormButton>
      </form>
    </FormLayout>
  );
};

export default withTranslation('common')(PasswordRecoveryForm);
