import React from 'react';

import VideoBubbleView from './VideoBubbleView';

import { useAttachMediaStream } from './hooks';

import { IVideoBubble } from './interfaces';
import { useMytaverseEvent } from '../../../providers';

const VideoBubble = ({ username, speaking, stream, muted }: IVideoBubble) => {
  const videoRef = useAttachMediaStream(stream);
  const { videoBubbleSize } = useMytaverseEvent();

  return (
    <VideoBubbleView
      videoRef={videoRef}
      username={username}
      speaking={speaking}
      muted={muted}
      videoBubbleSize={videoBubbleSize}
    />
  );
};

export default VideoBubble;
