import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { COLORS } from '../../../../constants/colors';

import { IStyles } from '../../../../interfaces/styles';

export const useStyles = (): IStyles => {
  const { appTheme } = useMytaverse();

  const assets = appTheme.avatarPage.assets;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden',
    },
    prevAvatarButtons: {
      flexDirection: 'row',
      columnGap: 2,
      '& button': {
        textTransform: 'capitalize',
        mb: 5,
        border: `1px solid ${COLORS.MYTAVERSE_BLUE}`,
        borderRadius: 2,
        width: 200,
        height: 45,
        color: assets.buttonTextColor,
        fontSize: 16,
        '&:hover': {
          color: COLORS.WHITE,
          background: COLORS.MYTAVERSE_BLUE,
        },
      },
      '& a': {
        textDecoration: 'none',
      },
    },
  };
};
