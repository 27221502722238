import React from 'react';
import { Box, Typography, Button, IconButton, Slider } from '@mui/material';

import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Stack } from '@mui/system';

import SaveFile from '../SaveFile';
import LoadingProgress from '../../../../../components/LoadingProgress';
import { VIDEO_PLAYER } from './constants';
import { IVideoViewComponent } from '../interfaces';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

const VideoPreviewView = ({
  currentTime,
  parsedTime,
  handleSliderSoundChange,
  handleSliderChange,
  play,
  handlePlayVideo,
  volume,
  handleMuteSound,
  fullscreenHandler,
  video,
  videoRef,
  src,
}: IVideoViewComponent) => {
  const classes = useStyles();
  const { t: translate } = useTranslation('common');

  return (
    <Box sx={classes.toolsWrapper}>
      {video ? (
        <>
          <Box component="nav" sx={classes.tools}>
            <Box sx={classes.toolsLink}>
              <IconButton onClick={handlePlayVideo}>
                {play ? (
                  <PauseCircleOutlineOutlinedIcon />
                ) : (
                  <PlayCircleFilledWhiteOutlinedIcon />
                )}
              </IconButton>
              <Typography>{parsedTime}</Typography>

              <Box sx={classes.sliderWrapper}>
                <Slider
                  sx={classes.slider}
                  aria-label="Timing"
                  value={currentTime}
                  onChange={(e, value) =>
                    handleSliderChange(e, value as number)
                  }
                  step={VIDEO_PLAYER.SLIDER_STEP}
                  max={
                    videoRef.current && videoRef.current.duration
                      ? videoRef.current.duration
                      : VIDEO_PLAYER.MAX_VALUE_DEFAULT
                  }
                />
                <Box sx={classes.sliderVolumeWrapper}>
                  <IconButton onClick={handleMuteSound}>
                    {volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
                  </IconButton>
                  <Slider
                    sx={classes.sliderVolume}
                    aria-label="Volume"
                    value={volume * VIDEO_PLAYER.VALUE_MULTIPLIER}
                    step={VIDEO_PLAYER.SLIDER_STEP}
                    onChange={(e, value) =>
                      handleSliderSoundChange(e, value as number)
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Stack flexDirection="row" columnGap={2}>
              <Box sx={classes.toolsButtonWrapper}>
                <Button onClick={fullscreenHandler}>
                  <FullscreenIcon />
                  <Typography>{translate('tooltips.fullscreen')}</Typography>
                </Button>
              </Box>
              <Box sx={classes.toolsButtonWrapper}>
                <SaveFile downloadUrl={src} />
              </Box>
            </Stack>
          </Box>
        </>
      ) : (
        <LoadingProgress />
      )}

      <video ref={videoRef}>
        <source src={src} />
      </video>
    </Box>
  );
};

export default VideoPreviewView;
