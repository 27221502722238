import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Button, SxProps, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { DEVICES_TEST_STEPS_COUNT } from '../../constants/devicesSteps';

import { IDevicesTestWrapperView } from './interfaces';

import { useStyles } from './styles';

const DevicesTestWrapperView = ({
  children,
  title,
  description,
  onNextStep,
  activeStep,
  isLastStep,
  disabled,
  sx = {},
  t: translate,
}: IDevicesTestWrapperView) => {
  const classes = useStyles();
  const rootSx = { ...classes.root, ...sx } as SxProps;

  return (
    <Box sx={rootSx}>
      <Box sx={classes.content}>
        <Typography sx={classes.title}>{title}</Typography>
        <Typography sx={classes.description}>{description}</Typography>
        {children}
      </Box>
      <Box sx={classes.footer}>
        <Box sx={classes.box}>
          <Typography sx={classes.step}>
            {translate('devicesTest.step')} {activeStep}/
            {DEVICES_TEST_STEPS_COUNT}
          </Typography>
        </Box>
        <Box sx={classes.box}>
          <Button
            sx={classes.nextBtn}
            variant="contained"
            color="primary"
            disableRipple
            disabled={disabled}
            onClick={onNextStep}
          >
            {translate(
              isLastStep ? 'devicesTest.continue' : 'devicesTest.next',
            )}{' '}
            <ArrowForwardIcon sx={classes.icon} />
          </Button>
        </Box>
        {!isLastStep && (
          <Box sx={classes.box}>
            <Button sx={classes.skipBtn} disableRipple onClick={onNextStep}>
              {translate('devicesTest.skip')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default withTranslation('common')(DevicesTestWrapperView);
