import { useCallback, useEffect, useState } from 'react';
import { parseTimer } from './helpers';

export const useVideoControls = (videoRef: HTMLVideoElement | null) => {
  const [time, setTime] = useState('00:00');
  const [play, setPlay] = useState(false);
  const [volume, setVolume] = useState(videoRef ? videoRef.volume : 1);

  useEffect(() => {
    if (!videoRef) {
      return;
    }
    videoRef.addEventListener('timeupdate', currentTimeHandler);
    videoRef.addEventListener('play', playVideoListener);
    videoRef.addEventListener('pause', pauseVideoListener);
    videoRef.addEventListener('volumechange', volumeChangeListener);
    videoRef.addEventListener('fullscreenchange', fullscreenListener);

    return () => {
      videoRef.removeEventListener('timeupdate', currentTimeHandler);
      videoRef.removeEventListener('play', playVideoListener);
      videoRef.removeEventListener('pause', pauseVideoListener);
      videoRef.removeEventListener('volumechange', volumeChangeListener);
      videoRef.removeEventListener('fullscreenchange', fullscreenListener);
    };
  }, [videoRef]);

  const currentTimeHandler = useCallback(() => {
    if (!videoRef) {
      return;
    }
    const seconds = videoRef.currentTime;

    setTime(parseTimer(seconds));
  }, [videoRef]);

  const playVideoListener = useCallback(() => {
    setPlay(true);
  }, [setPlay]);

  const volumeChangeListener = useCallback(() => {
    if (!videoRef) {
      return;
    }
    setVolume(videoRef.volume);
  }, [videoRef, setVolume]);

  const fullscreenListener = useCallback(() => {
    if (!videoRef) {
      return;
    }
    videoRef.controls = document.fullscreen;
  }, [videoRef, setVolume]);

  const pauseVideoListener = useCallback(() => {
    setPlay(false);
  }, [setPlay]);

  const handlePlayVideo = useCallback(() => {
    if (!videoRef) {
      return;
    }
    setPlay((prev) => !prev);

    if (!play) {
      videoRef.play();
      return;
    }

    videoRef.pause();
  }, [play, setPlay, videoRef]);

  const handleSliderChange = useCallback(
    (event: Event, value: number) => {
      if (!videoRef) {
        return;
      }
      videoRef.currentTime = value;
    },
    [videoRef],
  );

  const handleSliderSoundChange = useCallback(
    (event: Event, value: number) => {
      if (!videoRef) {
        return;
      }
      videoRef.volume = value / 100;
      setVolume(value / 100);
    },
    [videoRef, setVolume],
  );

  const handleMuteSound = useCallback(() => {
    if (!videoRef) {
      return;
    }

    const nextVolume = volume === 0 ? 1 : 0;

    setVolume(nextVolume);
    videoRef.volume = nextVolume;
  }, [volume, videoRef]);

  const fullscreenHandler = useCallback(() => {
    if (!videoRef) {
      return;
    }
    videoRef.requestFullscreen();
  }, [videoRef]);

  return {
    parsedTime: time,
    currentTime: videoRef?.currentTime,
    currentTimeHandler,
    play,
    volume,
    handlePlayVideo,
    handleSliderSoundChange,
    handleSliderChange,
    handleMuteSound,
    fullscreenHandler,
  };
};
