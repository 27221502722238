import { colors } from '../../../constants/colors';

export const useStyles = () => ({
  root: {
    padding: '23px 22px 23px 17px',
    background:
      'radial-gradient(63.43% 86.14% at 50% -44.06%, #122148 0%, #070709 100%)',
    borderRadius: '8px',
    backdropFilter: 'blur(4px)',
    border: '1px solid rgba(140, 140, 140, 0.5)',
    width: 347,
    position: 'relative',
  },
  actions: {
    display: 'flex',
    columnGap: '10px',
    position: 'absolute',
    top: 8,
    right: 10,
  },
  icon: {
    fontSize: 18,
    color: colors.white,
    opacity: 0.7,
    cursor: 'pointer',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
  },
  avatar: {
    width: 30,
    height: 30,
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: colors.white,
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
    marginTop: '4px',
  },
  userName: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: colors.white,
    opacity: 0.8,
    whiteSpace: 'nowrap',
  },
  messageText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: colors.white,
    opacity: 0.6,
  },
  attachmentContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
  },
  attachmentIcon: {
    width: 18,
    height: 20,
    color: colors.white,
    opacity: 0.7,
    transform: 'rotate(45deg)',
  },
  attachmentText: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: colors.white,
    opacity: 0.6,
  },
});
