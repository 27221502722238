import { colors } from '../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
  },
  icon: {
    color: colors.white,
  },
  select: {
    color: colors.white,
    width: '100%',
    overflow: 'hidden',
    '& .MuiInputBase-root': {
      opacity: 1,
      width: '100%',
    },
    '& .MuiSelect-select': {
      '& p': {
        color: colors.white,
      },
    },
    '& svg': {
      color: colors.white,
    },
  },
});
