import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import TipImage from '../../../../../../../public/images/Tips/FindYourFriends.png';
import RedIndicator from '../../../../../../../public/images/Tips/RedIndicator.png';
import GreenIndicator from '../../../../../../../public/images/Tips/GreenIndicator.png';

import { useStyles } from './styles';

const FindYourFriends = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={classes.tipImage}>
        <img src={TipImage} alt="Tip Image" />
      </Box>
      <Box sx={classes.content}>
        <Typography sx={classes.title}>
          {translate('tips.findYourFriends.title')}
        </Typography>
        <Typography sx={classes.description}>
          {translate('tips.findYourFriends.description1')}
        </Typography>
        <Typography sx={classes.description}>
          {translate('tips.findYourFriends.description2')}
        </Typography>
        <Box sx={classes.actions}>
          <Box sx={classes.action}>
            <img src={RedIndicator} alt="Red indicator" />
            {translate('tips.findYourFriends.description3')}
          </Box>
          <Box sx={classes.action}>
            <img src={GreenIndicator} alt="Green indicator" />
            {translate('tips.findYourFriends.description4')}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withTranslation('common')(FindYourFriends);
