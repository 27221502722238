import React from 'react';

import PasswordRuleView from './PasswordRuleView';

import { getPasswordRuleContext } from './helpers';

import { IPasswordRule } from './interfaces';

const PasswordRule = ({ text, isCompleted, isError }: IPasswordRule) => {
  const { icon, color } = getPasswordRuleContext({
    isCompleted,
    isError,
  });

  return <PasswordRuleView text={text} icon={icon} color={color} />;
};

export default PasswordRule;
