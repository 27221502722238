import React from 'react';

import { SvgIcon } from '@mui/material';

export const VirtualAssistantIcon = () => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.12305 24L2.82358 19.782C3.56155 20.6023 3.91449 20.9178 4.74872 21.5803L1.12305 24Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7593 22.1543C21.4784 20.1498 24 16.2644 24 11.7998C24 5.28294 18.6274 0 12 0C5.37258 0 0 5.28294 0 11.7998C0 16.2866 2.54676 20.1885 6.29638 22.1841C6.73491 20.6531 7.86153 19.341 9.34896 18.608C9.03598 18.5672 8.78066 18.343 8.69442 18.0467H8.14849C6.66069 18.0467 5.45459 16.8406 5.45459 15.3528V14.3809C4.64057 14.1565 4.04272 13.4108 4.04272 12.5254C4.04272 11.7868 4.45885 11.1453 5.06945 10.8227V6.99248C4.75829 6.76511 4.55615 6.39743 4.55615 5.98253C4.55615 5.29217 5.11579 4.73253 5.80615 4.73253C6.4965 4.73253 7.05615 5.29217 7.05615 5.98253C7.05615 6.38548 6.86549 6.7439 6.56945 6.97249V9.24113C7.74027 7.51063 9.72128 6.37313 11.968 6.37313C14.1727 6.37313 16.1215 7.46852 17.3 9.14477V7.01828C16.9681 6.79355 16.75 6.41351 16.75 5.98253C16.75 5.29217 17.3097 4.73253 18 4.73253C18.6904 4.73253 19.25 5.29217 19.25 5.98253C19.25 6.36859 19.075 6.71377 18.8 6.94306V10.7893C19.4466 11.0995 19.893 11.7603 19.893 12.5254C19.893 13.4107 19.2952 14.1564 18.4813 14.3809V15.3528C18.4813 16.8406 17.2752 18.0467 15.7874 18.0467H15.2414C15.1638 18.3132 14.9493 18.5215 14.6791 18.5903C16.1748 19.3151 17.3111 20.6237 17.7593 22.1543Z"
        fill="white"
      />
      <ellipse cx="9.17136" cy="11.1308" rx="1.15" ry="1.13081" fill="white" />
      <ellipse cx="15.2035" cy="11.1308" rx="1.15" ry="1.13081" fill="white" />
      <path
        d="M9 14.2727C9 14.1221 9.1221 14 9.27273 14H15C15 15.6569 13.6569 17 12 17H11.7273C10.221 17 9 15.779 9 14.2727Z"
        fill="white"
      />
    </SvgIcon>
  );
};
