import React from 'react';

import { Box } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SettingsIcon from '@mui/icons-material/Settings';
import MicOffIcon from '@mui/icons-material/MicOff';

import { ReactComponent as DroneMode } from '../../../../../../public/images/DroneMode.svg';
import IconPopover, { useToggleIconPopover } from '../../../IconPopover';
import ToolBoxItem from '../../../ToolBoxItem';

import { CrownIcon } from '../../../../../../icons';

import { useConference } from '../../../DashboardContent/Dolby';
import { useAdminTools } from './hooks';

import { classes } from './styles';

const AdminTools = () => {
  const { isConferenceInitialized } = useConference();
  const {
    translate,
    handleCreatePOIs,
    handleDroneMode,
    handleOpenAdminSettings,
    handleMuteAll,
  } = useAdminTools();
  const { anchorEl, handleClick, handleClose } = useToggleIconPopover();

  if (!isConferenceInitialized) {
    return null;
  }

  return (
    <IconPopover
      icon={<CrownIcon sx={classes.icon} />}
      label={`${translate('adminToolbox.toolbox')} admin`}
      anchorEl={anchorEl}
      onClick={handleClick}
      onClose={handleClose}
      buttonSx={classes.root}
      popoverSx={classes.popover}
    >
      <Box component="div" sx={classes.container}>
        <Box component="div" sx={classes.tools}>
          <ToolBoxItem
            Icon={<UploadFileIcon />}
            title={translate('adminToolbox.createPOIs')}
            handleClick={handleCreatePOIs}
          />
          <ToolBoxItem
            Icon={<SettingsIcon />}
            title={'Room Settings'}
            handleClick={handleOpenAdminSettings}
          />
          <ToolBoxItem
            Icon={<DroneMode />}
            title={translate('adminToolbox.droneMode')}
            handleClick={handleDroneMode}
          />
          <ToolBoxItem
            Icon={<MicOffIcon />}
            title={translate('adminToolbox.muteAll')}
            handleClick={handleMuteAll}
          />
        </Box>
      </Box>
    </IconPopover>
  );
};

export default AdminTools;
