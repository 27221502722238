import React from 'react';
import { useNavigate } from 'react-router-dom';

import EmailConfirmationPageView from './EmailConfirmationPageView';

import { getSearchParamValue } from '../../../../helpers/path';

import ROUTES from '../../../../constants/routes';

const EmailConfirmationPage = () => {
  const emailParam = decodeURIComponent(
    getSearchParamValue(location.search, 'email'),
  );
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(`${ROUTES.LOGIN}/${ROUTES.REGISTER}`);
  };

  const handleBackToLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <EmailConfirmationPageView
      email={emailParam}
      onBackClick={handleBackClick}
      onBackToLogin={handleBackToLogin}
    />
  );
};

export default EmailConfirmationPage;
