import React from 'react';

import SelectAvatarSkinItem from '../SelectAvatarSkinItem';

import { useDataSwitcher } from '../../../../hooks';

import Totem1 from '../../../../public/images/Totems/totem1.png';
import Totem2 from '../../../../public/images/Totems/totem2.png';
import Totem3 from '../../../../public/images/Totems/totem3.png';
import Totem4 from '../../../../public/images/Totems/totem4.png';
import Totem5 from '../../../../public/images/Totems/totem5.png';
import Totem6 from '../../../../public/images/Totems/totem6.png';

import { ITotem } from './interfaces';

import { classes } from './styles';

const TOTEMS = [Totem1, Totem2, Totem3, Totem4, Totem5, Totem6];

const Totem = ({ onClick }: ITotem) => {
  const activeTotem = useDataSwitcher(TOTEMS, 500);

  return (
    <SelectAvatarSkinItem
      src={activeTotem}
      alt="ready player me"
      onClick={onClick}
      sx={classes.root}
    />
  );
};

export default Totem;
