import { TabPanel } from '@mui/lab';
import React, { useEffect, useState } from 'react';

import { Box, Stack, Button } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';

import HomeBottomButtonOptionsCam from '../../HomeBottomButtonOptionsCam/HomeBottomButtonOptionsCam';
import HomeBottomButtonOptionsSettings from '../../components/HomeBottomButtonOptionsSettings/HomeBottomButtonOptionsSettings';
import LoadingProgress from '../../../../../../components/LoadingProgress';
import PermissionsGuide from '../../../../../../public/images/ChromeOperation.jpg';
import { DeviceSettingVideoViewProps } from './interfaces';
import { useStyles } from './styles';

const DevicesSettingsVideoView = ({
  setCheckVideo,
  checkVideo,
  cameras,
  selectedCamera,
  selectCamera,
  changingDevice,
  translate,
}: DeviceSettingVideoViewProps) => {
  const classes = useStyles();
  const [showGuide, setShowGuide] = useState(false);

  useEffect(() => {
    navigator.permissions
      .query({
        name: 'camera' as any,
      })
      .then((permissionStatus) => {
        if (permissionStatus.state !== 'granted') {
          setShowGuide(true);
        }
      });
  }, []);

  return (
    <TabPanel value="camera" sx={classes.root}>
      {showGuide ? (
        <Box sx={classes.permissionsWrapper}>
          <img src={PermissionsGuide} alt="" />
        </Box>
      ) : (
        <Box sx={classes.subRoot} component="div">
          <Stack spacing={2} sx={classes.stack}>
            <HomeBottomButtonOptionsSettings
              devices={cameras}
              handleSelect={(e) => selectCamera(e.target.value)}
              label={translate('settingsModal.camera')}
              selectedValue={selectedCamera}
              SelectIcon={VideocamIcon}
            />
          </Stack>
          <Box component="div">
            {changingDevice ? (
              <LoadingProgress />
            ) : checkVideo ? (
              <Button onClick={() => setCheckVideo(!checkVideo)}>
                <HomeBottomButtonOptionsCam selectedCam={selectedCamera} />
              </Button>
            ) : (
              <Button
                onClick={() => setCheckVideo(!checkVideo)}
                sx={classes.button}
              >
                {translate('settingsModal.checkConfig')}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </TabPanel>
  );
};

export default DevicesSettingsVideoView;
