import React from 'react';
import { useTranslation } from 'react-i18next';

import DevicesSettingsVideoView from './DevicesSettingsVideoView';

import { useConference } from '../../../DashboardContent/Dolby';
import { useConferenceState } from '../../../../../../hooks/conferenceContext';

import { DeviceSettingVideoProps } from './interfaces';

const DevicesSettingsVideo = ({
  setCheckVideo,
  checkVideo,
}: DeviceSettingVideoProps) => {
  const { cameras, selectCamera, changingDevice } = useConference();
  const { activeCameraDeviceId, activeMicroDeviceId } = useConferenceState();

  const { t: translate } = useTranslation('common');

  return (
    <DevicesSettingsVideoView
      cameras={cameras}
      translate={translate}
      selectedMicrophone={activeMicroDeviceId}
      selectedCamera={activeCameraDeviceId}
      selectCamera={selectCamera}
      changingDevice={changingDevice}
      setCheckVideo={setCheckVideo}
      checkVideo={checkVideo}
    />
  );
};

export default DevicesSettingsVideo;
