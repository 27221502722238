import React, { useMemo } from 'react';

import ParticipantsTabView from './ParticipantsTabView';

import { useMytaverseEvent } from '../../../../providers';
import { useSearchField } from '../../../SearchField';
import { useGetActiveRooms } from './hooks';

import { ParticipantsTabProps } from './interfaces';
import { IParticipant } from '../../../../../../interfaces/participants';

const ParticipantsTab = ({ participants }: ParticipantsTabProps) => {
  const { rooms, currentParticipant } = useMytaverseEvent();
  const {
    inputValue,
    filteredData: filteredParticipants,
    handleInputChange,
    handleInputClear,
  } = useSearchField<IParticipant>(participants, 'fullName');
  const getActiveRooms = useGetActiveRooms();

  const activeRooms = useMemo(
    () => getActiveRooms(filteredParticipants, rooms),
    [filteredParticipants, rooms, getActiveRooms],
  );

  return (
    <ParticipantsTabView
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onInputClear={handleInputClear}
      activeRooms={activeRooms}
      currentParticipantId={currentParticipant?.id || ''}
    />
  );
};

export default ParticipantsTab;
