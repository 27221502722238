import { SPATIAL_AUDIO_OPTIONS } from './constants';

import { SpatialType } from '../../../../../interfaces/webSocketConnectionInfo';
import { JoinOptions } from '@voxeet/voxeet-web-sdk/types/models/Options';
import { GetConferenceAliasType, GetConferenceConfigType } from './interfaces';
import { IParticipantRegion } from '../../../../../interfaces/participants';

export const getConferenceConfig: GetConferenceConfigType = ({
  spatialType,
  dolbySpatialAudioScale,
}) => {
  let audioConfig: JoinOptions = SPATIAL_AUDIO_OPTIONS.spatialAudio;
  let conferenceScale: number | null = dolbySpatialAudioScale || 75;
  let bubblesWithNearbyParticipants = true;

  if (spatialType === SpatialType.SpatialMeeting) {
    audioConfig = SPATIAL_AUDIO_OPTIONS.spatialMeeting;
    conferenceScale = 200;
    bubblesWithNearbyParticipants = false;
  }

  if (spatialType === SpatialType.OneToMany) {
    audioConfig = SPATIAL_AUDIO_OPTIONS.oneToMany;
    conferenceScale = null;
    bubblesWithNearbyParticipants = false;
  }

  return {
    audioConfig,
    conferenceScale,
    bubblesWithNearbyParticipants,
  };
};

export const getGameSessionId = (roomGameSessionId: string): string => {
  if (!roomGameSessionId) return '';

  const gameSessionParts = roomGameSessionId.split('/');
  const lastSessionPart = gameSessionParts[gameSessionParts.length - 1];
  const isGsessSessionId = lastSessionPart.startsWith('gsess-');

  if (isGsessSessionId) {
    return lastSessionPart.replace('gsess-', '');
  }

  return '';
};

export const getConferenceAlias: GetConferenceAliasType = ({
  currentRegion,
  currentEvent,
  currentRoom,
  gameSessionId,
}) => {
  const lastPartConferenceAlias = currentRegion
    ? `event-${currentEvent.id}-room-${currentRoom.id}-region-${currentRegion.region}`
    : `event-${currentEvent.id}-room-${currentRoom.id}`;

  if (
    !!gameSessionId &&
    currentRegion?.regionSpatialType !== SpatialType.OneToMany
  ) {
    return `mytaverse-session-${gameSessionId}-${lastPartConferenceAlias}`;
  }

  return `mytaverse-${lastPartConferenceAlias}`;
};

export const getSpatialType = (
  currentRegion: IParticipantRegion | null,
): SpatialType => {
  if (currentRegion && currentRegion.regionSpatialType) {
    return currentRegion.regionSpatialType;
  }

  return SpatialType.SpatialAudio;
};

export const getNextSelectedCamera = (
  videoInput: MediaDeviceInfo[],
): string => {
  const storageSelectedCamera = localStorage.getItem('selectedCamera') || '';
  const deviceId = videoInput.length !== 0 ? videoInput[0].deviceId : '';

  return storageSelectedCamera || deviceId;
};
