import { MouseEvent, useState } from 'react';

export const useSelectDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    open,
    handleClick,
    handleClose,
  };
};
