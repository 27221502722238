import React, { ReactNode, useContext, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../providers';
import { useCurrentParticipantSpeaking } from '../Pureweb/hooks';
import { useGameCastConnection } from './hooks';
import { GameCastStreamSession } from './interfaces';
import { getUserBrowser } from './helpers';
import { WebsocketAction } from '../../../../../interfaces/webSocketConnectionInfo';
import { useConference } from '../Dolby';
// import { useNotification } from '../../../../../components/Notification';
// import { useTranslation } from 'react-i18next';

interface IGameCastContext {
  isStreamLoading: boolean;
  isStreamLoaded: boolean;
  setIsStreamLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStreamLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  streamSession: GameCastStreamSession | null;
}

// @ts-ignore
export const GameCastContext = React.createContext<IGameCastContext>({});

export const useGameCast = () => useContext(GameCastContext);

type GameCastProviderProps = {
  children: ReactNode;
};

export const GameCastProvider: React.FC<GameCastProviderProps> = ({
  children,
}) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const {
    isStreamLoaded,
    isStreamLoading,
    setIsStreamLoaded,
    setIsStreamLoading,
    streamSession,
    connectionTimer,
  } = useGameCastConnection(videoRef.current);

  const { sendJSONMessageToWebSocket } = useMytaverse();
  const {
    currentRoom,
    muted,
    trackAnalytics,
    gameSound,
    currentParticipant,
    setGameCastStreamId,
    setGameCastStreamRegion,
    // ue5WebsocketConnected,
  } = useMytaverseEvent();
  const { currentParticipantSpeaking } = useCurrentParticipantSpeaking();
  const { conference } = useConference();
  // const unrealEngineResponseTimer = useRef<NodeJS.Timer | null>(null);
  // const { t: translate } = useTranslation('common');

  // const { showNotification, getStreamingNotification } = useNotification();

  useEffect(() => {
    return () => {
      setGameCastStreamId('');
      setGameCastStreamRegion('');
    };
  }, []);

  const sendParticipantState = React.useCallback(() => {
    if (!currentRoom) {
      return;
    }

    const message = {
      action: WebsocketAction.ParticipantState,
      speaking: currentParticipantSpeaking || false,
      muted,
      dolbyConferenceAlias: conference ? conference.alias : null,
      dolbySpatialAudioStyle:
        conference && conference.params.spatialAudioStyle
          ? conference.params.spatialAudioStyle
          : 'disabled',
      dolbyParticipantId: currentParticipant?.dolbyParticipantId,
    };

    sendJSONMessageToWebSocket(message);
  }, [
    currentRoom,
    currentParticipantSpeaking,
    currentParticipant,
    muted,
    sendJSONMessageToWebSocket,
    conference,
  ]);

  // Sync participants state
  useEffect(() => {
    const interval = setInterval(async () => {
      await sendParticipantState();
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [sendParticipantState]);

  // useEffect(() => {
  //   if (!isStreamLoaded) {
  //     return;
  //   }
  //
  //   if (currentRoom && ue5WebsocketConnected) {
  //     if (unrealEngineResponseTimer.current) {
  //       clearTimeout(unrealEngineResponseTimer.current);
  //       unrealEngineResponseTimer.current = null;
  //     }
  //
  //     return;
  //   }
  //
  //   unrealEngineResponseTimer.current = setTimeout(() => {
  //     const notificationConfig = getStreamingNotification({
  //       message: translate('notifications.noGameSession'),
  //     });
  //
  //     showNotification(notificationConfig);
  //   }, 30000);
  // }, [isStreamLoaded, currentRoom, ue5WebsocketConnected]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = gameSound;
    }
  }, [gameSound, videoRef]);

  useAsyncEffect(async () => {
    if (!currentRoom || !streamSession) {
      return;
    }

    if (connectionTimer.current) {
      const connectionTime = new Date().getTime() - connectionTimer.current;
      await trackAnalytics('GAMECAST_CONNECTION_FINISH', {
        // TODO update bigquery
        // gamecastClientOptions: {
        //   streamGroupId: streamId,
        //   participantId: currentParticipant.id,
        // },
        duration: connectionTime,
      });

      console.log(`GAME CAST CONNECTION TIME ${connectionTime / 1000}s`);
      connectionTimer.current = null;
    }
  }, [currentRoom]);

  useAsyncEffect(() => {
    const browserType = getUserBrowser();

    console.log(`ENTERING BY ${browserType} BROWSER`);
  }, []);

  return (
    <GameCastContext.Provider
      value={{
        isStreamLoading,
        isStreamLoaded,
        setIsStreamLoaded,
        setIsStreamLoading,
        videoRef,
        streamSession,
      }}
    >
      {children}
    </GameCastContext.Provider>
  );
};
