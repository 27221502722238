import React from 'react';

import ChatNotificationView from './ChatNotificationView';

import { useNotification } from '../hooks';

import { IChatNotification } from './interfaces';

const ChatNotification = ({ ref, id, message, onReply }: IChatNotification) => {
  const { hideNotification } = useNotification();

  const handleReply = () => {
    onReply(message.user?.id || '');
    handleClose();
  };

  const handleClose = () => hideNotification(id);

  return (
    <ChatNotificationView
      ref={ref}
      message={message}
      onReply={handleReply}
      onClose={handleClose}
    />
  );
};

export default ChatNotification;
