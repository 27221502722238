import { COLORS } from '../../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    p: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  icon: {
    color: COLORS.LIGHT_GREY,
  },

  generalButton: {
    display: 'flex',
    alignItems: 'center',
    mt: 3,
    justifyContent: 'center',
  },
  slider: {
    width: 520,
    mt: 3,
    '& span': {
      color: COLORS.MYTAVERSE_BLUE,
    },
    '& p': {
      color: COLORS.WHITE,
      fontSize: 12,
    },
  },
  checkBox: {
    width: 520,
    mt: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& span': {
      color: COLORS.MYTAVERSE_BLUE,
    },
    '& p': {
      color: COLORS.WHITE,
      fontSize: 12,
    },
  },
});
