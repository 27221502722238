import { COLORS, colors } from '../../../../../constants/colors';
import { IStyles } from '../../../../../interfaces/styles';

export const classes: IStyles = {
  root: {
    padding: '4px',
    width: 152,
    borderRadius: '8px',
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '30px',
    letterSpacing: '-0.25px',
    color: colors.white,
    textTransform: 'capitalize',
  },
  button: {
    border: `1px solid ${colors.white}`,
    '&:hover': {
      backgroundColor: colors.white,
      color: '#1C1C1C',
    },
  },
  submit: {
    border: '1px solid #FC5151',
    '&:hover': {
      backgroundColor: COLORS.ERROR,
      color: colors.white,
      border: `1px solid ${COLORS.ERROR}`,
    },
  },
};
