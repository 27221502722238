import React from 'react';
import Webcam from 'react-webcam';
import { withTranslation } from 'react-i18next';

import { Box, Button, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ITakePhoto } from './interfaces';

import { classes } from './styles';

const TakePhoto = ({
  webcamRef,
  setShowTakePhoto,
  onTakePhoto,
  t: translate,
}: ITakePhoto) => {
  return (
    <Box component="div">
      <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
      <Stack flexDirection="row" sx={classes.button}>
        <Button onClick={() => setShowTakePhoto(false)}>
          <CloseIcon />
          {translate('userProfile.cancel')}
        </Button>
        <Button onClick={onTakePhoto}>
          {translate('userProfile.takePhoto')}
        </Button>
      </Stack>
    </Box>
  );
};

export default withTranslation('common')(TakePhoto);
