import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import DevicesTestWrapper from '../DevicesTestWrapper';
import LoadingProgress from '../../../../components/LoadingProgress';

import { useInitWebcamDevices } from './hooks';

import { useStyles } from './styles';
import CamerasSelection from './CamerasSelection';
import { useConferenceState } from '../../../../hooks/conferenceContext';

const WebcamTest = () => {
  const classes = useStyles();
  const { initializing, videoRef, devices, startCameraStream } =
    useInitWebcamDevices();
  const { activeCameraDeviceId, setActiveCameraDeviceId } =
    useConferenceState();
  const { t: translate } = useTranslation('common');

  const title = translate('devicesTest.webcamTest.title');
  const description = translate('devicesTest.webcamTest.description');

  const handleDeviceChange = async (deviceId: string) => {
    setActiveCameraDeviceId(deviceId);
    await startCameraStream(deviceId);
  };

  if (initializing) {
    return <LoadingProgress />;
  }

  return (
    <DevicesTestWrapper title={title} description={description}>
      <Box sx={classes.root}>
        <video ref={videoRef} autoPlay />
        <CamerasSelection
          devices={devices}
          activeDeviceId={activeCameraDeviceId}
          onDeviceChange={handleDeviceChange}
          sx={classes.selection}
        />
      </Box>
      <Typography sx={classes.helper}>
        {translate('devicesTest.webcamTest.helper')}
      </Typography>
    </DevicesTestWrapper>
  );
};

export default WebcamTest;
