import React from 'react';

import { Box, Modal } from '@mui/material';

import UploadButtons from './UploadButtons';
import TakePhoto from './TakePhoto';

import { IUploadImageModalView } from './interfaces';

import { useStyles } from './styles';

const UploadImageModalView = ({
  open,
  onClose,
  webcamRef,
  showTakePhoto,
  setShowTakePhoto,
  onTakePhoto,
  hasCamera,
  onChangeUploadImage,
}: IUploadImageModalView) => {
  const classes = useStyles(showTakePhoto);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop
    >
      <Box sx={classes.root} component="div">
        {showTakePhoto ? (
          <TakePhoto
            webcamRef={webcamRef}
            setShowTakePhoto={setShowTakePhoto}
            onTakePhoto={onTakePhoto}
          />
        ) : (
          <UploadButtons
            hasCamera={hasCamera}
            setShowTakePhoto={setShowTakePhoto}
            onChangeUploadImage={onChangeUploadImage}
            onClose={onClose}
          />
        )}
      </Box>
    </Modal>
  );
};

export default UploadImageModalView;
