import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, IconButton, Stack, Toolbar } from '@mui/material';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuIcon from '@mui/icons-material/Menu';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LogoutIcon from '@mui/icons-material/Logout';
import CircleIcon from '@mui/icons-material/Circle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import DashboardBarButton from './DashboardBarButton';
import UserAvatarButton from '../../../../../components/UserAvatarButton';
import AdminTools from './AdminTools';
import { MytaverseLogoWhite } from '../../../../../icons/MytaverseLogoWhite';

import { EventDrawerTabs } from '../../../constants';

import { IDashboardTopBarContentView } from './interfaces';

import { useStyles } from './styles';

const DashboardTopBarContentView = ({
  currentRoom,
  isOpenChat,
  hasMediaStream,
  showInviteUserBtn,
  participantTotal,
  onOpenEventDrawer,
  onFullscreenClick,
  onOpenConfirmLogoutModal,
  onOpenInviteUserModal,
  t: translate,
}: IDashboardTopBarContentView) => {
  const classes = useStyles({
    isOpenChat,
    hasMediaStream,
  });

  return (
    <Toolbar sx={classes.toolbar}>
      {currentRoom ? (
        <>
          <Box sx={classes.root} component="div">
            <DashboardBarButton
              buttonIcon={<LocationOnIcon />}
              onClick={() => onOpenEventDrawer(EventDrawerTabs.Locations)}
            >
              {currentRoom.name || 'Lobby'}
            </DashboardBarButton>
            <DashboardBarButton
              buttonIcon={<CircleIcon sx={classes.indicator} />}
              onClick={() => onOpenEventDrawer(EventDrawerTabs.Participants)}
            >
              {participantTotal}
            </DashboardBarButton>
          </Box>
          <IconButton
            sx={classes.iconButton}
            onClick={() => onOpenEventDrawer(EventDrawerTabs.Locations)}
          >
            <MenuIcon />
          </IconButton>
        </>
      ) : (
        <Box sx={classes.rootLoading}>
          <Stack flexDirection="row" alignItems="center">
            {window.location.hostname.includes('troovrs') ? null : (
              <MytaverseLogoWhite />
            )}
          </Stack>
        </Box>
      )}
      <Stack sx={classes.stack} direction="row">
        <DashboardBarButton
          buttonIcon={<FullscreenIcon />}
          onClick={onFullscreenClick}
        >
          {translate('tooltips.fullscreen')}
        </DashboardBarButton>
        {showInviteUserBtn ? (
          <DashboardBarButton
            onClick={onOpenInviteUserModal}
            buttonIcon={<PersonAddIcon sx={classes.addPersonIcon} />}
            sx={classes.inviteUserBtn}
          />
        ) : null}
        {showInviteUserBtn ? <AdminTools /> : null}
        <UserAvatarButton />
        <DashboardBarButton
          onClick={onOpenConfirmLogoutModal}
          buttonIcon={<LogoutIcon sx={classes.logoutIcon} />}
        />
      </Stack>
    </Toolbar>
  );
};

export default withTranslation('common')(DashboardTopBarContentView);
