import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Button, Stack } from '@mui/material';

import SelectAvatarSkinItem from '../SelectAvatarSkinItem';
import Totem from '../Totem';

import { IPreviousSelectedAvatarView } from './interfaces';

import { useStyles } from './styles';

const PreviousSelectedAvatarView = ({
  previousSkin,
  onChangeAvatar,
  onSelectAvatar,
  onSelectPrevAvatar,
}: IPreviousSelectedAvatarView) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <Stack sx={classes.prevAvatarButtons}>
        <Button onClick={onChangeAvatar}>Change Avatar</Button>
        <Button onClick={onSelectAvatar}>Continue</Button>
      </Stack>
      {previousSkin ? (
        <SelectAvatarSkinItem
          src={previousSkin.fullBodyImage}
          alt="skin preview"
          onClick={onSelectAvatar}
        />
      ) : (
        <Totem onClick={onSelectPrevAvatar} />
      )}
    </Box>
  );
};

export default withTranslation('common')(PreviousSelectedAvatarView);
