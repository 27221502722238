import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';
import { errorsMessage, firebaseConfig } from '../config/Firebase';
import { AuthProvider } from '@firebase/auth';
import { SocialProviders } from '../interfaces';

export interface FirebaseResponseProps {
  success: boolean;
  user?: {
    firstName: string;
    lastName: string;
    email: string;
    providerId: string | number;
    provider: SocialProviders;
    profileImage: string | null;
  };
  error?: {
    code: string;
    message: string;
  };
}

const Firebase = {
  async signInWithProvider(provider: SocialProviders) {
    initializeApp(firebaseConfig);
    const auth = getAuth();
    const authProvider = getAuthProvider(provider);
    const response: FirebaseResponseProps = {
      success: false,
    };

    await signInWithPopup(auth, authProvider as AuthProvider)
      .then((result) => {
        if (result.user && result.user.providerData.length > 0) {
          const providerData = result.user.providerData[0];
          const splitedNames = splitNames(
            providerData.displayName || result.user.displayName || '',
          );
          response.user = {
            firstName: splitedNames.firstName,
            lastName: splitedNames.lastName,
            email: result.user.email || providerData.email || '',
            provider,
            providerId: providerData.uid,
            profileImage: providerData.photoURL || '',
          };
          response.success = true;
        }
      })
      .catch(({ code, message }) => {
        response.error = {
          message: errorsMessage[code] || message,
          code,
        };
      });

    return response;
  },
};

const getAuthProvider = (providerName: string) => {
  switch (providerName) {
    case 'apple': {
      const appleProvider = new OAuthProvider('apple.com');
      appleProvider.addScope('email');
      appleProvider.addScope('name');
      return appleProvider;
    }
    case 'microsoft': {
      const microsoftProvider = new OAuthProvider('microsoft.com');
      microsoftProvider.addScope('email');
      return microsoftProvider;
    }
    case 'google': {
      const googleProvider = new GoogleAuthProvider();
      googleProvider.addScope('email');
      return googleProvider;
    }
    case 'facebook': {
      const facebookProvider = new FacebookAuthProvider();
      facebookProvider.addScope('email');
      return facebookProvider;
    }
  }
};

const splitNames = (fullName: string) => {
  const names: { firstName: string; lastName: string } = {
    firstName: '',
    lastName: '',
  };
  const [firstName, ...rest] = fullName.split(' ');
  names.firstName = firstName;
  if (rest.length > 0) {
    names.lastName = rest.join().replaceAll(',', ' ');
  } else {
    names.lastName = firstName;
  }
  return names;
};

export default Firebase;
