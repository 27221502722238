import React from 'react';

import { Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

import { usePreventGameKeydownListening } from '../../../../../../../../../hooks';

import { ISearchField } from './interfaces';

import { useStyles } from './styles';

const SearchField = ({ value, onChange, onClear, sx = {} }: ISearchField) => {
  const classes = useStyles({ value });
  const inputClass = { ...classes.input, ...sx };
  const ref = usePreventGameKeydownListening<HTMLInputElement>();

  return (
    <Input
      ref={ref}
      value={value}
      onChange={onChange}
      startAdornment={<SearchIcon sx={classes.searchIcon} />}
      endAdornment={
        value ? <CancelIcon sx={classes.clearIcon} onClick={onClear} /> : null
      }
      sx={inputClass}
    />
  );
};

export default SearchField;
