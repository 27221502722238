import React from 'react';

import { Box, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import SearchField from './SearchField';
import SortDropdown from './SortDropdown';

import { IFilesHeader } from './interfaces';

import { useStyles } from './styles';

const FilesHeader = ({
  value,
  onChange,
  onClear,
  activeSortField,
  activeSortType,
  activeSortDirection,
  onSort,
  currentPage,
  pageNumber,
  onPrevPage,
  onNextPage,
  showPagination,
}: IFilesHeader) => {
  const hasPrevPage = currentPage > 1;
  const hasNextPage = currentPage < pageNumber;

  const classes = useStyles({ hasPrevPage, hasNextPage, showPagination });

  return (
    <Box sx={classes.root}>
      <Box sx={classes.searchSection}>
        <SearchField
          value={value}
          onChange={onChange}
          onClear={onClear}
          sx={classes.searchField}
        />
        <SortDropdown
          activeSortField={activeSortField}
          activeSortType={activeSortType}
          activeSortDirection={activeSortDirection}
          onSort={onSort}
        />
      </Box>
      {showPagination && (
        <Box sx={classes.paginationSection}>
          <ChevronLeftIcon onClick={onPrevPage} sx={classes.prevIcon} />
          <Typography sx={classes.page}>{currentPage}</Typography>
          <Typography sx={classes.separator}>|</Typography>
          <Typography sx={classes.page}>{pageNumber}</Typography>
          <ChevronRightIcon onClick={onNextPage} sx={classes.nextIcon} />
        </Box>
      )}
    </Box>
  );
};

export default FilesHeader;
