import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EmojiIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 0C5.34375 0 0 5.39062 0 12C0 18.6562 5.34375 24 12 24C18.6094 24 24 18.6562 24 12C24 5.39062 18.6094 0 12 0ZM15.75 8.25C16.5469 8.25 17.2031 8.95312 17.2031 9.75C17.2031 10.5938 16.5469 11.25 15.75 11.25C14.9062 11.25 14.2031 10.5938 14.2031 9.75C14.2031 8.95312 14.9062 8.25 15.75 8.25ZM8.20312 8.25C9 8.25 9.70312 8.95312 9.70312 9.75C9.70312 10.5938 9 11.25 8.20312 11.25C7.35938 11.25 6.70312 10.5938 6.70312 9.75C6.70312 8.95312 7.40625 8.25 8.20312 8.25ZM17.3438 16.2656C16.0312 17.8594 14.0625 18.75 12 18.75C9.89062 18.75 7.92188 17.8125 6.60938 16.2188C5.95312 15.4688 7.125 14.4844 7.73438 15.2344C8.8125 16.5469 10.3594 17.25 12 17.25C13.6406 17.25 15.1406 16.5469 16.2188 15.2812C16.8281 14.5312 18 15.4688 17.3438 16.2656Z"
      fill="currentColor"
    />
  </SvgIcon>
);
