import { colors } from '../../../../../../../constants/colors';

export const classes = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.gray,
    padding: 2,
  },
  time: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: 1,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: colors.white_60,
  },
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: 1,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: colors.white,
    p: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  image: {
    height: 336,
    '& img': {
      width: '100%',
    },
  },
  imageContent: {
    padding: '8px 0px 8px 8px',
  },
};
