import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

export const DeviceOrientationIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="118"
    height="144"
    viewBox="0 0 118 144"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52 0.5C47.5817 0.5 44 4.08173 44 8.5V135.5C44 139.918 47.5817 143.5 52 143.5H110C114.418 143.5 118 139.918 118 135.5V8.5C118 4.08172 114.418 0.5 110 0.5H52ZM72.5 5.5C71.6716 5.5 71 6.17157 71 7C71 7.82843 71.6716 8.5 72.5 8.5H89.5C90.3284 8.5 91 7.82843 91 7C91 6.17157 90.3284 5.5 89.5 5.5H72.5ZM88 134.5C88 138.366 84.866 141.5 81 141.5C77.134 141.5 74 138.366 74 134.5C74 130.634 77.134 127.5 81 127.5C84.866 127.5 88 130.634 88 134.5ZM50 11.5C48.3431 11.5 47 12.8431 47 14.5V122.5C47 124.157 48.3431 125.5 50 125.5H112C113.657 125.5 115 124.157 115 122.5V14.5C115 12.8431 113.657 11.5 112 11.5H50Z"
      fill="black"
    />
    <path
      d="M22.4258 39.4812L26.9814 24.5L12 29.0559L15.5869 32.6426C5.85449 44.3406 0 59.3804 0 75.7874C0 92.4138 6.01172 107.636 15.9795 119.4L19.5283 115.851C10.459 105.003 5 91.0327 5 75.7874C5 60.7615 10.3027 46.9739 19.1387 36.1941L22.4258 39.4812Z"
      fill="black"
    />
  </SvgIcon>
);
