import React, { ChangeEvent, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';

import PasswordRecoveryForm from './PasswordRecoveryForm';
import SuccessPasswordRecovery from './SuccessPasswordRecovery';

import { useUpdatePassword } from './hooks';

import { validateForm } from '../../components/PasswordValidation';

import { RecoveryPasswordSchema } from './validation';

import { IFormValues } from './interfaces';

const PasswordRecovery = () => {
  const [openPasswordRules, setOpenPasswordRules] = useState(false);

  const { token } = useParams<{ token: string }>();
  const { loading, success, updatePassword } = useUpdatePassword();

  const onUpdatePassword = ({ password }: IFormValues) => {
    updatePassword(password, token || '');
  };

  const {
    values,
    touched,
    errors,
    setFieldTouched,
    handleChange,
    handleSubmit,
  } = useFormik<IFormValues>({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    onSubmit: onUpdatePassword,
    validationSchema: RecoveryPasswordSchema,
    enableReinitialize: true,
    validate: (values) => validateForm(RecoveryPasswordSchema, values),
  });

  const handleInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFieldTouched(event.target.name, false, false);
    handleChange(event);
  };

  return (
    <>
      {success ? (
        <SuccessPasswordRecovery />
      ) : (
        <PasswordRecoveryForm
          values={values}
          errors={errors}
          touched={touched}
          onChange={handleInputChange}
          onPasswordHandler={setOpenPasswordRules}
          loading={loading}
          onSubmit={handleSubmit}
          openPasswordRules={openPasswordRules}
        />
      )}
    </>
  );
};

export default PasswordRecovery;
