import { useMemo } from 'react';
import { COLORS } from '../../../../../../constants/colors';
import { IStyles } from '../../../../../../interfaces/styles';

export const useStyles = (): IStyles =>
  useMemo(
    () => ({
      root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignContent: 'space-between',
      },

      form: {
        m: 0,
        width: '100%',
      },

      formIcon: {
        color: COLORS.MEDIUM_GREY,
      },

      input: {
        backgroundColor: COLORS.DARK_GREY,
        borderRadius: 1,
        height: 50,
        color: COLORS.MEDIUM_GREY,
        width: '100%',
        mt: 3,
      },

      icon: {
        mr: 3,
      },

      group: {
        mt: 3,
        width: '100%',
        display: 'flex',
      },

      groupName: {
        fontSize: 24,
        textAlign: 'center',
        color: COLORS.SUBTITLE,
        fontWeight: 500,
      },

      groupLine: {
        width: '40%',
        height: '1px',
        borderRadius: 1,
        backgroundColor: COLORS.MEDIUM_GREY,
      },
    }),
    [],
  );
