import ExpressYourself from './TutorialTips/ExpressYourself';
import Chat from './TutorialTips/Chat';
import Camera from './TutorialTips/Camera';
import ParticipantsList from './TutorialTips/ParticipantsList';
import FindYourFriends from './TutorialTips/FindYourFriends';
import SafeSpace from './TutorialTips/SafeSpace';

export const TIPS = [
  ExpressYourself,
  Chat,
  Camera,
  ParticipantsList,
  FindYourFriends,
  SafeSpace,
];
