import { useState } from 'react';

import { UseOverflowType } from './interfaces';

// fix the problem when element is rounded to the upper value
const ROUND_CORRECTION = 1;

export const useOverflow: UseOverflowType = () => {
  const [isOverflow, setIsOverflow] = useState<boolean | null>(null);

  const resetIsOverflow = () => {
    if (isOverflow !== null) {
      setIsOverflow(null);
    }
  };

  const getElWidth = (el: HTMLElement): number => {
    const gaps = ['paddingLeft', 'paddingRight', 'borderLeft', 'borderRight'];
    return el.offsetWidth - getGapsSum(el, gaps);
  };

  const getGapsSum = (el: HTMLElement, params: string[] = []): number => {
    if (!el || !params?.length) {
      return 0;
    }

    const style = getComputedStyle(el);

    return params.reduce((sum, param) => {
      const paramValue = style.getPropertyValue(param);
      const value = parseFloat(paramValue) || 0;
      return sum + value;
    }, 0);
  };

  const checkOverflow = (el: HTMLSpanElement) => {
    if (!el || !el.parentNode || isOverflow !== null) {
      return;
    }

    let isElFound = false;
    let parentWidth = getElWidth(el.parentNode as HTMLElement);
    const childrenArray = [...el.parentNode.children];

    childrenArray.forEach((child) => {
      const htmlChild = child as HTMLElement;

      if (htmlChild.offsetWidth !== el.offsetWidth) {
        const gaps = ['marginLeft', 'marginRight'];
        parentWidth -= htmlChild.offsetWidth + getGapsSum(htmlChild, gaps);
      }

      if (htmlChild.offsetWidth === el.offsetWidth && !isElFound) {
        isElFound = true;
      }
    });

    const elWidth = getElWidth(el);

    setIsOverflow(elWidth - ROUND_CORRECTION > parentWidth);
  };

  return { isOverflow, checkOverflow, resetIsOverflow };
};
