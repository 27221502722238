import React from 'react';
import { IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { IArrowButton } from './interfaces';

import { useStyles } from './styles';

const ArrowButton = ({
  sx = {},
  onClick = () => {},
  forwardIcon = false,
}: IArrowButton) => {
  const classes = useStyles();
  const rootSx = { ...classes.root, ...sx };

  const Icon = forwardIcon ? ArrowForwardIosIcon : ArrowBackIosIcon;

  return (
    <IconButton sx={rootSx} onClick={onClick} disableRipple>
      <Icon sx={classes.icon} />
    </IconButton>
  );
};

export default ArrowButton;
