import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import DevicesSettingsModalView from './DevicesSettingsModalView';
import { DevicesSettingsModalProps } from './interfaces';

const DevicesSettingsModal = ({ open, handleClose }: DevicesSettingsModalProps) => {
  const [checkVideo, setCheckVideo] = useState(false);
  const { t: translate } = useTranslation('common');
  const [settingsTab, setSettingsTab] = useState('sound');

  return <DevicesSettingsModalView
    checkVideo={checkVideo}
    settingsTab={settingsTab}
    setCheckVideo={setCheckVideo}
    setSettingsTab={setSettingsTab}
    open={open}
    translate={translate}
    handleClose={handleClose}
  />;
};

export default DevicesSettingsModal;
