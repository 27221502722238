import React, { Suspense } from 'react';

import { Box } from '@mui/material';

import { useEmojiContext, useMessageInputContext } from 'stream-chat-react';
import { StreamChatGenerics } from 'stream-chat-react/dist/stories/utils';

import { useStyles } from './styles';

const ChatEmojiPicker = () => {
  const classes = useStyles();

  const { emojiConfig, EmojiPicker: EmojiPickerComponent } =
    useEmojiContext('EmojiPicker');

  const { emojiPickerIsOpen, emojiPickerRef, onSelectEmoji } =
    useMessageInputContext<StreamChatGenerics>('EmojiPicker');

  const { emojiData } = emojiConfig || {};

  if (!emojiPickerIsOpen || !emojiData) return null;

  return (
    <Box
      ref={emojiPickerRef}
      sx={classes.root}
      className={
        'str-chat__emoji-picker-container str-chat__input--emojipicker'
      }
    >
      <Suspense fallback={null}>
        <EmojiPickerComponent
          color="#ae65c5"
          data={emojiData}
          emoji="point_up"
          onSelect={onSelectEmoji}
          set="apple"
          showPreview={false}
          showSkinTones={false}
          title="Pick your emoji"
          theme="dark"
          useButton
        />
      </Suspense>
    </Box>
  );
};

export default ChatEmojiPicker;
