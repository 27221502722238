import { COLORS } from '../../../../../../constants/colors';
import { IStyles } from '../../../../../../interfaces/styles';

export const useStyles = (): IStyles => ({
  root: {
    mt: 4,
  },

  label: {
    color: COLORS.WHITE,
    border: 'none',
    left: '-15px !important',
    width: '480px !important',
    maxWidth: 'unset',
    textAlign: 'left',
  },

  select: {
    flexDirection: 'row-reverse',
    width: 520,
    height: 40,
    mt: 8,
    color: COLORS.WHITE,
    '& .MuiInput-input': {
      textAlign: 'center',
      ml: 1,
    },

    '&.MuiInputBase-formControl': {
      border: 1,
      borderColor: COLORS.GREY,
      borderRadius: 1,
    },

    '& svg': {
      color: COLORS.WHITE,
    },
    '& .selectIcon': {
      ml: 1,
      opacity: 0.5,
      color: COLORS.AZURE,
    },
  },
});
