import React from 'react';

import { Box, Typography } from '@mui/material';

import AppHeader from '../../../../components/AppHeader';
import Footer from '../../../../components/Footer';

import { DeviceOrientationIcon } from '../../../../icons';

import { useStyles } from './styles';

const OrientationOverlay = () => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <AppHeader isAuthorized showEventsDropdown />
      <Box sx={classes.container}>
        <Box sx={classes.content}>
          <DeviceOrientationIcon sx={classes.icon} />
          <Typography sx={classes.title}>Please, turn your device.</Typography>
          <Typography sx={classes.description}>
            For a better experience, please turn your device to the{' '}
            <Typography component="span" sx={classes.boldText}>
              landscape position.
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default OrientationOverlay;
