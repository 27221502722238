import { colors } from '../../../../../../../../constants/colors';

export const classes = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 3,
    padding: '18px 8px',
    height: 332,
  },
  emptyContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 3,
    padding: '18px 8px',
    height: 332,
  },
  text: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: colors.white,
    opacity: 0.4,
  },
};
