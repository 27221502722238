import React from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import { IBackButtonOld } from './interfaces';

import { useStyles } from './styles';

const BackButtonOld = ({
  onClick,
  text = '',
  t: translate,
}: IBackButtonOld) => {
  const classes = useStyles();

  const fullText = `${translate('back')} ${text}`;

  return (
    <Button onClick={onClick} sx={classes.root}>
      <ArrowLeftIcon sx={classes.icon} fontSize="large" />
      <Typography sx={classes.text}>{fullText}</Typography>
    </Button>
  );
};

export default withTranslation('common')(BackButtonOld);
