import { useTranslation } from 'react-i18next';

import Hello from '../../../../../../../../public/gifs/hello.gif';
import Clap from '../../../../../../../../public/gifs/clapping.gif';
import Thumbsup from '../../../../../../../../public/gifs/Thumbsup.gif';
import Victory from '../../../../../../../../public/gifs/victory.gif';
import Waving from '../../../../../../../../public/gifs/waving.gif';
import Twist from '../../../../../../../../public/gifs/twist.gif';

import { IReaction } from '../interfaces';

export const useReactions = () => {
  const { t: translate } = useTranslation('common');

  const reactions: IReaction[] = [
    {
      previewImage: Hello,
      id: 'waving',
      title: translate('actions.hello'),
    },
    {
      previewImage: Clap,
      id: 'clap',
      title: translate('actions.clap'),
    },
    {
      previewImage: Thumbsup,
      id: 'agreeing',
      title: translate('actions.agree'),
    },
    {
      previewImage: Victory,
      id: 'victory',
      title: translate('actions.victory'),
    },
    {
      previewImage: Waving,
      id: 'waving2',
      title: translate('actions.waving'),
    },
    {
      previewImage: Twist,
      id: 'cooldance',
      title: translate('actions.dance'),
    },
  ];

  return reactions;
};
