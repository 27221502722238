import { COLORS } from '../../../../../constants/colors';
import { IStyles } from '../../../../../interfaces/styles';

export const useStyles = (): IStyles => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: [280, 640],
    height: 'auto',
    border: `2px solid ${COLORS.BLACK}`,
    boxShadow: 24,
    p: ['20px 10px', 3],
    pt: 2.5,
    backdropFilter: 'blur(12px)',
    backgroundColor: COLORS.NEW_BLACK,
  },
  title: {
    textAlign: 'left',
    color: COLORS.WHITE,
    fontSize: 18,
    textTransform: 'capitalize',
  },
  settingsContainer: {
    '& .MuiFormControl-root:hover fieldset': {
      borderColor: COLORS.BLUE,
      borderWidth: '2px',
    },

    '& .MuiFormLabel-root': {
      color: COLORS.WHITE,
    },

    '& fieldset': {
      borderColor: COLORS.BLUE,
      borderWidth: '2px',
    },

    '& .MuiInputBase-input': {
      background: 'none',
      color: COLORS.WHITE,
      opacity: 1,
    },

    '& .MuiIconButton-root': {
      color: COLORS.WHITE,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    '& svg': {
      color: COLORS.WHITE,
    },
  },
});
