import { COLORS } from '../../../../constants/colors';

import { IStyles } from '../../../../interfaces/styles';

export const useStyles = (showOverlay: boolean): IStyles => ({
  root: {
    position: 'absolute',
    top: 0,
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    backgroundColor: '#cccccc',
    zIndex: -1,
    '& #poi_view': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      transform: 'translate(-50%, -50%)',
      '& iframe': {
        pointerEvents: 'all',
        width: '60vw',
        height: '80vh',
        border: 'none',
      },
      '& button': {
        '& svg': {
          color: COLORS.RED,
          fontSize: 'bold',
        },
      },
    },
  },
  homePage: {
    display: showOverlay ? 'none' : 'block',
  },
});
