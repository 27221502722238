import { useEffect, useRef, useState } from 'react';

import { UseManualTimerResponseType } from './interfaces';

export const useTimer = (): number => {
  const [time, setTime] = useState(5);
  const interval = useRef<NodeJS.Timer>();

  useEffect(() => {
    interval.current = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1200);

    if (time <= 1) {
      clearInterval(interval.current);
    }

    return () => clearInterval(interval.current);
  }, [time]);

  return time;
};

export const useManualTimer = (): UseManualTimerResponseType => {
  const [timer, setTimer] = useState(5);
  const intervalIdRef = useRef<NodeJS.Timer>();

  useEffect(() => clearInterval(intervalIdRef.current), []);

  const startTimer = () => {
    intervalIdRef.current = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
  };

  const clearTimer = () => {
    setTimer(5);
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = undefined;
  };

  return { timer, intervalId: intervalIdRef.current, startTimer, clearTimer };
};
