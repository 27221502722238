import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SwiperSlide } from 'swiper/react/swiper-react';

import SwiperWrapper from '../SwiperWrapper';
import SelectAvatarSkinItem from '../SelectAvatarSkinItem';
import Totem from '../Totem';

import { useMytaverseEvent } from '../../../dashboard/providers';

import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';
import { getAvatarImage } from './helpers';

import ROUTES from '../../../../constants/routes';
import {
  AVATAR_SKIN_BREAKPOINTS,
  AVATAR_SKIN_SLIDER_WIDTH,
} from '../../constants';

import { IChooseAvatar } from './interfaces';
import { IAvatar } from '../../../../interfaces/avatar';

const ChooseAvatar = ({ loop }: IChooseAvatar) => {
  const { avatars, getSkins, setCurrentAvatarId } = useMytaverseEvent();
  const navigate = useNavigate();

  const handleAvatarClick = (avatar: IAvatar) => {
    const avatarId = avatar.idAvatar;

    sendCustomSentryBreadcrumb({ message: 'select avatar' });
    setCurrentAvatarId(avatarId);
    getSkins(avatarId);
    navigate(ROUTES.SELECT_AVATAR_SKIN);
  };

  const handleCustomAvatarClick = () => navigate(ROUTES.READY_PLAYER);

  return (
    <SwiperWrapper
      breakpoints={AVATAR_SKIN_BREAKPOINTS}
      loop={loop}
      sliderWidth={AVATAR_SKIN_SLIDER_WIDTH}
    >
      {avatars.map((avatar) => (
        <SwiperSlide key={avatar.id}>
          <SelectAvatarSkinItem
            src={getAvatarImage(avatar)}
            alt={avatar.avatarName}
            onClick={() => handleAvatarClick(avatar)}
          />
        </SwiperSlide>
      ))}
      <SwiperSlide>
        <Totem onClick={handleCustomAvatarClick} />
      </SwiperSlide>
    </SwiperWrapper>
  );
};

export default ChooseAvatar;
