import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenShare } from '@mui/icons-material';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';

import AimPulse from '../../AimPulse';
import InitialMuteModal from '../../InitialMuteModal';
import DevicesSettingsModal from '../DevicesSettingsModal';
import ParticipantAvatar from '../../DashboardContent/ParticipantAvatar';
import HomeBarButton from '../../HomeBarButton';
import WarningButtonBadge from '../../WarningButtonBadge';

import { DolbyService, useConference } from '../../DashboardContent/Dolby';
import { useMytaverseEvent } from '../../../providers';
import { useIsSpeakerMutedNotificationShown } from '../../DashboardContent/Dolby/hooks';
import { useModal } from '../../../../../hooks/modal';
import { useOpenDevicesSettingsModal, useShowInitialMuteModal } from './hooks';
import { useNotification } from '../../../../../components/Notification';

import { sendCustomSentryBreadcrumb } from '../../../../../helpers/sentry';
import { getCatchErrorMessage } from '../../../../../helpers/error';

import { useStyles } from './styles';

const HomeBottomButtonOptions = () => {
  const classes = useStyles();

  const {
    microphones,
    videoStarted,
    toggleVideo,
    cameras,
    isConferenceInitialized,
    isFirstPersonView,
    setIsFirstPersonView,
    disabledPersonView,
    isConferenceListener,
  } = useConference();
  const { muted, setMuted, currentEvent } = useMytaverseEvent();
  const { screenMediaStreams } = useConference();
  const { currentParticipant } = useMytaverseEvent();
  const { speakerMutedNotification, resetSpeakerMutedNotification } =
    useIsSpeakerMutedNotificationShown(isConferenceInitialized, muted);
  const { t: translate } = useTranslation('common');

  const initialMuteModalData = useModal();
  const devicesSettingsModalData = useOpenDevicesSettingsModal();
  const { showNotification, getDolbyNotification } = useNotification();

  const currentEventId = currentEvent?.id || '';
  const currentParticipantId = currentParticipant?.id || '';
  const cameraDeviceId = cameras.length ? cameras[0].deviceId : '';
  const microDeviceId = microphones.length ? microphones[0].deviceId : '';

  const showDolbyShareScreen = !!currentParticipant?.isSpeaker;

  const sharingMedia = screenMediaStreams.length !== 0;
  const currentSharing =
    screenMediaStreams?.[0]?.participantId === currentParticipantId;

  useShowInitialMuteModal({
    eventId: currentEvent?.id || '',
    initialMuteModalData,
  });

  const handleVideoClick = () => {
    sendCustomSentryBreadcrumb({
      message: `${!videoStarted ? 'start' : 'stop'} video`,
    });
    toggleVideo();
  };

  const handleMicroClick = () => {
    sendCustomSentryBreadcrumb({
      message: `${!muted ? 'mute' : 'unmute'} micro`,
    });
    setMuted();
    resetSpeakerMutedNotification(speakerMutedNotification);
  };

  const handleMute = (isMuted: boolean) => {
    sendCustomSentryBreadcrumb({
      message: `${isMuted ? 'mute' : 'unmute'} micro in initial mute modal`,
    });
    setMuted(isMuted);
    resetSpeakerMutedNotification(speakerMutedNotification);
  };

  const handleDolbyShareScreenClick = async () => {
    try {
      if (screenMediaStreams.length) {
        sendCustomSentryBreadcrumb({ message: 'stop dolby share screen' });
        await DolbyService.stopShareScreen();
      } else {
        sendCustomSentryBreadcrumb({ message: 'start dolby share screen' });
        await DolbyService.startShareScreen(
          currentEventId,
          currentParticipantId,
        );
      }
    } catch (error: unknown) {
      showNotification(getDolbyNotification());
      throw Error(getCatchErrorMessage(error));
    }
  };

  const handleSetPersonView = useCallback(
    () => setIsFirstPersonView(),
    [setIsFirstPersonView],
  );

  return (
    <>
      {videoStarted && !!cameraDeviceId && !isConferenceListener ? (
        <ParticipantAvatar participant={currentParticipant} />
      ) : (
        <HomeBarButton
          label={translate('tooltips.toggleCam')}
          handleClick={handleVideoClick}
          disabled={!isConferenceInitialized || isConferenceListener}
          icon={
            <WarningButtonBadge
              classes={{ root: classes.badge(cameraDeviceId) }}
            >
              <VideocamOffIcon />
            </WarningButtonBadge>
          }
        />
      )}

      <HomeBarButton
        label={translate('tooltips.toggleMic')}
        handleClick={handleMicroClick}
        showPulseAnimation={!!speakerMutedNotification}
        disabled={!isConferenceInitialized || isConferenceListener}
        icon={
          <>
            {muted && !!microDeviceId ? (
              <WarningButtonBadge
                classes={{
                  root: classes.badge(microDeviceId),
                  tooltip: classes.badgeMic(microDeviceId),
                }}
              >
                <MicOffIcon />
              </WarningButtonBadge>
            ) : (
              <MicIcon />
            )}
            {initialMuteModalData.open && <AimPulse />}
          </>
        }
      />
      {initialMuteModalData.open && (
        <InitialMuteModal
          onClose={initialMuteModalData.closeModal}
          muted={muted}
          setMuted={handleMute}
          eventId={currentEventId}
        />
      )}

      {showDolbyShareScreen && (
        <HomeBarButton
          label="Share Screen"
          icon={
            <ScreenShare sx={classes.stop(sharingMedia && currentSharing)} />
          }
          disabled={sharingMedia && !currentSharing}
          handleClick={handleDolbyShareScreenClick}
        />
      )}

      <HomeBarButton
        label="Toggle Person View"
        icon={isFirstPersonView ? <PersonIcon /> : <VisibilityIcon />}
        disabled={disabledPersonView}
        handleClick={handleSetPersonView}
      />

      <HomeBarButton
        label={translate('tooltips.settings')}
        icon={<SettingsIcon />}
        handleClick={devicesSettingsModalData.switchOpenModal}
      />

      <DevicesSettingsModal
        open={devicesSettingsModalData.open}
        handleClose={devicesSettingsModalData.closeModal}
      />
    </>
  );
};

export default HomeBottomButtonOptions;
