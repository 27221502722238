import { colors } from '../../../../constants/colors';

export const classes = {
  root: {
    padding: '16px 24px',
    columnGap: '10px',
    border: `1px solid ${colors.oxford_20}`,
    borderRadius: '4px',
    width: '100%',
    '&:hover': {
      background: 'transparent',
      border: `1px solid ${colors.oxford_40}`,
    },
  },
  text: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    color: colors.oxford,
    textTransform: 'capitalize',
  },
};
