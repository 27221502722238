import { useCallback, useRef, useState, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';

import { useConferenceState } from '../../../../hooks/conferenceContext';

import { getCatchErrorMessage } from '../../../../helpers/error';

import MillicastService from '../../../dashboard/components/HomeBottomBar/ShareScreen/helpers';

import { IDevicePermissionState } from '../../../../interfaces/permissions';

export const useInitWebcamDevices = () => {
  const [initializing, setInitializing] = useState(true);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const { activeCameraDeviceId, setActiveCameraDeviceId } =
    useConferenceState();

  const startCameraStream = useCallback(async (deviceId: string) => {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId: { exact: deviceId },
      },
    });

    setMediaStream(mediaStream);

    const video = videoRef.current;

    if (!video) {
      return;
    }

    video.srcObject = mediaStream;
    video.onloadedmetadata = () => {
      video.play();
    };
  }, []);

  useEffect(() => {
    setInitializing(true);
  }, [activeCameraDeviceId]);

  useAsyncEffect(async () => {
    const initWebcamDevices = async (state: IDevicePermissionState) => {
      switch (state) {
        case 'prompt': {
          setInitializing(false);
          navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true,
          });
          break;
        }
        case 'denied': {
          const video = videoRef.current;

          if (video) {
            video.srcObject = null;
          }

          setDevices([]);
          setMediaStream(null);
          setInitializing(false);
          break;
        }
        case 'granted': {
          if (!initializing) {
            return;
          }

          if (!navigator.mediaDevices?.enumerateDevices) {
            throw new Error('enumerateDevices() not supported.');
          }

          try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const cameraDevices = (devices || []).filter(
              (device) => device.kind === 'videoinput',
            );
            const activeDeviceId =
              cameraDevices.find(
                ({ deviceId }) => deviceId === activeCameraDeviceId,
              )?.deviceId || cameraDevices[0].deviceId;

            setDevices(cameraDevices);
            setActiveCameraDeviceId(activeDeviceId);
            setInitializing(false);
            await startCameraStream(activeDeviceId);
          } catch (error) {
            throw Error(getCatchErrorMessage(error));
          }
          break;
        }
      }
    };

    const permissionData = await navigator.permissions.query({
      // eslint-disable-next-line
      name: 'camera' as any,
    });

    initWebcamDevices(permissionData.state);

    permissionData.onchange = () => {
      initWebcamDevices(permissionData.state);
    };
  }, [
    activeCameraDeviceId,
    initializing,
    setActiveCameraDeviceId,
    startCameraStream,
  ]);

  useEffect(() => {
    return () => {
      if (mediaStream) {
        MillicastService.stopMediaStreamTracks(mediaStream);
      }
    };
  }, [mediaStream]);

  return {
    initializing,
    devices,
    videoRef,
    startCameraStream,
  };
};
