import { useState } from 'react';

import { IUseModalResponse } from '../interfaces';

export const useModal = (): IUseModalResponse => {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);

  const closeModal = () => setOpen(false);

  return { open, openModal, closeModal };
};
