import React, { useCallback, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { IMAGE_PREVIEW_SIZE } from './constants';
import { IImageView } from '../interfaces';
import { useStyles } from './styles';

const ImagePreview = ({ children }: IImageView) => {
  const [imageWidth, setImageWidth] = useState(IMAGE_PREVIEW_SIZE.DEFAULT);
  const classes = useStyles(imageWidth);

  const handleIncreaseSize = useCallback(() => {
    setImageWidth((prev) => {
      return prev >= 200 ? 200 : prev + 20;
    });
  }, [setImageWidth]);

  const handleDecreaseSize = useCallback(() => {
    setImageWidth((prev) => {
      return prev <= 50 ? 50 : prev - 10;
    });
  }, [setImageWidth]);

  return (
    <Box sx={classes.toolsWrapper}>
      <Box component="nav" sx={classes.tools}>
        <Box sx={classes.toolsLink}>
          <IconButton onClick={handleIncreaseSize}>
            <ZoomInIcon />
          </IconButton>
          <IconButton onClick={handleDecreaseSize}>
            <ZoomOutIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={classes.imageWrapper}>{children}</Box>
    </Box>
  );
};

export default ImagePreview;
