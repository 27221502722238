import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';

import ToolboxHeader from '../../../ToolboxHeader';

import { ICalendar } from './interfaces';

import { classes } from './styles';

const Calendar = ({ context }: ICalendar) => {
  const { t: translate } = useTranslation('common');

  return (
    <Box>
      <ToolboxHeader {...context} />
      <Box sx={classes.header}>{translate('toolbox.comingSoon')}</Box>
      <Box sx={classes.content}>
        <EventIcon sx={classes.icon} />
      </Box>
    </Box>
  );
};

export default Calendar;
