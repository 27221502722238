import React from 'react';

import { Box, Typography } from '@mui/material';

import { IChatTabLabel } from './interfaces';

import { useStyles } from './styles';

const ChatTabLabel = ({ icon: Icon, label }: IChatTabLabel) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root} component="div">
      <Icon sx={classes.icon} />
      <Typography sx={classes.label}>{label}</Typography>
    </Box>
  );
};

export default ChatTabLabel;
