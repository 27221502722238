import Happy from '../../../../../../../public/emotes/Happy.png';
import Funny from '../../../../../../../public/emotes/Funny.png';
import WinkingFace from '../../../../../../../public/emotes/WinkingFace.png';
import Phew from '../../../../../../../public/emotes/Phew.png';
import Ecstatic from '../../../../../../../public/emotes/Ecstatic.png';
import Kiss from '../../../../../../../public/emotes/Kiss.png';
import HeartEyes from '../../../../../../../public/emotes/HeartEyes.png';
import Love from '../../../../../../../public/emotes/Love.png';
import Tongue from '../../../../../../../public/emotes/Tongue.png';
import FaceWithStarryEyes from '../../../../../../../public/emotes/FaceWithStarryEyes.png';
import Relaxed from '../../../../../../../public/emotes/Relaxed.png';
import Confused from '../../../../../../../public/emotes/Confused.png';
import Wow from '../../../../../../../public/emotes/Wow.png';
import Embarrassed from '../../../../../../../public/emotes/Embarrassed.png';
import Frightened from '../../../../../../../public/emotes/Frightened.png';
import Crying from '../../../../../../../public/emotes/Crying.png';
import Angel from '../../../../../../../public/emotes/Angel.png';
import Sad from '../../../../../../../public/emotes/Sad.png';
import ExplodingHead from '../../../../../../../public/emotes/ExplodingHead.png';
import Angry from '../../../../../../../public/emotes/Angry.png';
import Scared from '../../../../../../../public/emotes/Scared.png';
import FaceWithColdSweat from '../../../../../../../public/emotes/FaceWithColdSweat.png';
import Frustrated from '../../../../../../../public/emotes/Frustrated.png';
import Sleeping from '../../../../../../../public/emotes/Sleeping.png';
import Mask from '../../../../../../../public/emotes/Mask.png';
import Excited from '../../../../../../../public/emotes/Excited.png';
import Joy from '../../../../../../../public/emotes/Joy.png';
import CrazyFace from '../../../../../../../public/emotes/CrazyFace.png';

import { IEmoji } from './interfaces';

export const EMOJI_LIST: IEmoji[] = [
  {
    img: Happy,
    id: 'Happy',
  },
  {
    img: Funny,
    id: 'Funny',
  },
  {
    img: WinkingFace,
    id: 'WinkingFace',
  },
  {
    img: Phew,
    id: 'Phew',
  },
  {
    img: Ecstatic,
    id: 'Ecstatic',
  },
  {
    img: Kiss,
    id: 'Kiss',
  },
  {
    img: HeartEyes,
    id: 'HeartEyes',
  },
  {
    img: Love,
    id: 'Love',
  },
  {
    img: Tongue,
    id: 'Tongue',
  },
  {
    img: FaceWithStarryEyes,
    id: 'FaceWithStarryEyes',
  },
  {
    img: Relaxed,
    id: 'Relaxed',
  },
  {
    img: Confused,
    id: 'Confused',
  },
  {
    img: Wow,
    id: 'Wow',
  },
  {
    img: Embarrassed,
    id: 'Embarrassed',
  },
  {
    img: Frightened,
    id: 'Frightened',
  },
  {
    img: Crying,
    id: 'Crying',
  },
  {
    img: Angel,
    id: 'Angel',
  },
  {
    img: Sad,
    id: 'Sad',
  },
  {
    img: ExplodingHead,
    id: 'ExplodingHead',
  },
  {
    img: Angry,
    id: 'Angry',
  },
  {
    img: Scared,
    id: 'Scared',
  },
  {
    img: FaceWithColdSweat,
    id: 'FaceWithColdSweat',
  },
  {
    img: Frustrated,
    id: 'Frustrated',
  },
  {
    img: Sleeping,
    id: 'Sleeping',
  },
  {
    img: Mask,
    id: 'Mask',
  },
  {
    img: Excited,
    id: 'Excited',
  },
  {
    img: Joy,
    id: 'Joy',
  },
  {
    img: CrazyFace,
    id: 'CrazyFace',
  },
];
