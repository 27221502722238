import React from 'react';

import { Box } from '@mui/material';

import { ILayout } from './interfaces';

import { classes } from './styles';

const Layout = ({ children, classes: propsClasses = {} }: ILayout) => {
  const { root = {}, container = {} } = propsClasses;

  const rootClass = { ...classes.root, ...root };
  const containerClass = { ...classes.container, ...container };

  return (
    <Box sx={rootClass}>
      <Box sx={containerClass}>{children}</Box>
    </Box>
  );
};

export default Layout;
