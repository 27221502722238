import { camelCase } from 'lodash';
import { BadRequest } from '../helpers/error';
import $api from '../http/axios';
import { IAvatarSkin } from '../interfaces/avatarSkin';

import {
  IEvent,
  IEventTokens,
  IFollowingDataResponse,
} from '../interfaces/event';
import { IEventSubscribe } from '../interfaces/eventSubscribe';
import { IMillicastTokens } from '../interfaces/millicastTokens';
import {
  IParticipant,
  IParticipantInfo,
  IParticipantRoles,
  IParticipantSoundSettings,
  ParticipantState,
} from '../interfaces/participants';
import { IPointOfInterest } from '../interfaces/pointsOfInterest';
import { IRoom } from '../interfaces/rooms';
import { ICustomerEvent } from '../interfaces';
import {
  IAcceptFollowingInvitationParams,
  IDeclineFollowingInvitationParams,
  IRoomInfoResponse,
  ISendFollowingInvitationParams,
} from './interfaces';
import { AxiosResponse } from 'axios';
import { nanoid } from 'nanoid';
import { StreamingProviders } from '../modules/dashboard/providers/MytaverseEventProvider/interfaces';
import { getFullUserName } from '../helpers/user';

export interface IEventParticipantData {
  skinId?: string;
  avatarId?: string;
  customAvatarUrl?: string;
  participantsSound?: number;
  selectedLanguage?: string;
  gameSound?: number;
  showActiveCameras?: boolean;
  eventId?: string;
  participantId?: string;
}

export default class EventsService {
  static getEvents(userId: string): Promise<IEvent[]> {
    return $api
      .get(`cms/events/list/${userId}`)
      .then((res) => res.data.subscribedEvents)
      .catch(() => {
        throw new BadRequest(new Error('Application error'));
      });
  }

  static getEvent(eventId: string): Promise<IEvent> {
    return $api
      .get(`cms/events/get/${eventId}`)
      .then((res: any) => {
        const event = res.data.event;

        return {
          id: event.id,
          image: event.image,
          name: event.name,
          customUrl: event.customUrl,
          region: event.region,
          startTime: event.startTime,
          endTime: event.endTime,
          streamServiceId: event.streamServiceId,
          user: event.user,
          logoImage: event.logoImage,
          agendaImage: event.agendaImage,
          role: event.role,

          // TODO remove after refactoring
          rooms: [],
          maps: [],
          participants: [],
          eventAvatars: [],

          dolbyConferenceId: '',
        };
      })
      .catch(() => {
        throw new BadRequest(new Error(`Can't load event data`));
      });
  }

  static getEventRooms(eventId: string): Promise<IRoom[]> {
    return $api
      .get(`cms/rooms/list/event/${eventId}`)
      .then((res) =>
        res.data.rooms.map((room: IRoom) => {
          return {
            id: room.id,
            name: room.name,
            user: room.user,
            event: room.event,
            isDefaultRoom: room.isDefaultRoom,
            image: room.image,
            isInvisible: room.isInvisible,
          };
        }),
      )
      .catch(() => {
        throw new BadRequest(new Error(`Can't load event rooms`));
      });
  }

  static getEventTokens(eventId: string): Promise<IEventTokens> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/tokens`,
      )
      .then((res) => res.data)
      .catch(() => {
        throw new BadRequest(new Error(`Can't load event tokens`));
      });
  }

  static prepareParticipant(participant: IParticipant): IParticipant {
    const fullName = getFullUserName({
      firstName: participant.firstName,
      lastName: participant.lastName,
    });
    const avatarString = fullName
      .split(' ')
      .map((word) => (word.length !== 0 ? word[0].toUpperCase() : ''))
      .join('');

    return {
      id: participant.userId,
      key: nanoid(10),
      userId: participant.userId,
      email: participant.email,
      firstName: participant.firstName,
      lastName: participant.lastName,
      fullName,
      avatarString,
      avatarImage: participant.profileImage,
      isSpeaker: participant.isSpeaker,
      region: null,
      regions: [],
      state: ParticipantState.Loaded,
    };
  }

  static getEventParticipants(eventId: string): Promise<IParticipant[]> {
    return $api
      .get(`cms/events/participants/${eventId}`)
      .then((res: AxiosResponse<{ subscribers: IParticipant[] }>) => {
        const { subscribers } = res.data;
        const participants = subscribers.reduce<IParticipant[]>(
          (participantsList, participant) => {
            if (!participant.userId) {
              return participantsList;
            }

            const mergingParticipant = this.prepareParticipant(participant);

            return [...participantsList, mergingParticipant];
          },
          [],
        );

        return participants;
      })
      .catch(() => {
        throw new BadRequest(new Error(`Can't load event participants`));
      });
  }

  static getEventParticipantsInfo(
    eventId: string,
  ): Promise<IParticipantInfo[]> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/participants`,
      )
      .then((res) => res.data.participants)
      .catch(() => {
        throw new BadRequest(new Error(`Can't load participant rooms`));
      });
  }
  static getEventParticipantsRoles(
    eventId: string,
  ): Promise<IParticipantRoles> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/roles`,
      )
      .then((res) => res.data)
      .catch(() => {
        throw new BadRequest(new Error(`Can't load participant roles`));
      });
  }

  static getEventRoomsInfo(eventId: string): Promise<IRoomInfoResponse[]> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/rooms`,
      )
      .then((res) => res.data.rooms)
      .catch(() => {
        throw new BadRequest(new Error(`Can't load participant rooms`));
      });
  }

  static getParticipantProfile(userId: string) {
    return $api.get(`cms/users/get/${userId}`).then((res) => {
      return {
        userId,
        ...res.data.user,
      };
    });
  }

  static getParticipant(participantId: string): Promise<IParticipant> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/participants/${participantId}`,
      )
      .then((res) => {
        return {
          ...res.data,
          key: nanoid(10),
          state: ParticipantState.Loaded,
        };
      });
  }

  static updateRoomSpatialScale(
    roomId: string,
    scale: number,
    eventId: string,
  ) {
    return $api.patch(
      `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/rooms/${roomId}`,
      {
        dolbySpatialAudioScale: scale,
        eventId,
      },
    );
  }

  static updateEventParticipantSettings(
    eventId: string,
    participantId: string,
    data: IParticipantSoundSettings,
  ) {
    return $api.patch(
      `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/participants/${participantId}/settings`,
      data,
    );
  }

  static getEventParticipantSettings(
    eventId: string,
    participantId: string,
  ): Promise<IParticipantSoundSettings> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/participants/${participantId}/settings`,
      )
      .then((res) => res.data.userSettings);
  }

  static getEventAvatarSkins(
    eventId: string,
    avatarId: string,
  ): Promise<IAvatarSkin[]> {
    return $api
      .get(
        `cms/skins/list/skinsByAvatar?avatarId=${avatarId}&orderBy=name&eventId=${eventId}`,
      )
      .then((res) => res.data);
  }

  static setEventSkin(credo: {
    event: string;
    player: string;
    avatar: string;
    skin: string;
  }) {
    return $api
      .patch('/cms/skins-player/setDefault', credo)
      .then((res) => res.data);
  }

  static getMillicastStreamName(
    roomId: string,
    region: string,
    screenName: string,
  ): string {
    return [roomId, region, screenName]
      .filter((s) => !!s)
      .map((s) => camelCase(s))
      .join('-');
  }

  static getCustomerEvent(customerId: string): Promise<ICustomerEvent> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/customers/${customerId}`,
      )
      .then((res) => res.data.customer);
  }

  static startStreamVideo(data: any): Promise<IMillicastTokens> {
    return $api
      .post(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/streams`,
        data,
      )
      .then((res) => res.data)
      .catch(() => {
        throw new Error("Can't start millicast video");
      });
  }

  static getEventAvatars(eventId: string) {
    return $api
      .get('/cms/skins/list/avatarsByEvent', {
        params: {
          eventId: eventId,
          orderBy: 'avatarName',
        },
      })
      .then((res) => res.data);
  }

  static getStreamService(streamServiceId: string) {
    return $api
      .get(`/cms/stream-services/get/${streamServiceId}`)
      .then((res) => res.data);
  }

  static stopStreamVideo(streamName: string): Promise<IMillicastTokens> {
    return $api
      .delete(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/streams/${streamName}`,
      )
      .then((res) => res.data)
      .catch(() => {
        throw new Error("Can't stop millicast video");
      });
  }

  static getPointOfInterest(roomId: string): Promise<IPointOfInterest[]> {
    return $api
      .get(
        `${process.env.REACT_APP_API_URL}cms/points-interest/list/room/${roomId}`,
      )
      .then((res) => res.data.pointsInterest);
  }

  static confirmEvent(payload: IEventSubscribe): Promise<boolean> {
    return $api
      .post('auth/subscribeConfirm', payload)
      .then((res) => !!res.data?.result);
  }

  static getPrevSkin(skinId: string) {
    return $api
      .get(`/cms/skins-model/get/${skinId}`)
      .then((res) => res.data.skinModel);
  }

  static getEventInfo(eventId: string): Promise<any> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/`,
      )
      .then((res) => res.data)
      .catch(() => {
        throw new BadRequest(new Error(`Can't load event info`));
      });
  }

  static setEventUserData(
    eventId: string,
    participantId: string,
    data: IEventParticipantData,
  ) {
    return $api
      .patch(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/participants/${participantId}`,
        data,
      )
      .then((res) => res.data);
  }

  static getEventUserData(
    eventId: string,
    participantId: string,
  ): Promise<IEventParticipantData> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/participants/${participantId}`,
      )
      .then((res) => res.data[0]);
  }

  static getEventStreamingProvider(
    eventId: string,
  ): Promise<{ streamingProvider: StreamingProviders | null }> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/streaming-provider`,
      )
      .then((res) => res.data);
  }

  static getFollowingData(eventId: string): Promise<IFollowingDataResponse> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/following`,
      )
      .then((res) => res.data);
  }

  static sendFollowingInvitation({
    eventId,
    ...payload
  }: ISendFollowingInvitationParams): Promise<void> {
    return $api
      .post(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/following`,
        payload,
      )
      .then((res) => res.data);
  }

  static acceptFollowingInvitation({
    eventId,
    ...payload
  }: IAcceptFollowingInvitationParams): Promise<void> {
    return $api
      .post(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/following/accept`,
        payload,
      )
      .then((res) => res.data);
  }

  static declineFollowingInvitation({
    eventId,
    ...payload
  }: IDeclineFollowingInvitationParams): Promise<void> {
    return $api
      .post(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/events/${eventId}/following/decline`,
        payload,
      )
      .then((res) => res.data);
  }
}
