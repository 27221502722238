import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ClickAwayListener,
  Grow,
  Popper,
  Typography,
} from '@mui/material';

import { ISelectDropdown, ISelectDropdownData } from './interfaces';

import { classes } from './styles';

const SelectDropdown = <T,>({
  anchorEl,
  data,
  activeItem,
  onClick,
  onClose,
  placement = 'bottom-end',
}: ISelectDropdown<T>) => {
  const { t: translate } = useTranslation('common');

  const handleClick = (item: ISelectDropdownData<T>) => {
    onClick(item);
    onClose();
  };

  const getIsSelected = (item: ISelectDropdownData<T>): boolean => {
    return item.value === activeItem.value;
  };

  return (
    <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
      <Box>
        <Popper
          open
          anchorEl={anchorEl}
          transition
          placement={placement}
          sx={classes.popper}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
              <Box sx={classes.menu}>
                {data.map((item, index) => {
                  const isSelected = getIsSelected(item);

                  return (
                    <Typography
                      key={index}
                      sx={classes.getMenuItem(isSelected)}
                      onClick={() => handleClick(item)}
                    >
                      {translate(item.label)}
                    </Typography>
                  );
                })}
              </Box>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default SelectDropdown;
