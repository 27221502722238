import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useStyles } from './styles';
import { IToolBoxItem } from './interfaces';

const ToolBoxItem = ({ Icon, title, handleClick }: IToolBoxItem) => {
  const [background, setBackground] = React.useState('transparent');

  const classes = useStyles(background);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setBackground('green');

      setTimeout(() => {
        setBackground('transparent');
      }, 1000);
      handleClick(e);
    },
    [setBackground, handleClick],
  );

  return (
    <Box component="div" sx={classes.root} onClick={onClick}>
      <Box component="div" sx={classes.iconWrapper}>
        {Icon}
      </Box>
      <Typography sx={{ color: 'white' }}>{title}</Typography>
    </Box>
  );
};

export default ToolBoxItem;
