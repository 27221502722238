import React from 'react';

import { Button, Typography } from '@mui/material';

import LoadingProgress from '../../../../components/LoadingProgress';

import { ISocialButton } from './interfaces';

import { classes } from './styles';

const SocialButton = ({
  children,
  icon: Icon,
  onClick,
  loading = false,
  disabled = false,
}: ISocialButton) => {
  return (
    <Button
      onClick={onClick}
      sx={classes.root}
      disableRipple
      disabled={loading || disabled}
    >
      {loading ? (
        <LoadingProgress size={24} height="auto" />
      ) : (
        <>
          <Icon />
          <Typography sx={classes.text}>{children}</Typography>
        </>
      )}
    </Button>
  );
};

export default SocialButton;
