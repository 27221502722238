import React from 'react';

import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';

import { IPoiPreviewView } from './interfaces';

import { useStyles } from './styles';

const PoiPreviewView = ({ children, onClick, poiName }: IPoiPreviewView) => {
  const classes = useStyles();

  return (
    <Modal open={true} onClose={onClick}>
      <Box sx={classes.modal}>
        <Box sx={classes.title}>
          <Box sx={classes.titleWrapper}>
            <SettingsIcon />
            <Typography sx={classes.titleName}>{poiName}</Typography>
          </Box>
          <IconButton onClick={onClick} sx={classes.titleButton}>
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

export default PoiPreviewView;
