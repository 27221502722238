import React from 'react';

import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';

import EventDropdownItem from './EventDropdownItem';

import { IEventsDropdownView } from './intefaces';

import { classes } from './styles';

const EventsDropdownView = ({
  events,
  currentEventId,
  onChange,
}: IEventsDropdownView) => {
  return (
    <Box sx={classes.root} component="div">
      {events.length ? (
        <FormControl sx={classes.dropdown}>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={currentEventId}
            onChange={onChange}
            sx={classes.select}
          >
            {events.map((event) => (
              <MenuItem key={event.id} value={event.id}>
                <EventDropdownItem event={event} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography>No available events</Typography>
      )}
    </Box>
  );
};

export default EventsDropdownView;
