import React, { ChangeEvent, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ForgotPasswordPageView from './ForgotPasswordPageView';

import { resetPassword } from './helpers';

import { ResetPasswordSchema } from './validation';
import ROUTES from '../../../../constants/routes';

import { IFormValues } from './interfaces';

const ForgotPasswordPage = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { t: translate } = useTranslation('common');

  const onSubmit = async () => {
    setLoading(true);

    const isSuccess = await resetPassword(values);

    if (isSuccess) {
      navigate(`${ROUTES.FORGOT_PASSWORD_VERIFICATION}?email=${values.email}`);
    } else {
      setFieldError('email', translate('forgotPassword.noEmailError'));
    }
    setLoading(false);
  };

  const {
    values,
    touched,
    errors,
    setFieldTouched,
    setFieldError,
    handleChange,
    handleSubmit,
  } = useFormik<IFormValues>({
    initialValues: {
      email: '',
    },
    onSubmit,
    validationSchema: ResetPasswordSchema,
    enableReinitialize: true,
  });

  const handleInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFieldTouched(event.target.name, false, false);
    handleChange(event);
  };

  const handleBackClick = () => navigate(ROUTES.LOGIN);

  return (
    <ForgotPasswordPageView
      values={values}
      errors={errors}
      touched={touched}
      onChange={handleInputChange}
      loading={loading}
      onSubmit={handleSubmit}
      onBackClick={handleBackClick}
    />
  );
};

export default ForgotPasswordPage;
