import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import useAsyncEffect from 'use-async-effect';
import { useTranslation } from 'react-i18next';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import EventsService from '../../../../services/EventsService';

import { IAvatar } from '../../../../interfaces/avatar';
import { IAvatarSkin } from '../../../../interfaces/avatarSkin';
import { IEvent } from '../../../../interfaces/event';
import {
  IParticipant,
  IParticipantRegion,
  ParticipantState,
} from '../../../../interfaces/participants';
import { IRoom } from '../../../../interfaces/rooms';
import { IStreamService } from '../../../../interfaces/streamService';
import {
  IMillicastEventTokens,
  IMillicastStreamTokens,
  IMillicastTokens,
} from '../../../../interfaces/millicastTokens';
import ParticipantsService from '../../../../services/ParticipantsService';
import ROUTES from '../../../../constants/routes';
import { useLocation } from 'react-router-dom';
import { IPointOfInterest } from '../../../../interfaces/pointsOfInterest';
import { useNotification } from '../../../../components/Notification';
import { getInitialMapParticipants } from '../../../../helpers/participant';
import { getInitialMapRooms } from '../../../../helpers/room';
import { IShareMediaParams } from '../../../../interfaces/shareMediaParams';
import { IMillicastPublisher } from '../../../../interfaces/millicastPublisher';

import { EventDrawerTabs } from '../../constants';
import {
  useEventParticipantData,
  useInitMessage,
  useMillicast,
  useParticipants,
  useParticipantsState,
} from './hooks';
import {
  StreamingProviders,
  JoinParticipantToRegionType,
  LeaveParticipantFromRegionType,
  SendMutedStateMessageEnum,
  SetParticipantStateType,
  UpdateParticipantType,
} from './interfaces';
import AnalyticsService from '../../../../services/AnalyticsService';
import { SendToBriefcasePropsTypeFull } from '../../components/DashboardContent/interfaces';
import { useChatState } from '../../../../hooks/context';
import { getUnmutedParticipants, mapFollowers } from './helpers';
import { IInitMessage } from '../../components/DashboardContent/Pureweb/interfaces';
import PurewebClientOptions from '../../components/DashboardContent/Pureweb/helpers';
import { DolbyService } from '../../components/DashboardContent/Dolby';
import { useInitGameCastStream } from './hooks/streamProviders';
import { IFollowerData } from '../../../../interfaces/followers';

interface IMytaverseEventContext {
  isOpenLeftMenu: boolean;

  openLeftMenu: (tab?: EventDrawerTabs) => void;
  closeLeftMenu: () => void;
  setParticipantsSound: (level: number) => void;
  participantsSound: number;

  eventLoaded: boolean;
  setEventLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  leftMenuTab: EventDrawerTabs;
  setLeftMenuTab: (tab: EventDrawerTabs) => void;
  customAvatarUrl: string | null;
  setCustomAvatarUrl: (url: string | null) => void;
  setGameSound: (level: number) => void;
  initMessageHandler: () => IInitMessage | null;
  gameSound: number;
  bubblesWithNearbyParticipants: boolean;
  setBubblesWithNearbyParticipants: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  clientOptions: PurewebClientOptions | null;
  rooms: IRoom[];
  reaction: string | null;
  emoji: string | null;
  setEmoji: (emoji: string | null) => void;
  setReaction: (reaction: string | null) => void;
  sharingWindowFullsceen: boolean;
  setSharingWindowFullscreen: () => void;
  currentEvent: IEvent | undefined;

  currentRoom: IRoom | null | undefined;
  setCurrentRoom: Dispatch<SetStateAction<IRoom | null>>;
  gameCastStreamId: string;
  setGameCastStreamId: Dispatch<SetStateAction<string>>;
  gameCastStreamRegion: string;
  setGameCastStreamRegion: Dispatch<SetStateAction<string>>;
  currentRegion: IParticipantRegion | null;
  setCurrentRegion: (region: IParticipantRegion | null) => void;
  gameCastStreamRequestSended: boolean;
  setGameCastStreamRequestSended: React.Dispatch<React.SetStateAction<boolean>>;
  participants: IParticipant[];
  setParticipants: React.Dispatch<React.SetStateAction<IParticipant[]>>;
  currentParticipant: IParticipant | undefined;
  setCurrentParticipant: React.Dispatch<
    React.SetStateAction<IParticipant | undefined>
  >;
  shareMillicastVideoWithSound: boolean;
  setShareMillicastVideoWithSound: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  unmutedParticipantsIds: string[];
  setUnmutedParticipantsIds: Dispatch<SetStateAction<string[]>>;
  teleportingToRoom: IRoom | null;
  setTeleportingToRoom: Dispatch<SetStateAction<IRoom | null>>;
  teleportToRoom: (room: IRoom | null) => void;
  isTeleportingToRoomByUnreal: boolean;
  setIsTeleportingToRoomByUnreal: Dispatch<SetStateAction<boolean>>;

  teleportingToParticipant: IParticipant | undefined | null;
  setTeleportingToParticipant: (participant: IParticipant | null) => void;
  teleportToParticipant: (participant: IParticipant | null) => Promise<void>;
  currentRoomDolbySpatialAudioScale: number;
  setRoomDolbySpatialAudioScale: (
    roomId: string,
    dolbySpatialAudioScale: number,
  ) => void;
  updateRoomScale: (value: number) => void;
  avatars: IAvatar[];
  previousSkin: IAvatarSkin | null;
  ue5WebsocketConnected: boolean;
  setUe5WebsocketConnected: React.Dispatch<React.SetStateAction<boolean>>;
  avatarSkins: IAvatarSkin[];
  getSkins: (avatarId: string) => void;
  selectSkin: (skin: IAvatarSkin) => void;
  loading: boolean;
  loadingSkins: boolean;
  loadingAvatars: boolean;
  openAdminSettingsModal: boolean;
  setOpenAdminSettingsModal: React.Dispatch<React.SetStateAction<boolean>>;

  muted: boolean;
  poiPreviewSrc: SendToBriefcasePropsTypeFull | null;
  setPoiPreviewSrc: React.Dispatch<
    React.SetStateAction<SendToBriefcasePropsTypeFull | null>
  >;
  setMuted: (value?: boolean) => void;
  showActiveCameras: boolean;
  setShowActiveCameras: React.Dispatch<React.SetStateAction<boolean>>;
  dolbyToken: string;
  streamChatToken: string;
  millicastTokens: IMillicastEventTokens | null;
  getMillicastStreamTokens: (
    screenName: string,
  ) => Promise<IMillicastStreamTokens | null>;
  streamingProvider: StreamingProviders;
  setStreamingProvider: Dispatch<SetStateAction<StreamingProviders>>;
  pointsOfInterest: IPointOfInterest[] | null;
  resetPreviousSkin: () => void;
  streamService?: IStreamService;
  userFiles: IPointOfInterest[];
  setUserFiles: React.Dispatch<React.SetStateAction<IPointOfInterest[]>>;
  micUsingByMillicast: boolean;
  setMicUsingByMillicast: React.Dispatch<React.SetStateAction<boolean>>;
  setParticipantState: SetParticipantStateType;
  joinParticipantToRegion: JoinParticipantToRegionType;
  leaveParticipantFromRegion: LeaveParticipantFromRegionType;
  updateParticipant: UpdateParticipantType;
  setSpeakingParticipants: (speakingParticipantIds: string[]) => void;
  loadEventAvatars: () => void;

  openShareScreenModal: boolean;
  setOpenShareScreenModal: React.Dispatch<React.SetStateAction<boolean>>;

  openMillicastScreenBroadcastingDataModal: boolean;
  setOpenMillicastScreenBroadcastingDataModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;

  loadingShareScreenModal: boolean;
  setLoadingShareScreenModal: React.Dispatch<React.SetStateAction<boolean>>;

  openShareVideoModal: boolean;
  setOpenShareVideoModal: React.Dispatch<React.SetStateAction<boolean>>;

  loadingShareVideoModal: boolean;
  setLoadingShareVideoModal: React.Dispatch<React.SetStateAction<boolean>>;

  shareMediaParams?: IShareMediaParams | null;
  setShareMediaParams: React.Dispatch<
    React.SetStateAction<IShareMediaParams | null>
  >;

  showControls: boolean;
  videoBubbleSize: number;
  setVideoBubbleSize: React.Dispatch<React.SetStateAction<number>>;
  setShowControls: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentSkin: React.Dispatch<React.SetStateAction<IAvatarSkin | null>>;
  currentSkin: IAvatarSkin | null;
  setCurrentAvatarId: React.Dispatch<React.SetStateAction<string | null>>;
  currentAvatarId: string | null;
  initMessageSended: boolean;
  openLeaveRegionDialog: boolean;
  setOpenLeaveRegionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openCameraPublisherDialog: boolean;
  setOpenCameraPublisherDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openTeleportToRoomDialog: boolean;
  setOpenTeleportToRoomDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTeleportRoom: IRoom | null;
  setSelectedTeleportRoom: React.Dispatch<React.SetStateAction<IRoom | null>>;
  initScreenMediaStream: (force?: boolean) => Promise<void>;
  handleStopScreenStream: (streamId: string) => Promise<void>;
  setInitMessageSended: React.Dispatch<React.SetStateAction<boolean>>;

  shareVideoPublishers: IMillicastPublisher | null;
  setShareVideoPublishers: (publisher: IMillicastPublisher | null) => void;

  shareVideoMediaStream: MediaStream | null;
  setShareVideoMediaStream: React.Dispatch<
    React.SetStateAction<MediaStream | null>
  >;

  shareScreenPublishers?: IMillicastPublisher[];
  setShareScreenPublishers: (publisher: any) => void;

  shareScreenMediaStreams?: MediaStream[];
  setShareScreenMediaStreams: React.Dispatch<
    React.SetStateAction<MediaStream[]>
  >;

  resetPreviousCustomSkin: () => void;
  previousCustomAvatar: string | null;
  startShareVideo: (
    shareVideoPublishers: any,
    shareVideoTokens: IMillicastTokens,
  ) => void;
  stopShareVideo: () => void;

  startShareScreen: (
    shareScreenPublishers: IMillicastPublisher,
    shareScreenTokens: IMillicastTokens,
  ) => void;
  stopShareScreen: (streamId: string) => void;
  cleanStates: () => void;

  nearbyParticipants: string[];
  setNearbyParticipants: Dispatch<SetStateAction<string[]>>;

  trackAnalytics: (type: string, payload: any) => Promise<void>;

  pendingFollowersData: IFollowerData[];
  setPendingFollowersData: Dispatch<SetStateAction<IFollowerData[]>>;
  acceptedFollowersData: IFollowerData[];
  setAcceptedFollowersData: Dispatch<SetStateAction<IFollowerData[]>>;
}

export const MytaverseEventContext =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  React.createContext<IMytaverseEventContext>({});

export const useMytaverseEvent = () => useContext(MytaverseEventContext);

type MytaverseEventProviderProps = {
  children: ReactNode;
};

export const MytaverseEventProvider: React.FC<MytaverseEventProviderProps> = ({
  children,
}) => {
  const {
    currentEventId,
    token,
    userId,
    user,
    sendJSONMessageToWebSocket,
    events,
    websocketSessionId,
  } = useMytaverse();
  const { t: translate } = useTranslation('common');

  const [openLeftMenu, setOpenLeftMenu] = React.useState<boolean>(false);
  const [leftMenuTab, setLeftMenuTab] = React.useState<EventDrawerTabs>(
    EventDrawerTabs.Locations,
  );
  const [reaction, setReaction] = React.useState<string | null>(null);
  const [emoji, setEmoji] = React.useState<string | null>(null);
  const [bubblesWithNearbyParticipants, setBubblesWithNearbyParticipants] =
    useState(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openAdminSettingsModal, setOpenAdminSettingsModal] = useState(false);
  const [loadingAvatars, setLoadingAvatars] = React.useState<boolean>(false);
  const [loadingSkins, setLoadingSkins] = React.useState<boolean>(false);
  const [muted, setMuted] = useState(true);
  const [currentEvent, setCurrentEvent] = React.useState<IEvent>();
  const [eventLoaded, setEventLoaded] = React.useState<boolean>(false);
  const [rooms, setRooms] = React.useState<IRoom[]>([]);
  const [currentRoom, setCurrentRoom] = React.useState<IRoom | null>(null);
  const [openLeaveRegionDialog, setOpenLeaveRegionDialog] =
    React.useState(false);
  const [openCameraPublisherDialog, setOpenCameraPublisherDialog] =
    React.useState(false);
  const [ue5WebsocketConnected, setUe5WebsocketConnected] =
    React.useState(false);
  const [currentRegion, setCurrentRegion] =
    React.useState<IParticipantRegion | null>(null);
  const [
    currentRoomDolbySpatialAudioScale,
    setCurrentRoomDolbySpatialAudioScale,
  ] = React.useState<number>(80);
  const [poiPreviewSrc, setPoiPreviewSrc] =
    React.useState<SendToBriefcasePropsTypeFull | null>(null);
  const [initMessageSended, setInitMessageSended] = React.useState(false);
  const [previousSkin, setPreviousSkin] = React.useState<IAvatarSkin | null>(
    null,
  );
  const [gameCastStreamRequestSended, setGameCastStreamRequestSended] =
    useState(false);
  const [previousCustomAvatar, setPreviousCustomAvatar] = React.useState<
    string | null
  >(null);
  const [pointsOfInterest, setPointsOfInterest] = React.useState<
    IPointOfInterest[] | null
  >(null);
  const [userFiles, setUserFiles] = React.useState<IPointOfInterest[]>([]);

  const [currentParticipant, setCurrentParticipant] = React.useState<
    IParticipant | undefined
  >();
  const [teleportingToRoom, setTeleportingToRoom] =
    React.useState<IRoom | null>(null);
  const [isTeleportingToRoomByUnreal, setIsTeleportingToRoomByUnreal] =
    React.useState(false);
  const [teleportingToParticipant, setTeleportingToParticipant] =
    React.useState<IParticipant | undefined | null>();
  const [participants, setParticipants] = React.useState<IParticipant[]>([]);
  const [loadingNewParticipants, setLoadingNewParticipants] =
    React.useState(false);
  const [avatars, setAvatars] = React.useState<IAvatar[]>([]);
  const [avatarSkins, setAvatarSkins] = React.useState<IAvatarSkin[]>([]);
  const [streamService, setStreamService] = React.useState<IStreamService>();
  const [streamingProvider, setStreamingProvider] =
    React.useState<StreamingProviders>(StreamingProviders.Pureweb);
  const [openTeleportToRoomDialog, setOpenTeleportToRoomDialog] =
    React.useState(false);
  const [selectedTeleportRoom, setSelectedTeleportRoom] =
    React.useState<IRoom | null>(null);
  const [dolbyToken, setDolbyToken] = React.useState<string>('');
  const [streamChatToken, setStreamChatToken] = React.useState<string>('');

  const [showControls, setShowControls] = React.useState<boolean>(true);
  const [sharingWindowFullsceen, setSharingWindowFullscreen] =
    React.useState(false);

  const [nearbyParticipants, setNearbyParticipants] = useState<string[]>([]);
  const [videoBubbleSize, setVideoBubbleSize] = React.useState(140);
  const [pendingFollowersData, setPendingFollowersData] = useState<
    IFollowerData[]
  >([]);
  const [acceptedFollowersData, setAcceptedFollowersData] = useState<
    IFollowerData[]
  >([]);

  const clientOptions = React.useMemo(() => {
    if (
      !streamService ||
      !currentEvent ||
      streamingProvider !== StreamingProviders.Pureweb
    ) {
      return null;
    }

    const purewebClientOptions = new PurewebClientOptions(
      streamService.purewebProjectId,
      streamService.purewebModelId,
    );

    if (currentEvent.region) {
      purewebClientOptions.regionOverride = currentEvent.region;
    }

    return purewebClientOptions;
  }, [currentEvent, streamService, streamingProvider]);

  const {
    millicastTokens,
    getMillicastStreamTokens,
    setMillicastTokens,
    shareMillicastVideoWithSound,
    shareMediaParams,
    shareVideoPublishers,
    shareScreenPublishers,
    shareVideoMediaStream,
    shareScreenMediaStreams,
    openScreenModal,
    openVideoModal,
    micUsingByMillicast,
    setMicUsingByMillicast,
    setOpenVideoModal,
    setOpenScreenModal,
    initScreenMediaStream,
    handleStopScreenStream,
    stopShareScreenHandler,
    startShareScreenHandler,
    stopShareVideoHandler,
    startShareVideoHandler,
    setShareScreenMediaStreams,
    setShareVideoMediaStream,
    setShareVideoPublishers,
    setShareScreenPublishers,
    setShareMediaParams,
    setShareMillicastVideoWithSound,

    loadingShareVideoModal,
    setLoadingShareVideoModal,

    loadingShareScreenModal,
    setLoadingShareScreenModal,

    openMillicastScreenBroadcastingDataModal,
    setOpenMillicastScreenBroadcastingDataModal,
  } = useMillicast({
    currentRoom,
    currentRegion,
    currentParticipant: currentParticipant as IParticipant,
    muted,
    setMuted,
  });

  const { unmutedParticipantsIds, setUnmutedParticipantsIds } =
    useParticipants();

  const {
    setParticipantState,
    joinParticipantToRegion,
    leaveParticipantFromRegion,
  } = useParticipantsState({
    userId,
    currentEventId,

    currentParticipant,
    setParticipants,

    participants,
    setCurrentParticipant,

    currentRoom,
    setCurrentRoom,

    currentRegion,
    setCurrentRegion,

    rooms,
  });

  const {
    showActiveCameras,
    participantsSound,
    gameSound,
    customAvatarUrl,
    currentAvatarId,
    currentSkin,
    setCurrentSkin,
    setCurrentAvatarId,
    setCustomAvatarUrl,
    setShowActiveCameras,
    setGameSoundHandle,
    setParticipantsSoundHandle,
    selectSkinHandler,
  } = useEventParticipantData({
    currentEvent,
    participant: user,
    setLoading,
  });

  const { pathname } = useLocation();
  const {
    showNotification,
    getSuccessNotification,
    getBadRequestNotification,
    // getStreamingNotification,
  } = useNotification();
  const { setOpen: setOpenChat } = useChatState();

  const { initMessageHandler } = useInitMessage({
    currentEvent,
    currentParticipant,
    currentSkin,
    customAvatarUrl,
    dolbyToken,
    teleportingToRoom,
    token,
    websocketSessionId,
  });

  const {
    streamId: gameCastStreamId,
    setStreamId: setGameCastStreamId,
    streamRegion: gameCastStreamRegion,
    setStreamRegion: setGameCastStreamRegion,
  } = useInitGameCastStream(currentEvent);

  useEffect(() => {
    if (currentRoom) {
      localStorage.setItem('currentRoomId', currentRoom.id);
      showNotification(
        getSuccessNotification({
          message: `${translate('notifications.roomChangedTo')} ${
            currentRoom?.name
          }`,
        }),
      );
    }
  }, [currentRoom]);

  useEffect(() => {
    if (userFiles) {
      localStorage.setItem('userFiles', JSON.stringify(userFiles) as string);
    }
  }, [userFiles, currentRoom]);

  useEffect(() => {
    if (currentRoom) {
      setUserFiles([]);
    }
  }, [currentRoom]);

  useAsyncEffect(async () => {
    if (!currentRoom) {
      return;
    }

    if (pointsOfInterest) {
      setPointsOfInterest(null);
    }

    const pointsInterest = await EventsService.getPointOfInterest(
      currentRoom.id,
    );

    if (pointsInterest.length) {
      setPointsOfInterest(pointsInterest);
    }
  }, [currentRoom]);

  useEffect(() => {
    if (!currentEventId) {
      return;
    }

    sendJSONMessageToWebSocket({
      action: muted
        ? SendMutedStateMessageEnum.MUTE
        : SendMutedStateMessageEnum.UNMUTE,
      timestamp: Date.now(),
    });
  }, [muted, currentEventId, sendJSONMessageToWebSocket]);

  useAsyncEffect(async () => {
    if (!currentEvent) {
      return;
    }

    const streamingProvider = await EventsService.getEventStreamingProvider(
      currentEvent.id,
    );

    if (streamingProvider && streamingProvider.streamingProvider) {
      setStreamingProvider(streamingProvider.streamingProvider);
    }
  }, [currentEvent]);

  useAsyncEffect(async () => {
    if (loadingNewParticipants) {
      return;
    }

    const loadingParticipants = participants.filter(
      (p) => p.state === ParticipantState.Loading,
    );

    if (loadingParticipants.length === 0) {
      return;
    }

    setLoadingNewParticipants(true);
    const loadedParticipants = await Promise.allSettled(
      loadingParticipants.map(async (p: IParticipant) => {
        const participant = await EventsService.getParticipantProfile(p.userId);

        return {
          ...p,
          ...EventsService.prepareParticipant(participant),
        };
      }),
    );

    if (loadedParticipants.length !== 0) {
      setParticipants((prev) => {
        return prev.map((participant) => {
          const loadedParticipant = loadedParticipants.find(
            (p) => p.status === 'fulfilled' && p.value.id === participant.id,
          );

          if (!loadedParticipant || loadedParticipant.status !== 'fulfilled') {
            return participant;
          }

          return {
            ...loadedParticipant.value,
            key: participant.key,
            eventId: participant.eventId,
            roomId: participant.roomId,
            region: participant.region,
            regions: participant.regions,
            gameSessionId: participant.gameSessionId,
            state: ParticipantState.Loaded,
          };
        });
      });
    }

    setLoadingNewParticipants(false);
  }, [loadingNewParticipants, participants]);

  const loadEventAvatars = useCallback(async () => {
    setLoadingAvatars(true);
    if (currentEventId) {
      const eventAvatars = await EventsService.getEventAvatars(currentEventId);
      setAvatars(eventAvatars);
    }
    setLoadingAvatars(false);
  }, [currentEventId, setAvatars]);

  const loadEventParticipants = useCallback(async () => {
    if (!currentEventId) {
      return;
    }
    const [participants, participantsInfo, participantsRoles] =
      await Promise.all([
        EventsService.getEventParticipants(currentEventId),
        EventsService.getEventParticipantsInfo(currentEventId),
        EventsService.getEventParticipantsRoles(currentEventId),
      ]);

    setParticipants(
      getInitialMapParticipants(
        participants,
        participantsInfo,
        participantsRoles,
      ),
    );
    setUnmutedParticipantsIds(getUnmutedParticipants(participantsInfo));

    const currentParticipant = participants.find((p) => p.userId === userId);
    const dolbySessionParticipant = DolbyService.getSessionParticipant();

    if (currentParticipant) {
      setCurrentParticipant({
        ...currentParticipant,
        dolbyParticipantId: dolbySessionParticipant
          ? dolbySessionParticipant.id
          : null,
      });
    }
  }, [
    setParticipants,
    setCurrentParticipant,
    getInitialMapParticipants,
    currentEventId,
  ]);

  const loadEventRooms = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    const [rooms, roomsInfoScale] = await Promise.all([
      EventsService.getEventRooms(currentEventId),
      EventsService.getEventRoomsInfo(currentEventId),
      EventsService.getEventInfo(currentEventId),
    ]);

    setRooms(getInitialMapRooms(rooms, roomsInfoScale));

    setCurrentRoom(null);

    let teleportingToRoom;
    const currentRoomId = localStorage.getItem('currentRoomId');

    if (currentRoomId) {
      teleportingToRoom = rooms.find((room) => room.id === currentRoomId);
    }

    if (!teleportingToRoom) {
      teleportingToRoom = rooms.find((room) => room.isDefaultRoom);
    }

    if (teleportingToRoom) {
      localStorage.setItem('currentRoomId', teleportingToRoom.id);
      setTeleportingToRoom(teleportingToRoom);
    } else {
      showNotification(
        getBadRequestNotification({
          message: translate('notifications.noRoomInEvent'),
        }),
      );
    }
  }, [
    currentEventId,
    setRooms,
    setCurrentRoom,
    setTeleportingToRoom,
    showNotification,
    getInitialMapRooms,
    getBadRequestNotification,
  ]);

  const loadEventTokens = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    const eventTokens = await EventsService.getEventTokens(currentEventId);

    setDolbyToken(eventTokens.dolbyToken);
    setStreamChatToken(eventTokens.streamChatToken);
    setMillicastTokens(eventTokens.millicastTokens);
  }, [currentEventId]);

  useEffect(() => {
    if (currentRoom && currentRoom.dolbySpatialAudioScale) {
      setCurrentRoomDolbySpatialAudioScale(currentRoom.dolbySpatialAudioScale);
    }
  }, [currentRoom]);

  const loadEventStreamServices = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    const streamService = await EventsService.getStreamService(
      events.find((e) => e.id === currentEventId)?.streamServiceId || '',
    );

    // if (!streamService) {
    //   const notificationConfig = getStreamingNotification({
    //     message: translate('notifications.noAlias'),
    //   });
    //
    //   showNotification(notificationConfig);
    // }
    setStreamService(streamService);
  }, [currentEventId, setStreamService, streamingProvider]);

  useAsyncEffect(async () => {
    if (pathname === ROUTES.HOME_PAGE) {
      await loadEventStreamServices();
      await loadEventParticipants();
      await loadEventRooms();
      await loadEventTokens();
    }
  }, [pathname, currentEventId]);

  // TODO merge it with load participant settings data
  useAsyncEffect(async () => {
    try {
      if (currentEventId && token && userId) {
        setLoading(true);
        setEventLoaded(false);

        setLoadingAvatars(true);

        setPreviousCustomAvatar(null);
        setPreviousSkin(null);
        setCurrentSkin(null);
        setCurrentAvatarId(null);
        setCustomAvatarUrl(null);
        setAvatarSkins([]);

        const event = await EventsService.getEvent(currentEventId);
        setCurrentEvent(event);

        if (pathname === ROUTES.HOME_PAGE) {
          setLoading(false);
        }

        const eventData = await EventsService.getEventUserData(
          currentEventId,
          userId,
        );

        if (eventData) {
          if (
            eventData.skinId &&
            eventData.avatarId &&
            eventData.customAvatarUrl?.length === 0
          ) {
            const prevSkin = await EventsService.getPrevSkin(eventData.skinId);
            setPreviousSkin(prevSkin);
            setCurrentSkin(prevSkin);
            setCurrentAvatarId(prevSkin.avatar);
          }

          if (
            !eventData.skinId &&
            !eventData.avatarId &&
            eventData.customAvatarUrl?.length !== 0
          ) {
            if (eventData.customAvatarUrl) {
              setPreviousCustomAvatar(eventData.customAvatarUrl);
              setCustomAvatarUrl(eventData.customAvatarUrl);
            }
          }
        }

        const followingData = await EventsService.getFollowingData(
          currentEventId,
        );

        const { pending, accepted } = mapFollowers(followingData.followers);

        setPendingFollowersData(pending);
        setAcceptedFollowersData(accepted);

        setLoadingAvatars(false);

        setLoading(false);
      } else {
        setCurrentEvent(undefined);

        setRooms([]);
        setCurrentRoom(null);

        setParticipants([]);
        setCurrentParticipant(undefined);
      }
    } catch (error) {
      showNotification(
        getBadRequestNotification({ message: (error as Error).message }),
      );
    }
  }, [userId, currentEventId, pathname]);

  useEffect(() => {
    if (
      currentEvent &&
      currentParticipant &&
      currentParticipant.eventId !== currentEvent.id
    ) {
      sendJSONMessageToWebSocket({
        action: 'JOIN_TO_EVENT',
        timestamp: Date.now(),
        eventId: currentEvent.id,
        analyticsPayload: {
          event: {
            id: currentEvent.id,
            name: currentEvent.name,
          },
          participant: {
            id: currentParticipant.id,
            name: `${currentParticipant.firstName} ${currentParticipant.lastName}`,
            email: currentParticipant.email,
          },
        },
      });
    }
  }, [currentEvent, currentParticipant]);

  useEffect(() => {
    if (avatars.length) {
      if (currentAvatarId) {
        getAvatarSkins(currentAvatarId);
      }
    }
  }, [avatars]);

  const getAvatarSkins = useCallback(
    async (avatarId: string) => {
      setLoadingSkins(true);

      if (avatars.length && currentEventId) {
        const skins = await EventsService.getEventAvatarSkins(
          currentEventId,
          avatarId,
        );
        setAvatarSkins(skins);
      }

      setLoadingSkins(false);
    },
    [setLoadingSkins, setAvatarSkins, currentEventId, avatars],
  );

  const updateParticipant: UpdateParticipantType = useCallback(
    (participantId, newParticipantInfo) => {
      if (currentParticipant && currentParticipant.id === participantId) {
        setCurrentParticipant((prevParticipant) => ({
          ...prevParticipant,
          ...newParticipantInfo,
          isSpeaker: !!prevParticipant?.isSpeaker,
        }));
      }

      setParticipants((prevParticipants) =>
        prevParticipants.map((prevParticipant) =>
          prevParticipant.id === participantId
            ? {
                ...prevParticipant,
                ...newParticipantInfo,
              }
            : prevParticipant,
        ),
      );
    },
    [currentParticipant],
  );

  const resetPreviousSkin = useCallback(() => {
    if (previousSkin) {
      setPreviousSkin(null);
    }
  }, [previousSkin, setPreviousSkin]);

  const resetPreviousCustomSkin = useCallback(() => {
    if (previousCustomAvatar) {
      setPreviousCustomAvatar(null);
    }
  }, [previousCustomAvatar, setPreviousCustomAvatar]);

  const setRoomDolbySpatialAudioScale = useCallback(
    (roomId: string, dolbySpatialAudioScale: number) => {
      if (currentRoom && currentRoom.id === roomId) {
        setCurrentRoomDolbySpatialAudioScale(dolbySpatialAudioScale);
      }
      /*
      setCurrentRoom((prev) => {
        if (!prev || prev.id !== roomId) {
          return prev;
        }

        return {
          ...prev,
          dolbySpatialAudioScale,
        };
      });
       */

      setRooms((prev) =>
        prev.map((room) => {
          if (room.id === roomId) {
            return {
              ...room,
              dolbySpatialAudioScale,
            };
          }

          return room;
        }),
      );
    },
    [currentRoom],
  );

  const updateRoomScale = useCallback(
    async (value: number) => {
      if (currentRoom && currentEventId) {
        await EventsService.updateRoomSpatialScale(
          currentRoom.id as string,
          value,
          currentEventId,
        );

        setRoomDolbySpatialAudioScale(currentRoom.id, value);
      }
    },
    [currentRoom, currentEventId, setRoomDolbySpatialAudioScale],
  );

  const cleanStates = useCallback(() => {
    setAvatars([]);
    setAvatarSkins([]);
    setRooms([]);
    setParticipants([]);
    setOpenChat(false);
    setCurrentParticipant(undefined);
    setCurrentRoom(null);
    setCurrentEvent(undefined);

    sendJSONMessageToWebSocket({
      action: 'LEAVE_EVENT',
    });
  }, [
    setAvatars,
    setAvatarSkins,
    setRooms,
    setParticipants,
    setCurrentParticipant,
    setCurrentRoom,
    setCurrentEvent,
    sendJSONMessageToWebSocket,
  ]);

  const setSpeakingParticipants = React.useCallback(
    (speakingParticipantIds: string[]) => {
      if (currentParticipant) {
        const speaking: boolean =
          speakingParticipantIds.indexOf(currentParticipant.userId) !== -1;

        if (currentParticipant.speaking !== speaking) {
          setCurrentParticipant({
            ...currentParticipant,
            speaking,
          });
        }
      }
    },
    [currentParticipant, setCurrentParticipant, setParticipants],
  );

  const teleportToRoom = React.useCallback(
    (room: IRoom | null) => {
      setTeleportingToRoom(room);
      //TODO add is speaker to message
      if (room && currentParticipant) {
        // eslint-disable-next-line no-console
        console.log(`Teleporting to room: ${room.id} ${room.name}`);

        sendJSONMessageToWebSocket({
          action: 'TELEPORT_TO_ROOM',
          roomId: room.id,
          isSpeaker: currentParticipant.isSpeaker,
        });
      }
    },
    [setTeleportingToRoom, sendJSONMessageToWebSocket, currentParticipant],
  );

  const teleportToParticipant = React.useCallback(
    async (participant: IParticipant | null) => {
      if (!participant || !currentRoom) {
        return;
      }
      // eslint-disable-next-line no-console
      console.log(
        `Teleporting to participant: ${participant.userId} ${participant.fullName}`,
      );

      const { roomId } = await ParticipantsService.getParticipantRoom(
        participant.id,
      );

      // if (roomId === currentRoom.id) {
      sendJSONMessageToWebSocket({
        action: 'TELEPORT_TO_PARTICIPANT',
        participantId: participant.userId,
        roomId,
        CurrentGameSessionId: participant.gameSessionId || null,
      });

      setTeleportingToParticipant(null);
      // } else {
      //   setTeleportingToParticipant(participant);
      //
      //   const room = rooms.find((r) => r.id === roomId);
      //
      //   if (room) {
      //     teleportToRoom(room);
      //   }
      // }
    },
    [currentRoom, sendJSONMessageToWebSocket, setTeleportingToParticipant],
  );

  useAsyncEffect(async () => {
    if (teleportingToParticipant && !teleportingToRoom) {
      await teleportToParticipant(teleportingToParticipant);
    }
  }, [currentRoom, teleportingToRoom, teleportingToParticipant]);

  const closeLeftMenu = React.useCallback(() => {
    setOpenLeftMenu(false);
  }, [setOpenLeftMenu]);

  const openLeftMenuHandler = React.useCallback(
    (tab: any) => {
      setOpenLeftMenu(true);
      if (tab) {
        setLeftMenuTab(tab);
      }
    },
    [setOpenLeftMenu, setLeftMenuTab],
  );

  const setMutedHandler = React.useCallback(
    (value: boolean | undefined) => {
      setMuted(value || !muted);
    },
    [muted, setMuted],
  );

  const setEmojiHandler = React.useCallback(
    (emoji: string | null) => {
      setEmoji(emoji);

      sendJSONMessageToWebSocket({
        action: 'SEND_EMOJI',
        emoji,
      });
    },
    [setEmoji, sendJSONMessageToWebSocket],
  );

  const setReactionHandler = React.useCallback(
    (reaction: string | null) => {
      setReaction(reaction);

      sendJSONMessageToWebSocket({
        action: 'TOGGLE_REACTION',
        reactionId: reaction,
      });
    },
    [setReaction, sendJSONMessageToWebSocket],
  );

  useEffect(() => {
    if (shareScreenPublishers.length) {
      const isSameScreenRegion = shareScreenPublishers.some(
        (s) => s.region !== currentRegion?.region,
      );
      if (isSameScreenRegion) {
        setOpenLeaveRegionDialog(true);
      }

      return;
    }

    if (shareVideoPublishers) {
      const isSameVideoRegion =
        shareVideoPublishers.region !== currentRegion?.region;

      if (isSameVideoRegion) {
        setOpenLeaveRegionDialog(true);
      }
    }
  }, [currentRegion, shareScreenPublishers, shareVideoPublishers]);

  const getSkinsHandler = React.useCallback(
    (avatarId: string) => {
      localStorage.setItem('avatarId', avatarId);
      getAvatarSkins(avatarId);
    },
    [getAvatarSkins],
  );

  const setSharingWindowFullscreenHandle = useCallback(() => {
    setSharingWindowFullscreen(!sharingWindowFullsceen);
  }, [setSharingWindowFullscreen, sharingWindowFullsceen]);

  const trackAnalytics = React.useCallback(
    async (type: string, payload: any = {}) => {
      if (token && currentEvent && user && websocketSessionId) {
        await AnalyticsService.track(token, type, new Date().getTime(), {
          eventId: currentEventId,
          participantId: userId,
          sessionId: websocketSessionId,
          payload: {
            event: {
              id: currentEvent.id,
              name: currentEvent.name,
            },
            participant: {
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
            },
            ...payload,
          },
        });
      }
    },
    [token, currentEvent, user, websocketSessionId],
  );

  return (
    <MytaverseEventContext.Provider
      value={{
        isOpenLeftMenu: openLeftMenu,
        reaction,
        emoji,
        muted,
        showActiveCameras,
        eventLoaded,
        userFiles,
        previousSkin,
        previousCustomAvatar,
        leftMenuTab,
        rooms,
        currentEvent,
        currentRoom,
        currentRegion,
        avatars,
        avatarSkins,
        currentParticipant,
        clientOptions,
        gameCastStreamRequestSended,
        setGameCastStreamRequestSended,
        setCurrentParticipant,
        initMessageHandler,
        participants,
        teleportingToRoom,
        teleportingToParticipant,
        pointsOfInterest,
        loading,
        loadingSkins,
        loadingAvatars,
        dolbyToken,
        streamChatToken,
        millicastTokens,
        getMillicastStreamTokens,
        streamService,
        openLeaveRegionDialog,
        shareMediaParams,
        shareScreenPublishers,
        customAvatarUrl,
        nearbyParticipants,
        shareVideoPublishers,
        showControls,
        currentSkin,
        currentAvatarId,
        poiPreviewSrc,
        initMessageSended,
        sharingWindowFullsceen,
        shareMillicastVideoWithSound,
        participantsSound,
        selectedTeleportRoom,
        gameSound,
        openTeleportToRoomDialog,
        openShareScreenModal: openScreenModal,
        micUsingByMillicast,
        bubblesWithNearbyParticipants,
        openAdminSettingsModal,
        unmutedParticipantsIds,
        openCameraPublisherDialog,
        videoBubbleSize,
        setVideoBubbleSize,
        setOpenCameraPublisherDialog,
        setUnmutedParticipantsIds,
        setOpenAdminSettingsModal,
        setBubblesWithNearbyParticipants,
        setMicUsingByMillicast,
        setOpenShareScreenModal: setOpenScreenModal,
        streamingProvider,
        gameCastStreamId,
        setGameCastStreamId,
        gameCastStreamRegion,
        setGameCastStreamRegion,
        setStreamingProvider,
        setGameSound: setGameSoundHandle,
        setUe5WebsocketConnected,
        ue5WebsocketConnected,
        openShareVideoModal: openVideoModal,
        setOpenShareVideoModal: setOpenVideoModal,
        setOpenTeleportToRoomDialog,
        setSelectedTeleportRoom,
        setParticipantsSound: setParticipantsSoundHandle,
        initScreenMediaStream,
        handleStopScreenStream,
        setOpenLeaveRegionDialog,
        setSharingWindowFullscreen: setSharingWindowFullscreenHandle,
        setShareMillicastVideoWithSound,
        setInitMessageSended,
        loadEventAvatars,
        setPoiPreviewSrc,
        setCurrentAvatarId,
        setCurrentSkin,
        setShowControls,
        setParticipants,
        openLeftMenu: openLeftMenuHandler,
        closeLeftMenu,
        setReaction: setReactionHandler,
        setShowActiveCameras,
        setEventLoaded,
        setMuted: setMutedHandler,
        setEmoji: setEmojiHandler,
        setUserFiles,
        resetPreviousSkin,
        resetPreviousCustomSkin,
        selectSkin: selectSkinHandler,
        setLeftMenuTab,
        setCurrentRoom,
        setCurrentRegion,
        currentRoomDolbySpatialAudioScale,
        getSkins: getSkinsHandler,
        cleanStates,
        setTeleportingToRoom,
        teleportToRoom,
        setTeleportingToParticipant,
        teleportToParticipant,
        isTeleportingToRoomByUnreal,
        setIsTeleportingToRoomByUnreal,
        setParticipantState,
        joinParticipantToRegion,
        leaveParticipantFromRegion,
        updateParticipant,
        setSpeakingParticipants,
        setShareMediaParams,
        setShareVideoPublishers,
        startShareVideo: startShareVideoHandler,
        setRoomDolbySpatialAudioScale,
        updateRoomScale,
        stopShareVideo: stopShareVideoHandler,
        setShareScreenPublishers,
        startShareScreen: startShareScreenHandler,
        setCustomAvatarUrl,
        stopShareScreen: stopShareScreenHandler,
        setNearbyParticipants,

        shareVideoMediaStream,
        setShareVideoMediaStream,

        shareScreenMediaStreams,
        setShareScreenMediaStreams,

        trackAnalytics,

        loadingShareVideoModal,
        setLoadingShareVideoModal,

        loadingShareScreenModal,
        setLoadingShareScreenModal,

        pendingFollowersData,
        setPendingFollowersData,
        acceptedFollowersData,
        setAcceptedFollowersData,

        openMillicastScreenBroadcastingDataModal,
        setOpenMillicastScreenBroadcastingDataModal,
      }}
    >
      {children}
    </MytaverseEventContext.Provider>
  );
};
