import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageUploading from 'react-images-uploading';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { IUploadButtons } from './interfaces';

import { classes } from './styles';

const UploadButtons = ({
  hasCamera,
  setShowTakePhoto,
  onChangeUploadImage,
  onClose,
  t: translate,
}: IUploadButtons) => {
  return (
    <>
      <Box component="div">
        <Box component="div" sx={classes.header}>
          <Stack flexDirection="row">
            <Typography>{translate('userProfile.secondTitle')}</Typography>
          </Stack>
          <IconButton onClick={onClose} sx={classes.iconBtn}>
            <CloseIcon sx={classes.closeIcon} />
          </IconButton>
        </Box>
      </Box>
      <Stack flexDirection="row" columnGap={3.5} justifyContent="center">
        <ImageUploading value={[]} onChange={onChangeUploadImage} maxNumber={1}>
          {({ onImageUpload, isDragging, dragProps }) => (
            <Box
              component="div"
              sx={
                isDragging
                  ? { ...classes.tab, ...classes.dragging }
                  : classes.tab
              }
              onClick={onImageUpload}
              className="upload__image-wrapper"
              {...dragProps}
            >
              <FileUploadIcon />
              <Typography>{translate('userProfile.upload')}</Typography>
            </Box>
          )}
        </ImageUploading>
        {hasCamera ? (
          <Box
            component="div"
            onClick={() => setShowTakePhoto(true)}
            sx={classes.tab}
          >
            <AddAPhotoIcon />
            <Typography>{translate('userProfile.takePhoto')}</Typography>
          </Box>
        ) : null}
      </Stack>
      <Box component="div" sx={classes.button}>
        <Button onClick={onClose}>
          <CloseIcon />
          {translate('userProfile.cancel')}
        </Button>
      </Box>
    </>
  );
};

export default withTranslation('common')(UploadButtons);
