import React from 'react';
import { Input as BaseInput, Typography, Box } from '@mui/material';

import { usePreventGameKeydownListening } from '../../../../hooks';

import { IInput } from './interfaces';

import { useStyles } from './styles';

const Input = ({
  value,
  onChange,
  name,
  type = 'text',
  placeholder = '',
  showError = false,
  errorMessage = '',
  classes: propClasses = {},
  disabled = false,
}: IInput) => {
  const inputRef = usePreventGameKeydownListening<HTMLInputElement>();

  const { root: rootSx = {}, input: inputSx = {} } = propClasses;

  const classes = useStyles(errorMessage);
  const rootClass = { ...classes.root, ...rootSx };
  const inputClass = { ...classes.input, ...inputSx };

  return (
    <Box sx={rootClass} component="div">
      <BaseInput
        ref={inputRef}
        value={value}
        onChange={onChange}
        name={name}
        type={type}
        autoComplete={type}
        placeholder={placeholder}
        disabled={disabled}
        sx={inputClass}
      />
      {showError && !!errorMessage && (
        <Typography sx={classes.errorText}>{errorMessage}</Typography>
      )}
    </Box>
  );
};

export default Input;
