import React, { useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import ChatAttachments from '../ChatAttachments';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { useChannelActionContext } from 'stream-chat-react';

import { IChatQuotedMessagePreview } from './interfaces';
import { StreamChatGenerics } from 'stream-chat-react/dist/stories/utils';

import { getStyles } from './styles';

const ChatQuotedMessagePreview = ({
  quotedMessage,
}: IChatQuotedMessagePreview) => {
  const { setQuotedMessage } = useChannelActionContext<StreamChatGenerics>(
    'QuotedMessagePreview',
  );

  const attachments = useMemo(() => {
    const [attachment] = quotedMessage.attachments ?? [];
    return attachment ? [attachment] : [];
  }, [quotedMessage.attachments]);
  const hasAttachments = !!attachments.length;

  const classes = getStyles(hasAttachments);

  const handleCloseIcon = () => setQuotedMessage(undefined);

  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        <Typography component="span" sx={classes.replyTo}>
          Replying to:
        </Typography>
        {hasAttachments && <ChatAttachments attachments={attachments} />}
        <Typography sx={classes.text} component="span">
          {quotedMessage.text}
        </Typography>
      </Box>
      <CancelOutlinedIcon sx={classes.closeIcon} onClick={handleCloseIcon} />
    </Box>
  );
};

export default ChatQuotedMessagePreview;
