import axios from 'axios';

export default class AnalyticsService {
  static track(
    token: string,
    type: string,
    timestamp: number,
    data: any,
  ): Promise<undefined> {
    return axios.post(
      `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/analytics`,
      {
        type,
        timestamp,
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
}
