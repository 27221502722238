import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import AppHeader from '../../../../components/AppHeader';
import Footer from '../../../../components/Footer';
import Floor from '../Floor';

import { classes } from './styles';

const LoginWrapper = () => {
  return (
    <Box sx={classes.root}>
      <AppHeader />
      <Outlet />
      <Footer />
      <Floor />
    </Box>
  );
};

export default LoginWrapper;
