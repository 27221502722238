import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import TipImage from '../../../../../../../public/images/Tips/SafeSpace.png';

import { useStyles } from './styles';

const SafeSpace = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={classes.tipImage}>
        <img src={TipImage} alt="Tip Image" />
      </Box>
      <Box sx={classes.content}>
        <Typography sx={classes.title}>
          {translate('tips.safeSpace.title')}
        </Typography>
        <Typography sx={classes.description}>
          {translate('tips.safeSpace.description1')}
        </Typography>
        <Typography sx={classes.description}>
          {translate('tips.safeSpace.description2')}
        </Typography>
      </Box>
    </>
  );
};

export default withTranslation('common')(SafeSpace);
