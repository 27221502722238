import { AVATAR_SKIN_SLIDER_WIDTH } from '../../constants';

export const classes = {
  root: {
    width: AVATAR_SKIN_SLIDER_WIDTH,
    '& img': {
      objectFit: 'contain',
      maxWidth: '100%',
      height: 'auto',
      opacity: 0.8,
      '&:hover': {
        transform: 'scale(1.01)',
        opacity: 1,
      },
    },
  },
};
