/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IParticipant } from '../../../../../../interfaces/participants';
import { IRoom } from '../../../../../../interfaces/rooms';
import { IActiveRoom } from './interfaces';

export const useGetActiveRooms = () => {
  const { t: translate } = useTranslation('common');

  const getActiveRooms = useCallback(
    (participants: IParticipant[], rooms: IRoom[]): IActiveRoom[] => {
      const participantsRooms = Array.from(
        new Set(
          participants.reduce<IRoom[]>((participantsRooms, participant) => {
            const room = rooms.find((room) => room.id === participant.roomId);

            return room ? [...participantsRooms, room] : participantsRooms;
          }, []),
        ),
      );
      const activeRooms: IActiveRoom[] = participantsRooms.map((room) => {
        const activeParticipants =
          participants.filter(
            (participant) => participant.roomId === room.id,
          ) || [];

        return { ...room, activeParticipants };
      });
      const offlineRoom: IActiveRoom = {
        id: 'offline',
        name: translate('sidebar.offline'),
        activeParticipants:
          participants.filter((participant) => !participant.roomId) || [],
        dolbySpatialAudioScale: 0,
        event: '',
        image: '',
        isDefaultRoom: false,
        isInvisible: false,
        user: '',
      };

      if (offlineRoom.activeParticipants.length) {
        activeRooms.push(offlineRoom);
      }

      return activeRooms;
    },
    [],
  );

  return useMemo(() => getActiveRooms, []);
};
