/* eslint-disable no-nested-ternary */
import { COLORS } from '../../../../constants/colors';
import { CHAT_DRAWER_WIDTH, SHARED_WINDOW_HEIGHT } from '../../../../constants';

export const useStyles = (
  sharingWindowFullsceen: boolean,
  isOpenChat: boolean,
) => ({
  root: {
    width: sharingWindowFullsceen
      ? isOpenChat
        ? `calc(100vw - ${CHAT_DRAWER_WIDTH}px) !important`
        : '100vw !important'
      : 400,
    height: sharingWindowFullsceen ? '100vh !important' : SHARED_WINDOW_HEIGHT,
    position: 'fixed',
    left: sharingWindowFullsceen ? 0 : 'unset',
    right: sharingWindowFullsceen ? 'unset' : 0,
    top: 0,
    zIndex: isOpenChat ? 999999 : sharingWindowFullsceen ? -5 : 6,
    background: COLORS.NEW_BLACK,
    '& video': {
      width: sharingWindowFullsceen
        ? isOpenChat
          ? `calc(100vw - ${CHAT_DRAWER_WIDTH}px) !important`
          : '100vw !important'
        : 400,
      height: sharingWindowFullsceen ? '100vh !important' : 225,
    },
    '&:hover': {
      '& button': {
        display: sharingWindowFullsceen ? 'none !important' : 'flex',
      },
      '& p': {
        display: 'flex',
        top: sharingWindowFullsceen ? 60 : 0,
      },
    },
  },
  fullscreen: {
    display: 'none',
    position: 'absolute',
    top: 180,
    right: 20,
    '& svg': {
      color: COLORS.MYTAVERSE_BLUE,
    },
  },
});
