import React from 'react';
import { withTranslation } from 'react-i18next';

import SelectWrapper from '../../components/SelectWrapper';
import PreviousSelectedAvatar from '../../components/PreviousSelectedAvatar';
import ChooseAvatar from '../../components/ChooseAvatar';

import { ISelectAvatarPageView } from './interfaces';

const SelectAvatarPageView = ({
  hasPreviousAvatar,
  loop,
  onBackButtonClick,
  t: translate,
}: ISelectAvatarPageView) => {
  return (
    <SelectWrapper
      title={translate('selectAvatar.title')}
      description={translate('selectAvatar.description')}
      showEventsDropdown
      onBackClick={onBackButtonClick}
    >
      {hasPreviousAvatar ? (
        <PreviousSelectedAvatar />
      ) : (
        <ChooseAvatar loop={loop} />
      )}
    </SelectWrapper>
  );
};

export default withTranslation('common')(SelectAvatarPageView);
