import { colors } from '../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    width: '100%',
    marginTop: '69px',
    marginBottom: '80px',
  },
  section: {
    position: 'relative',
  },
  textContent: {
    position: 'absolute',
    content: '""',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '14px 32px',
  },
  text: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '27px',
    color: colors.white,
  },
  speed: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '27px',
    color: colors.white,
  },
});
