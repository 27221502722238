import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';

dayjs.extend(calendar);

export const useFormatDateSeparatorDate = () => {
  const { t: translate } = useTranslation('common');
  const { selectedLanguage } = useMytaverse();

  const formats = {
    sameDay: `[${translate('chatDrawer.today')}]`,
    nextDay: `[${translate('chatDrawer.tomorrow')}]`,
    nextWeek: 'dddd',
    lastDay: `[${translate('chatDrawer.yesterday')}]`,
    lastWeek: 'dddd',
    sameElse: 'DD/MM/YYYY',
  };

  const formatDateSeparatorDate = (date: Date): string => {
    const value = dayjs(date)
      .locale(selectedLanguage.toLowerCase())
      .calendar(null, formats);
    return value;
  };

  return formatDateSeparatorDate;
};
