import { useEffect, useState } from 'react';

import GameCastService from '../../../../../services/GameCastService';

import { UseInitGameCastStreamType } from './interfaces';

const DEFAULT_GAME_CAST_REGION = 'us-east-2';

export const useInitGameCastStream: UseInitGameCastStreamType = (
  currentEvent,
) => {
  const [streamId, setStreamId] = useState<string>('');
  const [streamRegion, setStreamRegion] = useState<string>('');

  useEffect(() => {
    if (!currentEvent) {
      return;
    }

    const init = async () => {
      const { streamGroupId, region } = await GameCastService.getGameSessionId(
        currentEvent.id,
      );

      if (!streamGroupId) {
        return;
      }

      setStreamRegion(region || DEFAULT_GAME_CAST_REGION);
      setStreamId(streamGroupId);
    };

    init();
  }, [currentEvent, setStreamRegion]);

  return {
    streamId,
    setStreamId,
    streamRegion,
    setStreamRegion,
  };
};
