import { COLORS } from '../../../../../constants/colors';

import { IChatMessageStyles } from './interfaces';

export const useStyles = ({
  isMyMessage,
  firstMessageOfGroup,
}: IChatMessageStyles) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '6px',
    alignItems: isMyMessage ? 'flex-end' : 'flex-start',
    paddingTop: firstMessageOfGroup ? 0 : 2,
    '&:hover .str-chat__message-simple__actions__action--options > button': {
      opacity: 1,
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '6px',
  },
  userContainer: {
    display: 'flex',
    columnGap: '6px',
    alignItems: 'center',
    alignSelf: isMyMessage ? 'flex-end' : 'flex-start',
    paddingTop: 3,
    marginRight: 3,
  },
  userName: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.25px',
    color: COLORS.WHITE_6,
    textTransform: 'capitalize',
  },
  date: {
    color: COLORS.WHITE,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.44,
    letterSpacing: '0.25px',
  },
  messageContainer: {
    display: 'flex',
    alignSelf: isMyMessage ? 'flex-end' : 'flex-start',
    alignItems: 'flex-start',
    '& .str-chat__message-attachment-card:first-of-type': {
      margin: 0,
    },
    '&:hover .context-menu-icon': {
      opacity: 0.6,
    },
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: isMyMessage ? 'flex-end' : 'flex-start',
    '& .str-chat__message-text-inner': {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.5px',
      textAlign: 'start',
      color: COLORS.WHITE,
      backgroundColor: isMyMessage ? COLORS.MYTAVERSE_BLUE : COLORS.GREY,
      borderRadius: '4px',
      border: 'none',
      padding: '12px 16px',
      '& p': {
        margin: 0,
      },
    },
    '& .str-chat__message-simple-text-inner.str-chat__message-simple-text-inner--is-emoji p':
      {
        lineHeight: 'initial',
      },
  },
});
