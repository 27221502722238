import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import MovieIcon from '@mui/icons-material/Movie';
import LanguageIcon from '@mui/icons-material/Language';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import OverflowTitle from '../../../../../../../../components/OverflowTitle';

import { formatDate } from '../../../../../../../../helpers/datetime';
import { checkIsThreeDObject } from '../../helpers';

import { IFileItem } from './interfaces';
import { PoiMediaType } from '../../../../../DashboardContent/interfaces';

import { classes } from './styles';

const FILE_ICONS = {
  [PoiMediaType.FILE]: DescriptionIcon,
  [PoiMediaType.IMAGE]: ImageIcon,
  [PoiMediaType.VIDEO]: MovieIcon,
  [PoiMediaType.WEBSITE]: LanguageIcon,
  [PoiMediaType.THREE_D_OBJECT_VIEW]: ViewInArIcon,
  [PoiMediaType.THREE_D_PROP]: ViewInArIcon,
};

const FileItem = ({
  file,
  onDownload,
  onOpenInNewTab,
  onDelete,
  t: translate,
}: IFileItem) => {
  const { displayName, mediaType, createdAt } = file;

  const Icon = FILE_ICONS[mediaType];
  const isWebsiteFile = mediaType === PoiMediaType.WEBSITE;
  const is3DObjectFile = checkIsThreeDObject(mediaType);
  const showDownloadButton = !(isWebsiteFile || is3DObjectFile);
  const showOpenInNewTabButton = isWebsiteFile;

  return (
    <Box sx={classes.root}>
      <Box sx={classes.iconContainer}>
        <Icon />
      </Box>
      <Box sx={classes.content}>
        <Box sx={classes.info}>
          <OverflowTitle title={displayName} sx={classes.fileName} />
          <Typography sx={classes.createdDate}>
            {formatDate(createdAt)}
          </Typography>
        </Box>
        <Box sx={classes.actions}>
          {showDownloadButton && (
            <Button sx={classes.action} disableRipple onClick={onDownload}>
              <DownloadIcon />
              {translate('toolbox.download')}
            </Button>
          )}
          {showOpenInNewTabButton && (
            <Button sx={classes.action} disableRipple onClick={onOpenInNewTab}>
              <DownloadIcon />
              {translate('toolbox.accessUrl')}
            </Button>
          )}
          <Button sx={classes.action} disableRipple onClick={onDelete}>
            <DeleteIcon />
            {translate('toolbox.delete')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(FileItem);
