import React from 'react';
import { useTranslation } from 'react-i18next';

import { VirtualAssistantIcon } from '../../../../../icons/VirtualAssistantIcon';
import HomeBarButton from '../../HomeBarButton/HomeBarButton';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

export enum VirtualAssistantActions {
  TOGGLE = 'TOGGLE_VIRTUAL_ASSISTANT',
}

const VirtualAssistant = () => {
  const { t: translate } = useTranslation('common');
  const { sendMessageToUnreal } = useMytaverse();
  const handleToggleAssistant = () => {
    sendMessageToUnreal({
      action: VirtualAssistantActions.TOGGLE,
    });
  };

  return (
    <HomeBarButton
      handleClick={handleToggleAssistant}
      icon={<VirtualAssistantIcon />}
      label={translate('tooltips.virtualAssistant')}
    />
  );
};

export default VirtualAssistant;
