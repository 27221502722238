import { getScrollbarStyles } from '../../../helpers';

export const useStyles = () => ({
  root: {
    '& .emoji-mart': {
      ...getScrollbarStyles({ width: 14, borderWidth: 2 }),
      '& .emoji-mart-search': {
        display: 'none',
      },
      '& .emoji-mart-scroll': {
        '& .emoji-mart-category .emoji-mart-category-label': {
          top: -1,
        },
      },
    },
  },
});
