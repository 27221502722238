/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';

import { IMillicastVideoView } from './interfaces';
import { useStyles } from './styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LoadingProgress from '../../../../../../components/LoadingProgress';
import { useMytaverseEvent } from '../../../../providers';

const MillicastVideoView = ({
  loading,
  shareVideoPublishers,
  onShareVideoStreamClick,
  mediaStream,
  videoTagMaxHeight,
}: IMillicastVideoView) => {
  const classes = useStyles(shareVideoPublishers, videoTagMaxHeight);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { shareMillicastVideoWithSound, setShareMillicastVideoWithSound } =
    useMytaverseEvent();

  useEffect(() => {
    if (!mediaStream) {
      return;
    }
    //@ts-ignore
    navigator.attachMediaStream(videoRef.current, mediaStream);
  }, [mediaStream]);

  return (
    <Box component="div" sx={classes.root}>
      <Box component="div" sx={classes.videoContainer}>
        {mediaStream ? (
          <video ref={videoRef} autoPlay playsInline muted={true} />
        ) : (
          <LoadingProgress height="450px" />
        )}

        <FormGroup sx={classes.checkbox}>
          <FormControlLabel
            label={
              <Typography sx={classes.checkboxTitle}>
                Share Video With Sound
              </Typography>
            }
            control={
              <Checkbox
                checked={shareMillicastVideoWithSound}
                disabled={!!shareVideoPublishers}
                onChange={() =>
                  setShareMillicastVideoWithSound(!shareMillicastVideoWithSound)
                }
              />
            }
          />
        </FormGroup>
      </Box>

      <Button
        variant="contained"
        onClick={onShareVideoStreamClick}
        disabled={loading || !mediaStream}
        sx={classes.button}
      >
        {shareVideoPublishers ? 'Stop' : 'Share video'}
        {loading ? <CircularProgress size={20} sx={{ ml: 2 }} /> : ''}
      </Button>
    </Box>
  );
};

export default MillicastVideoView;
