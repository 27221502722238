import React from 'react';

import {
  Box, Typography, Backdrop, Fade, Modal, Tab, IconButton,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList } from '@mui/lab';

import DevicesSettingsVideo from './DevicesSettingsVideo';
import DevicesSettingsGeneral from './DevicesSettingsGeneral';
import DevicesSettingsSound from './DevicesSettingsSound';
import { DevicesSettingsModalViewProps } from './interfaces';
import { useStyles } from './styles';

const DevicesSettingsModalView = ({
  open,
  handleClose,
  checkVideo,
  setCheckVideo,
  settingsTab,
  setSettingsTab,
  translate,
}: DevicesSettingsModalViewProps) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        setCheckVideo(false);
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={classes.modal} component="div">
          <TabContext
            value={settingsTab}
          >
            <Box
              sx={classes.title}
              component="div"
            >
              <Box component="div" sx={classes.titleWrapper}>
                <SettingsIcon />
                <Typography
                  sx={classes.titleName}
                >
                  {translate('settingsModal.settings')}
                </Typography>
              </Box>
              <IconButton
                onClick={handleClose}
                sx={classes.titleButton}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={classes.tablistRoot}
              component="div"
            >
              <TabList
                onChange={(event: React.SyntheticEvent, tab: any) => setSettingsTab((tab))}
                sx={classes.tablist}
              >
                <Tab
                  value="sound"
                  label={(
                    <Box
                      sx={classes.tab}
                      component="div"
                    >
                      <Typography>
                        {translate('settingsModal.sound')}
                      </Typography>
                    </Box>
                  )}
                  sx={classes.tabSelect}
                />
                <Tab
                  value="camera"
                  label={(
                    <Box
                      sx={classes.tab}
                      component="div"
                    >
                      <Typography>
                        {translate('settingsModal.camera')}

                      </Typography>
                    </Box>
                  )}
                  sx={classes.tabSelect}
                />
                <Tab
                  value="general"
                  label={(
                    <Box
                      sx={classes.tab}
                      component="div"
                    >
                      <Typography>
                        {translate('settingsModal.general')}
                      </Typography>
                    </Box>
                  )}
                  sx={classes.tabSelect}
                />
              </TabList>
              <Box
                sx={classes.tabContent}
                component="div"
              >
                <DevicesSettingsSound />
                <DevicesSettingsVideo
                  checkVideo={checkVideo}
                  setCheckVideo={() => setCheckVideo(!checkVideo)}
                />
                <DevicesSettingsGeneral />
              </Box>
            </Box>
          </TabContext>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DevicesSettingsModalView;
