import React from 'react';

import { Box } from '@mui/material';

import { ISvgIconWrapper } from './interfaces';

import { useStyles } from './styles';

const SvgIconWrapper = ({ children, sx = {} }: ISvgIconWrapper) => {
  const classes = useStyles();
  const rootSx = { ...classes.root, ...sx };

  return <Box sx={rootSx}>{children}</Box>;
};

export default SvgIconWrapper;
