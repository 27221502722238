import React from 'react';

import { Box, Typography } from '@mui/material';

import { IReactionItem } from './interfaces';

import { useStyles } from './styles';

const ReactionItem = ({ reaction, onClick }: IReactionItem) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root} onClick={onClick}>
      <img src={reaction.previewImage} alt="Reaction" />
      <Typography sx={classes.title}>{reaction.title}</Typography>
    </Box>
  );
};

export default ReactionItem;
