import { CSSProperties } from 'react';

import { COLORS, colors } from '../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  } as CSSProperties,
  content: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 3,
  },
  userData: {
    width: '100%',
    overflow: 'hidden',
  },
  rootInput: {
    width: '100%',
  },
  input: {
    color: COLORS.LIGHT_GREY,
    fontSize: [18, 22],
    '&::before': {
      borderBottom: `1px solid ${COLORS.BG}`,
    },
    '&::after': {
      borderBottom: `1px solid ${colors.white}`,
    },
    '&.MuiInput-root:hover': {
      '&::before': {
        borderBottom: `1px solid ${colors.white}`,
      },
      '&::after': {
        borderBottom: `1px solid ${colors.white}`,
      },
    },
    '& input': {
      p: 0,
    },
  },
  inputCompany: {
    fontSize: 16,
    mb: 3.5,
  },
  inputItems: {
    fontSize: 14,
  },
  text: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.1,
    color: COLORS.PROFILE_GREY,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  saveBtn: {
    p: 1,
    height: 33,
    width: 90,
    ml: 0,
    display: 'flex',
    background: '#00000080',
    whiteSpace: 'nowrap',
    '& p': {
      textTransform: 'capitalize',
      color: colors.white,
      fontSize: 14,
    },
    '& svg': {
      color: COLORS.BG,
      height: 20,
      width: 20,
      mr: 1,
    },
  },
  iconButton: {
    p: 0,
    mr: 1.8,
  },
  closeIcon: {
    color: COLORS.RED,
  },
  checkIcon: {
    color: COLORS.GREEN,
  },
});
