import React from 'react';
import { useNavigate } from 'react-router-dom';

import ForgotPasswordVerificationPageView from './ForgotPasswordVerificationPageView';

import { getSearchParamValue } from '../../../../helpers/path';

import ROUTES from '../../../../constants/routes';

const ForgotPasswordVerificationPage = () => {
  const navigate = useNavigate();

  const emailParam = decodeURIComponent(
    getSearchParamValue(location.search, 'email'),
  );

  const handleBackClick = () => {
    navigate(`${ROUTES.LOGIN}/${ROUTES.FORGOT_PASSWORD}`);
  };

  return (
    <ForgotPasswordVerificationPageView
      email={emailParam}
      onBackClick={handleBackClick}
    />
  );
};

export default ForgotPasswordVerificationPage;
