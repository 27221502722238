import React, { MouseEvent } from 'react';
import { ReactEventHandler } from 'stream-chat-react/dist/components/Message/types';

import {
  Box,
  ClickAwayListener,
  Grow,
  Popper,
  Typography,
} from '@mui/material';

import { IContextMenu } from './interfaces';

import { classes } from './styles';

const ContextMenu = ({
  anchorEl,
  onClose,
  content,
  placement = 'bottom-end',
}: IContextMenu) => {
  const handleClick = (evt: MouseEvent, onCallback: ReactEventHandler) => {
    onCallback(evt);
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
      <Box>
        <Popper
          open
          anchorEl={anchorEl}
          transition
          placement={placement}
          sx={classes.popper}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
              <Box sx={classes.menu}>
                {content.map(({ text, onClick }, index) => (
                  <Typography
                    key={index}
                    sx={classes.menuItem}
                    onClick={(evt) => handleClick(evt, onClick)}
                  >
                    {text}
                  </Typography>
                ))}
              </Box>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default ContextMenu;
