import React from 'react';
import { Modal, Backdrop, Box, Typography } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';

import HomeBottomButtonOptionsSettings from '../../HomeBottomBar/components/HomeBottomButtonOptionsSettings';
import MillicastVideoComponent from '../../HomeBottomBar/ShareScreen/MilicastVideo/MilicastVideo';

import { useConference } from '../../DashboardContent/Dolby';
import { useMytaverseEvent } from '../../../providers';
import { useConferenceState } from '../../../../../hooks/conferenceContext';

import { useStyles } from './styles';

const MillicastShareVideoModal = () => {
  const classes = useStyles();
  const { setOpenShareVideoModal, loadingShareVideoModal } =
    useMytaverseEvent();

  const { cameras, selectCamera } = useConference();
  const { activeCameraDeviceId } = useConferenceState();

  return (
    <Modal
      open
      closeAfterTransition
      onClose={() => {
        if (loadingShareVideoModal) {
          return;
        }

        setOpenShareVideoModal(false);
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box sx={classes.modal}>
        <Typography sx={classes.title}>Share Video</Typography>
        <Box sx={classes.settingsContainer}>
          <HomeBottomButtonOptionsSettings
            devices={cameras}
            handleSelect={(e) => selectCamera(e.target.value)}
            label={null}
            selectedValue={activeCameraDeviceId}
            SelectIcon={VideocamIcon}
          />
          <MillicastVideoComponent videoTagMaxHeight={390} />
        </Box>
      </Box>
    </Modal>
  );
};

export default MillicastShareVideoModal;
