import { useTranslation } from 'react-i18next';

import { useMytaverseEvent } from '../../../../providers';
import { useGameCast } from '../GameCastProvider';

import { getProgressStepTitle } from '../../../AppLoader';

export const useGameCastProgressBar = () => {
  const { initMessageSended, currentRoom } = useMytaverseEvent();
  const { isStreamLoaded } = useGameCast();
  const { t: translate } = useTranslation('common');

  const secondStepLoading = !initMessageSended;
  const secondStepCompleted = initMessageSended;
  const thirdStepLoading = secondStepCompleted && !isStreamLoaded;
  const thirdStepCompleted = secondStepCompleted && isStreamLoaded;
  const forthStepLoading = thirdStepCompleted && !currentRoom;
  const forthStepCompleted = thirdStepCompleted && !!currentRoom;
  const title = getProgressStepTitle({
    secondStepLoading,
    thirdStepLoading,
  });

  return {
    secondStepLoading,
    secondStepCompleted,
    thirdStepLoading,
    thirdStepCompleted,
    forthStepLoading,
    forthStepCompleted,
    title: translate(title),
  };
};
