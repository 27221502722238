/* eslint-disable prefer-const */
import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel, Navigation } from 'swiper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/material';

import VideoBubble from './VideoBubble';

import { useMytaverseEvent } from '../../providers';
import { useConference } from '../DashboardContent/Dolby';

import { serializeCameraMediaStreams } from './helpers';

import { useStyles } from './styles';
import { ISerializeCameraMediaStreams } from './interfaces';

import 'swiper/swiper.min.css';
import { SpatialType } from '../../../../interfaces/webSocketConnectionInfo';

SwiperCore.use([Mousewheel, Navigation]);

const VideoBubbles = () => {
  const {
    sharingWindowFullsceen,
    showActiveCameras,
    nearbyParticipants,
    currentParticipant,
    participants,
    bubblesWithNearbyParticipants,
    unmutedParticipantsIds,
    currentRegion,
  } = useMytaverseEvent();

  const {
    cameraMediaStreams,
    screenMediaStreams,
    speakingParticipantIds,
    spatialTypeName,
  } = useConference();

  const speakingBubbles = useRef<ISerializeCameraMediaStreams[]>([]);
  const silentBubbles = useRef<ISerializeCameraMediaStreams[]>([]);

  const bubbles = useRef<ISerializeCameraMediaStreams[]>([]);

  const classes = useStyles({
    sharingFullscreen: sharingWindowFullsceen,
    hasScreenMediaStreams: !!screenMediaStreams.length,
  });

  const serializedCameraMediaStreams: ISerializeCameraMediaStreams[] =
    serializeCameraMediaStreams({
      cameraMediaStreams,
      nearbyParticipants,
      currentParticipant,
      participants,
      speakingParticipantIds,
      bubblesWithNearbyParticipants,
      unmutedParticipantsIds,
      spatialTypeName,
    });

  useEffect(() => {
    const silentStreams = serializedCameraMediaStreams.filter(
      (b) => !b.speaking,
    );

    speakingParticipantIds.forEach((id) => {
      const currentBubble = speakingBubbles.current.find(
        (b) => b.participantId === id,
      );
      const currentStream = serializedCameraMediaStreams.find(
        (b) => b.participantId === id,
      );

      if (!currentBubble && currentStream) {
        speakingBubbles.current.push({
          ...currentStream,
          lastTimeSpeaking: Date.now(),
        });
      }

      if (silentBubbles.current.some((b) => b.participantId === id)) {
        silentBubbles.current = silentBubbles.current.filter(
          (b) => b.participantId !== id,
        );
      }
    });

    silentStreams.forEach((stream) => {
      const currentBubble = speakingBubbles.current.find(
        (b) => b.participantId === stream.participantId,
      );

      const currentSilentBubble = silentBubbles.current.find(
        (b) => b.participantId === stream.participantId,
      );

      if (
        speakingBubbles.current.some(
          (b) => b.participantId === stream.participantId,
        )
      ) {
        speakingBubbles.current = speakingBubbles.current.filter(
          (b) => b.participantId !== stream.participantId,
        );

        silentBubbles.current.push({
          ...stream,
          lastTimeSpeaking: currentBubble?.lastTimeSpeaking,
        });

        return;
      }

      if (!currentSilentBubble) {
        silentBubbles.current.push({
          ...stream,
          lastTimeSpeaking: 0,
        });
      }
    });

    bubbles.current = [...speakingBubbles.current, ...silentBubbles.current];

    bubbles.current = bubbles.current
      .map((bubble) => {
        const isMuted = unmutedParticipantsIds.some(
          (id) => id === bubble.participantId,
        );

        return {
          ...bubble,
          muted: !isMuted,
        };
      })
      .sort((a, b) => Number(b.lastTimeSpeaking) - Number(a.lastTimeSpeaking));
  }, [
    serializedCameraMediaStreams,
    speakingParticipantIds,
    unmutedParticipantsIds,
  ]);

  const showBubbles =
    showActiveCameras &&
    !!bubbles.current.length &&
    currentRegion?.regionSpatialType !== SpatialType.OneToMany;

  if (!showBubbles || !currentParticipant) return null;

  return (
    <Box sx={classes.rootWrapper} component="div">
      {bubbles.current.length <= 4 ? (
        <Box className="swiper-button-next">
          <ArrowForwardIcon />
        </Box>
      ) : null}
      <Swiper
        direction="horizontal"
        breakpoints={{
          640: {
            slidesPerView:
              bubbles.current.length < 2 ? bubbles.current.length : 2,
          },
          768: {
            slidesPerView:
              bubbles.current.length < 3 ? bubbles.current.length : 3,
          },
          1450: {
            slidesPerView: 4,
          },
        }}
        className="mySwiper"
        mousewheel={{
          sensitivity: 1.5,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        style={classes.root as React.CSSProperties}
      >
        {bubbles.current.map(
          ({ participantId, username, speaking, stream, muted }) => (
            <SwiperSlide key={participantId}>
              <VideoBubble
                key={participantId}
                username={username}
                speaking={speaking}
                stream={stream}
                muted={muted}
              />
            </SwiperSlide>
          ),
        )}
      </Swiper>
      {bubbles.current.length <= 4 ? (
        <Box className="swiper-button-prev">
          <ArrowBackIcon />
        </Box>
      ) : null}
    </Box>
  );
};

export default VideoBubbles;
