import { keyframes } from '@mui/system';

import { colors } from '../../../../constants/colors';

const iconPulseKeyframe = keyframes`
  0% {
    box-shadow: 0 0 0 0 ${colors.white_60};
    width: 110px;
    height: 110px;
  }
  100% {
    box-shadow: 0 0 0 25px ${colors.white_20};
    width: 90px;
    height: 90px;
  }
`;

export const classes = {
  aimPulse: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animation: `${iconPulseKeyframe} 0.7s infinite`,
    borderRadius: 72,
    position: 'absolute',
    border: `10px solid ${colors.white_90}`,
  },
};
