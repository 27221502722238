import { useState } from 'react';

import { getCatchErrorMessage } from '../../../../helpers/error';

import UserService from '../../../../services/UserService';

export const useUpdatePassword = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const updatePassword = async (password: string, token: string) => {
    try {
      setLoading(true);

      const isSuccess = await UserService.updatePassword(password, token);

      setSuccess(isSuccess);
    } catch (error: unknown) {
      throw Error(getCatchErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    success,
    updatePassword,
  };
};
