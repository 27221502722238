import { useMytaverse } from '../../providers/MytaverseProvider';

export const useStyles = () => {
  const { appTheme } = useMytaverse();

  const assets = appTheme.languageButton.assets;

  return {
    button: {
      p: 1,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    languageIcon: {
      color: assets.iconColor,
      mr: 1,
    },
    selectedLanguage: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15008px',
      color: assets.fontColor,
    },
  };
};
