import { colors, COLORS } from '../../../../../../../../../constants/colors';

import { ISearchFieldStyles } from './interfaces';

export const useStyles = ({ value }: ISearchFieldStyles) => ({
  input: {
    backgroundColor: colors.gray,
    columnGap: 2,
    borderRadius: '4px',
    border: `1px solid ${colors.white_20}`,
    padding: '4px 8px',
    height: 32,
    '&:hover': {
      border: `1px solid ${colors.white_20}`,
      '&:not(.Mui-disabled)::before': {
        borderBottom: 'none',
      },
    },
    '&.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled)::before': {
      content: "''",
      transition: 'none',
      borderBottom: 'none',
    },
    '&::after': {
      borderBottom: 'none',
    },
    '& input': {
      padding: 0,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      color: colors.white_80,
      '&::placeholder': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 1.43,
        letterSpacing: '0.25px',
        color: colors.white_60,
        opacity: 1,
      },
    },
  },
  searchIcon: {
    width: 24,
    height: 24,
    fontSize: 16,
    color: colors.white,
    opacity: value ? 1 : 0.4,
  },
  clearIcon: {
    fontSize: 16,
    width: 24,
    height: 24,
    color: COLORS.MEDIUM_GREY,
    cursor: 'pointer',
    '&:hover': {
      color: colors.white,
    },
  },
});
