import { useState } from 'react';

import { UseOpenConfirmClearChatModalType } from './interfaces';

export const useOpenConfirmClearChatModal: UseOpenConfirmClearChatModalType =
  () => {
    const [open, setOpen] = useState(false);

    const openModal = () => setOpen(true);

    const closeModal = () => setOpen(false);

    return { open, openModal, closeModal };
  };
