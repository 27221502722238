import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';

import { IEventConfirmationPageView } from './interfaces';

import { useStyles } from './styles';

const EventConfirmationPageView = ({
  isConfirmed,
  timer,
  t: translate,
}: IEventConfirmationPageView) => {
  const classes = useStyles();

  const title = isConfirmed
    ? 'eventConfirmation.successTitle'
    : 'eventConfirmation.errorTitle';

  return (
    <Box sx={classes.root}>
      {isConfirmed ? (
        <VerifiedIcon sx={classes.successIcon} />
      ) : (
        <CancelIcon sx={classes.failedIcon} />
      )}
      <Typography sx={classes.title}>{translate(title)}</Typography>
      <Typography sx={classes.teleport}>
        {isConfirmed ? (
          `${translate('eventConfirmation.successSubtitle')} ${timer}`
        ) : (
          // TODO uncomment when support will be ready
          <></>
          // <>
          //   {translate('eventConfirmation.errorSubtitle')}{' '}
          //   <Typography component="span" sx={classes.support}>
          //     {translate('eventConfirmation.support')}.
          //   </Typography>
          // </>
        )}
      </Typography>
    </Box>
  );
};

export default withTranslation('common')(EventConfirmationPageView);
