import React from 'react';

import { Box, Typography } from '@mui/material';

import ParticipantItem from './ParticipantItem';
import SearchField from '../../../SearchField';

import { ParticipantsTabViewProps } from './interfaces';

import { useStyles } from './styles';
import LoadingProgress from '../../../../../../components/LoadingProgress';

const ParticipantsTabView = ({
  inputValue,
  onInputChange,
  onInputClear,
  activeRooms,
  currentParticipantId,
}: ParticipantsTabViewProps) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root} component="div">
      <Box component="div">
        <SearchField
          onChange={onInputChange}
          onClear={onInputClear}
          value={inputValue}
          sx={classes.input}
        />
        <Box component="div">
          {activeRooms.map((room) => (
            <Box component="div" key={room.id}>
              <Box sx={classes.group} component="div">
                <Typography sx={classes.groupName}>{room.name}</Typography>
              </Box>
              {room.activeParticipants.map((participant) => (
                <ParticipantItem
                  key={participant.key}
                  participant={participant}
                  currentParticipantId={currentParticipantId}
                />
              ))}
            </Box>
          )) || <LoadingProgress />}
        </Box>
      </Box>
    </Box>
  );
};

export default ParticipantsTabView;
