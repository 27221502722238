import { COLORS } from '../../../../../constants/colors';

import { SxProps } from '@mui/material';

export const useStyles = () => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: [280, 640],
    height: 640,
    border: `2px solid ${COLORS.BLACK}`,
    boxShadow: 24,
    p: ['20px 10px', 3],
    pt: 2.5,
    backdropFilter: 'blur(12px)',
    backgroundColor: COLORS.NEW_BLACK,
  } as SxProps,

  title: {
    width: '100%',
    pb: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
    '& svg': {
      color: COLORS.LIGHT_GREY,
    },
  },

  titleName: {
    textAlign: 'left',
    color: COLORS.WHITE,
    fontSize: 18,
    lineHeight: 3,
  },

  titleButton: {
    '& svg': {
      color: COLORS.WHITE,
    },
  },

  tablistRoot: {
    display: 'flex',
    flexDirection: 'column',
  },

  tablist: {
    mb: 2,
    '& button': {
      textTransform: 'none',
    },

    '& .MuiTabs-flexContainer': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      columnGap: 3.8,
    },
    '& span': {
      display: 'none',
      lineHeight: 4,
    },
  },

  tab: {
    display: 'flex',
    flexDirection: 'row !important',
    alignItems: 'flex-start',
    width: '100%',
    '& p': {
      fontSize: 24,
      fontWeight: 500,
    },
  },

  tabSelect: {
    color: COLORS.MEDIUM_GREY,
    pl: 0,
    pr: 0,
    '&.Mui-selected': {
      color: COLORS.WHITE,
      borderBottom: 'none',
    },
  },

  tabContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
