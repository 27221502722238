import React from 'react';

import {
  Box,
  FormControl,
  Input as BaseInput,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { IInput } from './interfaces';

import { classes } from './styles';

const Input = ({
  value,
  onChange,
  name,
  type = 'text',
  placeholder = '',
  showError = false,
  errorMessage = '',
  classes: propClasses = {},
  disabled = false,
  ...baseInputProps
}: IInput) => {
  const { root: rootSx = {}, input: inputSx = {} } = propClasses;

  const rootClass = { ...classes.root, ...rootSx };
  const inputClass = { ...classes.input, ...inputSx };

  return (
    <FormControl sx={rootClass} error={showError}>
      <BaseInput
        value={value}
        onChange={onChange}
        name={name}
        type={type}
        autoComplete="off"
        placeholder={placeholder}
        disabled={disabled}
        sx={inputClass}
        {...baseInputProps}
      />
      {showError && !!errorMessage && (
        <Box sx={classes.errorContainer}>
          <CancelIcon sx={classes.errorIcon} />
          <Typography sx={classes.errorText}>{errorMessage}</Typography>
        </Box>
      )}
    </FormControl>
  );
};

export default Input;
