import React from 'react';

import { Box, SvgIconProps } from '@mui/material';
import BaseMarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import { colors } from '../constants/colors';

const classes = {
  root: {
    display: 'flex',
    padding: '15px 8px',
    backgroundColor: colors.myta_10,
    borderRadius: 2,
  },
  icon: {
    color: colors.myta,
    flexShrink: 0,
    width: 50,
    height: 36,
  },
};

export const MarkEmailReadIcon = (props: SvgIconProps) => (
  <Box sx={classes.root}>
    <BaseMarkEmailReadIcon sx={classes.icon} {...props} />
  </Box>
);
