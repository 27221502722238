import { useState } from 'react';

import { UseOpenConfirmLogoutModalType } from './interfaces';

export const useOpenConfirmLogoutModal: UseOpenConfirmLogoutModalType = () => {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);

  const closeModal = () => setOpen(false);

  return { open, openModal, closeModal };
};
