import { COLORS } from '../../../../constants/colors';

export const classes = {
  tab: {
    mt: 8.8,
    color: COLORS.WHITE,
    width: 158,
    height: 99,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.PROFILE_BACKGROUND_GREY,
    borderRadius: 1,
    cursor: 'pointer',
    '& svg': {
      color: COLORS.WHITE,
      fontSize: 47,
    },
    '& input': {
      width: 158,
      height: 99,
      position: 'absolute',
      cursor: 'pointer',
      opacity: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      color: COLORS.WHITE,
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 400,
      ml: 1.5,
    },
    '& svg': {
      color: COLORS.WHITE,
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    mt: 5,
    '& button': {
      color: COLORS.WHITE,
      textTransform: 'capitalize',
      width: 99,
      height: 33,
      background: COLORS.PROFILE_BACKGROUND_GREY,
      '& svg': {
        color: COLORS.RED,
      },
    },
  },
  dragging: {
    transform: 'scale(1.02)',
  },
  iconBtn: {
    p: 0,
  },
  closeIcon: {
    color: COLORS.MEDIUM_GREY,
  },
};
