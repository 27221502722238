import React from 'react';

import { Box, SelectChangeEvent, SxProps } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';

import SingleSelect from '../../../../../components/SingleSelect';

import { ICamerasSelection } from './interfaces';

import { useStyles } from './styles';

const CamerasSelection = ({
  devices,
  activeDeviceId,
  onDeviceChange,
  disabled,
  menuProps,
  sx = {},
}: ICamerasSelection) => {
  const classes = useStyles();
  const rootSx = { ...classes.root, ...sx } as SxProps;

  const options = devices.map((device) => ({
    label: device.label,
    value: device.deviceId,
  }));

  const handleChange = (event: SelectChangeEvent) => {
    onDeviceChange(event.target.value);
  };

  return (
    <Box sx={rootSx}>
      <VideocamIcon sx={classes.icon} />
      <SingleSelect
        options={options}
        activeValue={activeDeviceId}
        onChange={handleChange}
        disabled={disabled}
        emptyPlaceholder="No available devices"
        sx={classes.select}
        menuProps={menuProps}
      />
    </Box>
  );
};

export default CamerasSelection;
