import { colors } from '../../constants/colors';

export const classes = {
  logo: {
    height: 32,
    '& svg': {
      color: colors.oxford,
      width: 184,
      height: 32,
    },
  },
};
