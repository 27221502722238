import { SpatialType } from '../../../../interfaces/webSocketConnectionInfo';
import {
  ISerializeCameraMediaStreams,
  SerializeCameraMediaStreamsType,
} from './interfaces';

export const serializeCameraMediaStreams: SerializeCameraMediaStreamsType = ({
  cameraMediaStreams,
  nearbyParticipants,
  currentParticipant,
  participants,
  speakingParticipantIds,
  bubblesWithNearbyParticipants,
  unmutedParticipantsIds,
  spatialTypeName,
}) => {
  const serializedCameraMediaStreams = cameraMediaStreams.reduce<
    ISerializeCameraMediaStreams[]
  >((acc, mediaStream) => {
    if (!currentParticipant) {
      return [];
    }
    const hasCameraPosition = nearbyParticipants.some(
      (participantId) => participantId === mediaStream.participantId,
    );
    const participant = participants.find(
      ({ id }) => id === mediaStream.participantId,
    );

    if (
      (!hasCameraPosition && bubblesWithNearbyParticipants) ||
      !participant ||
      participant.id === currentParticipant.id ||
      (spatialTypeName === SpatialType.OneToMany && !participant.isSpeaker)
    ) {
      return acc;
    }

    const username = participant.fullName;
    const speaking = speakingParticipantIds.some(
      (id) => id === mediaStream.participantId,
    );

    const muted = !unmutedParticipantsIds.some((ids) => ids === participant.id);

    acc.push({ ...mediaStream, username, speaking, muted: muted as boolean });

    return acc;
  }, []);

  return serializedCameraMediaStreams;
};
