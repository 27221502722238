import { colors } from '../../../../../constants/colors';

export const getStyles = (hasAttachments: boolean) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '12px',
    backgroundColor: colors.white_20,
    padding: '14px 24px 14px 27px',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: hasAttachments ? 'column' : 'row',
    width: '100%',
    minWidth: 0,
  },
  replyTo: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: colors.white,
    marginRight: '4px',
    whiteSpace: 'nowrap',
  },
  text: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: colors.white,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
  closeIcon: {
    color: colors.white_60,
    cursor: 'pointer',
  },
});
