import { useTheme } from '@mui/material';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  const { appTheme } = useMytaverse();
  const titleColor = appTheme.loginPage.assets.titleColor;
  const subtitleColor = appTheme.loginPage.assets.subtitleColor;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 1,
      textAlign: 'center',
      alignSelf: 'center',
      [breakpoints.down(ScreenSizes.Tablet)]: {
        width: '80%',
      },
    },
    title: {
      fontWeight: 500,
      fontSize: 30,
      lineHeight: '44px',
      color: titleColor,
      [breakpoints.down(ScreenSizes.Pc)]: {
        fontSize: 28,
      },
      [breakpoints.down(ScreenSizes.Tablet)]: {
        fontSize: 20,
        lineHeight: '24px',
      },
    },
    subtitle: {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '28px',
      letterSpacing: '0.25px',
      color: subtitleColor,
      [breakpoints.down(ScreenSizes.Pc)]: {
        fontSize: 18,
      },
      [breakpoints.down(ScreenSizes.Tablet)]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
  };
};
