import React from 'react';
import { Modal, Backdrop, Box, Typography, Stack } from '@mui/material';

import MilicastScreen from '../../HomeBottomBar/ShareScreen/MilicastScreen';
import { useMytaverseEvent } from '../../../providers';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { useStyles } from './styles';
import useAsyncEffect from 'use-async-effect';

const MillicastShareScreenModal = () => {
  const classes = useStyles();
  const {
    initScreenMediaStream,
    setOpenShareScreenModal,
    loadingShareScreenModal,
  } = useMytaverseEvent();

  useAsyncEffect(async () => {
    await initScreenMediaStream();
  }, []);

  return (
    <Modal
      open
      onClose={() => {
        if (loadingShareScreenModal) {
          return;
        }

        setOpenShareScreenModal(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box sx={classes.modal}>
        <Stack
          flexDirection="row"
          alignItems="center"
          mb={5}
          justifyContent="center"
        >
          <ScreenShareIcon sx={classes.icon} />
          <Typography sx={classes.title}>Share Screen</Typography>
        </Stack>
        <MilicastScreen />
      </Box>
    </Modal>
  );
};

export default MillicastShareScreenModal;
