import React, { useEffect } from 'react';

import LoadingProgress from '../../../../components/LoadingProgress';
import SelectEventPageView from './SelectEventPageView';

import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { useDetectSwiperLoop } from '../../hooks';

const SelectEventPage = () => {
  const { loadEvents, events, loading } = useMytaverse();
  const loop = useDetectSwiperLoop(events.length);

  useEffect(() => {
    if (!events.length) {
      loadEvents();
    }
  }, [events, loadEvents]);

  if ((!events.length && loading) || loop === null) {
    return <LoadingProgress fullHeight />;
  }

  return <SelectEventPageView events={events} loop={loop} />;
};

export default SelectEventPage;
