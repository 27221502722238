import React from 'react';

import { Box } from '@mui/material';

import PasswordValidation from '../PasswordValidation';

import { IPasswordValidationWrapper } from './interfaces';

import { classes } from './styles';

const PasswordValidationWrapper = ({
  children,
  open,
  hasPasswordValue,
  isSubmitting,
  error,
}: IPasswordValidationWrapper) => {
  return (
    <Box sx={classes.root}>
      {children}
      <PasswordValidation
        open={open}
        hasPasswordValue={hasPasswordValue}
        isSubmitting={isSubmitting}
        error={error}
      />
    </Box>
  );
};

export default PasswordValidationWrapper;
