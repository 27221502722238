import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import LoginPageView from './LoginPageView';

import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { useLogout, useSocialSignIn } from './hooks';

import { getSearchParamValue } from '../../../../helpers/path';
import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';

import { LoginSchema } from './validation';

import { IFormValues } from './interfaces';
import { IEventThemeTypes, SocialProviders } from '../../../../interfaces';

const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  const { loginUser, appTheme } = useMytaverse();
  const [socialLoading, socialSignIn] = useSocialSignIn();
  const { t: translate } = useTranslation('common');

  const isDefaultEvent = appTheme.themeName === IEventThemeTypes.DEFAULT;
  const appThemeTitle = appTheme.loginPage.typography.title;
  const title = isDefaultEvent
    ? translate(appThemeTitle)
    : `${translate('login.welcomeTo')} ${appThemeTitle}`;
  const welcomeBackFlag = decodeURIComponent(
    getSearchParamValue(location.search, 'welcome-back'),
  );

  useLogout();

  const handleSignIn = async (values: IFormValues) => {
    setLoading(true);
    await loginUser(values);
    setLoading(false);
  };

  const {
    values,
    touched,
    errors,
    setFieldTouched,
    handleChange,
    handleSubmit,
  } = useFormik<IFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: handleSignIn,
    validationSchema: LoginSchema,
    enableReinitialize: true,
  });

  const handleInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFieldTouched(event.target.name, false, false);
    handleChange(event);
  };

  const handleGoogleClick = () => {
    sendCustomSentryBreadcrumb({ message: 'sign in by Google' });
    socialSignIn(SocialProviders.Google);
  };

  const handleMicrosoftClick = () => {
    sendCustomSentryBreadcrumb({ message: 'sign in by Microsoft' });
    socialSignIn(SocialProviders.Microsoft);
  };

  return (
    <LoginPageView
      title={title}
      welcomeBackFlag={welcomeBackFlag}
      values={values}
      errors={errors}
      touched={touched}
      onChange={handleInputChange}
      loading={loading}
      onSubmit={handleSubmit}
      socialLoading={socialLoading}
      onGoogleClick={handleGoogleClick}
      onMicrosoftClick={handleMicrosoftClick}
    />
  );
};

export default LoginPage;
