import { colors } from '../../../../constants/colors';

export const classes = {
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: 1,
    backgroundColor: 'none',
    borderBottom: '1px solid transparent',
    borderRadius: 0,
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
  icon: {
    color: colors.oxford_60,
    width: 24,
    height: 24,
  },
  text: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: '0.1px',
    color: colors.oxford_60,
    textTransform: 'capitalize',
  },
};
