import React from 'react';

import { MytaverseLogoIcon } from '../icons';

import DefaultBackground from '../public/images/floor.81c43389.png';

import { colors, COLORS } from './colors';

import { ICustomerEvent, IEventThemeTypes } from '../interfaces';

// should be supported
// customerLogo
// loginPage.components.background
// loginPage.assets.loginButtonBg
// loginPage.assets.loginButtonBgHover
// loginPage.assets.filledButtonColor
// loginPage.assets.helperTextColor
// loginPage.assets.helperTextLinkColor
export const customAppThemes = {
  light: ({
    eventName,
    backgroundImage,
    loginButtonBackgroundColor,
    loginButtonBackgroundColorHover,
    helperTextLinkColor,
    submitButtonBackgroundColor,
    submitButtonBackgroundColorHover,
    signFormSelectedTab,
    customerLogo,
  }: ICustomerEvent) => ({
    themeName: IEventThemeTypes.LIGHT,
    customerLogo,
    loginPage: {
      typography: {
        title: eventName,
      },
      components: {
        withRobot: true,
        background: backgroundImage,
        withVideo: false,
      },
      assets: {
        loginButtonBg: loginButtonBackgroundColor,
        loginButtonBgHover: loginButtonBackgroundColorHover,
        filledButtonColor: COLORS.LOGIN_LIGHT_GREY,

        titleColor: COLORS.ST_PATRICKS,
        subtitleColor: COLORS.NEW_BLACK,
        helperTextColor: COLORS.GREY,
        helperTextLinkColor: helperTextLinkColor,
        helperTextMT: 6,
        imageOpacity: '',
        formBoxShadow: `5px 5px 18px rgba(0, 0, 0, 0.2), -31px -30px 15px -20px ${COLORS.WHITE}`,
      },
    },
    backButton: {
      assets: {
        color: COLORS.BLACK,
      },
    },
    languageButton: {
      assets: {
        iconColor: colors.oxford_60,
        fontColor: colors.oxford,
      },
    },
    signForm: {
      assets: {
        button: {},
        backButton: {},
        tabs: {
          width: '100%',
          borderTopRightRadius: 'unset',
          borderTopLeftRadius: 'unset',
        },
        registerNameWidth: 0,
        tabPanel: {},
        tabSelected: {
          '& p': {
            color: signFormSelectedTab,
          },
        },
        submitButton: {
          backgroundColor: submitButtonBackgroundColor,

          '&:hover': {
            backgroundColor: submitButtonBackgroundColorHover,
          },
        },
        registerWithColor: '',
        helperTextColor: COLORS.GREY,
        helperTextLinkColor: COLORS.GREY,
        helperTextMT: 0,
      },
    },
    eventPage: {
      assets: {
        titleColor: COLORS.ST_PATRICKS,
        boxBackground: 'transparent',
        opacity: '0.7',
      },
    },
    avatarPage: {
      assets: {
        titleColor: COLORS.ST_PATRICKS,
        subtitleColor: COLORS.AVATAR_BLACK,
        avatarName: COLORS.AVATAR_BLACK,
        buttonTextColor: COLORS.BLACK,
      },
    },
    eventDropdown: {
      assets: {
        fontColor: COLORS.AVATAR_BLACK,
      },
    },
    skinsPage: {
      assets: {
        titleColor: COLORS.ST_PATRICKS,
        subtitleColor: COLORS.AVATAR_BLACK,
        skinName: COLORS.AVATAR_BLACK,
      },
    },
  }),

  dark: ({
    eventName,
    backgroundImage,
    loginButtonBackgroundColor,
    loginButtonBackgroundColorHover,
    helperTextLinkColor,
    submitButtonBackgroundColor,
    submitButtonBackgroundColorHover,
    signFormSelectedTab,
    customerLogo,
  }: ICustomerEvent) => ({
    themeName: IEventThemeTypes.DARK,
    customerLogo,
    loginPage: {
      typography: {
        title: eventName,
      },
      components: {
        withRobot: false,
        background: backgroundImage,
        withVideo: false,
      },
      assets: {
        loginButtonBg: loginButtonBackgroundColor,
        loginButtonBgHover: loginButtonBackgroundColorHover,
        filledButtonColor: COLORS.LOGIN_LIGHT_GREY,
        titleColor: COLORS.WHITE,
        subtitleColor: COLORS.WHITE,
        helperTextColor: COLORS.WHITE,
        helperTextLinkColor: helperTextLinkColor,
        helperTextMT: 4,
        formBoxShadow: 'unset',
      },
    },
    backButton: {
      assets: {
        color: COLORS.WHITE,
      },
    },
    languageButton: {
      assets: {
        iconColor: colors.white,
        fontColor: colors.white,
      },
    },
    signForm: {
      assets: {
        button: {
          pb: 0,
          pt: 0,
          paddingRight: 0,
          paddingLeft: 0,
          width: ['100%', 275],
          helperTextColor: COLORS.GREY,
          helperTextLinkColor: COLORS.GREY,
          borderCollapse: 'collapse',
          '& p': {
            justifyContent: 'center',
          },
        },
        submitButton: {
          backgroundColor: submitButtonBackgroundColor,

          '&:hover': {
            backgroundColor: submitButtonBackgroundColorHover,
          },
        },
        backButton: {
          backdropFilter: 'unset',
        },
        tabs: {
          width: ['100%', 275],
          background: 'none',
          color: COLORS.WHITE,
          fontSize: 32,
        },
        tabSelected: {
          '& p': {
            color: signFormSelectedTab,
          },
        },
        registerNameWidth: 230,
        tabPanel: {
          background: 'none',
          p: '15px 32px',
          width: 550,
          '& form': {
            '& .MuiInputBase-root': {
              border: `1px solid ${COLORS.GREY}`,
              borderRadius: 1,
            },
          },
        },
        helperTextColor: COLORS.WHITE,
        registerWithColor: COLORS.WHITE,
        helperTextMT: 0,
        helperTextLinkColor,
      },
    },
    eventPage: {
      assets: {
        titleColor: COLORS.WHITE,
        boxBackground: COLORS.WHITE,
        opacity: 'unset',
      },
    },
    avatarPage: {
      assets: {
        titleColor: COLORS.WHITE,
        subtitleColor: COLORS.WHITE,
        avatarName: COLORS.WHITE,
        buttonTextColor: COLORS.WHITE,
      },
    },
    eventDropdown: {
      assets: {
        fontColor: COLORS.AVATAR_BLACK,
      },
    },
    skinsPage: {
      assets: {
        titleColor: COLORS.WHITE,
        subtitleColor: COLORS.WHITE,
        skinName: COLORS.WHITE,
      },
    },
  }),

  default: () => ({
    themeName: IEventThemeTypes.DEFAULT,
    customerLogo: <MytaverseLogoIcon />,
    loginPage: {
      typography: {
        title: 'login.pageTitle',
      },
      components: {
        withRobot: true,
        background: DefaultBackground,
        withVideo: false,
      },
      assets: {
        loginButtonBg: colors.myta,
        loginButtonBgHover: colors.myta,
        filledButtonColor: COLORS.LOGIN_LIGHT_GREY,

        titleColor: colors.oxford,
        subtitleColor: colors.oxford_60,
        helperTextColor: COLORS.GREY,
        helperTextLinkColor: COLORS.GREY,
        helperTextMT: 6,
        imageOpacity: '',
        formBoxShadow: `5px 5px 18px rgba(0, 0, 0, 0.2), -31px -30px 15px -20px ${COLORS.WHITE}`,
      },
    },
    backButton: {
      assets: {
        color: COLORS.BLACK,
      },
    },
    languageButton: {
      assets: {
        iconColor: colors.oxford_60,
        fontColor: colors.oxford,
      },
    },
    signForm: {
      assets: {
        button: {},
        backButton: {},
        tabs: {
          width: '100%',
          borderTopRightRadius: 'unset',
          borderTopLeftRadius: 'unset',
        },
        registerNameWidth: 0,
        tabPanel: {},
        tabSelected: {},
        submitButton: {},
        registerWithColor: '',
        helperTextColor: COLORS.GREY,
        helperTextLinkColor: COLORS.GREY,
        helperTextMT: 0,
      },
    },
    eventPage: {
      assets: {
        titleColor: COLORS.ST_PATRICKS,
        boxBackground: 'transparent',
        opacity: '0.7',
      },
    },
    avatarPage: {
      assets: {
        titleColor: COLORS.ST_PATRICKS,
        subtitleColor: COLORS.AVATAR_BLACK,
        avatarName: COLORS.AVATAR_BLACK,
        buttonTextColor: COLORS.BLACK,
      },
    },
    eventDropdown: {
      assets: {
        fontColor: colors.oxford_60,
      },
    },
    skinsPage: {
      assets: {
        titleColor: COLORS.ST_PATRICKS,
        subtitleColor: COLORS.AVATAR_BLACK,
        skinName: COLORS.AVATAR_BLACK,
      },
    },
  }),
};
export { IEventThemeTypes };
