export const firebaseConfig = {
  apiKey: 'AIzaSyB9v5gq6dHNLVjevzCUgZSc5eZSGPLt8QI',
  authDomain: 'mytaverse-app.firebaseapp.com',
  projectId: 'mytaverse-app',
  storageBucket: 'mytaverse-app.appspot.com',
  messagingSenderId: '417150574159',
  appId: '1:417150574159:web:72bef25c91be0e6051943a',
};

export const errorsMessage: { [key: string]: string } = {
  'auth/expired-action-code': 'The action code or link has expired',
  'auth/invalid-action-code':
    'The action code is invalid. This may happen if the code is malformed or has already been used.',
  'auth/user-disabled':
    'The user corresponding to the given credential has been deactivated.',
  'auth/user-not-found': "The user doesn't correspond to any credential.",
  'auth/weak-password': 'This password is too weak.',
  'auth/email-already-in-use':
    'An account already exists with the provided email address.',
  'auth/invalid-email': 'The email address is invalid.',
  'auth/operation-not-allowed':
    'The type of account corresponding to this credential is not yet activated.',
  'auth/account-exists-with-different-credential':
    'Email address already associated with another account',
  'auth/auth-domain-config-required': 'Authentication settings not provided.',
  'auth/credential-already-in-use':
    "There's a pre-existing account for this credential",
  'auth/operation-not-supported-in-this-environment':
    'This operation is not supported in the environment it is being executed. Please check if it should be htttp or https.',
  'auth/timeout':
    'Response time exceeded. The domain may not be authorized to perform operations.',
  'auth/missing-android-pkg-name':
    'A package name must be provided for Android app installation',
  'auth/missing-continue-uri': 'The next URL must be provided in the request',
  'auth/missing-ios-bundle-id':
    'A package name must be provided for installing the iOS app',
  'auth/invalid-continue-uri':
    'The next URL provided in the request is invalid',
  'auth/unauthorized-continue-uri':
    'The next URL domain is not in the permit list',
  'auth/invalid-dynamic-link-domain':
    'The dynamic link domain provided is not authorized or configured in the current project',
  'auth/invalid-persistence-type':
    'The specified type for data persistence is invalid',
  'auth/cancelled-popup-request':
    'Only one pop-up window request is allowed at a time',
  'auth/popup-blocked': 'The pop-up window was blocked by the browser',
  'auth/unauthorized-domain':
    'The app domain is not authorized to perform operations',
  'auth/invalid-user-token': 'The current user was not identified',
  'auth/user-token-expired': 'Current user token has expired',
  'auth/null-user': 'The current user is null.',
  'auth/app-not-authorized': 'App not authorized',
  'auth/invalid-api-key': 'Invalid API Key',
  'auth/network-request-failed': 'Network request failed',
  'auth/too-many-requests': 'Too many requests',
  'auth/web-storage-unsupported': 'Web storage unsupported',
  'auth/id-token-expired': 'Token expired',
  'auth/id-token-revoked': 'Token revoked',
  'auth/invalid-argument': 'Invalid argument(s)',
  'auth/invalid-password': 'Invalid password',
  'auth/missing-uid': 'Missing UID',
  'auth/session-cookie-revoked': 'Session cookie revoked',
  'auth/project-not-found': 'Project not found',
  'auth/internal-error': 'Internal error',
};
