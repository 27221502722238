import React from 'react';

import { Box } from '@mui/material';

import { useMytaverseEvent } from '../../../../../providers';

import { EMOJI_LIST } from './constants';

import { useStyles } from './styles';

const Emojis = () => {
  const classes = useStyles();
  const { setEmoji } = useMytaverseEvent();

  return (
    <Box sx={classes.root}>
      {EMOJI_LIST.map((emoji) => (
        <img
          key={emoji.id}
          src={emoji.img}
          alt="Emoji"
          onClick={() => setEmoji(emoji.id)}
        />
      ))}
    </Box>
  );
};

export default Emojis;
