import { COLORS, colors } from '../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 3,
    width: '100%',
  },
  userData: {
    width: '100%',
    overflow: 'hidden',
  },
  name: {
    fontSize: [28, 22],
    color: colors.white,
    borderBottom: '1px solid transparent',
    lineHeight: '26px',
    mb: 0.3,
  },
  company: {
    color: COLORS.PROFILE_GREY,
    fontSize: 16,
    letterSpacing: 0.1,
    lineHeight: '24px',
    mb: 3.5,
  },
  text: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.1,
    color: COLORS.PROFILE_GREY,
  },
  handledText: (hasCursor = false) => ({
    cursor: hasCursor ? 'pointer' : 'auto',
  }),
  button: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  actionBtn: {
    p: 1,
    height: 33,
    width: 'auto',
    mr: 1 / 8,
    display: 'flex',
    background: '#00000080',
    whiteSpace: 'nowrap',
  },
  actionIcon: {
    color: COLORS.BG,
    height: 20,
    width: 20,
    mr: 1,
  },
  actionText: {
    textTransform: 'capitalize',
    color: colors.white,
    fontSize: 14,
  },
});
