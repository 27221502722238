import { COLORS } from '../../constants/colors';
import { IStyles } from '../../interfaces/styles';

export const useStyles = (): IStyles => ({
  root: {
    '& div': {
      border: 'none',
    },
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ['80%', 540],
    height: 311,
    pt: 0,
    border: 'none',
    boxShadow: 24,
    backdropFilter: 'blur(12px)',
    backgroundColor: COLORS.DARK_GREY,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contentWrapper: {
    padding: '18px 15px 0px',
    height: '100%',
  },
});
