import { colors, COLORS } from '../../../../../constants/colors';

import { VideoBubbleStyleType } from './interfaces';

export const useStyles: VideoBubbleStyleType = ({
  speaking,
  muted,
  videoBubbleSize,
}) => ({
  root: {
    width: 168,
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&:hover': {
      transform: 'scale(1.01)',
      height: '100%',

      '& p': {
        textOverflow: 'unset',
        whiteSpace: 'break-spaces',
      },
    },
    '& video': {
      width: '140px !important',
      height: '140px !important',
      objectFit: 'cover',
    },
    '& p': {
      color: muted
        ? COLORS.BUBBLES_RED
        : speaking
        ? COLORS.BUBBLES_GREEN
        : colors.white,
      maxWidth: '140px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  username: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& svg': {
      color: muted
        ? COLORS.BUBBLES_RED
        : speaking
        ? COLORS.BUBBLES_GREEN
        : COLORS.WHITE,
      mr: 0.5,
    },
    '& p': {
      color: muted
        ? COLORS.BUBBLES_RED
        : speaking
        ? COLORS.BUBBLES_GREEN
        : colors.white,
      maxWidth: '140px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  avatar: {
    width: `${videoBubbleSize}px`,
    height: `${videoBubbleSize}px`,
    border: muted
      ? `3px solid ${COLORS.BUBBLES_RED}`
      : speaking
      ? `3px solid ${COLORS.BUBBLES_GREEN}`
      : `3px solid ${COLORS.WHITE}`,
  },
});
