import React from 'react';

import {
  JoinOptions,
  ParticipantInfo,
} from '@voxeet/voxeet-web-sdk/types/models/Options';
import DolbyConference from '@voxeet/voxeet-web-sdk/types/models/Conference';
import { Resolution } from '@pureweb/platform-sdk';

import { SpatialType } from '../../../../../interfaces/webSocketConnectionInfo';
import {
  IMediaStream,
  IParticipant,
  IParticipantRegion,
} from '../../../../../interfaces/participants';
import { IEvent } from '../../../../../interfaces/event';
import { IRoom } from '../../../../../interfaces/rooms';
import { ParticipantPosition } from '../interfaces';

export enum ConferenceError {
  MaxCapacityError = 'MaxCapacityError',
  NotAllowedError = 'NotAllowedError',
}

export type GetConferenceConfigType = (params: {
  spatialType: SpatialType;
  dolbySpatialAudioScale: number;
}) => {
  audioConfig: JoinOptions;
  conferenceScale: number | null;
  bubblesWithNearbyParticipants: boolean;
};

export type GetConferenceAliasType = (params: {
  currentRegion: IParticipantRegion | null;
  currentEvent: IEvent;
  currentRoom: IRoom;
  gameSessionId: string;
}) => string;

export interface IConferenceQueue {
  currentEvent: IEvent;
  currentRoom: IRoom;
  currentRegion: IParticipantRegion | null;
}

export interface IConferenceContext {
  isDolbyInitialized: boolean;
  isConferenceInitialized: boolean;

  openSession: (participant: ParticipantInfo) => Promise<void>;

  conference: DolbyConference | null;
  streamQuality: Resolution;
  setStreamQuality: (quality: any) => void;
  leaveConference: () => Promise<void>;
  createAndJoinToConference: (
    currentEvent: IEvent,
    currentRoom: IRoom,
    currentRegion: IParticipantRegion | null,
  ) => Promise<void>;
  isFirstPersonView: boolean;
  setIsFirstPersonView: () => void;
  microphones: any[];
  speakers: any[];
  cameras: any[];
  speakingParticipantIds: string[];
  previewingParticipant: IParticipant | null;
  setPreviewingParticipant: React.Dispatch<
    React.SetStateAction<IParticipant | null>
  >;
  selectMicrophone: (microphoneId: string) => void;
  selectSpeaker: (speakerId: string) => void;
  disabledPersonView: boolean;
  setDisabledPersonView: React.Dispatch<React.SetStateAction<boolean>>;
  selectCamera: (cameraId: string) => void;
  spatialTypeName: SpatialType | null;
  muted: boolean;
  videoStarted: boolean;
  toggleVideo: () => void;
  setFullscreen: () => void;
  isFullscreen: boolean;
  setScreenMediaStreams: React.Dispatch<React.SetStateAction<IMediaStream[]>>;
  sharingMedia: string[];
  setSharingMedia: React.Dispatch<React.SetStateAction<string[]>>;

  changingDevice: boolean;
  setParticipantPosition: (
    participantId: string,
    timestamp: number,
    participantPosition: ParticipantPosition,
  ) => void;
  isConferenceListener: boolean;

  cameraMediaStreams: IMediaStream[];
  screenMediaStreams: IMediaStream[];
}
