// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Director, Publish } from '@millicast/sdk';
import { IMillicastPublisher } from '../../../../../interfaces/millicastPublisher';
import EventsService from '../../../../../services/EventsService';
import { IMillicastTokens } from '../../../../../interfaces/millicastTokens';
import $api from '../../../../../http/axios';

export default class MillicastService {
  static async broadcast(
    token: any,
    streamName: string,
    mediaStream: MediaStream,
  ): Promise<void> {
    const tokenGenerator = () =>
      Director.getPublisher({
        token,
        streamName,
      });
    const publisher = new Publish(streamName, tokenGenerator);

    const broadcastOptions = {
      mediaStream,
      codec: 'vp8',
    };

    try {
      await publisher.connect(broadcastOptions);
    } catch (e) {
      // console.log('Connection failed, handle error', e);
    }

    return publisher;
  }

  static stopMediaStreamTracks(mediaStream: MediaStream) {
    mediaStream
      .getTracks()
      .forEach((track: { stop: () => any }) => track.stop());
  }

  static async stopStream(publisher: IMillicastPublisher): Promise<void> {
    publisher.stop();

    this.stopMediaStreamTracks(publisher.options.mediaStream);

    await EventsService.stopStreamVideo(publisher.streamName);
  }

  static updatePublishToken(
    publishTokenId: string,
    screenName: string,
  ): Promise<IMillicastTokens> {
    return $api
      .patch(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/publish-tokens/${publishTokenId}`,
        {
          screenName,
        },
      )
      .then((res) => res.data)
      .catch(() => {
        throw new Error("Can't update millicast publish token");
      });
  }

  static updateSubscribeToken(
    subscribeTokenId: string,
    screenName: string,
  ): Promise<IMillicastTokens> {
    return $api
      .patch(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/subscribe-tokens/${subscribeTokenId}`,
        {
          screenName,
        },
      )
      .then((res) => res.data)
      .catch(() => {
        throw new Error("Can't update millicast subscribe token");
      });
  }
}
