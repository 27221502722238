import { useTheme } from '@mui/material';

import { getSelectEventsSwiperMaxWidth } from './helpers';

import { colors } from '../../../../constants/colors';

import { SwiperScreenSizes } from '../../../../interfaces';
import { ISwiperWrapperStyles } from './interfaces';

export const useStyles = ({
  swiperBreakpoints,
  loop,
  sliderWidth,
}: ISwiperWrapperStyles) => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
      '& .swiper': {
        maxWidth: getSelectEventsSwiperMaxWidth(
          swiperBreakpoints[SwiperScreenSizes.Pc],
          sliderWidth,
        ),
        width: '100%',
        margin: 0,
        [breakpoints.down(SwiperScreenSizes.Pc)]: {
          maxWidth: getSelectEventsSwiperMaxWidth(
            swiperBreakpoints[SwiperScreenSizes.Laptop],
            sliderWidth,
          ),
        },
        [breakpoints.down(SwiperScreenSizes.Laptop)]: {
          maxWidth: getSelectEventsSwiperMaxWidth(
            swiperBreakpoints[SwiperScreenSizes.Tablet],
            sliderWidth,
          ),
        },
        [breakpoints.down(SwiperScreenSizes.Tablet)]: {
          maxWidth: getSelectEventsSwiperMaxWidth(
            swiperBreakpoints[SwiperScreenSizes.Mobile],
            sliderWidth,
          ),
        },
      },
      '& .swiper-wrapper': {
        justifyContent: loop ? 'space-between' : 'center',
        alignItems: 'center',
      },
      '& .swiper-slide:last-child': {
        marginRight: loop ? 'auto' : '0px !important', // override element.style
      },
    },
    navigationBtn: {
      [breakpoints.down(SwiperScreenSizes.Tablet)]: {
        display: 'none',
      },
    },
    pagination: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      marginTop: '40px',
      [breakpoints.down(SwiperScreenSizes.Pc)]: {
        marginTop: '45px',
      },
      [breakpoints.down(SwiperScreenSizes.Tablet)]: {
        width: '100%',
        marginTop: '20px',
      },
      '& .swiper-pagination': {
        display: 'flex',
        alignItems: 'center',
        columnGap: 1,
        width: 'fit-content',
        position: 'relative',
        bottom: 0,
        backgroundColor: colors.black_60,
        borderRadius: '100px',
        border: `1px solid ${colors.white_40}`,
        padding: '9px 16px',
        '& .swiper-pagination-bullet': {
          backgroundColor: colors.white_60,
          opacity: 1,
          m: 0,
        },
        '& .swiper-pagination-bullet-active': {
          backgroundColor: colors.white,
        },
      },
      '& .swiper-pagination-lock': {
        display: 'none',
      },
    },
    paginationNavigationBtn: {
      display: 'none',
      [breakpoints.down(SwiperScreenSizes.Tablet)]: {
        display: 'flex',
      },
    },
  };
};
