import React from 'react';

import PoiPreviewView from './PoiPreviewView';
import { usePointsOfInterestsPreview } from './hooks';

const PoiPreview = () => {
  const { component, handleClick, poiName } = usePointsOfInterestsPreview();
  if (!component) {
    return null;
  }

  return (
    <PoiPreviewView
      onClick={handleClick}
      poiName={poiName || 'Point of interest'}
    >
      {component}
    </PoiPreviewView>
  );
};

export default PoiPreview;
