export const SPATIAL_AUDIO_OPTIONS = {
  spatialAudio: {
    constraints: {
      audio: true,
      video: false,
    },

    preferRecvMono: false,
    preferSendMono: false,
    spatialAudio: true,
  },

  oneToMany: {
    constraints: {
      audio: true,
      video: false,
    },

    preferRecvMono: false,
    preferSendMono: false,
    spatialAudio: false,
  },

  spatialMeeting: {
    constraints: {
      audio: true,
      video: false,
    },

    preferRecvMono: false,
    preferSendMono: false,
    spatialAudio: true,
  },
};
