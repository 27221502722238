import { COLORS } from '../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    mb: 2.2,
  },
  icon: {
    mr: 1.8,
    width: 17,
    height: 17,
    color: COLORS.PROFILE_GREY,
  },
});
