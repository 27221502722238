import { PoiMediaType } from '../modules/dashboard/components/DashboardContent/interfaces';

export interface IWebSocketConnectionInfo {
  connectionId: string;
  participantId: string;
  eventId?: string;
  roomId?: string;
  region?: string;
  gameSessionId?: string | null;
  spatialType: SpatialType;
  screenName?: string;
  clientType: string;
  pairConnectionId: string;
  dolbySpatialAudioScale: number;
  muted?: boolean;
}

export enum SpatialType {
  SpatialMeeting = 'SPATIAL_MEETING',
  SpatialAudio = 'SPATIAL_AUDIO',
  OneToMany = 'ONE_TO_MANY',
}

export interface PlayerPosition {
  CurrentGameSessionId: string;
  eventId: string;
  roomId: string;
  x: number;
  y: number;
  z: number;
  r: number;
}

export interface ILastWebsocketJsonMessage {
  data: IWebSocketConnectionInfo;
  action: WebsocketAction;
  timestamp?: string;
  message?: WebsocketMessage;
  gameSessionId?: string;
  eventId?: string;
  roomId?: string;
  region?: string;
  regionName?: string;
  regionSpatialType: SpatialType;
  screenName?: string;
  participantId?: string;
  fileName?: string;
  asset?: string;
  downloadUrl?: string;
  mediaType?: PoiMediaType;
  muted?: boolean;
  conferenceId?: string;
  mutedBy?: string;
  spatialType?: SpatialType;
  x: number;
  y: number;
  z: number;
  r: number;
  mediaStreamId?: string;
  usersinscreen: string[] | undefined;
  playerPosition: PlayerPosition;
  dolbySpatialAudioScale?: number;
  // follow me
  followerId?: string;
  ownerId?: string;
  isEventMessage?: boolean;
}

export interface IParticipantPositionMessage {
  timestamp: number;
  eventId: string;
  roomId: string;
  gameSessionId: string;
  regionName?: string;
  regionSpatialType?: SpatialType;
  x: number;
  y: number;
  z: number;
  r: number;
}

export type LastWebsocketJsonMessageType = ILastWebsocketJsonMessage | null;

export enum WebsocketAction {
  ConnectionInfo = 'CONNECTION_INFO',
  JoinedToRoom = 'JOINED_TO_ROOM',
  LeaveRoom = 'LEAVE_ROOM',
  LeftRoom = 'LEFT_ROOM',
  JoinedToEvent = 'JOINED_TO_EVENT',
  ParticipantPosition = 'PARTICIPANT_POSITION',
  LeftEvent = 'LEFT_EVENT',
  JoinToRegion = 'JOIN_TO_REGION',
  JoinedToRegion = 'JOINED_TO_REGION',
  LeaveRegion = 'LEAVE_REGION',
  LeftRegion = 'LEFT_REGION',
  ChangeRoomScale = 'CHANGE_ROOM_SCALE',
  BrowserUrlRequest = 'BROWSER_URL_REQUEST',
  MuteParticipant = 'MUTE_PARTICIPANT',
  ShowParticipantInfo = 'SHOW_PARTICIPANT_INFO',
  SendToBriefcase = 'SEND_TO_BRIEFCASE',
  ChangeParticipantProfile = 'CHANGE_PARTICIPANT_PROFILE',
  MessageToWebapp = 'MESSAGE_TO_WEBAPP',

  StartShareVideoRequest = 'START_SHARE_VIDEO_REQUEST',
  StopShareVideoRequest = 'STOP_SHARE_VIDEO_REQUEST',

  StartShareScreenRequest = 'START_SCREEN_VIDEO_REQUEST',
  StopShareScreenRequest = 'STOP_SCREEN_VIDEO_REQUEST',

  ShowMillicastScreenBroadcastingDataRequest = 'SHOW_MILLICAST_SCREEN_BROADCASTING_DATA_REQUEST',

  Ue5WsConnected = 'UE5_WS_CONNECTED',
  Ue5WsDisconnected = 'UE5_WS_DISCONNECTED',
  FirstPersonViewRegion = 'FIRST_PERSON_VIEW_REGION',
  FreePersonViewRegion = 'FREE_PERSON_VIEW_REGION',
  ParticipantMutedState = 'PARTICIPANT_MUTED_STATE',
  MuteParticipantState = 'MUTE_PARTICIPANT_STATE',
  MuteConference = 'MUTE_CONFERENCE',
  StopDolbyScreenSharing = 'STOP_DOLBY_SCREEN_SHARING',
  ParticipantCameraPosition = 'PARTICIPANT_VIDEO_POSITION',

  ParticipantState = 'PARTICIPANT_STATE',

  StartPoiCreatingMode = 'START_POI_CREATING_MODE',
  StopPoiCreatingMode = 'STOP_POI_CREATING_MODE',

  EnableDroneCamera = 'ENABLE_DRONE_CAMERA',
  DisableDroneCamera = 'DISABLE_DRONE_CAMERA',

  Hoverboard = 'HOVERBOARD',
  LaserPointer = 'LASER_POINTER',
  Magnifier = 'MAGNIFIER',
  BeamMeUp = 'BEAM_ME_UP',
  SetupKeyboard = 'SETUP_KEYBOARD',

  FollowPlayer = 'FOLLOW_PLAYER',
  InviteFollowingByAdmin = 'INVITE_FOLLOWING_BY_ADMIN',
  StopFollowingByAdmin = 'STOP_FOLLOWING_BY_ADMIN',
  CancelInviteFollowingByAdmin = 'CANCEL_INVITE_FOLLOWING_BY_ADMIN',
  AcceptFollowing = 'ACCEPT_FOLLOWING',
  DisengageFollowing = 'DISENGAGE_FOLLOWING',
  StopFollowing = 'STOP_FOLLOWING',
  OpenTeleportModal = 'OPEN_TELEPORT_MODAL',
  CloseTeleportModal = 'CLOSE_TELEPORT_MODAL',
}

export enum WebsocketMessage {
  ShowControls = 'SHOW_CONTROLS',
  HideControls = 'HIDE_CONTROLS',
}

export enum WebsocketErrorCode {
  CloseAbnormal = 1006,
}
