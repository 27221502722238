export const useStyles = () => ({
  content: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  title: {
    fontSize: 40,
    mt: 5,
  },
});
