import { colors } from '../../../../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 3,
    color: colors.white,
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    p: '12px',
    border: `1px solid ${colors.white_60}`,
    backdropFilter: 'blur(6px)',
    borderRadius: '72px',
    flexShrink: 0,
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
  },
  name: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: colors.white,
  },
  description: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: colors.white,
    opacity: 0.5,
  },
});
