import React from 'react';

import { Avatar, Box, Typography } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';

import { AttachFileIcon } from '../../../icons';

import { wordPluralize } from '../../../helpers/string';

import { IChatNotificationView } from './interfaces';

import { useStyles } from './styles';

const ChatNotificationView = React.forwardRef<
  HTMLDivElement,
  IChatNotificationView
>(({ message, onReply, onClose }, ref) => {
  const classes = useStyles();

  const hasText = message.text;
  const attachments = message.attachments ?? [];

  return (
    <Box ref={ref} sx={classes.root}>
      <Box sx={classes.actions}>
        <ReplyIcon sx={classes.icon} onClick={onReply} />
        <CloseIcon sx={classes.icon} onClick={onClose} />
      </Box>
      <Box sx={classes.titleContainer}>
        <Avatar src={message.userImage} sx={classes.avatar} />
        <Typography sx={classes.title}>New Message!</Typography>
      </Box>
      <Box sx={classes.messageContainer}>
        <Typography sx={classes.userName}>{message.user?.name}:</Typography>
        {hasText ? (
          <Typography sx={classes.messageText}>{message.text}</Typography>
        ) : (
          <Box sx={classes.attachmentContainer}>
            <AttachFileIcon sx={classes.attachmentIcon} />
            <Typography sx={classes.attachmentText}>
              {wordPluralize(attachments.length, 'Attachment')} (
              {attachments.length})
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
});

ChatNotificationView.displayName = 'ChatNotificationView';

export default ChatNotificationView;
