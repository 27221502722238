import React from 'react';

import { Box } from '@mui/material';

import { useStyles } from './styles';

import { useDataSwitcher } from '../../../../../hooks';

import { TIPS } from './constants';

const LoadingTutorial = () => {
  const classes = useStyles();
  const ActiveTip = useDataSwitcher(TIPS, 20000);

  return (
    <Box sx={classes.root}>
      <ActiveTip />
    </Box>
  );
};

export default LoadingTutorial;
