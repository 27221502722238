import React from 'react';

import EventDrawerHeaderView from './EventDrawerHeaderView';

import { useMytaverseEvent } from '../../../providers';
import { useChatState } from '../../../../../hooks/context';

const EventDrawerHeader = () => {
  const { closeLeftMenu } = useMytaverseEvent();
  const { resetChangeEventChatState } = useChatState();

  const handleChangeEvent = () => {
    closeLeftMenu();
    resetChangeEventChatState();
  };

  return (
    <EventDrawerHeaderView
      onCloseEventDrawer={closeLeftMenu}
      onChangeEvent={handleChangeEvent}
    />
  );
};

export default EventDrawerHeader;
