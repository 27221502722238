import { create } from 'zustand';

import { IInitialToolsState, IToolsState } from './interfaces';

const initialToolsState: IInitialToolsState = {
  isHoverboardApplied: true,
  isLaserPointerApplied: true,
  isMagnifierApplied: true,
  isTeleportApplied: false,
};

export const useToolsState = create<IToolsState>((set) => ({
  ...initialToolsState,
  setHoverboard: (isHoverboardApplied) => set({ isHoverboardApplied }),
  setLaserPointer: (isLaserPointerApplied) => set({ isLaserPointerApplied }),
  setMagnifier: (isMagnifierApplied) => set({ isMagnifierApplied }),
  setTeleport: (isTeleportApplied) => set({ isTeleportApplied }),
  resetState: () => set(initialToolsState),
}));
