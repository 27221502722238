import { COLORS } from '../../../../../constants/colors';
import { SxProps } from '@mui/material';

export const useStyles = () => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: [280, 640],
    height: 640,
    border: `2px solid ${COLORS.NEW_BLACK}`,
    boxShadow: 24,
    p: ['20px 10px', 3],
    pt: 2.5,
    backdropFilter: 'blur(12px)',
    backgroundColor: COLORS.NEW_BLACK,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    outline: 'none',

    '@media screen and ( max-height: 750px )': {
      height: 540,
  }
  } as SxProps,
  title: {
    color: COLORS.WHITE,
    fontSize: 24,
  },
  root: {
    border: 'none !important',
    width: '100%',
    height: '100%',
  },
  startSharing: {
    color: COLORS.WHITE,
    border: `1px solid ${COLORS.WHITE}`,
    fontSize: 16,
    borderRadius: 1,
    textTransform: 'capitalize',
    pt: 1,
    pb: 1,
    mt: 4,
    '&:hover': {
      transform: 'scale(1.01)',
    },
  } as SxProps,
  settingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      mt: 0,
      mb: 3,
    },

    '& .MuiButton-root': {
      mt: 0,
      width: 520,
    },

    '& .MuiBox-root': {
      width: 520,
      '& .MuiBox-root': {
        height: 440,
        '@media screen and ( max-height: 750px )': {
          height: 340,
        },
        '& .MuiFormGroup-root': {
          mb: 1,
        },
      },
    },
  },
});
