import React, { ChangeEvent } from 'react';
import { useFormik } from 'formik';

import UserProfileFormView from './UserProfileFormView';

import { useMytaverse } from '../../../providers/MytaverseProvider';

import { sendCustomSentryBreadcrumb } from '../../../helpers/sentry';
import { getFullUserName } from '../../../helpers/user';
import { getCatchErrorMessage } from '../../../helpers/error';

import { UserChangeValidationSchema } from './validations';

import { WebsocketAction } from '../../../interfaces/webSocketConnectionInfo';
import { InitialValues, IUserProfileForm } from './interfaces';

const UserProfileForm = ({
  updImage,
  onClose,
  openUpload,
  setOpenUpload,
}: IUserProfileForm) => {
  const { user, changeUserProfile, sendMessageToEvent, currentEventId } =
    useMytaverse();

  const {
    profileImage,
    email,
    firstName,
    lastName,
    company = '',
    phoneNumber = '',
    linkedIn = '',
  } = user;

  const avatarSrc = updImage
    ? updImage instanceof File
      ? URL.createObjectURL(updImage)
      : updImage
    : profileImage;

  const onSubmit = async () => {
    try {
      onClose();
      await changeUserProfile(values);
      sendMessageToEvent(currentEventId as string, {
        action: WebsocketAction.ChangeParticipantProfile,
        participantId: user.id,
      });
      sendCustomSentryBreadcrumb({
        message: `update user profile = ${JSON.stringify(values)}`,
      });
    } catch (error: unknown) {
      throw Error(getCatchErrorMessage(error));
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    handleChange,
    handleSubmit,
  } = useFormik<InitialValues>({
    initialValues: {
      username: getFullUserName({ firstName, lastName }),
      company,
      phoneNumber,
      linkedIn,
      profileImage: updImage || profileImage,
    },
    onSubmit,
    validationSchema: UserChangeValidationSchema,
    enableReinitialize: true,
  });

  const handleInputChange = (
    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFieldTouched(evt.target.name, false, false);
    handleChange(evt);
  };

  const handleOpenUploadModal = () => setOpenUpload(!openUpload);

  return (
    <UserProfileFormView
      email={email}
      avatarSrc={avatarSrc}
      values={values}
      errors={errors}
      touched={touched}
      onChange={handleInputChange}
      onSubmit={handleSubmit}
      onCloseButton={onClose}
      onOpenUploadModal={handleOpenUploadModal}
    />
  );
};

export default UserProfileForm;
