import { COLORS } from '../../../../../constants/colors';

export const classes = {
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& div': {
      color: COLORS.WHITE,
    },
  },
  button: {
    '&:hover': {
      '& svg': {
        backgroundColor: COLORS.GREY,
        color: COLORS.LIGHT_GREY,
      },
    },
  },
  icon: {
    color: COLORS.BLACK,
    backgroundColor: COLORS.LIGHT_GREY,
    borderRadius: 72,
  },
};
