import { AVATAR_SKIN_SLIDER_WIDTH } from '../../constants';

import { IStyles } from '../../../../interfaces/styles';

export const useStyles = (): IStyles => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: AVATAR_SKIN_SLIDER_WIDTH,
      cursor: 'pointer',
      '& img': {
        objectFit: 'contain',
        maxWidth: '100%',
        height: 'auto',
        '&:hover': {
          transform: 'scale(1.01)',
        },
      },
    },
  };
};
