import { colors } from '../../../../constants/colors';

export const useStyles = () => {
  return {
    root: {
      padding: '24px 24px 32px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 3,
      textAlign: 'center',
      marginTop: 3,
    },
    title: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.5,
      color: colors.oxford,
      opacity: 0.8,
    },
    subtitle: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.56,
      letterSpacing: '0.25px',
      color: colors.oxford_60,
    },
    email: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.56,
      letterSpacing: '0.25px',
      color: colors.oxford,
      opacity: 0.8,
    },
    reminder: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.56,
      letterSpacing: '0.25px',
      color: colors.oxford_60,
    },
    // TODO uncomment when api will be ready
    // resendActivation: {
    //   inherit: 'all',
    //   color: colors.cyan,
    //   cursor: 'pointer',
    // },
    button: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 1.11,
    },
  };
};
