import { SortType } from '../constants/sort';
import { GetSortFunctionType, SortByType } from './interfaces';

const sortByDate: SortByType = ({ list, fieldName, direction }) => {
  return [...list].sort((itemA, itemB) => {
    if (!itemA[fieldName] && !itemB[fieldName]) return 0;

    if (!itemA[fieldName]) return direction ? -1 : 1;

    if (!itemB[fieldName]) return direction ? 1 : -1;

    const firstValue = (direction ? itemA : itemB)[
      fieldName
    ] as unknown as string;
    const secondValue = (direction ? itemB : itemA)[
      fieldName
    ] as unknown as string;

    return Date.parse(firstValue) - Date.parse(secondValue);
  });
};

const sortByChar: SortByType = ({ list, fieldName, direction }) => {
  return [...list].sort((itemA, itemB) => {
    if (!itemA[fieldName] && !itemB[fieldName]) return 0;

    if (!itemA[fieldName]) return direction ? -1 : 1;

    if (!itemB[fieldName]) return direction ? 1 : -1;

    const firstValue = (direction ? itemB : itemA)[
      fieldName
    ] as unknown as string;
    const secondValue = (direction ? itemA : itemB)[
      fieldName
    ] as unknown as string;

    return firstValue.localeCompare(secondValue);
  });
};

export const getSortFunction: GetSortFunctionType = (type) => {
  const sortFunctions = {
    [SortType.Date]: sortByDate,
    [SortType.Alphabetical]: sortByChar,
  };

  return sortFunctions[type];
};
