import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../interfaces';
import { colors } from '../../../../../constants/colors';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      justifyItems: 'center',
      width: 720,
      height: 480,
      backgroundColor: colors.black_25,
      border: '1px solid #BEBEBE',
      backdropFilter: 'blur(16.5px)',
      borderRadius: 2,
      textAlign: 'start',
      [breakpoints.down(ScreenSizes.Pc)]: {
        width: 640,
        height: 410,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        gridTemplateColumns: '182px 1fr',
        width: 549,
        height: 256,
      },
    },
  };
};
