import { colors } from '../../../../constants/colors';

import { IStyles } from './interfaces';

export const useStyles = ({ isCompleted }: IStyles) => ({
  root: {
    width: '100%',
    height: 56,
    borderRadius: '500px',
    '&.MuiLinearProgress-root': {
      backgroundColor: colors.oxford_30,
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: isCompleted ? colors.green : colors.cyan,
    },
  },
});
