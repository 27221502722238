/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Box } from '@mui/material';

import { IMillicastScreenView } from './interfaces';
import { useStyles } from './styles';
import ShareScreenPreview from './ShareScreenPreview';

const MillicastScreenView = ({
  loading,
  shareScreenPublishers,
  onShareScreenStreamClick,
  mediaStreams,
  handleStopStream,
}: IMillicastScreenView) => {
  const classes = useStyles(mediaStreams?.length as number);

  const newMediaStream = React.useMemo(() => {
    const publishedMediaIds = shareScreenPublishers
      ? shareScreenPublishers.map((p) => p.options.mediaStream.id)
      : [];

    return mediaStreams
      ? mediaStreams.find((ms) => !publishedMediaIds.includes(ms.id))
      : null;
  }, [mediaStreams, shareScreenPublishers]);

  return (
    <Box component="div" sx={classes.root}>
      <Box component="div" sx={classes.videoContainer}>
        {newMediaStream ? (
          <ShareScreenPreview
            onShareScreenStreamClick={onShareScreenStreamClick}
            handleStopStream={handleStopStream}
            mediaStream={newMediaStream}
            loading={loading}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default MillicastScreenView;
