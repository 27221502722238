import React, { CSSProperties } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { Box } from '@mui/material';

import AppHeader from '../../../../components/AppHeader';
import BackButton from '../BackButton';
import PageTitleContent from '../PageTitleContent';
import Footer from '../../../../components/Footer';
import Floor from '../Floor';

import { ISelectWrapper } from './interfaces';

import { useStyles } from './styles';

const SelectWrapper = ({
  children,
  title = '',
  description = '',
  showEventsDropdown = false,
  onBackClick,
}: ISelectWrapper) => {
  const classes = useStyles();

  return (
    <Scrollbars
      style={classes.root as CSSProperties}
      autoHide
      autoHideTimeout={1500}
      autoHideDuration={300}
      thumbSize={100}
    >
      <Box sx={classes.container}>
        <AppHeader isAuthorized showEventsDropdown={showEventsDropdown} />
        <Box sx={classes.header}>
          <Box sx={classes.box}>
            {!!onBackClick && <BackButton onClick={onBackClick} />}
          </Box>
          <PageTitleContent title={title} subtitle={description} />
          <Box sx={classes.box} />
        </Box>
        <Box sx={classes.content}>{children}</Box>
        <Footer />
        <Floor />
      </Box>
    </Scrollbars>
  );
};

export default SelectWrapper;
