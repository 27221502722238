export const classes = {
  root: {
    '& .str-chat__message-attachment': {
      margin: '6px 0 0',
      '& .str-chat__message-attachment--img': {
        width: 200,
        height: 'auto',
        objectFit: 'contain',
      },
    },
  },
};
