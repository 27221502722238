import React from 'react';

import { Backdrop, Box, Modal } from '@mui/material';

import { CloseIcon } from '../../../../icons';

import { IConfirmModal } from './interfaces';

import { classes } from './styles';

const ConfirmModal = ({ open, onClose, children, sx = {} }: IConfirmModal) => {
  const rootSx = { ...classes.root, ...sx };

  return (
    <Modal sx={rootSx} open={open} onClose={onClose} components={{ Backdrop }}>
      <Box sx={classes.container}>
        <CloseIcon sx={classes.closeIcon} onClick={onClose} />
        {children}
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
