import { IFollowerData } from '../interfaces/followers';

export const getIsAdminParticipantBusy = (
  followersData: IFollowerData[],
  adminId: string,
): boolean => {
  const followers = followersData.filter(
    (followerData) => followerData.adminId === adminId,
  );
  const isFollower = followersData.some(
    (followerData) => followerData.userId === adminId,
  );

  return isFollower || followers.length === 10;
};

export const getIsParticipantBusy = (
  followersData: IFollowerData[],
  participantId: string,
): boolean => {
  const isBusy = followersData.some(
    (followerData) =>
      followerData.userId === participantId ||
      followerData.adminId === participantId,
  );

  return isBusy;
};
