import React from 'react';

import { Typography, Box } from '@mui/material';

import AppHeader from '../../../../components/AppHeader';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { useStyles } from './styles';

const PageNotFoundPage = () => {
  const classes = useStyles();

  const { user } = useMytaverse();

  return (
    <>
      <AppHeader isAuthorized={!!user} />
      <Box sx={classes.content} component="div">
        <Typography sx={classes.title}>Page Not Found</Typography>
      </Box>
    </>
  );
};
export default PageNotFoundPage;
