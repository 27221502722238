import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { useConferenceState } from '../../../../hooks/conferenceContext';

import { getCatchErrorMessage } from '../../../../helpers/error';

import { IDevicePermissionState } from '../../../../interfaces/permissions';

export const useInitMicrophonesDevices = () => {
  const [initializing, setInitializing] = useState(true);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [permissionState, setPermissionState] =
    useState<IDevicePermissionState>('granted');

  const { activeMicroDeviceId, setActiveMicroDeviceId } = useConferenceState();

  useAsyncEffect(async () => {
    const initMicrophonesDevices = async (state: IDevicePermissionState) => {
      setPermissionState(state);

      switch (state) {
        case 'prompt': {
          setInitializing(false);
          navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
          break;
        }
        case 'denied': {
          setDevices([]);
          setInitializing(false);
          break;
        }
        case 'granted': {
          if (!initializing) {
            return;
          }

          if (!navigator.mediaDevices?.enumerateDevices) {
            throw new Error('enumerateDevices() not supported.');
          }

          try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const microDevices = (devices || []).filter(
              (device) => device.kind === 'audioinput',
            );
            const activeDeviceId =
              microDevices.find(
                ({ deviceId }) => deviceId === activeMicroDeviceId,
              )?.deviceId || microDevices[0].deviceId;

            setDevices(microDevices);
            setActiveMicroDeviceId(activeDeviceId);
            setInitializing(false);
          } catch (error) {
            throw Error(getCatchErrorMessage(error));
          }
          break;
        }
      }
    };

    const permissionData = await navigator.permissions.query({
      // eslint-disable-next-line
      name: 'microphone' as any,
    });

    initMicrophonesDevices(permissionData.state);

    permissionData.onchange = () => {
      initMicrophonesDevices(permissionData.state);
    };
  }, [activeMicroDeviceId, initializing, setActiveMicroDeviceId]);

  return {
    initializing,
    devices,
    activeDeviceId: activeMicroDeviceId,
    setActiveDeviceId: setActiveMicroDeviceId,
    permissionState,
  };
};
