import { colors } from '../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '53px',
  },
  layoutContainer: {
    '& form': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 3,
      mb: 3,
    },
  },
  formTitle: {
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 1.5,
    color: colors.oxford_60,
    mb: 3,
    textAlign: 'center',
  },
  link: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.1px',
    color: colors.oxford,
    opacity: 0.4,
    textDecoration: 'none',
  },
  divider: {
    mb: 2,
    '& .MuiDivider-wrapper': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: '0.1px',
      color: colors.oxford,
      opacity: 0.6,
    },
    '&::before': {
      borderTop: `1px solid ${colors.oxford_20}`,
    },
    '&::after': {
      borderTop: `1px solid ${colors.oxford_20}`,
    },
  },
  socialButtonsContainer: {
    display: 'flex',
    columnGap: '17px',
  },
});
