import { useRef, useState } from 'react';
import Webcam from 'react-webcam';

export const useTakePhoto = () => {
  const webcamRef = useRef<Webcam>(null);

  const [generatedPhoto, setGeneratedPhoto] = useState<File | string>('');

  const setWebcamPhoto = async () => {
    const imageSrc = webcamRef.current?.getScreenshot();

    if (!imageSrc) {
      return;
    }

    const file = await fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => new File([blob], 'avatar', { type: 'image/png' }));

    setGeneratedPhoto(file);
  };

  return {
    webcamRef,
    generatedPhoto,
    setWebcamPhoto,
  };
};
