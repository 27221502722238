import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Link, Typography } from '@mui/material';

import { ILegalNotes } from './interfaces';

import { useStyles } from './styles';

const LegalNotes = ({ t: translate, children }: ILegalNotes) => {
  const classes = useStyles();

  const { REACT_APP_TERMS_OF_SERVICE, REACT_APP_PRIVACY_POLICY } = process.env;

  const legalNotes = (
    <Typography sx={classes.text}>
      {translate('legalNotes.preFix')}{' '}
      <Link href={REACT_APP_TERMS_OF_SERVICE} target="_blank" sx={classes.link}>
        {translate('legalNotes.terms')}
      </Link>{' '}
      {translate('legalNotes.and')}{' '}
      <Link href={REACT_APP_PRIVACY_POLICY} target="_blank" sx={classes.link}>
        {translate('legalNotes.privacy')}
      </Link>
    </Typography>
  );

  if (children) {
    return (
      <Box sx={classes.container}>
        {children}
        {legalNotes}
      </Box>
    );
  }

  return legalNotes;
};

export default withTranslation('common')(LegalNotes);
