import React from 'react';

import { Box } from '@mui/material';

import { IProfileItem } from './interfaces';

import { useStyles } from './styles';

const ProfileIInfoItem = ({ icon: Icon, children }: IProfileItem) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <Icon sx={classes.icon} />
      {children}
    </Box>
  );
};

export default ProfileIInfoItem;
