import { useMemo } from 'react';

import { colors, COLORS } from '../../../../constants/colors';
import { VideoBubblesStyleType } from './interfaces';

export const useStyles: VideoBubblesStyleType = ({
  sharingFullscreen,
  hasScreenMediaStreams,
}) => ({
  rootWrapper: {
    position: 'absolute',
    pl: 8,
    pr: 8,
    minWidth: '45vw',
    overflow: 'hidden',
    minHeight: 120,
    display: sharingFullscreen ? 'none' : 'flex',
    flexDirection: 'row',
    top: ['200px', '200px', '180px', '110px'],
    left: hasScreenMediaStreams ? '40%' : '47%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,

    '& .swiper-button-next::after': {
      display: 'none',
    },
    '& .swiper-button-prev::after': {
      display: 'none',
    },

    '& .swiper-button-disabled': {
      display: 'none',
    },

    '.swiper-button-next, .swiper-button-prev': {
      border: `1px solid ${COLORS.NEW_LOGIN_GREY}`,
      background: COLORS.DARK_GREY,
      borderRadius: 50,
      p: 3,
      backdropFilter: 'blur(12px)',

      top: '40%',

      '& svg': {
        color: colors.white,
      },
    },
    '.swiper-button-prev': {},

    '.swiper-slide': {
      display: 'flex',
      justifyContent: 'center',
      minWidth: 200,
    },

    '@media screen and ( max-width: 1420px )': {
      top: 170,
    },
  },
  root: useMemo(
    () => ({
      maxWidth: hasScreenMediaStreams ? 600 : '45vw',
      pl: 1,
      pr: 5,
    }),
    [hasScreenMediaStreams],
  ),
});
