import React from 'react';
import {
  Modal,
  Backdrop,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';

import { useMytaverseEvent } from '../../../providers';

import { useStyles } from './styles';
import useAsyncEffect from 'use-async-effect';
import EventsService from '../../../../../services/EventsService';
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../components/Notification';
import { getCatchErrorMessage } from '../../../../../helpers/error';
import { IMillicastStreamTokens } from '../../../../../interfaces/millicastTokens';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const MillicastScreenBroadcastingDataModal = () => {
  const classes = useStyles();

  const { t: translate } = useTranslation('common');

  const { showNotification, getMillicastNotification } = useNotification();
  const {
    shareMediaParams,
    getMillicastStreamTokens,
    setOpenMillicastScreenBroadcastingDataModal,
  } = useMytaverseEvent();

  const [broadcastingConfig, setBroadcastingConfig] =
    React.useState<IMillicastStreamTokens | null>(null);

  const showSuccessCopyMessage = React.useCallback(() => {
    showNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: translate('notifications.copiedToClipboard'),
    });
  }, [showNotification]);

  const onClose = () => setOpenMillicastScreenBroadcastingDataModal(false);

  useAsyncEffect(async () => {
    if (shareMediaParams) {
      const streamName = EventsService.getMillicastStreamName(
        shareMediaParams.roomId,
        shareMediaParams.region || '',
        shareMediaParams.screenName,
      );

      try {
        const millicastTokens = await getMillicastStreamTokens(streamName);

        if (!millicastTokens) {
          throw new Error(`Can't load millicast tokens`);
        }

        setBroadcastingConfig(millicastTokens);
      } catch (error: unknown | Error) {
        showNotification(
          getMillicastNotification({
            type: NOTIFICATION_TYPES.ERROR,
            message: getCatchErrorMessage(error),
          }),
        );
      }
    }
  }, [shareMediaParams, getMillicastStreamTokens]);

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box component="div" sx={classes.modal}>
        <Typography sx={classes.title}>
          {translate('millicastScreenBroadcastingDataModal.title')}
        </Typography>
        <Box sx={classes.settingsContainer}>
          {broadcastingConfig ? (
            <>
              <TextField
                label={translate(
                  'millicastScreenBroadcastingDataModal.streamNameLabel',
                )}
                fullWidth
                value={broadcastingConfig.streamName}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard
                        text={broadcastingConfig.streamName}
                        onCopy={showSuccessCopyMessage}
                      >
                        <IconButton edge="end">
                          <ContentCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={translate(
                  'millicastScreenBroadcastingDataModal.publishingTokenLabel',
                )}
                fullWidth
                value={broadcastingConfig.publishingToken}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard
                        text={broadcastingConfig.publishingToken}
                        onCopy={showSuccessCopyMessage}
                      >
                        <IconButton edge="end">
                          <ContentCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={translate(
                  'millicastScreenBroadcastingDataModal.codecLabel',
                )}
                fullWidth
                value="vp8"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard
                        text="vp8"
                        onCopy={showSuccessCopyMessage}
                      >
                        <IconButton edge="end">
                          <ContentCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          ) : (
            translate('millicastScreenBroadcastingDataModal.errorMessage')
          )}
        </Box>
        <IconButton onClick={onClose} sx={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default MillicastScreenBroadcastingDataModal;
