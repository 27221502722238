import { useState, useCallback, useEffect, useMemo } from 'react';
import { isDesktop } from 'react-device-detect';

export const useShowOrientationOverlay = (): boolean => {
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight,
  );

  const handleOrientationChange = useCallback(() => {
    setTimeout(() => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    }, 100);
  }, [setIsLandscape]);

  useEffect(() => {
    window.addEventListener('load', handleOrientationChange, false);
    window.addEventListener('resize', handleOrientationChange, false);

    return () => {
      window.removeEventListener('resize', handleOrientationChange, false);
      window.removeEventListener('load', handleOrientationChange, false);
    };
  }, [handleOrientationChange]);

  const showOverlay = useMemo(() => !(isDesktop || isLandscape), [isLandscape]);

  return useMemo(() => showOverlay, [showOverlay]);
};
