import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { FormLayout } from '../../components/Form';
import BackButton from '../../components/BackButton';

import { MarkEmailReadIcon } from '../../../../icons';

import { IForgotPasswordVerificationPageView } from './interfaces';

import { classes } from './styles';

const ForgotPasswordVerificationPageView = ({
  email,
  onBackClick,
  t: translate,
}: IForgotPasswordVerificationPageView) => {
  return (
    <FormLayout classes={{ container: classes.root }}>
      <BackButton onClick={onBackClick} />
      <Box sx={classes.content}>
        <MarkEmailReadIcon />
        <Typography sx={classes.title}>
          {translate('forgotPasswordVerification.title')}
        </Typography>
        <Typography sx={classes.subtitle}>
          {translate('forgotPasswordVerification.subtitle1')}:{' '}
          <Typography component="span" sx={classes.email}>
            {email}
          </Typography>
        </Typography>
        <Typography sx={classes.reminder}>
          {translate('forgotPasswordVerification.subtitle2')}.
        </Typography>
        {/*TODO unable when api will be ready*/}
        {/*<FormButton type="button">*/}
        {/*  {translate('forgotPasswordVerification.button')}*/}
        {/*</FormButton>*/}
      </Box>
    </FormLayout>
  );
};

export default withTranslation('common')(ForgotPasswordVerificationPageView);
