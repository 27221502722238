import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { colors } from '../../../../../constants/colors';

import { GetPasswordRuleContextType } from './interfaces';

export const getPasswordRuleContext: GetPasswordRuleContextType = ({
  isCompleted,
  isError,
}) => {
  if (isCompleted) {
    return {
      icon: CheckCircleIcon,
      color: colors.patrick,
    };
  }

  if (isError) {
    return {
      icon: CancelIcon,
      color: colors.red,
    };
  }

  return {
    icon: RadioButtonUncheckedIcon,
    color: colors.oxford_60,
  };
};
