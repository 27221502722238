import React from 'react';

import { Box } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { INavigationWrapper } from './interfaces';

import { useStyles } from './styles';

const NavigationWrapper = ({ children, sx = {} }: INavigationWrapper) => {
  const classes = useStyles();
  const prevBtnClass = { ...classes.prevBtn, ...sx };
  const nextBtnClass = { ...classes.nextBtn, ...sx };

  return (
    <>
      <Box className="swiper-button-prev" sx={prevBtnClass}>
        <KeyboardBackspaceIcon />
      </Box>
      {children}
      <Box className="swiper-button-next" sx={nextBtnClass}>
        <KeyboardBackspaceIcon />
      </Box>
    </>
  );
};

export default NavigationWrapper;
