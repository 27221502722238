import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

import LegalNotes from '../../components/LegalNotes';
import {
  FormButton,
  FormInput,
  FormLayout,
  FormPasswordInput,
} from '../../components/Form';
import PageTitleContent from '../../components/PageTitleContent';
import PasswordValidationWrapper from '../../components/PasswordValidation';
import SocialButton from '../../components/SocialButton';

import { GoogleColoredIcon, MicrosoftColoredIcon } from '../../../../icons';

import { ISignUpPageViewProps } from './interfaces';
import { SocialProviders } from '../../../../interfaces';

import { useStyles } from './styles';

const SignUpPageView = ({
  isDefaultEvent,
  openPasswordRules,
  values,
  errors,
  touched,
  onChange,
  onPasswordHandler,
  hasInitialEmail,
  loading,
  onSubmit,
  socialLoading,
  onSocialBtnClick,
  t: translate,
}: ISignUpPageViewProps) => {
  const classes = useStyles();

  const isLoginByGoogle = socialLoading === SocialProviders.Google;
  const isLoginByMicrosoft = socialLoading === SocialProviders.Microsoft;

  return (
    <Box sx={classes.root}>
      {isDefaultEvent && (
        <PageTitleContent
          title={translate('register.pageTitle')}
          subtitle={translate('register.subtitle')}
        />
      )}
      <Box sx={classes.container}>
        <LegalNotes>
          <FormLayout classes={{ container: classes.layoutContainer }}>
            <Typography sx={classes.title}>
              {translate('register.title')}
            </Typography>
            <form onSubmit={onSubmit}>
              <Box sx={classes.nameContainer}>
                <FormInput
                  name="firstName"
                  value={values.firstName}
                  errorMessage={
                    errors.firstName ? translate(errors.firstName) : ''
                  }
                  showError={
                    !loading && touched.firstName && !!errors.firstName
                  }
                  placeholder={translate('register.firstName')}
                  onChange={onChange}
                />
                <FormInput
                  name="lastName"
                  value={values.lastName}
                  errorMessage={
                    errors.lastName ? translate(errors.lastName) : ''
                  }
                  showError={!loading && touched.lastName && !!errors.lastName}
                  placeholder={translate('register.lastName')}
                  onChange={onChange}
                />
              </Box>
              <FormInput
                name="email"
                value={values.email}
                disabled={hasInitialEmail}
                errorMessage={errors.email ? translate(errors.email) : ''}
                showError={!loading && touched.email && !!errors.email}
                placeholder={translate('register.email')}
                onChange={onChange}
              />
              <PasswordValidationWrapper
                open={openPasswordRules}
                hasPasswordValue={!!values.password || !!values.passwordConfirm}
                isSubmitting={!!touched.password && !!touched.passwordConfirm}
                error={errors.password || ''}
              >
                <>
                  <FormPasswordInput
                    name="password"
                    value={values.password}
                    showError={
                      !loading && touched.password && !!errors.password
                    }
                    placeholder={translate('register.password')}
                    onChange={onChange}
                    onFocus={() => onPasswordHandler(true)}
                    onBlur={() => onPasswordHandler(false)}
                    enableShowPassword
                  />
                  <FormPasswordInput
                    name="passwordConfirm"
                    value={values.passwordConfirm}
                    showError={
                      !loading && touched.passwordConfirm && !!errors.password
                    }
                    placeholder={translate('register.passwordConfirmed')}
                    onChange={onChange}
                    onFocus={() => onPasswordHandler(true)}
                    onBlur={() => onPasswordHandler(false)}
                    enableShowPassword
                  />
                </>
              </PasswordValidationWrapper>
              <FormButton loading={loading} disabled={!!socialLoading}>
                {translate('register.title')}
              </FormButton>
            </form>

            <Divider sx={classes.divider}>{translate('register.or')}</Divider>

            <Box sx={classes.socialButtonsContainer}>
              <SocialButton
                icon={GoogleColoredIcon}
                onClick={() => onSocialBtnClick(SocialProviders.Google)}
                loading={isLoginByGoogle}
                disabled={loading || isLoginByMicrosoft}
              >
                Google
              </SocialButton>
              <SocialButton
                icon={MicrosoftColoredIcon}
                onClick={() => onSocialBtnClick(SocialProviders.Microsoft)}
                loading={isLoginByMicrosoft}
                disabled={loading || isLoginByGoogle}
              >
                Microsoft
              </SocialButton>
            </Box>
          </FormLayout>
        </LegalNotes>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(SignUpPageView);
