import React from 'react';
import { useNavigate } from 'react-router-dom';

import PreviousSelectedAvatarView from './PreviousSelectedAvatarView';

import { useMytaverseEvent } from '../../../dashboard/providers';

import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';

import ROUTES from '../../../../constants/routes';

const PreviousSelectedAvatar = () => {
  const {
    previousSkin,
    selectSkin,
    previousCustomAvatar,
    setCurrentSkin,
    setCustomAvatarUrl,
    resetPreviousSkin,
    resetPreviousCustomSkin,
  } = useMytaverseEvent();
  const navigate = useNavigate();

  const handleChangeAvatar = () => {
    sendCustomSentryBreadcrumb({ message: 'change avatar' });
    (previousSkin ? resetPreviousSkin : resetPreviousCustomSkin)();
  };

  const handleSelectAvatar = () => {
    if (previousSkin) {
      sendCustomSentryBreadcrumb({ message: 'go to select skin' });
      setCustomAvatarUrl(null);
      setCurrentSkin(previousSkin);
      selectSkin(previousSkin);
    } else {
      setCustomAvatarUrl(previousCustomAvatar);
    }

    navigate(ROUTES.DEVICES_TEST);
  };

  const handleSelectPrevAvatar = () => {
    sendCustomSentryBreadcrumb({ message: 'select previous avatar' });
    setCustomAvatarUrl(previousCustomAvatar);
    navigate(ROUTES.DEVICES_TEST);
  };

  return (
    <PreviousSelectedAvatarView
      previousSkin={previousSkin}
      onChangeAvatar={handleChangeAvatar}
      onSelectAvatar={handleSelectAvatar}
      onSelectPrevAvatar={handleSelectPrevAvatar}
    />
  );
};

export default PreviousSelectedAvatar;
