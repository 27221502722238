import $api from '../http/axios';
import { IInitMessage } from '../modules/dashboard/components/DashboardContent/Pureweb/interfaces';
import { CreateGameSessionResponse } from '../modules/dashboard/components/DashboardContent/GameCast/interfaces';

export interface StreamSessionData {
  gameName: string;
  description: string;
  participantId: string;
  signalRequest: string;
  timeout: number;
  streamId: string;
  initMessage: IInitMessage;
  region: string;
}

export interface StreamGroupIdResponse {
  eventId: string;
  streamGroupId: string;
  region: string;
}

export default class GameCastService {
  static startGameSession({
    gameName,
    description,
    participantId,
    signalRequest,
    timeout,
    streamId,
    initMessage,
    region,
  }: StreamSessionData): Promise<CreateGameSessionResponse> {
    return $api
      .post(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/gamecast/stream-session`,
        {
          gameName,
          description,
          participantId,
          signalRequest,
          timeout,
          streamGroupId: streamId,
          initMessage,
          region,
        },
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        },
      )
      .then((res) => res.data);
  }

  static getGameSessionId(eventId: string): Promise<StreamGroupIdResponse> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/gamecast/stream-session/${eventId}`,
      )
      .then((res) => res.data);
  }
}
