import { useRef } from 'react';

import { UseFullscreenType } from './interfaces';

export const useFullscreen: UseFullscreenType = () => {
  const isFullscreen = useRef(false);

  const toggleFullscreen = () => {
    if (!document.fullscreen && isFullscreen.current) {
      isFullscreen.current = false;
    }

    if (!document.activeElement) return;

    if (isFullscreen.current) {
      document.exitFullscreen();

      isFullscreen.current = !isFullscreen.current;

      return;
    }

    if (!isFullscreen.current) {
      document.documentElement.requestFullscreen();

      isFullscreen.current = !isFullscreen.current;

      return;
    }
  };

  return toggleFullscreen;
};
