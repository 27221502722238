import { keyframes } from '@mui/system';
import { useTheme } from '@mui/material';

import { colors } from '../../../../constants/colors';

import { IPasswordValidationStyles } from './interfaces';
import { ScreenSizes } from '../../../../interfaces';

const containerShakeFrame = keyframes`
  0% {
    transform: translate(0, 0)
  }
  1.78571% {
    transform: translate(5px, 0)
  }
  3.57143% {
    transform: translate(0, 0)
  }
  5.35714% {
    transform: translate(5px, 0)
  }
  7.14286% {
    transform: translate(0, 0)
  }
  8.92857% {
    transform: translate(5px, 0)
  }
  10.71429% {
    transform: translate(0, 0)
  }
  100% {
    transform: translate(0, 0)
  }
`;

const HEIGHT = 164;
const MARGIN = 6;

export const useStyles = ({ isSubmittingError }: IPasswordValidationStyles) => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 1,
      backgroundColor: colors.white,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      p: 2,
      position: 'absolute',
      left: '110%',
      height: HEIGHT,
      [breakpoints.down(ScreenSizes.Pc)]: {
        right: 0,
        left: 'auto',
        top: `-${HEIGHT + MARGIN}px`,
      },
      animation: isSubmittingError
        ? `${containerShakeFrame} 0.4s ease-in-out`
        : 'none',
    },
  };
};
