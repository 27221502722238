import { SyntheticEvent } from 'react';

export interface ActionsViewProps {
  activeTab: ActionsTabs;
  onChangeTab: (_: SyntheticEvent, value: ActionsTabs) => void;
  isOpenChat: boolean;
}

export enum ActionsTabs {
  Tools = 'tools',
  Emojis = 'emojis',
  Reactions = 'reactions',
}

export interface IInitialToolsState {
  isHoverboardApplied: boolean;
  isLaserPointerApplied: boolean;
  isMagnifierApplied: boolean;
  isTeleportApplied: boolean;
}

export interface IToolsState extends IInitialToolsState {
  setHoverboard: (isHoverboardApplied: boolean) => void;
  setLaserPointer: (isLaserPointerApplied: boolean) => void;
  setMagnifier: (isMagnifierApplied: boolean) => void;
  setTeleport: (isTeleportApplied: boolean) => void;
  resetState: () => void;
}
