import { IPrivateChannel } from '../interfaces/channel';

export const getExistedPrivateChannel = (
  privateChannels: IPrivateChannel[],
  participantUserId: string,
): IPrivateChannel | undefined => {
  const privateChannel = privateChannels.find(({ userIds }) =>
    userIds.includes(participantUserId),
  );

  return privateChannel;
};
