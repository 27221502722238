import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useNotification } from '../../../../../components/Notification';
import { useUpdateInitialParticipants } from '../../../../../hooks/participant';

import { getCatchErrorMessage } from '../../../../../helpers/error';

import UserService from '../../../../../services/UserService';

import {
  InviteUserToEventType,
  UseInviteUserToEventType,
  UseOpenInviteUserToEventModalType,
} from './interfaces';

export const useOpenInviteUserToEventModal: UseOpenInviteUserToEventModalType =
  () => {
    const [open, setOpen] = useState(false);

    const openModal = () => setOpen(true);

    const closeModal = () => setOpen(false);

    return { open, openModal, closeModal };
  };

export const useInviteUserToEvent: UseInviteUserToEventType = (onClose) => {
  const { t: translate } = useTranslation('common');
  const { currentEventId } = useMytaverse();
  const {
    showNotification,
    getSuccessNotification,
    getBadRequestNotification,
  } = useNotification();
  const updateInitialParticipants = useUpdateInitialParticipants();

  const inviteUserToEvent: InviteUserToEventType = async (email) => {
    try {
      const data = {
        event: currentEventId || '',
        emails: [email],
      };

      await UserService.subscribeUserToEvent(data);
      await UserService.inviteUserToEvent(data);
      await updateInitialParticipants();

      showNotification(
        getSuccessNotification({
          message: translate('inviteUserModal.successText'),
        }),
      );

      onClose();
    } catch (error: unknown) {
      const errorMessage = getCatchErrorMessage(error);

      showNotification(getBadRequestNotification({ message: errorMessage }));
      throw Error(getCatchErrorMessage(error));
    }
  };

  return inviteUserToEvent;
};
