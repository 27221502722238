import { checkIsStreamChannelUnread } from './';

import { IPrivateChannel } from '../../../interfaces/channel';

export const getPrivateChannelId = (streamChannelId: string) => {
  const streamChannelIdArray = streamChannelId.split('-');
  const privateChannelId =
    streamChannelIdArray[streamChannelIdArray.length - 1];

  return privateChannelId;
};

export const checkChannelFormCurrentEvent = (
  streamChannelId: string,
  currentEventId: string,
): boolean => {
  const isCurrentEventChannel = streamChannelId.includes(currentEventId);

  return isCurrentEventChannel;
};

export const checkIsEventChannel = (
  streamChannelId: string,
  eventId: string,
): boolean => streamChannelId === `mytaverse-event-${eventId}`;

export const checkHasUnreadPrivateChannel = (
  privateChannels: IPrivateChannel[],
): boolean => {
  const hasUnreadPrivateChannel = privateChannels
    .map((privateChannel) => privateChannel.channel)
    .some((channel) => checkIsStreamChannelUnread(channel));

  return hasUnreadPrivateChannel;
};
