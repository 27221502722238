import { COLORS } from '../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    width: 40,
    height: 40,
  },
  icon: {
    color: COLORS.WHITE,
    opacity: 0.3,
    cursor: 'pointer',
  },
});
