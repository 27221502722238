import { COLORS } from '../../../../constants/colors';

export const useStyles = () => ({
  root: {
    '& .MuiPaper-root': {
      border: `2px solid ${COLORS.BLACK}`,
      backdropFilter: 'blur(12px)',
      backgroundColor: COLORS.NEW_BLACK,
      color: COLORS.WHITE,
      '& button': {
        color: COLORS.WHITE,
        backgroundColor: COLORS.MYTAVERSE_BLUE,

        '&:hover': {
          opacity: 0.8,
        },
      },
    },
  },
});
