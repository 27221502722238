import React from 'react';
import { Box } from '@mui/material';

import { IScrollableContainer } from './interfaces';

import { classes } from './styles';

const ScrollableContainer = ({
  children,
  sxProps = {},
}: IScrollableContainer) => {
  const { root = {}, scrollContent = {}, content = {} } = sxProps;

  const rootSx = { ...classes.root, ...root };
  const scrollContentSx = { ...classes.scrollContent, ...scrollContent };

  return (
    <Box sx={rootSx}>
      <Box sx={scrollContentSx}>
        <Box sx={content}>{children}</Box>
      </Box>
    </Box>
  );
};

export default ScrollableContainer;
