import React, { useEffect, useMemo, useState } from 'react';

import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import UserProfileModalHeader from './UserProfileModalHeader';
import UserProfileForm from './UserProfileForm';
import UserProfilePreview from './UserProfilePreview';
import UploadImageModal from './UploadImageModal';
import LoadingProgress from '../LoadingProgress';

import { useMytaverse } from '../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../modules/dashboard/providers';

import { checkIsAdminRole } from '../../helpers/role';
import {
  getIsAdminParticipantBusy,
  getIsParticipantBusy,
} from '../../helpers/followMe';

import { IUserProfileModal } from './interfaces';
import { WebsocketAction } from '../../interfaces/webSocketConnectionInfo';
import { EventRole } from '../../interfaces/event';

import { useStyles } from './styles';

const UserProfileModal = ({
  onClose,
  enableEditing = false,
  previewingParticipant = null,
}: IUserProfileModal) => {
  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [updImage, setUpdImage] = useState<string | File>('');

  const { user, changingUserData, sendMessageToEvent } = useMytaverse();
  const {
    currentEvent,
    pendingFollowersData,
    acceptedFollowersData,
    currentParticipant,
    participants,
  } = useMytaverseEvent();

  const previewingParticipantData = useMemo(
    () =>
      participants.find(
        (participant) => participant.userId === previewingParticipant?.userId,
      ),
    [participants, previewingParticipant?.userId],
  );
  const isParticipantOnline = !!previewingParticipantData?.roomId;

  const getShowFollowMe = (): boolean => {
    const isCurrentParticipant =
      previewingParticipantData?.userId === currentParticipant?.userId;

    if (isCurrentParticipant) {
      return false;
    }

    const followersData = pendingFollowersData.concat(acceptedFollowersData);

    const isBusyCurrentParticipant = getIsParticipantBusy(
      followersData,
      currentParticipant?.userId || '',
    );

    if (isBusyCurrentParticipant) return false;

    const previewingParticipantRole =
      previewingParticipantData?.role || EventRole.None;
    const isPreviewingParticipantAdmin = checkIsAdminRole(
      previewingParticipantRole,
    );
    const previewingParticipantId = previewingParticipant?.userId || '';

    if (isPreviewingParticipantAdmin) {
      const isAdminPreviewingParticipantBusy = getIsAdminParticipantBusy(
        followersData,
        previewingParticipantId,
      );

      return !isAdminPreviewingParticipantBusy;
    }

    const isGuestPreviewingParticipantBusy = getIsParticipantBusy(
      followersData,
      previewingParticipantId,
    );

    return !isGuestPreviewingParticipantBusy;
  };

  const handleFollow = () => {
    sendMessageToEvent(currentEvent?.id || '', {
      action: WebsocketAction.FollowPlayer,
      ownerId: previewingParticipantData?.userId,
      followerId: currentParticipant?.userId,
      ownerName: previewingParticipantData?.fullName,
      ownerGameSessionId: previewingParticipantData?.gameSessionId,
    });
    onClose();
  };

  useEffect(() => {
    if (!editing) {
      setUpdImage('');
    }
  }, [editing]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      sx={classes.root}
    >
      <Box sx={classes.container}>
        <Box sx={classes.content}>
          <UserProfileModalHeader onClose={onClose} />
          <Box sx={classes.contentWrapper}>
            {changingUserData ? (
              <LoadingProgress height="100%" />
            ) : editing ? (
              <UserProfileForm
                openUpload={openUpload}
                setOpenUpload={setOpenUpload}
                updImage={updImage}
                onClose={() => setEditing(false)}
              />
            ) : (
              <UserProfilePreview
                user={previewingParticipant ?? user}
                enableEditing={enableEditing}
                showFollowMe={isParticipantOnline ? getShowFollowMe() : false}
                onEditClick={() => setEditing(!editing)}
                onFollow={handleFollow}
              />
            )}
          </Box>
        </Box>
        <UploadImageModal
          open={openUpload}
          setUpdImage={setUpdImage}
          setOpen={setOpenUpload}
        />
      </Box>
    </Modal>
  );
};

export default UserProfileModal;
