import { useEffect } from 'react';
import { StreamChatGenerics } from 'stream-chat-react/dist/stories/utils';

import { EventHandler, StreamChat } from 'stream-chat';
import useSound from 'use-sound';

import {
  useRedirectToGlobalChat,
  useRedirectToPrivateChat,
  useStreamChannels,
} from '../../../hooks/channel';
import {
  useNotification,
  ChatMessageType,
} from '../../../components/Notification';
import { useMytaverse } from '../../../providers/MytaverseProvider';
import { useChatState } from '../../../hooks/context';

import { checkIsStreamChannelUnread } from '../helpers';
import {
  checkChannelFormCurrentEvent,
  checkHasUnreadPrivateChannel,
  checkIsEventChannel,
  getPrivateChannelId,
} from '../helpers/channel';
import { getCatchErrorMessage } from '../../../helpers/error';

import ChannelService from '../../../services/ChannelService';

import NotificationSound from '../../../public/sounds/notification.wav';

import {
  IUseChatHandlers,
  IUseCheckInitialUnreadChannels,
  IUseCheckUnreadChannel,
  IUseInitializeStreamClientConnection,
  IUseNewChannel,
  IUseSetInitialChannels,
} from './interfaces';

export const useChatHandlers = ({
  currentEvent,
  streamChatToken,
}: IUseChatHandlers) => {
  const { user } = useMytaverse();
  const {
    streamClient,
    setStreamClient,
    eventStreamChannel,
    setEventStreamChannel,
    privateChannels,
    setPrivateChannels,
    addPrivateChannel,
    setInitialLoading,
    setIsUnreadEventChannel,
    setIsUnreadPrivateChannel,
  } = useChatState();

  const userId = user?.id || '';
  const currentEventId = currentEvent?.id || '';

  useInitializeStreamClientConnection({
    streamChatToken,
    streamClient,
    setStreamClient,
    user,
  });

  useSetInitialChannels({
    streamClient,
    setEventStreamChannel,
    setPrivateChannels,
    currentEventId,
    userId,
  });

  useCheckInitialUnreadChannels({
    eventChannel: eventStreamChannel,
    privateChannels,
    setInitialLoading,
    setIsUnreadEventChannel,
    setIsUnreadPrivateChannel,
  });

  useCheckUnreadChannel({
    privateChannels,
    setIsUnreadEventChannel,
    setIsUnreadPrivateChannel,
    streamClient,
    userId,
    currentEventId,
  });

  useNewChannel({
    streamClient,
    hasEventStreamChannel: !!eventStreamChannel,
    setEventStreamChannel,
    addPrivateChannel,
    currentEventId,
    userId,
  });
};

export const useInitializeStreamClientConnection = ({
  streamChatToken,
  streamClient,
  setStreamClient,
  user,
}: IUseInitializeStreamClientConnection) => {
  useEffect(() => {
    if (!user || !streamChatToken) return;

    const initializeStreamClientConnection = async () => {
      try {
        const apiKey = process.env.REACT_APP_STREAM_CHAT_API_KEY as string;
        const client = new StreamChat<StreamChatGenerics>(apiKey);
        const chatUser = {
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        };

        await client.connectUser(chatUser, streamChatToken);

        setStreamClient(client);
      } catch (error: unknown) {
        throw Error(getCatchErrorMessage(error));
      }
    };

    initializeStreamClientConnection();

    return () => {
      streamClient?.disconnectUser();
      setStreamClient(null);
    };
  }, [user, streamChatToken]);
};

export const useSetInitialChannels = ({
  streamClient,
  setEventStreamChannel,
  setPrivateChannels,
  currentEventId,
  userId,
}: IUseSetInitialChannels) => {
  const { getEventStreamChannel, getMapEventChannels } = useStreamChannels();

  useEffect(() => {
    if (!streamClient || !currentEventId || !userId) return;

    const setInitialChannels = async () => {
      const eventStreamChannel = await getEventStreamChannel({
        streamClient,
        userId,
        eventId: currentEventId,
      });

      setEventStreamChannel(eventStreamChannel);

      const eventChannels = await ChannelService.getEventChannels(
        currentEventId,
      );
      const privateChannels = await getMapEventChannels(
        streamClient,
        eventChannels,
        userId,
      );

      setPrivateChannels(privateChannels);
    };

    setInitialChannels();
  }, [streamClient, currentEventId, userId]);
};

export const useCheckInitialUnreadChannels = ({
  eventChannel,
  privateChannels,
  setInitialLoading,
  setIsUnreadEventChannel,
  setIsUnreadPrivateChannel,
}: IUseCheckInitialUnreadChannels) => {
  useEffect(() => {
    const isEventChannelUnread = checkIsStreamChannelUnread(eventChannel);

    setIsUnreadEventChannel(isEventChannelUnread);

    if (!privateChannels.length) {
      setInitialLoading(false);
      return;
    }

    const hasUnreadPrivateChannel =
      checkHasUnreadPrivateChannel(privateChannels);

    setIsUnreadPrivateChannel(hasUnreadPrivateChannel);
    setInitialLoading(false);
  }, [eventChannel, privateChannels]);
};

export const useCheckUnreadChannel = ({
  privateChannels,
  setIsUnreadEventChannel,
  setIsUnreadPrivateChannel,
  streamClient,
  userId,
  currentEventId,
}: IUseCheckUnreadChannel) => {
  const [play] = useSound(NotificationSound, {
    volume: 0.15,
  });
  const { showChatNotification } = useNotification();
  const redirectToPrivateChat = useRedirectToPrivateChat();
  const redirectToGlobalChat = useRedirectToGlobalChat();

  useEffect(() => {
    if (!streamClient) return;

    const handleNewMessage: EventHandler<StreamChatGenerics> = (evt) => {
      const readUserId = evt.user?.id || '';
      const isCurrentUser = readUserId === userId;

      if (isCurrentUser) return;

      const streamChannelId = evt.channel_id || '';
      const isCurrentEvent = checkChannelFormCurrentEvent(
        streamChannelId,
        currentEventId,
      );

      if (!isCurrentEvent) return;

      const isEventChannel = checkIsEventChannel(
        streamChannelId,
        currentEventId,
      );

      if (isEventChannel) {
        setIsUnreadEventChannel(true);
        play();
        showChatNotification({
          message: evt.message as ChatMessageType,
          onReply: redirectToGlobalChat,
        });
      } else {
        setIsUnreadPrivateChannel(true);
        play();
        showChatNotification({
          message: evt.message as ChatMessageType,
          onReply: redirectToPrivateChat,
        });
      }
    };

    const handleReadMessage: EventHandler<StreamChatGenerics> = (evt) => {
      const readUserId = evt.user?.id || '';
      const streamChannelId = evt.channel_id || '';
      const isCurrentUser = readUserId === userId;

      if (!isCurrentUser) return;

      const isCurrentEvent = checkChannelFormCurrentEvent(
        streamChannelId,
        currentEventId,
      );

      if (!isCurrentEvent) return;

      const isEventChannel = checkIsEventChannel(
        streamChannelId,
        currentEventId,
      );

      if (isEventChannel) {
        setIsUnreadEventChannel(false);
      } else {
        const hasUnreadPrivateChannel =
          checkHasUnreadPrivateChannel(privateChannels);

        setIsUnreadPrivateChannel(hasUnreadPrivateChannel);
      }
    };

    streamClient.on('message.new', handleNewMessage);
    streamClient.on('message.read', handleReadMessage);

    return () => {
      streamClient.off('message.new', handleNewMessage);
      streamClient.on('message.read', handleReadMessage);
    };
  }, [currentEventId, privateChannels, streamClient, userId]);
};

export const useNewChannel = ({
  streamClient,
  hasEventStreamChannel,
  setEventStreamChannel,
  addPrivateChannel,
  currentEventId,
  userId,
}: IUseNewChannel) => {
  const { getEventStreamChannel, getMapEventChannel } = useStreamChannels();

  useEffect(() => {
    if (!streamClient || !currentEventId || !userId) {
      return;
    }

    const newChannelSubscription = streamClient.on(
      'notification.added_to_channel',
      async (event) => {
        const streamChannelId = event.channel_id || '';
        const isCurrentEvent = checkChannelFormCurrentEvent(
          streamChannelId,
          currentEventId,
        );

        if (!isCurrentEvent) return;

        const isEventChannel = checkIsEventChannel(
          streamChannelId,
          currentEventId,
        );

        if (isEventChannel) {
          if (hasEventStreamChannel) return;

          const eventStreamChannel = await getEventStreamChannel({
            streamClient,
            userId: userId,
            eventId: currentEventId,
          });

          setEventStreamChannel(eventStreamChannel);
          return;
        }

        const privateChannelId = getPrivateChannelId(streamChannelId);
        const eventChannel = await ChannelService.getEventChannel(
          currentEventId,
          privateChannelId,
        );
        const newPrivateChannel = await getMapEventChannel(
          streamClient,
          eventChannel,
          userId,
        );

        addPrivateChannel(newPrivateChannel);
      },
    );

    return () => {
      newChannelSubscription.unsubscribe();
    };
  }, [streamClient, currentEventId, userId, hasEventStreamChannel]);
};
