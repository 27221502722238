import * as Sentry from '@sentry/react';

import { checkIsLocalhost } from './path';

import {
  SentryBreadcrumbCategory,
  SentryEnvironment,
  SentryXMLHttpRequest,
} from '../interfaces';
import {
  BreadcrumbHandlersType,
  CheckCategoryType,
  ISetSentryAuthUserDataProps,
  ISetSentryEventDataProps,
  SanitizedBreadcrumbType,
  SendCustomSentryBreadcrumbType,
} from './interfaces';

const { Xhr, Console, UserClick } = SentryBreadcrumbCategory;

export const getSentryEnvironment = (): SentryEnvironment => {
  const isLocalhost = checkIsLocalhost();

  if (isLocalhost) return SentryEnvironment.Local;

  const isDev = process.env.REACT_APP_MYTAVERSE_ENV === 'DEV';

  if (isDev) return SentryEnvironment.Development;

  return SentryEnvironment.Production;
};

export const sanitizedBreadcrumb: SanitizedBreadcrumbType = (
  breadcrumb,
  hint,
) => {
  const breadcrumbHandlers: BreadcrumbHandlersType = {
    [Xhr]: sanitizedXhrBreadcrumb,
    [Console]: sanitizedConsoleBreadcrumb,
  };

  const handler =
    breadcrumbHandlers[breadcrumb.category as SentryBreadcrumbCategory];

  return handler ? handler(breadcrumb, hint) : breadcrumb;
};

const sanitizedXhrBreadcrumb: SanitizedBreadcrumbType = (breadcrumb, hint) => {
  const xhr = hint.xhr as SentryXMLHttpRequest;
  const data = {
    url: xhr.responseURL,
    body: xhr.__sentry_xhr__ ? xhr.__sentry_xhr__.body : null,
    response: xhr.response,
  };

  return { ...breadcrumb, data };
};

const sanitizedConsoleBreadcrumb: SanitizedBreadcrumbType = (breadcrumb) => {
  delete breadcrumb.data;

  return breadcrumb;
};

export const sendCustomSentryBreadcrumb: SendCustomSentryBreadcrumbType = ({
  message,
  level = 'info',
}) => {
  Sentry.addBreadcrumb({
    category: UserClick,
    message,
    level,
  });
};

export const checkIsTransactionCategory: CheckCategoryType = (category) =>
  category === SentryBreadcrumbCategory.Transaction;

export const checkIsFetchCategory: CheckCategoryType = (category) =>
  category === SentryBreadcrumbCategory.Fetch;

export const setSentryAuthUserData = ({
  firstName,
  lastName,
  ...user
}: ISetSentryAuthUserDataProps) => {
  const name = `${firstName} ${lastName}`;

  Sentry.setUser({ ...user, name });
};

export const setSentryNotAuthUserData = (email: string) => {
  Sentry.setUser({ email });
};

export const setSentryEventData = ({ id, name }: ISetSentryEventDataProps) => {
  Sentry.setTag('eventId', id);
  Sentry.setTag('eventName', name);
};
