import { COLORS } from '../../../../../../constants/colors';
import { IStyles } from '../../../../../../interfaces/styles';

export const useStyles = (hasChildren: boolean): IStyles => ({
  root: {
    backgroundColor: COLORS.MYTAVERSE_BLACK,
    color: COLORS.WHITE,
    borderRadius: 72,
    border: `1px solid ${COLORS.NEW_LOGIN_GREY}`,
    backdropFilter: 'blur(12px)',
    textTransform: 'none',
    padding: '8px 20px',
    fontSize: 16,
    '& svg': {
      color: COLORS.WHITE,
      opacity: 0.5,
      mr: hasChildren ? 1.5 : 0,
    },
  },

  typography: {
    textTransform: 'capitalize',
  },
});
