import { COLORS } from '../../../../constants/colors';

export const useStyles = (background: string) => ({
  iconWrapper: {
    p: 2.5,
    borderRadius: '50%',
    border: `1px solid ${
      background === 'transparent' ? COLORS.WHITE : background
    }`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 71,
    maxHeight: 71,
    justifyContent: 'center',
    transition: 'background 0.5s ease, border 0.5s ease',
    background: background,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '30%',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    '& p': {
      fontSize: 14,
      lineHeight: 2.5,
      letterSpacing: '0.1px',
    },
  },
});
