import { GetProgressStepTitleType } from './interfaces';

export const getProgressStepTitle: GetProgressStepTitleType = ({
  secondStepLoading,
  thirdStepLoading,
}) => {
  if (secondStepLoading) {
    return 'loading.preparingWorld';
  }

  if (thirdStepLoading) {
    return 'loading.launchingExperience';
  }

  return 'loading.teleporting';
};
