import { IStyles } from '../../interfaces/styles';

export const classes: IStyles = {
  root: {
    '& .MuiBadge-dot': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: '#F44336',
    },
    '& .MuiBadge-badge': {
      top: -8,
      right: -8,
    },
  },
};
