import { IStreamChannel } from '../../../../../../interfaces/channel';
import { ISearchChatItem } from './interfaces';

export const getLastMessageDate = (channel: IStreamChannel): number => {
  const createdDate = channel.lastMessage()?.created_at || 0;

  return createdDate ? createdDate.getTime() / 1000 : 0;
};

export const filterListByMessageDate = (list: ISearchChatItem[]) => {
  const result = [...list].sort(
    (itemA, itemB) => itemB.lastMessageDate - itemA.lastMessageDate,
  );

  return result;
};
