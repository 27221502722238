import { useMytaverseEvent } from '../modules/dashboard/providers';
import { useMytaverse } from '../providers/MytaverseProvider';

import { getInitialMapParticipants } from '../helpers/participant';

import EventsService from '../services/EventsService';

export const useUpdateInitialParticipants = () => {
  const { currentEventId } = useMytaverse();
  const { setParticipants } = useMytaverseEvent();

  const updateInitialParticipants = async () => {
    const eventId = currentEventId || '';
    const participants = await EventsService.getEventParticipants(eventId);
    const participantsInfo = await EventsService.getEventParticipantsInfo(
      eventId,
    );
    const participantRoles = await EventsService.getEventParticipantsRoles(
      eventId,
    );

    setParticipants(
      getInitialMapParticipants(
        participants,
        participantsInfo,
        participantRoles,
      ),
    );
  };

  return updateInitialParticipants;
};
