import { IErrorWithMessage } from './interfaces';

export const getErrorMessage = (message: string): string => {
  return message
    ? message
        .split(/(?=[A-Z])/)
        .join(',')
        .replaceAll(',', ' ')
    : '';
};

export class BadRequest extends Error {
  title: string;

  constructor(error: Error) {
    super(getErrorMessage(error.message));

    this.title = 'Bad request';
  }
}

const getIsErrorWithMessage = (error: unknown): error is IErrorWithMessage => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
};

const convertToErrorWithMessage = (maybeError: unknown): IErrorWithMessage => {
  if (getIsErrorWithMessage(maybeError)) {
    return maybeError;
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringify the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
};

export const getCatchErrorMessage = (error: unknown): string => {
  return convertToErrorWithMessage(error).message;
};
