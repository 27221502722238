import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import Transition from '../../../../components/Transition';
import { TeleportToRoomDialogViewProps } from './interfaces';
import { useStyles } from './styles';

const TeleportToRoomDialogView = ({
  openTeleportToRoomDialog,
  currentRegion,
  handleKeepSharing,
  handleTeleportToRoom,
}: TeleportToRoomDialogViewProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openTeleportToRoomDialog}
      TransitionComponent={Transition}
      keepMounted
      onBackdropClick={() => false}
      sx={classes.root}
    >
      <DialogTitle>Are you sure you want to teleport?</DialogTitle>
      <DialogContentText>
        You have active broadcasting streams in this room (
        {currentRegion?.region})
      </DialogContentText>
      <DialogContent />
      <DialogActions>
        <Button onClick={handleKeepSharing}>Close</Button>
        <Button onClick={handleTeleportToRoom}>Teleport</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeleportToRoomDialogView;
