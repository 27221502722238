import { COLORS } from '../../../../../../constants/colors';

export const useStyles = (shareScreenPublisherNumber: number) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  videoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    columnGap: 1,
    rowGap: 1,
    gridTemplateColumns: shareScreenPublisherNumber === 1 ? '100%' : '1fr 1fr',
    gridTemplateRows: shareScreenPublisherNumber < 3 ? 'unset' : '1fr 1fr',
    '& video': {
      width: '100%',
    },
  },
  button: {
    width: '100%',
    marginTop: 2,
    backgroundColor: shareScreenPublisherNumber
      ? COLORS.RED
      : COLORS.MYTAVERSE_BLUE,

    '&:disabled': {
      color: COLORS.MEDIUM_GREY,
      backgroundColor: shareScreenPublisherNumber
        ? COLORS.RED
        : COLORS.MYTAVERSE_BLUE,
    },
  },
});
