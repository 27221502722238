import React from 'react';

import { Box } from '@mui/material';

import { classes } from './styles';

const AimPulse = () => {
  return <Box sx={classes.aimPulse} />;
};

export default AimPulse;
