import React from 'react';
import { DateSeparator, DateSeparatorProps } from 'stream-chat-react';

import { useFormatDateSeparatorDate } from './hooks';

const ChatDateSeparator = ({ date }: DateSeparatorProps) => {
  const formatDateSeparatorDate = useFormatDateSeparatorDate();

  return (
    <DateSeparator
      formatDate={formatDateSeparatorDate}
      date={date}
      position="center"
    />
  );
};

export default ChatDateSeparator;
