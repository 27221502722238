import { useEffect, useRef } from 'react';

import { UseShowVideoBubbleType } from './interfaces';

export const useAttachMediaStream: UseShowVideoBubbleType = (stream) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigator.attachMediaStream(videoRef.current, stream);
  }, [stream]);

  return videoRef;
};
