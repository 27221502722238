import React from 'react';

import { Box, Typography } from '@mui/material';

import { useStyles } from './styles';

import { IPageTitleContent } from './interfaces';

const PageTitleContent = ({ title = '', subtitle = '' }: IPageTitleContent) => {
  const classes = useStyles();

  const showContent = !!title || !!subtitle;

  if (!showContent) {
    return null;
  }

  return (
    <Box sx={classes.root}>
      {title && <Typography sx={classes.title}>{title}</Typography>}
      {subtitle && <Typography sx={classes.subtitle}>{subtitle}</Typography>}
    </Box>
  );
};

export default PageTitleContent;
