import { COLORS } from '../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    mt: 1.5,
    mb: 5,
  },

  icon: {
    color: COLORS.GREY,
  },

  linearProgress: {
    width: 512,
    pt: 1,
    borderRadius: 2,
    background: COLORS.GREY,

    '& span': {
      background: COLORS.BLUE,
    },

  },
});
