import React from 'react';

import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { ICloseDrawerButtonView } from './interfaces';

import { classes } from './styles';

const CloseDrawerButtonView = ({
  sx = {},
  onClick,
}: ICloseDrawerButtonView) => {
  const rootClass = { ...classes.root, ...sx };

  return (
    <IconButton sx={rootClass} onClick={onClick}>
      <CloseIcon sx={classes.icon} />
    </IconButton>
  );
};

export default CloseDrawerButtonView;
