import React from 'react';

import LogoutButtonView from './LogoutButtonView';

import { useMytaverse } from '../../providers/MytaverseProvider';

import { sendCustomSentryBreadcrumb } from '../../helpers/sentry';

import { ILogoutButtonProps } from './interfaces';

const LogoutButton = ({ sx = {} }: ILogoutButtonProps) => {
  const { logoutUser, user, appTheme } = useMytaverse();

  if (!user) {
    return null;
  }

  const handleLogoutUser = () => {
    sendCustomSentryBreadcrumb({
      message: 'log out in event configuration page',
    });
    logoutUser();
  };

  return (
    <LogoutButtonView
      logoutUser={handleLogoutUser}
      iconColor={appTheme.languageButton.assets.iconColor}
      sx={sx}
    />
  );
};

export default LogoutButton;
