import { colors } from '../../../../../constants/colors';

export const classes = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 3,
    position: 'relative',
    padding: '24px 24px 32px',
    '& form': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 3,
    },
  },
  title: {
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 1.5,
    color: colors.oxford_60,
    textAlign: 'center',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.56,
    letterSpacing: '0.25px',
    color: colors.oxford_60,
    textAlign: 'center',
  },
};
