import React from 'react';

import Popover from '@mui/material/Popover';

import HomeBarButton from '../HomeBarButton';

import { IIconPopoverView } from './interfaces';

import { useStyles } from './styles';

const IconPopover = ({
  children,
  icon,
  label,
  onClick,
  onClose,
  anchorEl,
  buttonSx = {},
  popoverSx = {},
}: IIconPopoverView) => {
  const isOpened = !!anchorEl;

  const classes = useStyles(isOpened);
  const buttonClass = { ...classes.button, ...buttonSx };
  const popoverClass = { ...classes.popover, ...popoverSx };

  return (
    <>
      <HomeBarButton
        label={label}
        icon={icon}
        handleClick={onClick}
        buttonSx={buttonClass}
      />
      <Popover
        open={isOpened}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={popoverClass}
      >
        {children}
      </Popover>
    </>
  );
};

export default IconPopover;
