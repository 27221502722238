import { create } from 'zustand';

import { IDevicesTestState, IInitialDevicesTestState } from './interfaces';

const initialState: IInitialDevicesTestState = {
  step: 1,
};

export const useDevicesTestState = create<IDevicesTestState>((set) => ({
  ...initialState,
  setStep: (step) => set({ step }),
  resetState: () => set(initialState),
}));
