import React from 'react';

import { withTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';

import { Box, Typography } from '@mui/material';

import {
  ChatAutoComplete,
  SendButton,
  UploadsPreview,
} from 'stream-chat-react';
import { ImageDropzone, FileUploadButton } from 'react-file-utils';

import ChatQuotedMessagePreview from '../ChatQuotedMessagePreview';
import ChatEmojiPicker from '../ChatEmojiPicker';

import { AttachFileIcon, EmojiIcon } from '../../../../../icons';

import { IChatInputView } from './interfaces';

import { useStyles } from './styles';

const ChatInputView = ({
  hasText,
  quotedMessage,
  onClickEmoji,
  onChange,
  onSubmit,
  onSelectFiles,
  t: translate,
}: IChatInputView) => {
  const classes = useStyles({ hasText });

  return (
    <Box sx={classes.root}>
      <ImageDropzone multiple>
        {quotedMessage && (
          <ChatQuotedMessagePreview quotedMessage={quotedMessage} />
        )}
        <UploadsPreview />
        <Box sx={classes.inputContainer}>
          {isDesktop ? (
            <>
              <ChatEmojiPicker />
              <Typography
                component="span"
                sx={classes.emojiIcon}
                onClick={onClickEmoji}
              >
                <EmojiIcon />
              </Typography>
            </>
          ) : null}
          <FileUploadButton handleFiles={onSelectFiles} multiple>
            <AttachFileIcon />
          </FileUploadButton>
          <Box sx={classes.input}>
            <ChatAutoComplete
              handleSubmit={onSubmit}
              placeholder={translate('chatDrawer.inputPlaceholder')}
              onChange={onChange}
            />
          </Box>
          <Box sx={classes.btnContainer}>
            <SendButton sendMessage={onSubmit} />
          </Box>
        </Box>
      </ImageDropzone>
    </Box>
  );
};

export default withTranslation('common')(ChatInputView);
