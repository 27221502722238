import { COLORS } from '../../../../../constants/colors';

export const useStyles = (isOpenChat: boolean) => ({
  root: {
    mr: isOpenChat ? 40 : 'unset',
  },
  tablistRoot: {
    width: '100%',

    '& button': {
      width: '50%',
      fontSize: 24,
      textTransform: 'none',
    },
  },
  tab: {
    color: COLORS.MEDIUM_GREY,
    pl: 0,
    '&.Mui-selected': {
      color: COLORS.WHITE,
    },
  },
  emojiRoot: {
    '& div ::-webkit-scrollbar': {
      display: 'none',
    },
    '& aside': {
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      '& ul::before': {
        background: 'none !important',
        color: `${COLORS.WHITE} !important`,
      },
    },
  },
  emojiPicker: {
    background: 'none !important',
  },
  reactions: {
    width: 300,
    height: 400,
    '& aside': {
      background: 'none',
      '& ul::before': {
        backgroundColor: 'white !important',
        color: `${COLORS.WHITE} !important`,
      },
    },
  },
});
