import React from 'react';

import { Swiper } from 'swiper/react/swiper-react';
import { Navigation, Pagination } from 'swiper';

import { Box } from '@mui/material';

import NavigationWrapper from './NavigationWrapper';

import { ISwiperWrapper } from './interfaces';

import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

import { useStyles } from './styles';

const SwiperWrapper = ({
  children,
  loop,
  breakpoints,
  sliderWidth,
}: ISwiperWrapper) => {
  const classes = useStyles({
    swiperBreakpoints: breakpoints,
    loop,
    sliderWidth,
  });

  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        <NavigationWrapper sx={classes.navigationBtn}>
          <Swiper
            loop={loop}
            modules={[Navigation, Pagination]}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            breakpoints={breakpoints}
          >
            {children}
          </Swiper>
        </NavigationWrapper>
      </Box>
      <Box sx={classes.pagination}>
        <NavigationWrapper sx={classes.paginationNavigationBtn}>
          <Box className="swiper-pagination" />
        </NavigationWrapper>
      </Box>
    </Box>
  );
};

export default SwiperWrapper;
