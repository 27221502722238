import { COLORS } from '../../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '12px',
  },
  icon: {
    color: COLORS.WHITE,
    opacity: 0.6,
    width: 24,
    height: 28,
  },
  label: {
    color: COLORS.WHITE,
    opacity: 0.6,
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 22,
    lineHeight: 1.27,
  },
});
