import { colors } from '../../../../../../../../constants/colors';

export const classes = {
  root: {
    display: 'flex',
    columnGap: 3,
    width: '100%',
    color: colors.white,
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  iconContainer: {
    display: 'flex',
    width: 'fit-content',
    height: 'fit-content',
    flexShrink: 0,
    backgroundColor: colors.white_10,
    padding: '16px 10px',
    color: colors.white_60,
    borderRadius: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
    width: '100%',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
  fileName: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: colors.white,
  },
  createdDate: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: colors.white,
    opacity: 0.4,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 3,
  },
  action: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: 1,
    p: 0,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: colors.white_80,
    '& svg': {
      width: 16,
      height: 16,
      color: colors.white,
      opacity: 0.3,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};
