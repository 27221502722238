import { useEffect, useState } from 'react';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import Firebase from '../../../../services/Firebase';

import { SocialProviders } from '../../../../interfaces';

export const useSocialSignIn = () => {
  const [socialLoading, setSocialLoading] = useState<SocialProviders | string>(
    '',
  );
  const { socialLogin } = useMytaverse();

  const socialSignIn = async (provider: SocialProviders) => {
    setSocialLoading(provider);

    const { success, user } = await Firebase.signInWithProvider(provider);

    if (success) {
      await socialLogin(user);
    }

    setSocialLoading('');
  };

  return [socialLoading, socialSignIn] as const;
};

export const useLogout = () => {
  const { logoutUser } = useMytaverse();

  useEffect(() => {
    logoutUser(false);
    // eslint-disable-next-line
  }, []);
};
