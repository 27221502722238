import { useTheme } from '@mui/material';

import { colors, COLORS } from '../../../../../../constants/colors';

import { ScreenSizes } from '../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      backgroundColor: COLORS.DARK_GREY,
      border: `1px solid ${colors.gray}`,
      boxShadow: '0px 4px 40px #3548C1',
      borderRadius: '100px',
      padding: '23.5px 20px',
      margin: '0px auto',
      '& svg': {
        width: 35,
        height: 28,
        color: colors.white,
      },
      [breakpoints.down(ScreenSizes.Pc)]: {
        padding: '13.5px 10px',
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '2px 4px',
        '& svg': {
          width: 24,
          height: 28,
        },
      },
    },
  };
};
