import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubble';

import TipImage from '../../../../../../../public/images/Tips/ParticipantsList.png';
import UserIcon from '../../../../../../../public/images/Tips/UserIcon.png';
import LocationOnIcon from '../../../../../../../public/images/pin.png';

import { useStyles } from './styles';

const ParticipantsList = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={classes.tipImage}>
        <img src={TipImage} alt="Tip Image" />
      </Box>
      <Box sx={classes.content}>
        <Typography sx={classes.title}>
          {translate('tips.participantsList.title')}
        </Typography>
        <Typography sx={classes.description}>
          {translate('tips.participantsList.description1')}{' '}
          <Typography component="span" sx={classes.imageInText}>
            <img src={UserIcon} alt="User icon" />
          </Typography>{' '}
          {translate('tips.participantsList.description2')}
        </Typography>
        <Typography sx={classes.description}>
          {translate('tips.participantsList.description3')}{' '}
          <Typography component="span" sx={classes.imageInText}>
            <ChatBubbleOutlineOutlinedIcon />
          </Typography>{' '}
          {translate('tips.participantsList.description4')}{' '}
          <Typography component="span" sx={classes.imageInText}>
            <img src={LocationOnIcon} alt="Location icon" />
          </Typography>{' '}
          {translate('tips.participantsList.description5')}
        </Typography>
      </Box>
    </>
  );
};

export default withTranslation('common')(ParticipantsList);
