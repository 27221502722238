import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';

import reportWebVitals from './reportWebVitals';
import {
  checkIsFetchCategory,
  checkIsTransactionCategory,
  getSentryEnvironment,
  sanitizedBreadcrumb,
} from './helpers/sentry';

import { SentryBreadcrumbCategory } from './interfaces';

Sentry.init({
  dsn: 'https://220fdb97e36a4a1296984b74aeb07913@o993426.ingest.sentry.io/4504356383817728',
  environment: getSentryEnvironment(),
  integrations: [
    new BrowserTracing(),
    new Sentry.Breadcrumbs({ dom: false, console: true }),
  ],
  beforeBreadcrumb(breadcrumb, hint) {
    if (!breadcrumb.category || !hint) {
      return breadcrumb;
    }

    const category = breadcrumb.category as SentryBreadcrumbCategory;

    if (
      checkIsTransactionCategory(category) ||
      checkIsFetchCategory(category)
    ) {
      return null;
    }

    const updatedBreadcrumb = sanitizedBreadcrumb(breadcrumb, hint);

    return updatedBreadcrumb;
  },
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
