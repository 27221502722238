import { colors } from '../../../../constants/colors';

export const classes = {
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '39px 40px 33px',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 667,
    background:
      'radial-gradient(45.47% 122.24% at 97.04% -19.65%, rgba(45, 52, 66, 0.9) 0%, rgba(11, 11, 13, 0.9) 100%)',
    border: '1px solid rgba(140, 140, 140, 0.5)',
    backdropFilter: 'blur(4px)',
    borderRadius: 1,
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 17,
    color: colors.white_60,
    fontSize: 16,
    cursor: 'pointer',
  },
};
