import { COLORS } from '../../../../../../constants/colors';
import { IMillicastPublisher } from '../../../../../../interfaces/millicastPublisher';

export const useStyles = (
  shareScreenPublishers: IMillicastPublisher | null,
  videoTagMaxHeight: number,
) => ({
  root: {
    width: '100%',
  },
  videoContainer: {
    width: '100%',
    height: 470,

    '& video': {
      width: '100%',
      maxHeight: videoTagMaxHeight,
      '@media screen and ( max-height: 750px )': {
        maxHeight: 290,
      },
    },
    '@media screen and ( max-height: 750px )': {
      height: 340,
    },
  },
  button: {
    width: '600px',
    marginTop: 4,
    backgroundColor: shareScreenPublishers ? COLORS.RED : COLORS.MYTAVERSE_BLUE,

    '&:disabled': {
      color: COLORS.MEDIUM_GREY,
      backgroundColor: shareScreenPublishers
        ? COLORS.RED
        : COLORS.MYTAVERSE_BLUE,
    },
  },
  checkbox: {
    mb: 3,
    '& svg': {
      color: COLORS.MYTAVERSE_BLUE,
    },
    '& label': {
      zIndex: 99999,
    },
  },
  checkboxTitle: {
    color: COLORS.WHITE,
  },
});
