import { COLORS } from '../../../../../constants/colors';

import { IStyles } from '../../../../../interfaces/styles';

export const useStyles = (): IStyles => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: [280, 640],
    height: 'auto',
    border: `2px solid ${COLORS.BLACK}`,
    boxShadow: 24,
    p: ['20px 10px', 3],
    pt: 2.5,
    backdropFilter: 'blur(12px)',
    backgroundColor: COLORS.NEW_BLACK,
  },
  title: {
    textAlign: 'left',
    color: COLORS.WHITE,
    fontSize: 18,
    textTransform: 'capitalize',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
  },
  inputRoot: {
    '& div': {
      backgroundColor: 'transparent',
      width: '100%',
    },
  },
  input: {
    '& input': {
      padding: 1,
      borderBottom: `2px solid ${COLORS.BLACK}`,
      color: COLORS.WHITE,
      '&::placeholder': {
        color: COLORS.WHITE,
      },
    },
  },
  button: {
    width: 100,
    alignSelf: 'flex-end',
  },
  spinner: {
    color: COLORS.WHITE,
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    '& svg': {
      color: COLORS.WHITE,
    },
  },
});
