import { useTheme } from '@mui/material';

import { useCommonTipStyles } from '../../hooks';
import { colors } from '../../../../../../../constants/colors';
import { ScreenSizes } from '../../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const commonStyles = useCommonTipStyles(breakpoints);

  return {
    ...commonStyles,
    tipImage: {
      display: 'flex',
      alignSelf: 'center',
      '& img': {
        [breakpoints.down(ScreenSizes.Pc)]: {
          width: 210,
        },
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          width: 130,
        },
      },
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    action: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 2,
      width: 139,
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: '0.001px',
      color: colors.white_80,
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.Pc)]: {
        fontSize: 16,
        rowGap: 1,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        width: 113,
        rowGap: '4px',
        fontSize: 14,
        lineHeight: '20px',
      },
    },
  };
};
