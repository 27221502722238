import { colors } from '../../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
    width: 400,
  },
  tabs: {
    padding: 2,
    background: colors.gray,
    minHeight: 0,
    '& .MuiTabs-scroller': {
      display: 'flex',
    },
    '& .MuiTabs-flexContainer': {
      display: 'inline-flex',
      justifyContent: 'center',
      columnGap: 4,
      width: '100%',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    color: colors.white,
    opacity: 0.4,
    minHeight: 0,
    p: 0,
    '&.Mui-selected': {
      color: colors.white,
      opacity: 1,
    },
  },
  tabContent: {
    height: 332,
  },
  content: {
    height: '100%',
  },
});
