import { ChangeEventHandler, FormEventHandler } from 'react';
import { WithTranslation } from 'react-i18next';
import { FormikErrors, FormikTouched } from 'formik';

import { SocialProviders } from '../../../../interfaces';

export interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface ISignUpPageViewProps extends WithTranslation {
  isDefaultEvent: boolean;
  openPasswordRules: boolean;
  values: IFormValues;
  errors: FormikErrors<IFormValues>;
  touched: FormikTouched<IFormValues>;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onPasswordHandler: (isFocus: boolean) => void;
  hasInitialEmail: boolean;
  loading: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  socialLoading: SocialProviders | string;
  onSocialBtnClick: (provider: SocialProviders) => void;
}

export enum PasswordErrorType {
  MinLength = 'minLength',
  OneLowerCase = 'oneLowerCase',
  OneUpperCase = 'oneUpperCase',
  OneNumber = 'oneNumber',
  Match = 'match',
}
