import { Dispatch, SetStateAction } from 'react';

import { SVGType } from '../../../../../../interfaces';
import { PoiMediaType } from '../../../DashboardContent/interfaces';

export interface IToolboxContent {
  activeToolboxOption: ToolboxOption;
  setActiveToolboxOption: Dispatch<SetStateAction<ToolboxOption>>;
  onCloseToolbox: () => void;
}

export enum ToolboxOption {
  Briefcase = 'briefcase',
  Agenda = 'agenda',
  Calendar = 'calendar',
  Files = 'files',
}

export interface IToolboxDataContent {
  icon: SVGType;
  text: string;
  onClose: () => void;
}

export type ToolboxDataContextType = {
  [key in ToolboxOption]: IToolboxDataContent;
};

export type CheckToolboxOption = (option: ToolboxOption) => boolean;

export type CheckFileType = (type: PoiMediaType) => boolean;

export type UseGetToolboxDataContextType = (params: {
  setActiveToolboxOption: Dispatch<SetStateAction<ToolboxOption>>;
  onCloseToolbox: () => void;
}) => ToolboxDataContextType;
