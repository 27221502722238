import { IStyles } from '../../../../interfaces/styles';

export const classes: IStyles = {
  emptyPlaceholder: {
    fontWeight: 400,
    fontSize: [26, 30],
    textAlign: ['left', 'center'],
    mt: [4, 10],
    mb: 5,
  },
};
