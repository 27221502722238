import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';

import ReadIconWrapper from '../../../../../../components/ReadIconWrapper';

import { IParticipantSearchItem } from './interfaces';

import { useStyles } from './styles';

const ParticipantSearchItem = ({
  classes: propsClasses = {},
  item,
  lastMessage = '',
  isUnreadChannel = false,
  actions = null,
  onClick = () => {},
}: IParticipantSearchItem) => {
  const { root = {}, avatar = {}, name = {} } = propsClasses;

  const classes = useStyles({ isUnreadChannel });
  const rootSx = { ...classes.root, ...root };
  const avatarSx = { ...classes.avatar, ...avatar };
  const nameSx = { ...classes.name, ...name };

  return (
    <Box sx={rootSx} onClick={onClick}>
      <Box sx={classes.infoContainer}>
        <ReadIconWrapper
          sx={classes.unreadChannelWrapper}
          showRedIcon={isUnreadChannel}
        >
          <Avatar
            sx={avatarSx}
            src={item.avatarImage}
            alt={item.avatarString}
          />
        </ReadIconWrapper>
        <Box>
          <Typography sx={nameSx}>{item.fullName}</Typography>
          {!!lastMessage && (
            <Typography sx={classes.lastMessage}>{lastMessage}</Typography>
          )}
        </Box>
      </Box>
      {actions}
    </Box>
  );
};

export default ParticipantSearchItem;
