import React from 'react';

import { Badge } from '@mui/material';

import { IReadIconWrapper } from './interfaces';

import { classes } from './styles';

const ReadIconWrapper = ({
  sx = {},
  children,
  showRedIcon = false,
}: IReadIconWrapper) => {
  const rootClass = { ...classes.root, ...sx };

  return (
    <Badge
      color="secondary"
      variant="dot"
      overlap="circular"
      invisible={!showRedIcon}
      sx={rootClass}
    >
      {children}
    </Badge>
  );
};

export default ReadIconWrapper;
