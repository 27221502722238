import { colors } from '../../../../../constants/colors';

export const useStyles = (imageWidth: number) => ({
  tools: {
    height: 48,
    width: '100%',
    background: colors.gray,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingInline: 2,
    columnGap: 1,
    justifyContent: 'space-between',
    position: 'static',
    '& button': {
      p: 0,
    },
  },
  toolsLink: {
    width: '100%',
    height: 32,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
    },

    '& svg': {
      width: 16,
      height: 16,
      color: '#ACACAC !important',
    },
    '& button': {
      mr: 1,
    },
  },

  toolsButtonWrapper: {
    '& button': {
      padding: 0,
      '& p': {
        color: colors.white,
        textTransform: 'capitalize',
        p: 0,
        pl: 1,
        fontWeight: 500,
        fontSize: 16,
      },
    },
    minWidth: 'max-content',
  },

  toolsUrlControls: {
    columnGap: 2,
    display: 'flex',
  },
  toolsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    overflow: 'hidden',
  },
  imageWrapper: {
    overflow: 'scroll',
    width: '100%',
    height: '100%',
    mt: 1,
    display: 'grid',
    '& img': {
      width: `${imageWidth}%`,
      placeSelf: 'center',
      alignSelf: 'center',
    },
  },
});
