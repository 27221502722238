import React, { useState } from 'react';

import InitialMuteModalView from './InitialMuteModalView';

import { saveMuteModalHidden } from '../../helpers';

import { IInitialMuteModal } from './interfaces';

const InitialMuteModal = ({
  onClose,
  muted,
  setMuted,
  eventId,
}: IInitialMuteModal) => {
  const [isMuteUpdated, setIsMuteUpdated] = useState(false);
  const [hideModalNextTime, setHideModalNextTime] = useState(false);

  const handleMuteUpdate = (isMuted: boolean) => {
    setMuted(isMuted);
    setIsMuteUpdated(true);
  };

  const handleContinue = () => {
    if (hideModalNextTime) {
      saveMuteModalHidden(eventId);
    }
    onClose();
  };

  const handleHideModalNextTime = () => {
    setHideModalNextTime((value) => !value);
  };

  return (
    <InitialMuteModalView
      onClose={onClose}
      muted={muted}
      isMuteUpdated={isMuteUpdated}
      onMuteUpdate={handleMuteUpdate}
      onContinue={handleContinue}
      hideModalNextTime={hideModalNextTime}
      onHideModalNextTime={handleHideModalNextTime}
    />
  );
};

export default InitialMuteModal;
