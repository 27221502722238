import { colors } from '../../../../../../../../constants/colors';

export const useStyles = () => ({
  groupRemoveIcon: {
    color: colors.red,
  },
  restartIcon: {
    color: colors.white_40,
  },
  groupAddIcon: {
    color: colors.green,
  },
});
