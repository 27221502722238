import { IParticipantInfo } from '../../../../interfaces/participants';
import { FollowingStatus } from '../../../../interfaces/event';
import { IMapFollower, MapFollowersType } from './interfaces';
import { IFollowerData } from '../../../../interfaces/followers';
import { WsConnectionClientType } from '../../../../interfaces';

export const getUnmutedParticipants = (
  participantsInfo: IParticipantInfo[],
): string[] => {
  const unmutedParticipants = participantsInfo.reduce<string[]>(
    (ids, participantInfo) => {
      if (participantInfo.muted) {
        return ids;
      }

      return [...ids, participantInfo.participantId];
    },
    [],
  );

  return unmutedParticipants;
};

export const mapFollowers: MapFollowersType = (followers) => {
  const mapData = followers.reduce<IMapFollower>(
    (acc, follower) => {
      if (follower.clientType === WsConnectionClientType.UE) return acc;

      const followerData: IFollowerData = {
        adminId: follower.following,
        userId: follower.participantId,
      };

      if (follower.followingStatus === FollowingStatus.Pending) {
        acc.pending.push(followerData);
      } else {
        acc.accepted.push(followerData);
      }

      return acc;
    },
    {
      pending: [],
      accepted: [],
    },
  );

  return mapData;
};
