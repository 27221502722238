import * as Yup from 'yup';

import { PASSWORD_ERROR_MESSAGES } from '../../components/PasswordValidation';

import { IFormValues } from './interfaces';

const { minLength, oneLowerCase, oneUpperCase, oneNumber, match } =
  PASSWORD_ERROR_MESSAGES;

export const RecoveryPasswordSchema: Yup.SchemaOf<IFormValues> =
  Yup.object().shape({
    password: Yup.string()
      .min(8, minLength)
      .matches(/[a-z]/, oneLowerCase)
      .matches(/[A-Z]/, oneUpperCase)
      .matches(/\d/, oneNumber)
      .defined(),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password')], match)
      .defined(),
  });
