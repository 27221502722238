import React, { RefObject } from 'react';

import { Box, FormControl, Input, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { usePreventGameKeydownListening } from '../../hooks';

import { IGameInput } from './interfaces';

import { classes } from './styles';

const GameInput = ({
  showError = false,
  errorMessage = '',
  rootSx = {},
  ...props
}: IGameInput) => {
  const ref = usePreventGameKeydownListening<HTMLInputElement>(
    props.ref as RefObject<HTMLInputElement>,
  );

  return (
    <FormControl sx={rootSx} error={showError}>
      <Input {...props} ref={ref} />
      {showError && !!errorMessage && (
        <Box sx={classes.errorContainer}>
          <CancelIcon sx={classes.errorIcon} />
          <Typography sx={classes.errorText}>{errorMessage}</Typography>
        </Box>
      )}
    </FormControl>
  );
};

export default GameInput;
