import { useCallback, useEffect, useState } from 'react';

import { SwiperSettings } from '../constants';

import { SwiperScreenSizes } from '../../../interfaces';

export const useDetectSwiperLoop = (skinsLength: number) => {
  const [loop, setLoop] = useState<boolean | null>(null);

  const handleListener = useCallback(() => {
    if (window.innerWidth > SwiperScreenSizes.Pc) {
      setLoop(SwiperSettings[SwiperScreenSizes.Pc] <= skinsLength);
      return;
    }

    if (window.innerWidth > SwiperScreenSizes.Laptop) {
      setLoop(SwiperSettings[SwiperScreenSizes.Laptop] <= skinsLength);
      return;
    }

    if (window.innerWidth > SwiperScreenSizes.Tablet) {
      setLoop(SwiperSettings[SwiperScreenSizes.Tablet] <= skinsLength);
      return;
    }

    setLoop(SwiperSettings[SwiperScreenSizes.Mobile] < skinsLength);
  }, [skinsLength]);

  useEffect(() => {
    handleListener();
    window.addEventListener('resize', handleListener, false);

    return () => {
      window.removeEventListener('resize', handleListener, false);
    };
  }, [handleListener]);

  return loop;
};
