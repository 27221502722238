import React from 'react';
import { withTranslation } from 'react-i18next';

import { Typography, Box, CircularProgress } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

import { IEmailConfirmedView } from './interfaces';

import { useStyles } from './styles';

const EmailConfirmedPageView = ({
  isConfirmLoading,
  timer,
  t: translate,
}: IEmailConfirmedView) => {
  const classes = useStyles();

  const title = isConfirmLoading
    ? 'emailConfirmed.loadingTitle'
    : 'emailConfirmed.successTitle';

  return (
    <Box sx={classes.root}>
      {isConfirmLoading ? (
        <CircularProgress size={32} sx={classes.icon} />
      ) : (
        <VerifiedIcon sx={classes.icon} />
      )}
      <Typography sx={classes.title}>{translate(title)}</Typography>
      <Typography sx={classes.teleport}>
        {isConfirmLoading ? (
          translate('emailConfirmed.loadingSubtitle')
        ) : (
          <>
            {translate('emailConfirmed.successSubtitle')} {timer}
          </>
        )}
      </Typography>
    </Box>
  );
};

export default withTranslation('common')(EmailConfirmedPageView);
