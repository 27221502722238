import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../components/Notification';

import { getNotification } from '../components/Notification/helpers';
import { getHasMultipleWsWebAppConnection } from '../helpers/websocket';

import ParticipantsService from '../services/ParticipantsService';

export const useCheckUniqueApplicationAccessPerAccount = () => {
  const timerIdRef = useRef<NodeJS.Timer | null>(null);
  const { showNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  useEffect(() => {
    const checkUniqueAccess = async () => {
      const { wsConnections } =
        await ParticipantsService.getCurrentParticipantProfile();
      const hasMultipleWsConnection =
        getHasMultipleWsWebAppConnection(wsConnections);

      if (hasMultipleWsConnection) {
        showNotification(
          getNotification({
            message: translate('notifications.notUniqueAppAccess'),
            type: NOTIFICATION_TYPES.WARNING,
          }),
        );
      }
    };

    if (!timerIdRef.current) {
      checkUniqueAccess();
      timerIdRef.current = setInterval(checkUniqueAccess, 30000);
    }

    return () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
        timerIdRef.current = null;
      }
    };
  }, [showNotification, translate]);
};
