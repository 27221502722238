import { SxProps } from '@mui/material';
import { COLORS } from '../../../../constants/colors';

export const useStyles = () => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: [280, 640],
    height: 440,
    border: `2px solid ${COLORS.BLACK}`,
    boxShadow: 24,
    p: ['20px 10px', 3],
    pt: 2.5,
    backdropFilter: 'blur(12px)',
    backgroundColor: COLORS.NEW_BLACK,
  } as SxProps,

  title: {
    width: '100%',
    pb: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
    '& svg': {
      color: COLORS.LIGHT_GREY,
    },
  },

  titleName: {
    textAlign: 'left',
    color: COLORS.WHITE,
    fontSize: 18,
    lineHeight: 3,
  },

  titleButton: {
    '& svg': {
      color: COLORS.WHITE,
    },
  },

  slider: {
    '& > span': {
      marginTop: '40px',
    },
    '& span': {
      color: COLORS.MYTAVERSE_BLUE,
    },
    '& p': {
      color: COLORS.WHITE,
      fontSize: 12,
    },
  },
});
