import React from 'react';

import { CircularProgress } from '@mui/material';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import CircleIcon from '@mui/icons-material/Circle';

import { ILoadingStepIcon } from './interfaces';

import { classes } from './styles';

const LoadingStepIcon = ({ loading, completed }: ILoadingStepIcon) => {
  if (completed) {
    return <CircleIcon sx={classes.completed} />;
  }

  if (loading) {
    return (
      <CircularProgress size="inherit" thickness={9} sx={classes.loading} />
    );
  }

  return <TripOriginIcon sx={classes.default} />;
};

export default LoadingStepIcon;
