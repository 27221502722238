import React from 'react';

import { Box, SwipeableDrawer } from '@mui/material';

import EventDrawerHeader from './EventDrawerHeader';
import EventDrawerContent from './EventDrawerContent';

import { IEventDrawerView } from './interfaces';

import { classes } from './styles';

const EventDrawerView = ({
  isOpen,
  onOpenDrawer,
  onCloseDrawer,
}: IEventDrawerView) => {
  return (
    <SwipeableDrawer
      anchor="left"
      open={isOpen}
      onClose={onCloseDrawer}
      onOpen={onOpenDrawer}
      sx={classes.root}
    >
      <Box sx={classes.content} component="div">
        <Box sx={classes.backdrop} component="div">
          <Box sx={classes.main} component="div">
            <EventDrawerHeader />
            <EventDrawerContent />
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default EventDrawerView;
