import React, { SyntheticEvent } from 'react';

import EventTabsView from './EventTabsView';

import { useMytaverseEvent } from '../../../providers';

import { EventDrawerTabs } from '../../../constants';

const EventTabs = () => {
  const { leftMenuTab, setLeftMenuTab } = useMytaverseEvent();

  const handleChangeTab = async (_: SyntheticEvent, value: EventDrawerTabs) => {
    setLeftMenuTab(value);
  };

  return (
    <EventTabsView activeTab={leftMenuTab} onChangeTab={handleChangeTab} />
  );
};

export default EventTabs;
