import { nanoid } from 'nanoid';

import $api from '../http/axios';

import { IParticipant, ParticipantState } from '../interfaces/participants';
import { IProfile } from '../interfaces/profile';

export default class ParticipantsService {
  static getParticipantRoom(participantId: string): Promise<{
    id: string;
    roomId: string;
  }> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/participants/${participantId}`,
      )
      .then((res) => res.data);
  }

  static getEmptyParticipant(participantId: string): IParticipant {
    return {
      id: participantId,
      key: nanoid(10),
      userId: participantId,
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      avatarString: '',
      avatarImage: '',
      speaking: false,
      eventId: null,
      gameSessionId: null,
      roomId: null,
      region: null,
      isSpeaker: false,
      regions: [],
      state: ParticipantState.Loading,
    };
  }

  static getCurrentParticipantProfile(): Promise<IProfile> {
    return $api
      .get(`${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile`)
      .then((res) => res.data);
  }
}
