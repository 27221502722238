import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useMytaverseEvent } from '../../providers';
import { useCallback } from 'react';
import MillicastService from '../HomeBottomBar/ShareScreen/helpers';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  const { children, ...slideProps } = props;

  return (
    <Slide direction="up" ref={ref} {...slideProps}>
      {children}
    </Slide>
  );
});

const LeaveRegionDialog = () => {
  const {
    openLeaveRegionDialog,
    setOpenLeaveRegionDialog,
    shareScreenPublishers,
    shareVideoPublishers,
    stopShareVideo,
    handleStopScreenStream,
  } = useMytaverseEvent();

  const classes = useStyles();

  const handleStopPublishedStreams = useCallback(async () => {
    if (shareScreenPublishers?.length) {
      shareScreenPublishers.forEach((s) =>
        handleStopScreenStream(s.options.mediaStream.id),
      );
    }

    if (shareVideoPublishers) {
      await MillicastService.stopStream(shareVideoPublishers);
      await stopShareVideo();
    }

    setOpenLeaveRegionDialog(false);
  }, [
    handleStopScreenStream,
    shareScreenPublishers,
    setOpenLeaveRegionDialog,
    shareVideoPublishers,
    stopShareVideo,
  ]);

  const handleKeepSharing = useCallback(() => {
    setOpenLeaveRegionDialog(false);
  }, [setOpenLeaveRegionDialog]);

  return (
    <Dialog
      open={openLeaveRegionDialog}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      onBackdropClick={() => false}
      sx={classes.root}
    >
      <DialogTitle>{'Do you want to close your active streams?'}</DialogTitle>
      <DialogContent />
      <DialogActions>
        <Button onClick={handleStopPublishedStreams}>Stop</Button>
        <Button onClick={handleKeepSharing}>Keep sharing</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveRegionDialog;
