import React from 'react';

import { Box } from '@mui/material';

import ReactionItem from './ReactionItem';

import { useReactions } from './ReactionItem/hooks';
import { useMytaverseEvent } from '../../../../../providers';

import { useStyles } from './styles';

const Reactions = () => {
  const classes = useStyles();

  const reactions = useReactions();
  const { setReaction } = useMytaverseEvent();

  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        {reactions.map((reaction) => (
          <ReactionItem
            key={reaction.id}
            reaction={reaction}
            onClick={() => setReaction(reaction.id)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Reactions;
