import React, { useEffect } from 'react';

import { Box, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import GameCastLoader from './GameCastLoader';

import { useMytaverseEvent } from '../../../providers';
import { useGameCast } from './GameCastProvider';
import { useConference } from '../Dolby';

import { useStyles } from './styles';

const GameCast = () => {
  const { videoRef, streamSession } = useGameCast();
  const {
    currentRoom,
    setSharingWindowFullscreen,
    sharingWindowFullsceen,
    isOpenLeftMenu,
  } = useMytaverseEvent();
  const classes = useStyles(isOpenLeftMenu);

  const { screenMediaStreams } = useConference();

  useEffect(() => {
    if (streamSession && currentRoom && streamSession.attachClientInput) {
      streamSession.attachClientInput();
    }
  }, [streamSession, currentRoom]);

  return (
    <>
      {currentRoom ? null : <GameCastLoader />}
      <Box sx={{ visibility: currentRoom ? 'visible' : 'hidden' }}>
        {screenMediaStreams.length ? (
          <IconButton
            sx={classes.fullscreen}
            id="gameFull"
            onClick={setSharingWindowFullscreen}
          >
            {sharingWindowFullsceen ? (
              <FullscreenExitIcon />
            ) : (
              <FullscreenIcon />
            )}
          </IconButton>
        ) : null}
        <video playsInline autoPlay ref={videoRef} />
      </Box>
    </>
  );
};

export default GameCast;
