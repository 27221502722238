import React from 'react';

import EmailConfirmedPageView from './EmailConfirmedPageView';

import { useConfirmEmail } from './hooks';

const EmailConfirmedPage = () => {
  const { isConfirmLoading, timer } = useConfirmEmail();

  return (
    <EmailConfirmedPageView isConfirmLoading={isConfirmLoading} timer={timer} />
  );
};

export default EmailConfirmedPage;
