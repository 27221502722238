import React from 'react';

import { useNavigate } from 'react-router-dom';

import SelectEventItemView from './SelectEventItemView';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';

import { sendCustomSentryBreadcrumb } from '../../../../../helpers/sentry';

import ROUTES from '../../../../../constants/routes';

import { ISelectEventItem } from './interfaces';

const SelectEventItem = ({ event }: ISelectEventItem) => {
  const { setCurrentEventId } = useMytaverse();
  const navigate = useNavigate();

  const handleClick = () => {
    sendCustomSentryBreadcrumb({ message: `select event "${event.name}"` });
    setCurrentEventId(event.id);
    navigate(ROUTES.AVATAR_CHOOSING);
  };

  return <SelectEventItemView event={event} onClick={handleClick} />;
};

export default SelectEventItem;
