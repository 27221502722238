import { colors } from '../../../../constants/colors';

export const useStyles = (hideModalNextTime: boolean) => ({
  iconContainer: {
    display: 'flex',
    background: colors.white,
    backdropFilter: 'blur(6px)',
    borderRadius: 9,
    padding: 1,
    '& svg': {
      color: '#000000',
      width: 24,
      height: 24,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '36px',
    letterSpacing: '-0.25px',
    color: colors.white,
    marginTop: '9px',
  },
  description: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '30px',
    letterSpacing: '-0.25px',
    color: '#A6B1C9',
    marginTop: '9px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '33px',
    marginTop: '44px',
  },
  checkboxContainer: {
    marginTop: '23px',
  },
  checkboxLabel: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '27px',
    letterSpacing: '-0.25px',
    color: colors.white,
    opacity: hideModalNextTime ? 1 : 0.5,
    margin: 0,
  },
  checkbox: {
    color: colors.white,
    opacity: hideModalNextTime ? 1 : 0.5,
    '&.Mui-checked': {
      color: colors.white,
    },
  },
});
