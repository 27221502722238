import React from 'react';

import { Box, Typography } from '@mui/material';

import { IToolItem } from './interfaces';
import { useStyles } from './styles';

const ToolItem = ({ icon: Icon, name, description, onClick }: IToolItem) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root} onClick={onClick}>
      <Box sx={classes.iconContainer}>
        <Icon />
      </Box>
      <Box sx={classes.content}>
        <Typography sx={classes.name}>{name}</Typography>
        <Typography sx={classes.description}>{description}</Typography>
      </Box>
    </Box>
  );
};

export default ToolItem;
