import React, { useEffect } from 'react';
import HomeBottomButtonOptionsCamView from './HomeBottomButtonOptionsCamView';

const HomeBottomButtonOptionsCam = ({
  selectedCam,
}: {
  selectedCam: string;
}) => {
  const streamCamVideo = () => {
    const constraints = {
      audio: false,
      video: { deviceId: { exact: selectedCam } },
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((mediaStream) => {
        const video: any = document.getElementById('videoElement');

        video.srcObject = mediaStream;
        video.onloadedmetadata = () => {
          video.play();
        };
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(`${err.name}: ${err.message}`);
      });
  };

  useEffect(() => {
    streamCamVideo();
  }, []);

  return <HomeBottomButtonOptionsCamView />;
};

export default HomeBottomButtonOptionsCam;
