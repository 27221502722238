import { colors } from '../../../../../constants/colors';
import { SLIDER_WIDTH } from '../constants';

import { IStyles } from '../../../../../interfaces/styles';

export const useStyles = (): IStyles => {
  return {
    root: {
      width: SLIDER_WIDTH,
      backgroundColor: colors.white,
      borderRadius: 2,
      border: '1px solid #8E8E8E',
      boxSizing: 'border-box',
      boxShadow: 'none',
      cursor: 'pointer',
    },
    image: {
      objectFit: 'cover',
      borderBottom: '1px solid #8E8E8E',
    },
    content: {
      textAlign: 'center',
      padding: '12px 0px',
      '&:last-child': {
        padding: '12px 0px',
      },
    },
    eventName: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '30px',
      color: colors.oxford_60,
    },
  };
};
