import { useMytaverse } from '../../../../../providers/MytaverseProvider';

import { colors } from '../../../../../constants/colors';

import { IStyles } from '../../../../../interfaces/styles';

export const useStyles = (): IStyles => {
  const { appTheme } = useMytaverse();

  const { loginButtonBg, loginButtonBgHover } = appTheme.loginPage.assets;

  return {
    root: {
      width: '100%',
      height: 56,
      padding: '15px 24px',
      backgroundColor: loginButtonBg,
      borderRadius: '4px',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1,
      color: colors.white,
      textTransform: 'capitalize',
      transition: 'none',
      boxShadow: 'none',
      '&:hover': {
        transition: 'none',
        boxShadow: 'none',
        backgroundColor: loginButtonBgHover,
      },
      '&:active': {
        transition: 'none',
        boxShadow: 'none',
        backgroundColor: loginButtonBg ?? colors.mytaDark, // TODO removed colors.mytaDark when loginButtonBgClicked will be added
      },
    },
  };
};
