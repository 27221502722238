import React, { SyntheticEvent } from 'react';

import ChatButtonView from './ChatButtonView';

import { useChatState } from '../../../../../hooks/context';

const ChatButton = () => {
  const { setOpen, isUnreadEventChannel, isUnreadPrivateChannel } =
    useChatState();

  return (
    <ChatButtonView
      handleClick={(event: SyntheticEvent<HTMLButtonElement>) => {
        setOpen(true);
        event.currentTarget.blur();
      }}
      showUnreadIcon={isUnreadEventChannel || isUnreadPrivateChannel}
    />
  );
};

export default ChatButton;
