import React from 'react';

import { Box } from '@mui/material';

import { ISelectAvatarSkinItem } from './interfaces';

import { useStyles } from './styles';

const SelectAvatarSkinItem = ({
  src,
  alt,
  onClick,
  sx = {},
}: ISelectAvatarSkinItem) => {
  const classes = useStyles();
  const rootSx = { ...classes.root, ...sx };

  return (
    <Box sx={rootSx} onClick={onClick}>
      <img src={src} alt={alt} />
    </Box>
  );
};

export default SelectAvatarSkinItem;
