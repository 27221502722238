import React, { useState } from 'react';

import LoadingProgress from '../../../../../../components/LoadingProgress';
import PrivateChatView from './PrivateChatView';

import { useGetActiveChannelData } from './hooks';
import { useChatState } from '../../../../../../hooks/context';

const PrivateChat = () => {
  const [showClearBtn, setShowClearBtn] = useState(false);
  const { activePrivateChannel, setActivePrivateChannel } = useChatState();

  const getActiveChannelData = useGetActiveChannelData();
  const activeChannelData = getActiveChannelData(activePrivateChannel);

  const onArrowBtnClick = () =>
    setShowClearBtn((showClearBtn) => !showClearBtn);

  const handleClickBackButton = () => setActivePrivateChannel(null);

  if (!activeChannelData || !activePrivateChannel) {
    return <LoadingProgress />;
  }

  return (
    <PrivateChatView
      participant={activeChannelData.participant}
      channel={activeChannelData.privateChannel.channel}
      showClearBtn={showClearBtn}
      onArrowBtnClick={onArrowBtnClick}
      onClickBackButton={handleClickBackButton}
    />
  );
};

export default PrivateChat;
