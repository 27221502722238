import { useTheme } from '@mui/material';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { colors } from '../../../../constants/colors';

import { IEventThemeTypes, ScreenSizes } from '../../../../interfaces';

const iconStyles = {
  width: 32,
  height: 32,
};

export const useStyles = () => {
  const { appTheme } = useMytaverse();
  const { breakpoints } = useTheme();

  return {
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      rowGap: 2,
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.Tablet)]: {
        width: '80%',
        justifySelf: 'center',
      },
    },
    successIcon: {
      ...iconStyles,
      color: colors.myta,
    },
    failedIcon: {
      ...iconStyles,
      color: colors.red,
    },
    title: {
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '28px',
      color:
        appTheme.themeName === IEventThemeTypes.DARK
          ? colors.white
          : colors.oxford,
      [breakpoints.down(ScreenSizes.Tablet)]: {
        fontSize: 24,
        lineHeight: 1.17,
      },
    },
    teleport: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: 1.17,
      color:
        appTheme.themeName === IEventThemeTypes.DARK
          ? colors.white
          : colors.oxford_60,
      [breakpoints.down(ScreenSizes.Tablet)]: {
        fontSize: 16,
        lineHeight: 1.75,
      },
    },
    // TODO uncomment when support will be ready
    // support: {
    //   fontWeight: 400,
    //   fontSize: 24,
    //   lineHeight: 1.17,
    //   display: 'inline',
    //   color: colors.cyan,
    //   cursor: 'pointer',
    // },
  };
};
