import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import LoadingProgress from '../../../../components/LoadingProgress';
import DevicesTestWrapper from '../DevicesTestWrapper';
import ProgressBarSelection from '../ProgressBarSelection';
import MicrophoneTestProgressBar from './MicrophoneTestProgressBar';

import { useInitMicrophonesDevices } from './hooks';

import { useStyles } from './styles';

const MicrophoneTest = () => {
  const classes = useStyles();
  const {
    initializing,
    devices,
    activeDeviceId,
    setActiveDeviceId,
    permissionState,
  } = useInitMicrophonesDevices();
  const { t: translate } = useTranslation('common');

  const title = translate('devicesTest.microTest.title');
  const description = translate('devicesTest.microTest.description');

  if (initializing) {
    return <LoadingProgress />;
  }

  return (
    <DevicesTestWrapper title={title} description={description}>
      <Box sx={classes.selectionContainer}>
        <MicrophoneTestProgressBar
          activeDeviceId={activeDeviceId}
          permissionState={permissionState}
        />
        <ProgressBarSelection
          devices={devices}
          activeDeviceId={activeDeviceId}
          onDeviceChange={setActiveDeviceId}
          sx={classes.selection}
        />
      </Box>
      <Typography sx={classes.helper}>
        {translate('devicesTest.microTest.helper')}
      </Typography>
    </DevicesTestWrapper>
  );
};

export default MicrophoneTest;
