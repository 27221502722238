import React from 'react';

import FloorView from './FloorView';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { IEventThemeTypes } from '../../../../interfaces';

const Floor = () => {
  const { appTheme } = useMytaverse();

  const background = appTheme.loginPage.components.background;
  const isDefaultEvent = appTheme.themeName === IEventThemeTypes.DEFAULT;

  return <FloorView src={background} isDefaultEvent={isDefaultEvent} />;
};

export default Floor;
