import { colors } from '../../../../../constants/colors';

export const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    rowGap: 2,
    textAlign: 'center',
  },
  title: {
    fontWeight: 500,
    fontSize: 32,
    lineHeight: '28px',
    color: colors.oxford,
    opacity: 0.8,
  },
};
