import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useMytaverseEvent } from '../../providers';
import { useCallback } from 'react';
import MillicastService from '../HomeBottomBar/ShareScreen/helpers';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  const { children, ...slideProps } = props;

  return (
    <Slide direction="up" ref={ref} {...slideProps}>
      {children}
    </Slide>
  );
});

const DolbyCameraDialog = () => {
  const {
    openCameraPublisherDialog,
    setOpenCameraPublisherDialog,
    shareVideoPublishers,
    stopShareVideo,
    setOpenShareVideoModal,
  } = useMytaverseEvent();

  const classes = useStyles();

  const handleStopPublishedStream = useCallback(async () => {
    if (shareVideoPublishers) {
      await MillicastService.stopStream(shareVideoPublishers);
      await stopShareVideo();
    }

    setOpenCameraPublisherDialog(false);
    setOpenShareVideoModal(true);
  }, [setOpenCameraPublisherDialog, shareVideoPublishers, stopShareVideo]);

  const handleKeepSharing = useCallback(() => {
    setOpenCameraPublisherDialog(false);
  }, [setOpenCameraPublisherDialog]);

  return (
    <Dialog
      open={openCameraPublisherDialog}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      onBackdropClick={() => false}
      sx={classes.root}
    >
      <DialogTitle>
        {'You already have an active camera stream in the stream panel'}
      </DialogTitle>
      <DialogContent />
      <DialogActions>
        <Button onClick={handleStopPublishedStream}>Stop active stream</Button>
        <Button onClick={handleKeepSharing}>Keep sharing and close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DolbyCameraDialog;
