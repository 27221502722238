import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import HomePageView from './HomePageView';

import { useMytaverseEvent } from '../../providers';
import { useShowOrientationOverlay } from '../../components/OrientationOverlay';
import { useChatHandlers } from '../../hooks/channel';

import ROUTES from '../../../../constants/routes';

const HomePage = () => {
  const {
    showControls,
    currentEvent,
    streamChatToken,
    openShareScreenModal,
    openShareVideoModal,
    openMillicastScreenBroadcastingDataModal,
    openAdminSettingsModal,
  } = useMytaverseEvent();
  const showOverlay = useShowOrientationOverlay();
  const navigate = useNavigate();

  useChatHandlers({ currentEvent, streamChatToken });

  useEffect(() => {
    if (!sessionStorage.getItem('selectedEventId')) {
      navigate(ROUTES.SELECT_EVENT);
    }
  }, [navigate]);

  return (
    <HomePageView
      showOverlay={showOverlay}
      showControls={showControls}
      openMillicastShareScreenModal={openShareScreenModal}
      openMillicastShareVideoModal={openShareVideoModal}
      openMillicastScreenBroadcastingDataModal={
        openMillicastScreenBroadcastingDataModal
      }
      openAdminSettingsModal={openAdminSettingsModal}
    />
  );
};

export default HomePage;
