import React, { useEffect, useState } from 'react';

import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import SpeakerIcon from '@mui/icons-material/Speaker';
import MicIcon from '@mui/icons-material/Mic';

import { TabPanel } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';

import HomeBottomButtonOptionsMic from '../../HomeBottomButtonOptionsMic';
import HomeBottomButtonOptionsSettings from '../../components/HomeBottomButtonOptionsSettings/HomeBottomButtonOptionsSettings';
import LoadingProgress from '../../../../../../components/LoadingProgress';
import PermissionsGuide from '../../../../../../public/images/ChromeOperation.jpg';
import { DevicesSettingsSoundViewProps } from './interfaces';
import { useStyles } from './styles';

const DevicesSettingsSoundView = ({
  microphones,
  speakers,
  selectedMicrophone,
  selectMicrophone,
  selectedSpeaker,
  selectSpeaker,
  changingDevice,
  translate,
  play,
  stop,
  duration,
  isTestingSound,
  setIsTestingSound,
}: DevicesSettingsSoundViewProps) => {
  const classes = useStyles();
  const [showGuide, setShowGuide] = useState(false);

  useEffect(() => {
    navigator.permissions
      .query({
        name: 'microphone' as any,
      })
      .then((permissionStatus) => {
        if (permissionStatus.state !== 'granted') {
          setShowGuide(true);
        }
      });
  }, []);

  return (
    <TabPanel value="sound" sx={classes.root}>
      {showGuide ? (
        <Box sx={classes.permissionsWrapper}>
          <img src={PermissionsGuide} alt="" />
        </Box>
      ) : (
        <>
          <HomeBottomButtonOptionsSettings
            devices={microphones}
            handleSelect={async (e) => await selectMicrophone(e.target.value)}
            label={
              <Box sx={classes.bottomSettings} component="div">
                {translate('settingsModal.mic')}
              </Box>
            }
            selectedValue={selectedMicrophone}
            SelectIcon={MicIcon}
          />
          {changingDevice ? (
            <LoadingProgress />
          ) : (
            <HomeBottomButtonOptionsMic
              selectedMicrophone={selectedMicrophone}
            />
          )}
          <HomeBottomButtonOptionsSettings
            devices={speakers}
            handleSelect={async (e) => await selectSpeaker(e.target.value)}
            label={translate('settingsModal.speakers')}
            selectedValue={selectedSpeaker}
            SelectIcon={SpeakerIcon}
          />
          <Button
            sx={classes.box}
            onClick={
              isTestingSound
                ? () => {
                    setIsTestingSound(false);

                    setTimeout(() => {
                      setIsTestingSound(false);
                    }, duration as number);

                    stop();
                  }
                : () => {
                    setIsTestingSound(true);

                    setTimeout(() => {
                      setIsTestingSound(false);
                    }, duration as number);

                    play();
                  }
            }
          >
            {isTestingSound ? (
              <VolumeOffIcon />
            ) : (
              <VolumeUpIcon sx={classes.fontColor} />
            )}
            <Typography sx={classes.fontColor}>
              {translate('settingsModal.checkSound')}
            </Typography>
          </Button>
        </>
      )}
    </TabPanel>
  );
};

export default DevicesSettingsSoundView;
