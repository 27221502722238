export const classes = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    padding: '8px 24px',
    width: '100%',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    padding: '8px 24px',
  },
  leftBar: {
    width: 'auto',
    left: 0,
    right: 'unset',
  },
  rightBar: {
    width: 'auto',
    left: 'unset',
    right: 0,
  },
};
