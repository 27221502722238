import React from 'react';

import SortIcon from '@mui/icons-material/Sort';

import SelectDropdown, {
  ISelectDropdownData,
  useSelectDropdown,
} from './SelectDropdown';

import { SORT_DATA } from '../../constants';

import { ISortDropdown } from './interfaces';
import { ISortDataValue } from '../../interfaces';

import { classes } from './styles';

const SortDropdown = ({
  activeSortField,
  activeSortType,
  activeSortDirection,
  onSort,
}: ISortDropdown) => {
  const { anchorEl, open, handleClick, handleClose } = useSelectDropdown();

  const activeItem = SORT_DATA.find(
    ({ value }) =>
      value.fieldName === activeSortField &&
      value.type === activeSortType &&
      value.direction === activeSortDirection,
  ) as ISelectDropdownData<ISortDataValue>;

  const handleSelect = (item: ISelectDropdownData<ISortDataValue>) => {
    onSort(item.value);
    handleClose();
  };

  return (
    <>
      <SortIcon onClick={handleClick} sx={classes.icon} />
      {open && (
        <SelectDropdown
          anchorEl={anchorEl as SVGSVGElement}
          data={SORT_DATA}
          activeItem={activeItem}
          onClick={handleSelect}
          onClose={handleClose}
          placement="bottom-start"
        />
      )}
    </>
  );
};

export default SortDropdown;
