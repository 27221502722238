import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';

import { useMytaverse } from '../../providers/MytaverseProvider';

import { IAppLogo } from './interfaces';

import { classes } from './styles';

const AppLogo = ({ to }: IAppLogo) => {
  const { appTheme } = useMytaverse();
  const Logo = appTheme.customerLogo;

  return (
    <Link to={to}>
      <Box sx={classes.logo}>
        {typeof Logo === 'string' ? <img src={Logo} alt="" /> : Logo}
      </Box>
    </Link>
  );
};

export default AppLogo;
