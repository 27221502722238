import { useCallback } from 'react';

import { useMytaverseEvent } from '../modules/dashboard/providers';
import { useMytaverse } from '../providers/MytaverseProvider';
import { useChatState } from './context';

import { getExistedPrivateChannel } from '../helpers/channel';
import { getCatchErrorMessage } from '../helpers/error';

import ChannelService from '../services/ChannelService';

import {
  ChatTabs,
  GetChannelProps,
  IEventChannel,
  IPrivateChannel,
  IStreamChannel,
  IStreamChat,
} from '../interfaces/channel';

export const useStreamChannels = () => {
  const getStreamChannels = useCallback(
    async ({
      streamClient,
      userId,
      eventId,
      channelId = '',
    }: GetChannelProps): Promise<IStreamChannel[] | undefined> => {
      const finalChannelId = channelId
        ? `mytaverse-event-${eventId}-private-${channelId}`
        : `mytaverse-event-${eventId}`;

      const channels = await streamClient.queryChannels({
        type: 'messaging',
        id: finalChannelId,
        members: { $in: [userId] },
      });

      return channels;
    },
    [],
  );

  const getEventStreamChannel = async ({
    streamClient,
    userId,
    eventId,
  }: GetChannelProps): Promise<IStreamChannel | null> => {
    const channels = await getStreamChannels({ streamClient, userId, eventId });
    const eventStreamChannel = channels?.length === 1 ? channels[0] : null;

    return eventStreamChannel;
  };

  const getMapEventChannel = async (
    streamClient: IStreamChat,
    eventChannel: IEventChannel,
    currentUserId: string,
  ): Promise<IPrivateChannel> => {
    const channels = await getStreamChannels({
      streamClient,
      userId: currentUserId,
      eventId: eventChannel.eventId,
      channelId: eventChannel.id,
    });
    const channel = channels?.[0] as IStreamChannel;

    return { ...eventChannel, channel };
  };

  const getMapEventChannels = async (
    streamClient: IStreamChat,
    eventChannels: IEventChannel[],
    currentUserId: string,
  ): Promise<IPrivateChannel[]> => {
    const mapEventChannels = await Promise.all(
      eventChannels.map(async (eventChannel) => {
        const mapEventChannel = await getMapEventChannel(
          streamClient,
          eventChannel,
          currentUserId,
        );

        return mapEventChannel;
      }),
    );

    return mapEventChannels;
  };

  return {
    getStreamChannels,
    getEventStreamChannel,
    getMapEventChannel,
    getMapEventChannels,
  };
};

export const useRedirectToPrivateChat = () => {
  const { currentEvent } = useMytaverseEvent();
  const { user } = useMytaverse();
  const { setOpen: setOpenChat } = useChatState();
  const {
    streamClient,
    setActiveTab,
    privateChannels,
    addPrivateChannel,
    setActivePrivateChannel,
  } = useChatState();
  const { getStreamChannels } = useStreamChannels();

  const createEventChannel = useCallback(
    async (participantUserId: string): Promise<IEventChannel | undefined> => {
      try {
        const userIDs = [user.id, participantUserId];

        const eventChannel = await ChannelService.createEventChannel(
          currentEvent?.id || '',
          userIDs,
        );

        return eventChannel;
      } catch (error) {
        throw Error(getCatchErrorMessage(error));
      }
    },
    [currentEvent?.id, user?.id],
  );

  const redirectToPrivateChat = useCallback(
    async (participantUserId: string) => {
      let privateChannel = getExistedPrivateChannel(
        privateChannels,
        participantUserId,
      );

      if (!privateChannel) {
        const eventChannel = await createEventChannel(participantUserId);

        if (!eventChannel || !streamClient) return;

        const channels = await getStreamChannels({
          streamClient,
          userId: user.id,
          eventId: eventChannel.eventId,
          channelId: eventChannel.id,
        });
        const channel = channels?.[0] as IStreamChannel;
        privateChannel = { ...eventChannel, channel };

        addPrivateChannel(privateChannel);
      }

      setActiveTab(ChatTabs.Private);
      setActivePrivateChannel(privateChannel);
      setOpenChat(true);
    },
    [
      privateChannels,
      setActiveTab,
      setActivePrivateChannel,
      createEventChannel,
      streamClient,
      getStreamChannels,
      user?.id,
      addPrivateChannel,
    ],
  );

  return redirectToPrivateChat;
};

export const useRedirectToGlobalChat = () => {
  const { setOpen: setOpenChat } = useChatState();
  const { setActiveTab } = useChatState();

  const redirectToGlobalChat = () => {
    setActiveTab(ChatTabs.Global);
    setOpenChat(true);
  };

  return redirectToGlobalChat;
};
