import { colors } from '../../../../../../../constants/colors';

export const classes = {
  root: {},
  header: {
    backgroundColor: colors.gray,
    padding: '20px 0px',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: colors.white,
    opacity: 0.4,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 87px',
  },
  icon: {
    width: 226,
    height: 251,
    color: colors.gray,
    opacity: 0.5,
  },
};
