import { useTheme } from '@mui/material';

import { colors } from '../../../../constants/colors';

import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      padding: '44px 24px 52px',
      [breakpoints.down(ScreenSizes.Tablet)]: {
        padding: '24px 24px 32px',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 3,
      textAlign: 'center',
      marginTop: 3,
      '& form': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 3,
        textAlign: 'center',
      },
    },
    title: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: 1.5,
      color: colors.oxford_60,
    },
    subtitle: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.56,
      letterSpacing: '0.25px',
      color: colors.oxford_60,
    },
  };
};
