import { MouseEventHandler } from 'react';

export enum Language {
  En = 'EN',
  Es = 'ES',
  Pt = 'PT',
  Fr = 'FR',
}

export interface ILanguageButtonView {
  anchorEl: HTMLElement | null;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onClose: () => void;
  selectedLanguage: string;
  onItemClick: (lang: Language) => void;
}
