import { IStyles } from '../../../../interfaces/styles';

export const useStyles = (isDefaultEvent: boolean, src: string): IStyles => ({
  background: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: -1111,
    background: 'linear-gradient(180deg, #FAFCFF 0%, #F3F3F3 100%)',
  },
  imageWrapper: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: -2,
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: isDefaultEvent ? 'bottom' : 'center',
    backgroundAttachment: 'scroll',
    backgroundSize: isDefaultEvent ? 'contain' : 'cover',
  },
});
