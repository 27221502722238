import React from 'react';
import { useNavigate } from 'react-router-dom';

import DevicesTestWrapperView from './DevicesTestWrapperView';

import { useDevicesTestState } from '../../pages/DevicesTestPage/hooks/state';

import { DEVICES_TEST_STEPS_COUNT } from '../../constants/devicesSteps';
import ROUTES from '../../../../constants/routes';

import { IDevicesTestWrapper } from './interfaces';

const DevicesTestWrapper = ({
  children,
  title,
  description,
  disabled,
  sx,
}: IDevicesTestWrapper) => {
  const { step, setStep } = useDevicesTestState();
  const navigate = useNavigate();

  const isLastStep = step === DEVICES_TEST_STEPS_COUNT;

  const handleNextStep = () => {
    if (isLastStep) {
      navigate(ROUTES.HOME_PAGE);
    } else {
      setStep(step + 1);
    }
  };

  return (
    <DevicesTestWrapperView
      title={title}
      description={description}
      onNextStep={handleNextStep}
      activeStep={step}
      isLastStep={isLastStep}
      disabled={disabled}
      sx={sx}
    >
      {children}
    </DevicesTestWrapperView>
  );
};

export default DevicesTestWrapper;
