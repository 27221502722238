import avatarNeutral from '../../../../public/welcome/avatar1.png';
import avatarMale from '../../../../public/welcome/avatar2.png';
import avatarFemale from '../../../../public/welcome/avatar3.png';
import avatarMaleNextgen from '../../../../public/welcome/avatar4.png';
import avatarFemaleNextgen from '../../../../public/welcome/avatar5.png';

import { AvatarGender, IAvatar } from '../../../../interfaces/avatar';
import { UnknownObjectType } from '../../../../interfaces';

export const getAvatarImage = (avatar: IAvatar): string => {
  const avatarImage: UnknownObjectType<AvatarGender, string> = {
    [AvatarGender.Female]: avatar.avatarName.includes('MV2-F')
      ? avatarFemaleNextgen
      : avatarFemale,
    [AvatarGender.Male]: avatar.avatarName.includes('MV2-M')
      ? avatarMaleNextgen
      : avatarMale,
  };
  const selectedAvatarImage = avatarImage[avatar.gender];

  return selectedAvatarImage || avatarNeutral;
};
