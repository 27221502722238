import React from 'react';
import { IAvatarSkin } from '../../../../interfaces/avatarSkin';
import { IEvent, IFollower } from '../../../../interfaces/event';
import {
  IParticipant,
  IParticipantRegion,
} from '../../../../interfaces/participants';
import { IRoom } from '../../../../interfaces/rooms';
import { IUser } from '../../../../interfaces/user';
import { SpatialType } from '../../../../interfaces/webSocketConnectionInfo';
import { IFollowerData } from '../../../../interfaces/followers';

export interface MillicastPropsType {
  currentRoom: IRoom | null | undefined;
  currentRegion: IParticipantRegion | null;
  currentParticipant: IParticipant;
  muted: boolean;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface EventParticipantDataType {
  currentEvent?: IEvent;
  participant: IUser;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export type UpdateParticipantType = (
  participantId: string,
  newParticipantInfo: IParticipant,
) => void;

export interface ParticipantsStateHookProps {
  userId: string | undefined | null;
  currentEventId: string | undefined;
  currentParticipant: IParticipant | undefined;
  setCurrentParticipant: React.Dispatch<
    React.SetStateAction<IParticipant | undefined>
  >;
  participants: IParticipant[];
  setParticipants: React.Dispatch<React.SetStateAction<IParticipant[]>>;
  currentRoom: IRoom | null;
  setCurrentRoom: React.Dispatch<React.SetStateAction<IRoom | null>>;
  currentRegion: IParticipantRegion | null;
  setCurrentRegion: React.Dispatch<
    React.SetStateAction<IParticipantRegion | null>
  >;
  rooms: IRoom[];
}

export type SetParticipantStateType = (
  participantId: string,
  data: {
    eventId?: string | null;
    roomId?: string | null;
    region?: IParticipantRegion | null;
    regions?: IParticipantRegion[];
    gameSessionId?: string | null;
    isNewParticipantPositionFlow?: boolean;
  },
) => void;

export type NewRegionsType = {
  newRegion: IParticipantRegion | null;
  newRegions: IParticipantRegion[];
};

export type JoinParticipantToRegionType = (
  participantId: string,
  timestamp: number,
  region: string,
  regionSpatialType?: SpatialType,
) => void;

export type LeaveParticipantFromRegionType = (
  participantId: string,
  timestamp: number,
  region: string,
) => void;

export enum SendMutedStateMessageEnum {
  MUTE = 'MUTE',
  UNMUTE = 'UNMUTE',
}

export enum InitMessageActions {
  INIT = 'INIT',
}

export interface UseInitMessageProps {
  token?: string;
  websocketSessionId: string;
  currentSkin: IAvatarSkin | null;
  dolbyToken: string;
  currentParticipant?: IParticipant;
  currentEvent?: IEvent;
  customAvatarUrl: string | null;
  teleportingToRoom: IRoom | null;
}

export enum StreamingProviders {
  Pureweb = 'PURE_WEB',
  GameCast = 'GAME_CAST',
}

export interface IMapFollower {
  pending: IFollowerData[];
  accepted: IFollowerData[];
}

export type MapFollowersType = (followers: IFollower[]) => IMapFollower;
