import React from 'react';

import {
  FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';

import { HomeBottomButtonOptionsSettingsProps } from './interfaces';
import { useStyles } from './styles';

const HomeBottomButtonOptionsSettings = (
  {
    devices, handleSelect, selectedValue, label, SelectIcon,
  }: HomeBottomButtonOptionsSettingsProps,
) => {
  const classes = useStyles();

  return (
    <FormControl sx={classes.root}>
      <InputLabel
        sx={classes.label}
      >
        {label}
      </InputLabel>
      <Select
        label={label}
        value={selectedValue}
        onChange={handleSelect}
        disableUnderline
        variant="standard"
        sx={classes.select}
        IconComponent={() => <SelectIcon className="selectIcon" />}
      >
        {devices.map((device: any) => (
          <MenuItem
            value={device.deviceId}
            key={device.deviceId}
          >
            {device.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default HomeBottomButtonOptionsSettings;
