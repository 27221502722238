import React, { useEffect } from 'react';

import OverflowTitleView from './OverflowTitleView';

import { useOverflow } from './hooks';

import { IOverflowTitle } from './interfaces';

const OverflowTitle = ({
  title,
  variant,
  component,
  onClick,
  sx,
}: IOverflowTitle) => {
  const { isOverflow, checkOverflow, resetIsOverflow } = useOverflow();

  useEffect(() => {
    resetIsOverflow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <OverflowTitleView
      title={title}
      isOverflow={!!isOverflow}
      checkOverflow={checkOverflow}
      variant={variant}
      component={component}
      onClick={handleClick}
      sx={sx}
    />
  );
};

export default OverflowTitle;
