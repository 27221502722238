import React from 'react';
import { IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { ISelectionArrowButton } from './interfaces';

import { useStyles } from './styles';

const SelectionArrowButton = ({
  onClick,
  downIcon = false,
  sx = {},
}: ISelectionArrowButton) => {
  const classes = useStyles();
  const rootSx = { ...classes.root, ...sx };

  const Icon = downIcon ? ArrowDropDownIcon : ArrowDropUpIcon;

  return (
    <IconButton sx={rootSx} onClick={onClick} disableRipple>
      <Icon sx={classes.icon} />
    </IconButton>
  );
};

export default SelectionArrowButton;
