import React from 'react';
import { Button, Typography } from '@mui/material';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { ISaveFile } from './interfaces';
import { useTranslation } from 'react-i18next';

const SaveFile = ({ downloadUrl }: ISaveFile) => {
  const { t: translate } = useTranslation('common');
  const handleSaveFile = () => {
    window.open(downloadUrl, '_blank');
  };

  return (
    <Button onClick={handleSaveFile}>
      <BrowserUpdatedIcon />
      <Typography>{translate('poiPreview.saveFile')}</Typography>
    </Button>
  );
};

export default SaveFile;
