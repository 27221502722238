import React from 'react';

import { Box } from '@mui/material';

import { Attachment } from 'stream-chat-react';

import { IChatAttachments } from './interfaces';

import { classes } from './styles';

const ChatAttachments = ({ attachments }: IChatAttachments) => {
  return (
    <Box sx={classes.root}>
      <Attachment attachments={attachments} />
    </Box>
  );
};

export default ChatAttachments;
