import { useState } from 'react';

import { UsePaginationType } from './interfaces';

export const usePagination: UsePaginationType = ({
  list,
  itemsPerPage = 10,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageNumber = Math.ceil(list.length / itemsPerPage);

  const getDataPerPage = () => {
    return list.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );
  };

  const handlePrevPage = () => {
    if (currentPage === 1) return;

    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (currentPage === pageNumber) return;

    setCurrentPage((prev) => prev + 1);
  };

  const resetPagination = () => {
    setCurrentPage(1);
  };

  return {
    currentPage,
    pageNumber,
    getDataPerPage,
    handlePrevPage,
    handleNextPage,
    resetPagination,
  };
};
