import { colors } from '../../../../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    '& img': {
      width: 80,
      height: 100,
      border: `0.5px solid ${colors.white_40}`,
      borderRadius: '5px',
      boxSizing: 'border-box',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: colors.white,
  },
});
