import { colors } from '../../../../../constants/colors';

export const classes = {
  root: {
    width: '100%',
    '& .Mui-error': {
      borderColor: colors.red,
    },
  },
  input: {
    width: '100%',
    backgroundColor: colors.white,
    border: `1px solid ${colors.oxford_20}`,
    borderRadius: 1,
    padding: '12px 24px',
    '&::before': {
      content: 'none',
    },
    '&::after': {
      content: 'none',
    },
    '&:hover': {
      '&&::before': {
        content: 'none',
      },
      '&&::after': {
        content: 'none',
      },
    },
    '& input': {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.5px',
      color: colors.oxford,
      backgroundColor: colors.white,
      '&::placeholder': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: '0.5px',
        color: colors.oxford,
        opacity: 0.6,
      },
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
      },
    },
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
  errorText: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.1px',
    color: colors.red,
  },
  errorIcon: {
    width: 14,
    height: 20,
    color: colors.red,
  },
};
