import React from 'react';

import { Stack, Toolbar } from '@mui/material';

import MytaverseAppBar from '../MytaverseAppBar';
import EventsDropdown from '../EventsDropdown';
import UserAvatarButton from '../UserAvatarButton';
import LogoutButton from '../LogoutButton';

import { useMytaverseEvent } from '../../modules/dashboard/providers';

import { ILoggedAppBar } from './interfaces';

import { classes } from './styles';

// old app bar. AppHeader is new one
const LoggedAppBar = ({ sx = {} }: ILoggedAppBar) => {
  const toolbarSx = { ...classes.toolbar, ...sx };

  const { closeLeftMenu } = useMytaverseEvent();

  return (
    <MytaverseAppBar>
      <Toolbar sx={toolbarSx}>
        <EventsDropdown onChangeEvent={closeLeftMenu} />
        <Stack spacing={2} direction="row">
          <UserAvatarButton />
          <LogoutButton />
        </Stack>
      </Toolbar>
    </MytaverseAppBar>
  );
};

export default LoggedAppBar;
