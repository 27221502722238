import React from 'react';

import { Box, LinearProgress } from '@mui/material';

import { HomeBottomButtonOptionsMicViewProps } from './interfaces';

import { useStyles } from './styles';

const HomeBottomButtonOptionsMicView = ({
  level,
}: HomeBottomButtonOptionsMicViewProps) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root} component="div">
      <LinearProgress
        variant="determinate"
        value={level >= 100 ? 100 : level}
        sx={classes.linearProgress}
      />
    </Box>
  );
};

export default HomeBottomButtonOptionsMicView;
