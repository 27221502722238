import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { FormButton, FormInput, FormLayout } from '../../components/Form';
import BackButton from '../../components/BackButton';

import { IForgotPasswordPageView } from './interfaces';

import { useStyles } from './styles';

const ForgotPasswordPageView = ({
  values,
  errors,
  touched,
  onChange,
  loading,
  onSubmit,
  onBackClick,
  t: translate,
}: IForgotPasswordPageView) => {
  const classes = useStyles();

  return (
    <FormLayout classes={{ container: classes.root }}>
      <BackButton onClick={onBackClick} />
      <Box sx={classes.container}>
        <Typography sx={classes.title}>
          {translate('forgotPassword.title')}
        </Typography>
        <Typography sx={classes.subtitle}>
          {translate('forgotPassword.subtitle')}
        </Typography>
        <form onSubmit={onSubmit} noValidate>
          <FormInput
            type="email"
            name="email"
            value={values.email}
            errorMessage={errors.email ? translate(errors.email) : ''}
            showError={!loading && touched.email}
            placeholder={translate('forgotPassword.placeholder')}
            onChange={onChange}
          />
          <FormButton loading={loading}>
            {translate('forgotPassword.button')}
          </FormButton>
        </form>
      </Box>
    </FormLayout>
  );
};

export default withTranslation('common')(ForgotPasswordPageView);
