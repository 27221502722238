import React from 'react';

import ChatChannel from '../ChatChannel';
import LoadingProgress from '../../../../../components/LoadingProgress';

import { useMytaverseEvent } from '../../../providers';
import { useChatState } from '../../../../../hooks/context';

import { checkIsGlobalTab } from '../helpers';
import { checkIsAdminRole } from '../../../../../helpers/role';

const EventTab = () => {
  const { eventStreamChannel, activeTab } = useChatState();
  const { currentEvent } = useMytaverseEvent();

  const isGlobalTab = checkIsGlobalTab(activeTab);

  if (!eventStreamChannel || !currentEvent || !isGlobalTab) {
    return <LoadingProgress />;
  }

  const isAdminRole = checkIsAdminRole(currentEvent.role);

  return (
    <ChatChannel channel={eventStreamChannel} showClearChat={isAdminRole} />
  );
};

export default EventTab;
