import { COLORS } from '../../../constants/colors';

import { IStyles } from '../../../interfaces/styles';

export const useStyles = (showTakePhoto: boolean): IStyles => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ['80%', 540],
    height: 311,
    p: '9.75px 17.25px',
    border: `2px solid ${COLORS.BLACK}`,
    boxShadow: 24,
    backdropFilter: 'blur(12px)',
    backgroundColor: COLORS.DARK_GREY,
    display: showTakePhoto ? 'flex' : 'unset',
    alignItems: 'center',
    justifyContent: 'center',
    '& video': {
      width: 300,
      height: 250,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});
