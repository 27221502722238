import React from 'react';
import { withTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { FormButton, FormLayout } from '../../../components/Form';

import { VerifiedIcon } from '../../../../../icons';

import { ISuccessPasswordRecoveryView } from './interfaces';

import { classes } from './styles';

const SuccessPasswordRecoveryView = ({
  onBackToLogin,
  t: translate,
}: ISuccessPasswordRecoveryView) => {
  return (
    <FormLayout classes={{ container: classes.root }}>
      <VerifiedIcon />
      <Typography sx={classes.title}>
        {translate('passwordRecovery.successTitle')}!
      </Typography>
      <Typography sx={classes.subtitle}>
        {translate('passwordRecovery.successSubtitle')}
      </Typography>
      <FormButton onClick={onBackToLogin}>
        {translate('passwordRecovery.successButton')}
      </FormButton>
    </FormLayout>
  );
};

export default withTranslation('common')(SuccessPasswordRecoveryView);
