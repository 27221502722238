import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tabs, Tab } from '@mui/material';
import WidgetsIcon from '@mui/icons-material/Widgets';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

import ToolboxHeader from '../../ToolboxHeader';
import TabLabel from './TabLabel';
import ScrollableContainer from '../../../../../../components/ScrollableContainer';
import Tools from './Tools';
import Emojis from './Emojis';
import Reactions from './Reactions';

import { ActionsTabs } from '../interfaces';
import { IActionsContent } from './interfaces';

import { useStyles } from './styles';

const ActionsContent = ({
  activeTab,
  onChangeTab,
  onClose,
}: IActionsContent) => {
  const classes = useStyles();
  const { t: translate } = useTranslation('common');

  return (
    <Box sx={classes.root}>
      <ToolboxHeader
        icon={EmojiEmotionsIcon}
        text={translate('actions.actions')}
        onClose={onClose}
      />
      <Tabs value={activeTab} onChange={onChangeTab} sx={classes.tabs}>
        <Tab
          label={
            <TabLabel icon={WidgetsIcon} text={translate('actions.tools')} />
          }
          value={ActionsTabs.Tools}
          sx={classes.tab}
          disableRipple
        />
        <Tab
          label={
            <TabLabel
              icon={EmojiEmotionsIcon}
              text={translate('actions.emojis')}
            />
          }
          value={ActionsTabs.Emojis}
          sx={classes.tab}
          disableRipple
        />
        <Tab
          label={
            <TabLabel
              icon={EmojiPeopleIcon}
              text={translate('actions.reactions')}
            />
          }
          value={ActionsTabs.Reactions}
          sx={classes.tab}
          disableRipple
        />
      </Tabs>
      <ScrollableContainer
        sxProps={{ root: classes.tabContent, content: classes.content }}
      >
        {activeTab === ActionsTabs.Tools && <Tools />}
        {activeTab === ActionsTabs.Emojis && <Emojis />}
        {activeTab === ActionsTabs.Reactions && <Reactions />}
      </ScrollableContainer>
    </Box>
  );
};

export default ActionsContent;
