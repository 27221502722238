import React from 'react';

import { Box, Button, Backdrop, Modal, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import { CloseIcon } from '../../../../../icons';

import { IConfirmLogoutModalView } from './interfaces';

import { useStyles } from './styles';

const ConfirmLogoutModalView = ({
  open,
  onClose,
  onExitButtonClick,
}: IConfirmLogoutModalView) => {
  const classes = useStyles();
  const stayButtonSx = { ...classes.button, ...classes.stayButton };
  const exitButtonSx = { ...classes.button, ...classes.exitButton };

  return (
    <Modal
      sx={classes.root}
      open={open}
      onClose={onClose}
      components={{ Backdrop }}
    >
      <Box component="div" sx={classes.container}>
        <CloseIcon sx={classes.closeIcon} onClick={onClose} />
        <Box component="div" sx={classes.iconContainer}>
          <LogoutIcon />
        </Box>
        <Typography sx={classes.title}>
          You’re about to exit the application
        </Typography>
        <Typography sx={classes.description}>
          You just clicked the exit button, it will take you back to the home
          page. Do you want to exit?
        </Typography>
        <Box component="div" sx={classes.buttonContainer}>
          <Button onClick={onClose} variant="outlined" sx={stayButtonSx}>
            Stay
          </Button>
          <Button
            onClick={onExitButtonClick}
            variant="outlined"
            sx={exitButtonSx}
          >
            Exit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmLogoutModalView;
