import React from 'react';

import { Box, Avatar, Typography } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MicOffIcon from '@mui/icons-material/MicOff';

import { IVideoBubbleView } from './interfaces';

import { useStyles } from './styles';

const VideoBubbleView = ({
  videoRef,
  username,
  speaking,
  muted,
  videoBubbleSize,
}: IVideoBubbleView) => {
  const classes = useStyles({ speaking, muted, videoBubbleSize });

  return (
    <Box component="div" sx={classes.root}>
      <Avatar sx={classes.avatar}>
        <video ref={videoRef} autoPlay playsInline muted />
      </Avatar>
      <Box sx={classes.username}>
        {muted ? <MicOffIcon /> : speaking ? <VolumeUpIcon /> : null}
        <Typography>{username}.</Typography>
      </Box>
    </Box>
  );
};

export default VideoBubbleView;
