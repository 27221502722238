export enum AvatarGender {
  Female = 'female',
  Male = 'male',
}

export interface IAvatar {
  avatarName: string;
  id: string;
  idAvatar: string;
  gender: AvatarGender;
  isDefault: boolean;
  thumbnail?: string;
  image: string;
}
