import { IMytaverseAppBarStyles } from './interfaces';
import { IStyles } from '../../interfaces/styles';

export const useStyles = ({ position }: IMytaverseAppBarStyles): IStyles => ({
  root: {
    boxShadow: 'none',
    top: position === 'top' ? 0 : 'auto',
    bottom: position === 'bottom' ? 0 : 'auto',
  },
});
