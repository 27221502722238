import { create } from 'zustand';
import { IConferenceState } from './interfaces';

const initialState = {
  activeSpeakerDeviceId: 'default',
  activeCameraDeviceId: '',
  activeMicroDeviceId: 'default',
};

export const useConferenceState = create<IConferenceState>((set) => ({
  ...initialState,
  setInitialState: (initialState) => set(initialState),
  setActiveSpeakerDeviceId: (activeSpeakerDeviceId) =>
    set(() => {
      localStorage.setItem('selectedSpeaker', activeSpeakerDeviceId);

      return { activeSpeakerDeviceId };
    }),
  setActiveCameraDeviceId: (activeCameraDeviceId) =>
    set(() => {
      localStorage.setItem('selectedCamera', activeCameraDeviceId);

      return { activeCameraDeviceId };
    }),
  setActiveMicroDeviceId: (activeMicroDeviceId) =>
    set(() => {
      localStorage.setItem('selectedMicrophone', activeMicroDeviceId);

      return { activeMicroDeviceId };
    }),
  resetState: () => set(initialState),
}));
