import React from 'react';

import EventDrawerView from './EventDrawerView';

import { useMytaverseEvent } from '../../providers';

import { toggleDrawer } from '../../../../helpers';

import { ToggleDrawerType } from '../../interfaces';

const EventDrawer = () => {
  const { isOpenLeftMenu, openLeftMenu, closeLeftMenu } = useMytaverseEvent();

  const handleOpenDrawer: ToggleDrawerType = (event) => {
    toggleDrawer(event, openLeftMenu);
  };

  const handleCloseDrawer: ToggleDrawerType = (event) => {
    toggleDrawer(event, closeLeftMenu);
  };

  return (
    <EventDrawerView
      isOpen={isOpenLeftMenu}
      onOpenDrawer={handleOpenDrawer}
      onCloseDrawer={handleCloseDrawer}
    />
  );
};

export default EventDrawer;
