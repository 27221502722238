import React from 'react';

import { Box, Typography } from '@mui/material';

import { IPasswordRuleView } from './interfaces';

import { useStyles } from './styles';

const PasswordRuleView = ({ text, icon: Icon, color }: IPasswordRuleView) => {
  const classes = useStyles(color);

  return (
    <Box sx={classes.root}>
      <Icon sx={classes.icon} />
      <Typography sx={classes.text}>{text}</Typography>
    </Box>
  );
};

export default PasswordRuleView;
