import { SxProps } from '@mui/material';
import { colors, COLORS } from '../../../../constants/colors';

export const useStyles = () => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '88vw',
    height: '81vh',
    boxShadow: 24,
    pt: 2.5,
    pb: 0,
    backdropFilter: 'blur(12px)',
    backgroundColor: COLORS.NEW_BLACK,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: 'none',

    '& iframe': {
      pointerEvents: 'all',
      width: '100%',
      height: '100%',
      border: 'none',
      paddingInline: 2,
    },

    '& video': {
      width: '65vw',
      height: '100%',
    },

    '& img': {
      width: '65vw',
      height: 'auto',
    },

    '& div': {
      '& button': {
        position: 'relative',
        right: 0,
        '& svg': {
          color: colors.white,
          fontSize: 'bold',
        },
      },
    },
  } as SxProps,
  content: {
    overflowY: 'auto',
  },
  title: {
    width: '100%',
    pb: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: 2,
  },

  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
    '& svg': {
      color: COLORS.LIGHT_GREY,
    },
  },

  titleName: {
    textAlign: 'left',
    color: colors.white,
    fontSize: 18,
  },

  titleButton: {
    '& svg': {
      color: colors.white,
    },
  },
});
