/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useRef, useState } from 'react';
import { getUserBrowser, showInitMessage } from './helpers';
import { useMytaverseEvent } from '../../../providers';
import useAsyncEffect from 'use-async-effect';
import {
  CreateGameSessionResponse,
  GameCastInstance,
  GameCastStreamSession,
} from './interfaces';
import GameCastService from '../../../../../services/GameCastService';
import { GAME_CAST_REQUEST_TIMEOUT } from './constants';
import { IInitMessage } from '../Pureweb/interfaces';
import { StreamingProviders } from '../../../providers/MytaverseEventProvider/interfaces';

export const useGameCastConnection = (
  videoElement: HTMLVideoElement | null,
) => {
  const [isStreamLoading, setIsStreamLoading] = React.useState(false);
  const [isStreamLoaded, setIsStreamLoaded] = React.useState(false);

  const {
    currentParticipant,
    initMessageHandler,
    gameCastStreamRequestSended,
    setGameCastStreamRequestSended,
    setInitMessageSended,
    gameCastStreamId,
    gameCastStreamRegion,
  } = useMytaverseEvent();
  const browserType = getUserBrowser();
  const { initializeStream, streamSession } = useGameInitialization({
    browserType,
    description: 'Test connection with gamecast',
    gameName: gameCastStreamId,
    streamId: gameCastStreamId,
  });
  const connectionTimer = useRef<number | null>(0);

  useAsyncEffect(async () => {
    if (
      !videoElement ||
      !currentParticipant ||
      gameCastStreamRequestSended ||
      !gameCastStreamId ||
      !gameCastStreamRegion
    ) {
      return;
    }

    try {
      setIsStreamLoading(true);
      if (!initMessageHandler) {
        return;
      }

      connectionTimer.current = new Date().getTime();
      const initMessage = initMessageHandler();
      if (!initMessage) {
        return;
      }

      setGameCastStreamRequestSended(true);
      setInitMessageSended(true);

      const isStarted = await initializeStream(
        videoElement,
        currentParticipant.id,
        initMessage,
        gameCastStreamRegion,
      );

      if (isStarted) {
        setIsStreamLoading(false);
        setIsStreamLoaded(true);
      }
    } catch (error) {
      setIsStreamLoading(false);
    }
  }, [
    videoElement,
    currentParticipant,
    initMessageHandler,
    gameCastStreamRequestSended,
    gameCastStreamRegion,
  ]);

  return {
    isStreamLoading,
    isStreamLoaded,
    setIsStreamLoaded,
    setIsStreamLoading,
    streamSession,
    connectionTimer,
  };
};

export const useGameInitialization = ({
  gameName,
  description,
  browserType,
  streamId,
}: {
  gameName: string | null;
  description: string;
  browserType: string;
  streamId: string | null;
}) => {
  const [streamSession, setStreamSession] =
    useState<GameCastStreamSession | null>(null);
  const { currentParticipant, setStreamingProvider } = useMytaverseEvent();

  const initializeStream = async (
    videoElement: HTMLVideoElement,
    participantId: string,
    initMessage: IInitMessage,
    region: string,
  ) => {
    if (!gameName || !streamId) {
      return;
    }
    //@ts-ignore
    const GameCastClass = window.GameCast;
    const gameCast: GameCastInstance = new GameCastClass({
      videoElement,
      browserType,
    });

    const gamecastSignalData = await gameCast.generateSignalRequest();

    try {
      let signalResponse;

      signalResponse = await startGameSessionHandler(
        description,
        gameName,
        participantId,
        gamecastSignalData.signal_request,
        GAME_CAST_REQUEST_TIMEOUT,
        streamId,
        initMessage,
        region,
      );

      const streamSession = await gameCast.stream(signalResponse);

      if (streamSession) {
        setStreamSession(streamSession);
      }

      return true;
    } catch (e) {
      console.log(e);
      console.log('Changing streaming provider to Pureweb');

      setStreamingProvider(StreamingProviders.Pureweb);
    }
  };

  const startGameSessionHandler = async (
    description: string,
    gameName: string,
    userId: string,
    signalRequest: string,
    timeout: number,
    streamId: string,
    initMessage: IInitMessage,
    region: string,
  ) => {
    showInitMessage(JSON.stringify(initMessage));

    const response: CreateGameSessionResponse =
      await createAndWaitForStreamToBeActive(
        description,
        gameName,
        currentParticipant?.id as string,
        signalRequest,
        timeout,
        streamId,
        initMessage,
        region,
      );

    return {
      signal_response: response.SignalResponse,
      gamecast_protocol_url: response.WebSdkProtocolUrl,
    };
  };

  const createAndWaitForStreamToBeActive = (
    description: string,
    gameName: string,
    participantId: string,
    signalRequest: string,
    timeout: number,
    streamId: string,
    initMessage: IInitMessage,
    region: string,
  ) => {
    return new Promise<CreateGameSessionResponse>((resolve, reject) => {
      if (!initMessage) {
        return;
      }

      GameCastService.startGameSession({
        description,
        gameName,
        participantId,
        signalRequest,
        timeout,
        streamId,
        initMessage,
        region,
      })
        .then((data) => {
          resolve({
            SignalResponse: data.SignalResponse,
            Status: data.Status,
            WebSdkProtocolUrl: data.WebSdkProtocolUrl,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  return {
    initializeStream,
    streamSession,
  };
};
