import React from 'react';

import ConfirmClearChatModalView from './ConfirmClearChatModalView';

import { IConfirmClearChatModal } from './interfaces';

const ConfirmClearChatModal = ({
  open,
  onClose,
  channel,
}: IConfirmClearChatModal) => {
  const handleSubmit = async () => {
    await channel.truncate({ hard_delete: true });
    onClose();
  };

  return (
    <ConfirmClearChatModalView
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default ConfirmClearChatModal;
