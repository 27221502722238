import { COLORS } from '../../../../../constants/colors';

import { IStyles } from '../../../../../interfaces/styles';

export const classes: IStyles = {
  tabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& button': {
      fontSize: [20, 24],
      textTransform: 'none',
    },
  },
  tab: {
    color: COLORS.SUBTITLE,
    pl: 0,
    '&.Mui-selected': {
      color: COLORS.WHITE,
      borderColor: 'transparent',
    },
  },
};
