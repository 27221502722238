import React from 'react';

import DashboardTopBarContentView from './DashboardTopBarContentView';

import { useMytaverseEvent } from '../../../providers';
import { useFullscreen } from './hooks';
import { useChatState } from '../../../../../hooks/context';

import { checkIsAdminRole } from '../../../../../helpers/role';
import { sendCustomSentryBreadcrumb } from '../../../../../helpers/sentry';

import { EventDrawerTabs } from '../../../constants';
import { IDashboardTopBarContent } from './interfaces';
import { useConference } from '../../DashboardContent/Dolby';

const DashboardTopBarContent = ({
  onOpenConfirmLogoutModal,
  onOpenInviteUserModal,
}: IDashboardTopBarContent) => {
  const { openLeftMenu, participants, currentRoom, currentEvent } =
    useMytaverseEvent();
  const { screenMediaStreams } = useConference();
  const toggleFullscreen = useFullscreen();
  const { open: isOpenChat } = useChatState();

  const showInviteUserBtn = currentEvent
    ? checkIsAdminRole(currentEvent.role)
    : false;
  const participantTotal = participants.filter(({ roomId }) => roomId).length;
  const hasMediaStream = !!screenMediaStreams.length;

  const handleOpenEventDrawer = (tab: EventDrawerTabs) => {
    openLeftMenu(tab);
  };

  const handleFullscreenClick = () => {
    sendCustomSentryBreadcrumb({ message: 'show in full screen' });
    toggleFullscreen();
  };

  return (
    <DashboardTopBarContentView
      currentRoom={currentRoom}
      isOpenChat={isOpenChat}
      hasMediaStream={hasMediaStream}
      showInviteUserBtn={showInviteUserBtn}
      participantTotal={participantTotal}
      onOpenEventDrawer={handleOpenEventDrawer}
      onFullscreenClick={handleFullscreenClick}
      onOpenConfirmLogoutModal={onOpenConfirmLogoutModal}
      onOpenInviteUserModal={onOpenInviteUserModal}
    />
  );
};

export default DashboardTopBarContent;
