import { COLORS } from '../../../../constants/colors';

export const classes = {
  root: {
    '& div:nth-of-type(3)': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    height: '100%',
    width: ['100vw', '100vw', '41vw', '29vw'],
    backgroundColor: 'none',
  },
  backdrop: {
    '& > :not(style)': {
      height: '100vh',
      width: '100%',
      backdropFilter: 'blur(12px)',
      backgroundColor: COLORS.DARK_GREY,
      overflowY: 'scroll',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 32px',
  },
};
