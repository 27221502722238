import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  const { children, ...slideProps } = props;

  if (!children) {
    return null;
  }

  return (
    <Slide direction="up" ref={ref} {...slideProps}>
      {children}
    </Slide>
  );
});

export default Transition;
