import { getCatchErrorMessage } from '../../../../helpers/error';

import UserService from '../../../../services/UserService';
import LoginService from '../../../../services/LoginService';

import ROUTES from '../../../../constants/routes';

import { IFormValues } from './interfaces';

export const resetPassword = async ({
  email,
}: IFormValues): Promise<boolean> => {
  try {
    const response = await UserService.getUserByEmail(email);

    if (!response.data) {
      return false;
    }

    await LoginService.resetPassword(
      email,
      `${window.location.origin}${ROUTES.LOGIN}/${ROUTES.RECOVER_PASSWORD}`,
    );

    return true;
  } catch (error: unknown) {
    throw Error(getCatchErrorMessage(error));
  }
};
