import { colors } from '../../../../../constants/colors';

export const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 3,
    padding: '24px 24px 32px',
    textAlign: 'center',
  },
  title: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.5,
    color: colors.oxford,
    opacity: 0.8,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.56,
    letterSpacing: '0.25px',
    color: colors.oxford_60,
  },
};
