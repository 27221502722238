import { useTranslation } from 'react-i18next';

import SkateboardingIcon from '@mui/icons-material/Skateboarding';
import FlareIcon from '@mui/icons-material/Flare';
import SearchIcon from '@mui/icons-material/Search';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import { useToolsState } from '../../hooks';
import { useMytaverse } from '../../../../../../../providers/MytaverseProvider';

import { ITool } from './interfaces';
import { WebsocketAction } from '../../../../../../../interfaces/webSocketConnectionInfo';

export const useHandleToolClick = () => {
  const {
    isHoverboardApplied,
    setHoverboard,
    isLaserPointerApplied,
    setLaserPointer,
    isMagnifierApplied,
    setMagnifier,
    isTeleportApplied,
    setTeleport,
  } = useToolsState();
  const { sendMessageToUnreal } = useMytaverse();

  const handleClick = (action: WebsocketAction) => {
    if (action === WebsocketAction.Hoverboard) {
      sendMessageToUnreal({
        action,
        state: isHoverboardApplied,
      });
      setHoverboard(!isHoverboardApplied);
      return;
    }

    if (action === WebsocketAction.LaserPointer) {
      sendMessageToUnreal({
        action,
        state: isLaserPointerApplied,
      });
      setLaserPointer(!isLaserPointerApplied);
      return;
    }

    if (action === WebsocketAction.Magnifier) {
      sendMessageToUnreal({
        action,
        state: isMagnifierApplied,
      });
      setMagnifier(!isMagnifierApplied);
      return;
    }

    if (action === WebsocketAction.BeamMeUp) {
      sendMessageToUnreal({
        action,
        state: isTeleportApplied,
      });
      setTeleport(!isTeleportApplied);
    }
  };

  return handleClick;
};

export const useTools = () => {
  const { t: translate } = useTranslation('common');

  const tools: ITool[] = [
    {
      icon: SkateboardingIcon,
      name: translate('actions.hoverboard'),
      description: translate('actions.hoverboardDescription'),
      action: WebsocketAction.Hoverboard,
    },
    {
      icon: FlareIcon,
      name: translate('actions.laserPointer'),
      description: translate('actions.laserPointerDescription'),
      action: WebsocketAction.LaserPointer,
    },
    {
      icon: SearchIcon,
      name: translate('actions.magnifier'),
      description: translate('actions.magnifierDescription'),
      action: WebsocketAction.Magnifier,
    },
    {
      icon: RocketLaunchIcon,
      name: translate('actions.teleport'),
      description: translate('actions.teleportDescription'),
      action: WebsocketAction.BeamMeUp,
    },
  ];

  return tools;
};
