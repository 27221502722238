import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import WorkIcon from '@mui/icons-material/Work';

import IconPopover, { useToggleIconPopover } from '../../IconPopover';
import ToolboxContent from './ToolboxContent';

import { ToolboxOption } from './ToolboxContent/interfaces';

const ToolBox = () => {
  const [activeToolboxOption, setActiveToolboxOption] = useState<ToolboxOption>(
    ToolboxOption.Briefcase,
  );

  const { t: translate } = useTranslation('common');
  const { anchorEl, handleClick, handleClose } = useToggleIconPopover();

  return (
    <IconPopover
      icon={<WorkIcon />}
      label={translate('toolbox.briefcase')}
      anchorEl={anchorEl}
      onClick={handleClick}
      onClose={handleClose}
    >
      <ToolboxContent
        activeToolboxOption={activeToolboxOption}
        setActiveToolboxOption={setActiveToolboxOption}
        onCloseToolbox={handleClose}
      />
    </IconPopover>
  );
};

export default ToolBox;
