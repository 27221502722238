import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';

import Pureweb from './Pureweb';
import UserProfileModal from '../../../../components/UserProfileModal';
import BrowserUrlDialog from './BrowserUrlDialog';
import SharingWindow from '../SharingWindow';
import GameCast from './GameCast/GameCast';

import { PurewebProvider } from './Pureweb/PurewebProvider';
import { GameCastProvider } from './GameCast/GameCastProvider';

import { useMytaverseEvent } from '../../providers';
import { useConference } from './Dolby';
import { useChatState } from '../../../../hooks/context';

import { checkIsPurewebStreamingProvider } from '../../helpers';

import { IDashboardContentView } from './interfaces';

import { useStyles } from './styles';
import { useMytaverse } from '../../../../providers/MytaverseProvider';

const DashboardContentView = ({
  openBrowserUrlDialog,
  onSubmitBrowserUrl,
  onCloseBrowserUrlDialog,
}: IDashboardContentView) => {
  const { isConferenceInitialized } = useConference();
  const [open, setOpen] = useState(false);
  const { currentRoom, sharingWindowFullsceen, streamingProvider } =
    useMytaverseEvent();
  const {
    previewingParticipant,
    setPreviewingParticipant,
    screenMediaStreams,
  } = useConference();
  const { sendMessageToUnreal } = useMytaverse();
  const { open: isOpenChat } = useChatState();

  useEffect(() => {
    if (previewingParticipant) {
      setOpen(true);
    }
  }, [previewingParticipant]);

  const classes = useStyles(
    sharingWindowFullsceen,
    !!screenMediaStreams.length,
    isOpenChat,
  );
  const isFirstRender = useRef(true);

  const handleClickEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      sendMessageToUnreal({ action: 'ESCAPE_CLICK' });
    }
  };

  useEffect(() => {
    if (!currentRoom) {
      return;
    }

    window.addEventListener('keydown', handleClickEscape);

    return () => {
      window.removeEventListener('keydown', handleClickEscape);
    };
  }, [currentRoom]);

  useEffect(() => {
    if (isConferenceInitialized || currentRoom) {
      isFirstRender.current = false;
    }
  }, [isConferenceInitialized]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        transition: 'background-color 2s linear',
        backgroundColor: !isFirstRender.current ? 'black' : 'transparent',
      }}
    >
      <Box sx={classes.root}>
        {screenMediaStreams.length ? <SharingWindow /> : null}
        {checkIsPurewebStreamingProvider(streamingProvider) ? (
          <PurewebProvider>
            <Pureweb />
          </PurewebProvider>
        ) : (
          <GameCastProvider>
            <GameCast />
          </GameCastProvider>
        )}
        {open ? (
          <UserProfileModal
            onClose={() => {
              setOpen(false);
              setPreviewingParticipant(null);
            }}
            previewingParticipant={previewingParticipant}
          />
        ) : null}
        {openBrowserUrlDialog ? (
          <BrowserUrlDialog
            onClose={onCloseBrowserUrlDialog}
            onSubmit={onSubmitBrowserUrl}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default DashboardContentView;
