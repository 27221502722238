import { COLORS } from '../../../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    width: '100%',
    mt: 3,
    cursor: 'pointer',
    '&:hover': {
      '& button': {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 1,
        alignItems: 'center',
      },
      '& .img': {
        filter: 'brightness(0.5)',
        zIndex: -5,
      },
    },
  },
  roomImageContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      width: '100%',
      maxHeight: '300px !important',
      borderRadius: 2,
      objectFit: 'cover',
    },
  },
  teleportBtn: {
    display: 'none',
    position: 'absolute',
    flexDirection: 'column',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  teleportText: {
    color: COLORS.WHITE,
    fontSize: 20,
    fontWeight: 700,
    mt: 1.5,
    textTransform: 'capitalize',
  },
  roomNameContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 1.5,
    '& svg': {
      color: COLORS.GREEN,
      fontSize: 12,
    },
  },
  roomName: (isCurrentRoom: boolean) => ({
    color: isCurrentRoom ? COLORS.WHITE : COLORS.SUBTITLE,
    fontWeight: 700,
    letterSpacing: '0.05em',
    fontSize: 24,
    textTransform: 'capitalize',
  }),
  online: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.WHITE,
    columnGap: 1,
  },
});
