import React from 'react';

import EventDrawerContentView from './EventDrawerContentView';

import { useMytaverseEvent } from '../../../providers';

const EventDrawerContent = () => {
  const { leftMenuTab, loading, rooms, participants } = useMytaverseEvent();

  return (
    <EventDrawerContentView
      leftMenuTab={leftMenuTab}
      loading={loading}
      rooms={rooms}
      participants={participants}
    />
  );
};

export default EventDrawerContent;
