import { useTheme } from '@mui/material';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { colors } from '../../../../constants/colors';

import { IEventThemeTypes, ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      rowGap: 2,
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.Tablet)]: {
        width: '80%',
        justifySelf: 'center',
      },
    },
    icon: {
      color: colors.myta,
      width: 32,
      height: 32,
    },
    title: {
      fontWeight: 500,
      fontSize: 32,
      lineHeight: 0.88,
      color:
        appTheme.themeName === IEventThemeTypes.DARK
          ? colors.white
          : colors.oxford_80,
      [breakpoints.down(ScreenSizes.Tablet)]: {
        fontSize: 24,
        lineHeight: 1.17,
      },
    },
    teleport: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: 1.17,
      color:
        appTheme.themeName === IEventThemeTypes.DARK
          ? colors.white
          : colors.oxford_60,
      [breakpoints.down(ScreenSizes.Tablet)]: {
        fontSize: 16,
        lineHeight: 1.75,
      },
    },
  };
};
