import React from 'react';
import { useLocation, useRoutes } from 'react-router-dom';

import LoadingProgress from '../components/LoadingProgress/LoadingProgress';

import { useMytaverse } from '../providers/MytaverseProvider';
import { useNoNetworkConnection } from '../hooks';

import APP_ROUTES from './appRoutes';
import ROUTES from '../constants/routes';

const Routes = () => {
  const { loading, appTheme } = useMytaverse();
  const routes = useRoutes(APP_ROUTES);
  const { pathname } = useLocation();

  useNoNetworkConnection();

  if (loading && (!pathname.includes(ROUTES.LOGIN) || !appTheme)) {
    return <LoadingProgress fullHeight />;
  }

  return <>{routes}</>;
};

export default Routes;
