import { COLORS } from '../../../../constants/colors';

import { ISearchFieldStyles } from './interfaces';

export const useStyles = ({ value }: ISearchFieldStyles) => ({
  input: {
    backgroundColor: COLORS.WHITE_1,
    padding: '4px 16px',
    columnGap: 2,
    borderRadius: '4px',
    '&:hover': {
      borderBottom: 'none',
      '&:not(.Mui-disabled)::before': {
        borderBottom: 'none',
      },
    },
    '&::before': {
      content: "''",
      transition: 'none',
    },
    '&::after': {
      borderBottom: 'none',
    },
    '& input': {
      color: COLORS.WHITE,
      '&::placeholder': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 1.43,
        letterSpacing: '0.25px',
        color: COLORS.WHITE_6,
        opacity: 1,
      },
    },
  },
  searchIcon: {
    width: 24,
    height: 32,
    fontSize: 16,
    color: COLORS.WHITE,
    opacity: value ? 1 : 0.6,
  },
  clearIcon: {
    fontSize: 16,
    width: 24,
    height: 32,
    color: COLORS.MEDIUM_GREY,
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.WHITE,
    },
  },
});
