import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import {
  JoinOptions,
  ListenOptions,
  ParticipantInfo,
} from '@voxeet/voxeet-web-sdk/types/models/Options';
import Conference from '@voxeet/voxeet-web-sdk/types/models/Conference';
import { Participant as DolbyParticipant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import ConferenceOptions from '@voxeet/voxeet-web-sdk/types/models/ConferenceOptions';

import { getCatchErrorMessage } from '../../../../../helpers/error';

import dolbyLogger from './logger';

import { ParticipantPosition } from '../interfaces';

const forward = { x: 0, y: -1, z: 0 };
const up = { x: 0, y: 0, z: 1 };
const right = { x: 1, y: 0, z: 0 };

export default class DolbyService {
  static initializeDolbySDK(accessToken: string) {
    const token = accessToken.split('.')[1];
    const jwt = JSON.parse(window.atob(token));
    const accessTokenExpiration = new Date(jwt.exp * 1000);

    if (accessTokenExpiration.getTime() <= new Date().getTime()) {
      throw new Error('The access token you have provided has expired.');
    }

    VoxeetSDK.initializeToken(
      accessToken,
      () => new Promise((resolve) => resolve(accessToken)),
    );
  }

  static async openSession(participantInfo: ParticipantInfo): Promise<void> {
    if (VoxeetSDK.session.isOpen()) {
      return;
    }

    await VoxeetSDK.session.open(participantInfo);
  }

  static async closeSession() {
    return VoxeetSDK.session.isOpen()
      ? VoxeetSDK.session.close()
      : Promise.resolve();
  }

  static createConference(
    alias: string,
    useSpatial = true,
  ): Promise<Conference> {
    const conferenceOptions: ConferenceOptions = {
      alias,
      params: {
        liveRecording: false,
        rtcpMode: 'average', // worst, average, max
        ttl: 60,
        videoCodec: 'H264', // H264, VP8
        dolbyVoice: true,
        // @ts-ignore
        spatialAudioStyle: useSpatial ? 'shared' : 'disabled',
      },
    };

    return VoxeetSDK.conference.create(conferenceOptions);
  }

  static async joinToConference(
    conference: Conference,
    isMuted: boolean,
    joinOptions: JoinOptions,
  ) {
    await VoxeetSDK.conference.join(conference, joinOptions);
    this.toggleAudio(isMuted);
  }

  static async joinToConferenceAsListener(
    conference: Conference,
    options: ListenOptions,
  ) {
    await VoxeetSDK.conference.listen(conference, options);
  }

  static async startShareScreen(eventId: string, userId: string) {
    // add catch to prevent get permission denied error when Cancel start sharing
    await VoxeetSDK.conference
      .startScreenShare(`${eventId}-${userId}`)
      .catch(() => {});
  }

  static async stopShareScreen() {
    await VoxeetSDK.conference.stopScreenShare();
  }

  static async leaveConference() {
    return VoxeetSDK.conference.current
      ? VoxeetSDK.conference.leave()
      : Promise.resolve();
  }

  static setSpatialEnvironment(scaleValue: number) {
    const skipSpecialEnv =
      !VoxeetSDK.conference.current ||
      VoxeetSDK.conference.current.status !== 'joined' ||
      VoxeetSDK.conference.current.params.spatialAudioStyle !== 'shared';

    if (skipSpecialEnv) {
      return;
    }

    const scale = { x: scaleValue, y: scaleValue, z: scaleValue };

    VoxeetSDK.conference.setSpatialEnvironment(scale, forward, up, right);
  }

  static getSessionParticipant(): DolbyParticipant {
    return VoxeetSDK.session.participant;
  }

  static getSessionParticipantExternalId(): string {
    return VoxeetSDK.session.participant.info.externalId || '';
  }

  static toggleAudio(muted: boolean): void {
    if (!VoxeetSDK.session.participant || !VoxeetSDK.conference.current) {
      return;
    }

    try {
      VoxeetSDK.conference.mute(VoxeetSDK.session.participant, muted);
    } catch (error) {
      dolbyLogger.error(error);
      throw Error(getCatchErrorMessage(error));
    }
  }

  static async toggleVideo(videoStarted: boolean) {
    if (videoStarted) {
      await VoxeetSDK.video.local.start({
        width: {
          min: 140,
          max: 140,
        },
        height: {
          min: 140,
          max: 140,
        },
      });
      return;
    }

    await VoxeetSDK.video.local.stop();
  }

  static async selectMicrophone(microphoneId: string) {
    await VoxeetSDK.mediaDevice.selectAudioInput(microphoneId);
  }

  static async selectSpeaker(speakerId: string) {
    await VoxeetSDK.mediaDevice.selectAudioOutput(speakerId);
  }

  static async selectCamera(cameraId: string) {
    await VoxeetSDK.mediaDevice.selectVideoInput(cameraId);
  }

  static getConferenceId(): string {
    if (!VoxeetSDK.conference.current) {
      return '';
    }

    return VoxeetSDK.conference.current.id;
  }

  static setParticipantPosition(participantPosition: ParticipantPosition) {
    const skipParticipantPosition =
      !VoxeetSDK.conference.current ||
      VoxeetSDK.conference.current.status !== 'joined' ||
      VoxeetSDK.conference.current.params.spatialAudioStyle !== 'shared';

    if (skipParticipantPosition) {
      return;
    }

    const spatialPosition = {
      x: participantPosition.x,
      y: participantPosition.y,
      z: participantPosition.z,
    };

    const spatialDirection = {
      x: 0,
      y: 0,
      z: participantPosition.r,
    };

    VoxeetSDK.conference.setSpatialPosition(
      DolbyService.getSessionParticipant(),
      spatialPosition,
    );
    VoxeetSDK.conference.setSpatialDirection(
      DolbyService.getSessionParticipant(),
      spatialDirection,
    );
  }
}
