import { useTranslation } from 'react-i18next';

import WorkIcon from '@mui/icons-material/Work';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import EventIcon from '@mui/icons-material/Event';
import DownloadIcon from '@mui/icons-material/Download';

import {
  ToolboxDataContextType,
  ToolboxOption,
  UseGetToolboxDataContextType,
} from './interfaces';

export const useGetToolboxDataContext: UseGetToolboxDataContextType = ({
  onCloseToolbox,
  setActiveToolboxOption,
}) => {
  const { t: translate } = useTranslation('common');

  const toolboxDataContext: ToolboxDataContextType = {
    [ToolboxOption.Briefcase]: {
      text: translate('toolbox.briefcase'),
      icon: WorkIcon,
      onClose: onCloseToolbox,
    },
    [ToolboxOption.Agenda]: {
      text: translate('toolbox.agenda'),
      icon: ViewAgendaIcon,
      onClose: () => setActiveToolboxOption(ToolboxOption.Briefcase),
    },
    [ToolboxOption.Calendar]: {
      text: translate('toolbox.calendar'),
      icon: EventIcon,
      onClose: () => setActiveToolboxOption(ToolboxOption.Briefcase),
    },
    [ToolboxOption.Files]: {
      text: translate('toolbox.files'),
      icon: DownloadIcon,
      onClose: () => setActiveToolboxOption(ToolboxOption.Briefcase),
    },
  };

  return toolboxDataContext;
};
