import { RefObject, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMytaverseEvent } from '../../../dashboard/providers';
import { useMytaverse } from '../../../../providers/MytaverseProvider';

import EventsService from '../../../../services/EventsService';

import ROUTES from '../../../../constants/routes';

const eventSource = 'readyplayerme';
const eventNames = {
  frameReady: 'v1.frame.ready',
  avatarExported: 'v1.avatar.exported',
};

export const useSubscribeToReadyPlayerMeEvents =
  (): RefObject<HTMLIFrameElement> => {
    const frameRef = useRef<HTMLIFrameElement>(null);

    const navigate = useNavigate();
    const { setCustomAvatarUrl, setCurrentSkin } = useMytaverseEvent();
    const { currentEventId, userId } = useMytaverse();

    const parse = (event: MessageEvent) => {
      try {
        return JSON.parse(event.data);
      } catch (error) {
        return null;
      }
    };

    const subscribe = useCallback(
      async (event: MessageEvent) => {
        const json = parse(event);

        if (json?.source !== eventSource) {
          return;
        }

        const contentWindow = frameRef.current?.contentWindow;
        // Subscribe to all events sent from Ready Player Me once frame is ready
        if (json.eventName === eventNames.frameReady && contentWindow) {
          contentWindow.postMessage(
            JSON.stringify({
              target: eventSource,
              type: 'subscribe',
              eventName: 'v1.**',
            }),
            '*',
          );
        }

        if (
          json.eventName === eventNames.avatarExported &&
          currentEventId &&
          userId
        ) {
          setCustomAvatarUrl(json.data.url);
          setCurrentSkin(null);
          await EventsService.setEventUserData(currentEventId, userId, {
            avatarId: '',
            customAvatarUrl: json.data.url,
            skinId: '',
          });
          navigate(ROUTES.DEVICES_TEST);
        }
      },
      [currentEventId, navigate, setCurrentSkin, setCustomAvatarUrl, userId],
    );

    useEffect(() => {
      window.addEventListener('message', subscribe);

      return () => {
        window.removeEventListener('message', subscribe);
      };
    }, [subscribe]);

    return frameRef;
  };
