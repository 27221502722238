import { COLORS } from '../../../../../../constants/colors';
import { IStyles } from '../../../../../../interfaces/styles';

export const useStyles = (): IStyles => ({
  root: {
    p: 0,
  },

  permissionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& img': {
      width: '120%',
      height: 440,
    },
  },

  subRoot: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  stack: {
    '& .MuiFormControl-root': { mt: '10px !important' },
  },

  button: {
    width: 512,
    height: 308,
    mt: 2,
    backgroundColor: COLORS.GREY,
    color: COLORS.LIGHT_GREY,

    '&:hover': {
      backgroundColor: COLORS.MYTAVERSE_BLUE,
    },
  },
});
