import { COLORS } from '../../../../../../constants/colors';

import { IParticipantSearchItemStyles } from './interfaces';

export const useStyles = ({
  isUnreadChannel,
}: IParticipantSearchItemStyles) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
  },
  unreadChannelWrapper: {
    '& .MuiBadge-badge': {
      top: 4,
      right: -4,
    },
  },
  avatar: {
    width: 48,
    height: 48,
  },
  name: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.25px',
    color: COLORS.WHITE,
  },
  lastMessage: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: '0.4px',
    color: COLORS.WHITE,
    opacity: isUnreadChannel ? 1 : 0.6,
    marginTop: '4px',
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
});
