import { useEffect, useState } from 'react';

import { useConferenceState } from '../hooks/conferenceContext';

export const useInitPrivateRoutesState = () => {
  const [initializing, setInitializing] = useState(true);

  const { setInitialState, resetState } = useConferenceState();

  useEffect(() => resetState, [resetState]);

  useEffect(() => {
    if (!initializing) return;

    const activeSpeakerDeviceId =
      localStorage.getItem('selectedSpeaker') || 'default';
    const activeCameraDeviceId = localStorage.getItem('selectedCamera') || '';
    const activeMicroDeviceId =
      localStorage.getItem('selectedMicrophone') || 'default';

    setInitialState({
      activeSpeakerDeviceId,
      activeCameraDeviceId,
      activeMicroDeviceId,
    });
    setInitializing(false);
  }, [initializing, setInitialState]);

  return initializing;
};
