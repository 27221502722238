import { COLORS } from '../../../../constants/colors';

import { IStyles } from '../../../../interfaces/styles';

export const useStyles = (errorMessage: string): IStyles => ({
  input: {
    '& input': {
      width: ['100%', 480, 480, 480],
      border: 'none !important',
      background: 'transparent ',
      fontWeight: 500,
      fontSize: 18,
      textAlign: 'left',
      pl: 3,
      pb: 2,
      pt: 2,
      '&:focus': {
        outline: 'none',
      },
      '@media (max-width: 767px)': {
        fontSize: 18,
        lineHeight: '36px',
      },
    },
  },
  root: {
    mt: 4,
    '& div': {
      backgroundColor: COLORS.WHITE,
      width: '100%',
    },
    '& div:before': {
      borderBottom: 'none !important',
    },
    '& div:after': {
      borderBottom: 'none',
    },
  },
  errorText: {
    color: '#ff0000',
    fontSize: 14,
    mt: errorMessage.length ? 1 : 0,
  },
});
