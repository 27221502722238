import React from 'react';

import { Box, Typography } from '@mui/material';

import LoadingProgress from '../../../../../components/LoadingProgress';

import { classes } from './styles';

const ConfirmationLoading = () => {
  return (
    <Box sx={classes.root}>
      <LoadingProgress size={32} height="auto" />
      <Typography sx={classes.title}>Checking your invitation</Typography>
    </Box>
  );
};

export default ConfirmationLoading;
