import { useTranslation } from 'react-i18next';

import { useNotification } from '../components/Notification';

import { sendCustomSentryBreadcrumb } from '../helpers/sentry';

import { WebsocketErrorCode } from '../interfaces/webSocketConnectionInfo';

const { CloseAbnormal } = WebsocketErrorCode;

const useShowWebsocketDisconnectedNotification = () => {
  const { showNotification, getWebsocketNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  const showWebsocketNotification = () => {
    const message = translate('notifications.websocketDisconnected');

    showNotification(getWebsocketNotification({ message, withButton: true }));
    sendCustomSentryBreadcrumb({ message });
  };

  return showWebsocketNotification;
};

export const useHandleWebsocketError = () => {
  const showWebsocketDisconnectedNotification =
    useShowWebsocketDisconnectedNotification();

  const handleWebsocketError = (event: CloseEvent) => {
    if (event.code === CloseAbnormal) {
      showWebsocketDisconnectedNotification();
    }
  };

  return handleWebsocketError;
};
