import { useEffect, useState } from 'react';

import { IUseMicrophoneTestProps, RenderFrameType } from './interfaces';

export const useMicrophoneTest = ({
  activeDeviceId,
  permissionState,
}: IUseMicrophoneTestProps) => {
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    const setMicrophoneValue: RenderFrameType = ({
      analyser,
      bufferLength,
    }) => {
      const dataArray = new Uint8Array(bufferLength);

      requestAnimationFrame(() =>
        setMicrophoneValue({ analyser, bufferLength }),
      );
      analyser.getByteFrequencyData(dataArray);

      for (let i = 0; i < bufferLength; i++) {
        setTimeout(() => {
          setVolume(dataArray[i]);
        }, 12);
      }
    };

    const startCheckMicro = async () => {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: activeDeviceId }, noiseSuppression: true },
        video: false,
      });
      const context = new AudioContext();
      const src = context.createMediaStreamSource(mediaStream);
      const analyser = context.createAnalyser();

      src.connect(analyser);
      analyser.fftSize = 64;

      const bufferLength = analyser.frequencyBinCount;

      setMicrophoneValue({ analyser, bufferLength });
    };

    if (permissionState === 'granted') {
      startCheckMicro();
    }
  }, [activeDeviceId, permissionState]);

  return { volume };
};
