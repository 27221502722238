import * as Yup from 'yup';
import { EMAIL_SPECIAL_SYMBOLS } from '../../../../constants/regex';

export const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .matches(EMAIL_SPECIAL_SYMBOLS, 'validations.emailAddress')
    .email('validations.emailAddress')
    .required('validations.emailAddress')
    .max(254, 'validations.emailAddress'),
});
