import React from 'react';

import PrivateChat from './PrivateChat';
import SearchChat from './SearchChat';

import { useChatState } from '../../../../../hooks/context';

const PrivateTab = () => {
  const { activePrivateChannel } = useChatState();

  return activePrivateChannel ? <PrivateChat /> : <SearchChat />;
};

export default PrivateTab;
