import * as Yup from 'yup';

import { EMAIL_SPECIAL_SYMBOLS } from '../../../../constants/regex';
import { PASSWORD_ERROR_MESSAGES } from '../../components/PasswordValidation';

import { IFormValues } from './interfaces';

const { minLength, oneLowerCase, oneUpperCase, oneNumber, match } =
  PASSWORD_ERROR_MESSAGES;

export const RegisterSchema: Yup.SchemaOf<IFormValues> = Yup.object()
  .shape({
    firstName: Yup.string().required('validations.name').trim().defined(),
    lastName: Yup.string().required('validations.lastName').trim().defined(),
    email: Yup.string()
      .trim()
      .required('validations.emailAddress')
      .matches(EMAIL_SPECIAL_SYMBOLS, 'validations.emailAddress')
      .email('validations.emailAddress')
      .max(254, 'validations.emailAddress')
      .defined(),
    password: Yup.string()
      .min(8, minLength)
      .matches(/[a-z]/, oneLowerCase)
      .matches(/[A-Z]/, oneUpperCase)
      .matches(/\d/, oneNumber)
      .defined(),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password')], match)
      .defined(),
  })
  .defined();
