import React, { ChangeEvent } from 'react';
import { useFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import InviteUserToEventModalView from './InviteUserToEventModalView';

import { useInviteUserToEvent } from './hooks';

import { sendCustomSentryBreadcrumb } from '../../../../../helpers/sentry';

import { InviteUserToEventSchema } from './validations';

import { FormValues, IInviteUserToEventModal } from './interfaces';

const InviteUserToEventModal = ({
  open,
  onClose,
  t: translate,
}: IInviteUserToEventModal) => {
  const inviteUserToEvent = useInviteUserToEvent(onClose);

  const onSubmit = async (values: FormValues) => {
    sendCustomSentryBreadcrumb({ message: `invite user ${values.email}` });
    await inviteUserToEvent(values.email);
  };

  const {
    values,
    touched,
    setFieldTouched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik<FormValues>({
    initialValues: {
      email: '',
    },
    onSubmit,
    validationSchema: InviteUserToEventSchema,
    enableReinitialize: true,
  });

  const errorMessage = errors.email ? translate(errors.email) : '';

  const handleInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFieldTouched(event.target.name, false, false);
    handleChange(event);
  };

  return (
    <InviteUserToEventModalView
      open={open}
      onClose={onClose}
      emailValue={values.email}
      onInputChange={handleInputChange}
      errorMessage={errorMessage}
      showError={!!touched.email}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
};

export default withTranslation('common')(InviteUserToEventModal);
