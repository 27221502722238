import React from 'react';
import useAsyncEffect from 'use-async-effect';
import { useNavigate } from 'react-router-dom';

import LoadingProgress from '../../../../components/LoadingProgress';
import SelectAvatarPageView from './SelectAvatarPageView';

import { useMytaverseEvent } from '../../../dashboard/providers';
import { useDetectSwiperLoop } from '../../hooks';

import ROUTES from '../../../../constants/routes';

const READY_PLAYER_COUNT = 1;

const SelectAvatarPage = () => {
  const {
    loading,
    previousSkin,
    previousCustomAvatar,
    loadEventAvatars,
    avatars,
  } = useMytaverseEvent();
  const loop = useDetectSwiperLoop(avatars.length + READY_PLAYER_COUNT);
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    await loadEventAvatars();
  }, []);

  const onBackButtonClick = () => navigate(ROUTES.SELECT_EVENT);

  if (loading || loop === null) {
    return <LoadingProgress fullHeight />;
  }

  return (
    <SelectAvatarPageView
      hasPreviousAvatar={!!previousSkin || !!previousCustomAvatar}
      loop={loop}
      onBackButtonClick={onBackButtonClick}
    />
  );
};

export default SelectAvatarPage;
