import React from 'react';

import MytaverseAppBar from '../../../../components/MytaverseAppBar';
import DashboardTopBarContent from './DashboardTopBarContent';
import ConfirmLogoutModal, {
  useOpenConfirmLogoutModal,
} from './ConfirmLogoutModal';
import InviteUserToEventModal, {
  useOpenInviteUserToEventModal,
} from './InviteUserToEventModal';

const DashboardTopBar = () => {
  const {
    open: isOpenedConfirmLogoutModal,
    openModal: onOpenConfirmLogoutModal,
    closeModal: onCloseConfirmLogoutModal,
  } = useOpenConfirmLogoutModal();
  const {
    open: isOpenedInviteUserModal,
    openModal: openInviteUserModal,
    closeModal: closeInviteUserModal,
  } = useOpenInviteUserToEventModal();

  return (
    <MytaverseAppBar>
      <DashboardTopBarContent
        onOpenConfirmLogoutModal={onOpenConfirmLogoutModal}
        onOpenInviteUserModal={openInviteUserModal}
      />
      <ConfirmLogoutModal
        open={isOpenedConfirmLogoutModal}
        onClose={onCloseConfirmLogoutModal}
      />
      {isOpenedInviteUserModal ? (
        <InviteUserToEventModal
          open={isOpenedInviteUserModal}
          onClose={closeInviteUserModal}
        />
      ) : null}
    </MytaverseAppBar>
  );
};

export default DashboardTopBar;
