import { colors } from '../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 115,
    height: 115,
    borderRadius: '50%',
    position: 'relative',
    '& .MuiAvatar-root': {
      border: `1px solid ${colors.white}`,
      width: 115,
      height: 115,
    },
  },
  updateLayout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    position: 'absolute',
    inset: 0,
    borderRadius: '50%',
    backdropFilter: 'blur(4px)',
    '& p': {
      color: colors.white,
    },
    '& svg': {
      color: colors.white,
    },
  },
});
