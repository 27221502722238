import * as Yup from 'yup';

import { LINKED_IN_URL } from '../../../constants/regex';

export const UserChangeValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required('Firstname and Lastname are required')
    .max(60, 'Email must be at most 60 characters'),
  linkedIn: Yup.string().matches(LINKED_IN_URL, 'Enter correct url'),
});
