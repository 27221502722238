import React from 'react';

import { Box, MenuItem, Select, Typography } from '@mui/material';

import OverflowTitle from '../OverflowTitle';

import { ISingleSelect } from './interfaces';

import { useStyles } from './styles';

const SingleSelect = ({
  options,
  emptyPlaceholder,
  activeValue,
  onChange,
  disabled,
  sx = {},
  menuProps,
}: ISingleSelect) => {
  const classes = useStyles();

  return (
    <Box sx={sx}>
      {options.length ? (
        <Select
          value={activeValue}
          onChange={onChange}
          disabled={disabled}
          sx={classes.select}
          MenuProps={menuProps}
        >
          {options.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={classes.menuItem}
              disableRipple
            >
              <OverflowTitle title={item.label} sx={classes.selectItemText} />
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography>{emptyPlaceholder}</Typography>
      )}
    </Box>
  );
};

export default SingleSelect;
