import React from 'react';

import { useTranslation } from 'react-i18next';

import SearchFieldView from './SearchFieldView';

import { usePreventGameKeydownListening } from '../../../../hooks';

import { ISearchField } from './interfaces';

const SearchField = ({
  sx = {},
  value,
  onChange,
  onClear,
  placeholder = '',
}: ISearchField) => {
  const inputRef = usePreventGameKeydownListening<HTMLInputElement>();
  const { t: translate } = useTranslation('common');

  return (
    <SearchFieldView
      ref={inputRef}
      sx={sx}
      value={value}
      onChange={onChange}
      onClear={onClear}
      placeholder={placeholder || translate('inputPlaceholders.sidebarSearch')}
    />
  );
};

export default SearchField;
