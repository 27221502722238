import { useTheme } from '@mui/material';

import { useMytaverse } from '../../providers/MytaverseProvider';

import { ScreenSizes } from '../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '32px 46px',
      [breakpoints.down(ScreenSizes.Pc)]: {
        padding: '29px 32px',
      },
      [breakpoints.down(ScreenSizes.Tablet)]: {
        padding: '20px 16px',
      },
    },
    box: {
      flex: 1,
    },
    eventsDropdown: {
      [breakpoints.down(ScreenSizes.Tablet)]: {
        display: 'none',
      },
      '& .MuiInputBase-root': {
        maxWidth: 300,
      },
    },
    menuList: {
      '& .MuiMenuItem-root': {
        maxWidth: 300,
      },
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 2,
      flex: 1,
      justifyContent: 'flex-end',
      [breakpoints.down(ScreenSizes.Tablet)]: {
        columnGap: '4px',
      },
      '& svg': {
        color: appTheme.languageButton.assets.iconColor,
      },
    },
    logoutButton: {
      padding: '7px',
    },
  };
};
