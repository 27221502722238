import React, { useCallback } from 'react';

import { SelectChangeEvent } from '@mui/material';

import EventsDropdownView from './EventsDropdownView';

import { useMytaverse } from '../../providers/MytaverseProvider';

import { sendCustomSentryBreadcrumb } from '../../helpers/sentry';

import { IEventsDropdown } from './intefaces';

const EventsDropdown = ({ onChangeEvent }: IEventsDropdown) => {
  const { events, currentEventId, setCurrentEventId } = useMytaverse();

  const dropdownEvent =
    currentEventId ||
    sessionStorage.getItem('selectedEventId') ||
    events[0].id ||
    '';

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      const event = events.find((event) => event.id === e.target.value);

      if (event) {
        sendCustomSentryBreadcrumb({
          message: `change event id=${event.id}, name=${event.name}`,
        });
        setCurrentEventId(event.id);
        onChangeEvent();
      }
    },
    [events, setCurrentEventId, onChangeEvent],
  );

  return (
    <EventsDropdownView
      currentEventId={dropdownEvent}
      events={events}
      onChange={handleChange}
    />
  );
};

export default EventsDropdown;
