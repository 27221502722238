import { SpatialType } from './webSocketConnectionInfo';
import { EventRole } from './event';

export interface IMediaStream extends MediaStream {
  type: 'Camera' | 'ShareScreen';
  stream: MediaStream;
  participantId: string;
}

export interface IParticipantRegion {
  timestamp: number;
  region: string;
  state: string;
  regionSpatialType?: SpatialType;
}

export enum ParticipantState {
  Loading = 'LOADING',
  Loaded = 'LOADED',
}

export interface IParticipant {
  [x: string]: any;

  id: string;
  key: string;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarString: string;
  avatarImage: string;
  speaking?: boolean;
  eventId?: string | null;
  gameSessionId?: string | null;
  roomId?: string | null;
  region: IParticipantRegion | null;
  isSpeaker: boolean;
  regions: IParticipantRegion[];
  state: ParticipantState;
  isNewParticipantPositionFlow?: boolean;
  dolbyParticipantId?: string | null;
  role?: EventRole;
}

export interface IParticipantInfo {
  [x: string]: any;

  participantId: string;
  eventId: string;
  roomId: string;
  muted: boolean;
}

export interface IParticipantRoles {
  admin: string[];
  coordinator: string[];
  moderator: string[];
}

export interface IParticipantSoundSettings {
  eventId: string;
  participantId: string;
  participantsSound: number;
  selectedLanguage: string;
  gameSound: number;
  showActiveCameras: boolean;
}
