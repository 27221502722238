import { colors } from '../../../../../constants/colors';

export const classes = {
  completed: {
    color: colors.myta,
  },
  loading: {
    color: colors.myta,
  },
  default: {
    color: colors.white,
    opacity: 0.6,
  },
};
