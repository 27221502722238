import React, { useEffect, useRef, useState } from 'react';

import VideoPreviewView from './VideoPreviewView';
import { IVideoView } from '../interfaces';
import { useVideoControls } from './hooks';

const VideoPreview = ({ src }: IVideoView) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current && !video) {
      setVideo(videoRef.current);
    }
  }, [videoRef.current, video]);

  const {
    currentTime,
    parsedTime,
    handleSliderSoundChange,
    handleSliderChange,
    play,
    handlePlayVideo,
    volume,
    handleMuteSound,
    fullscreenHandler,
  } = useVideoControls(videoRef.current);

  return (
    <VideoPreviewView
      currentTime={currentTime}
      parsedTime={parsedTime}
      handleSliderSoundChange={handleSliderSoundChange}
      handleSliderChange={handleSliderChange}
      handlePlayVideo={handlePlayVideo}
      play={play}
      volume={volume}
      fullscreenHandler={fullscreenHandler}
      handleMuteSound={handleMuteSound}
      src={src}
      video={video}
      videoRef={videoRef}
    />
  );
};

export default VideoPreview;
