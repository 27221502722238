import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: 3,
      padding: '14px 16px',
      marginTop: '10px',
      '& .MuiCircularProgress-root': {
        width: 24,
        height: 24,
      },
      '& svg': {
        width: 24,
        height: 24,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '7px 0px',
        '& .MuiCircularProgress-root': {
          width: 16,
          height: 16,
        },
        '& svg': {
          width: 16,
          height: 16,
        },
      },
    },
  };
};
