import { useTheme } from '@mui/material';

import { useCommonTipStyles } from '../../hooks';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const commonStyles = useCommonTipStyles(breakpoints);

  return {
    ...commonStyles,
    tipImage: {
      height: 'inherit',
      '& img': {
        height: '100%',
        objectFit: 'cover',
      },
    },
  };
};
