import React from 'react';

import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import EventsDropdown from '../../../../../components/EventsDropdown';

import { IEventDrawerHeaderView } from './interfaces';

import { classes } from './styles';

const EventDrawerHeaderView = ({
  onCloseEventDrawer,
  onChangeEvent,
}: IEventDrawerHeaderView) => {
  return (
    <Box sx={classes.root}>
      <EventsDropdown onChangeEvent={onChangeEvent} />
      <IconButton onClick={onCloseEventDrawer} sx={classes.button}>
        <CloseIcon sx={classes.icon} />
      </IconButton>
    </Box>
  );
};

export default EventDrawerHeaderView;
