export const useStyles = (color: string) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '12px',
  },
  icon: {
    width: 24,
    height: 20,
    color,
  },
  text: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.1px',
    color,
    whiteSpace: 'nowrap',
  },
});
