import React from 'react';

import { Box } from '@mui/material';

import LoadingStepIcon from './LoadingStepIcon';

import { ILoadingProgressTutorial } from './interfaces';

import { useStyles } from './styles';

const LoadingProgressTutorial = ({
  secondStepLoading,
  secondStepCompleted,
  thirdStepLoading,
  thirdStepCompleted,
  forthStepLoading,
  forthStepCompleted,
}: ILoadingProgressTutorial) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <LoadingStepIcon loading={false} completed={true} />
      <LoadingStepIcon
        loading={secondStepLoading}
        completed={secondStepCompleted}
      />
      <LoadingStepIcon
        loading={thirdStepLoading}
        completed={thirdStepCompleted}
      />
      <LoadingStepIcon
        loading={forthStepLoading}
        completed={forthStepCompleted}
      />
    </Box>
  );
};

export default LoadingProgressTutorial;
