import { ChangeEvent, useMemo, useState } from 'react';

import _ from 'lodash';

export const useSearchField = <T>(searchList: T[], searchField: string) => {
  const [inputValue, setInputValue] = useState('');

  const filteredParticipants = useMemo(() => {
    const filteredSearchList = searchList.filter((searchItem) => {
      const searchValue = _.get(searchItem, searchField);

      return searchValue.toLowerCase().includes(inputValue.toLowerCase());
    });

    return filteredSearchList;
  }, [inputValue, searchField, searchList]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputClear = () => {
    setInputValue('');
  };

  return {
    inputValue,
    filteredData: filteredParticipants,
    handleInputChange,
    handleInputClear,
  };
};
