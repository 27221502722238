import { colors } from '../../../../../../../../constants/colors';

export const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
  iconBox: {
    width: 75,
    height: 75,
    padding: '20px',
    color: colors.white,
    border: `1px solid ${colors.white_60}`,
    backdropFilter: 'blur(6px)',
    borderRadius: '72px',
    marginBottom: '4px',
    cursor: 'pointer',
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  text: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '32px',
    color: colors.white,
    opacity: 0.6,
  },
};
