import React, { ChangeEvent } from 'react';

import { Box, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

import ToolboxHeader from '../../../ToolboxHeader';
import FilesHeader from './FilesHeader';
import FilesWrapper from './FilesWrapper';
import FileItem from './FileItem';

import { useMytaverseEvent } from '../../../../../providers';
import { useSearchField } from '../../../../SearchField';
import { useSort } from '../../../../../../../hooks/sort';
import { usePagination } from '../../../../../../../hooks/pagination';

import {
  downloadFile,
  downloadImage,
} from '../../../../../../../helpers/download';
import { getCatchErrorMessage } from '../../../../../../../helpers/error';

import { SORT_DATA } from './constants';

import { IFiles } from './interfaces';
import { IPointOfInterest } from '../../../../../../../interfaces/pointsOfInterest';
import { SortType } from '../../../../../../../constants/sort';
import { PoiMediaType } from '../../../../DashboardContent/interfaces';

import { classes } from './styles';

const Files = ({ context }: IFiles) => {
  const { text, onClose } = context;

  const { userFiles: files, setUserFiles } = useMytaverseEvent();

  const {
    inputValue,
    filteredData: filteredFiles,
    handleInputChange,
    handleInputClear,
  } = useSearchField(files, 'displayName');

  const { value: defaultValue } = SORT_DATA[0];

  const { sortField, sortType, sortDirection, sortedList, handleSort } =
    useSort({
      list: filteredFiles,
      defaultSortType: defaultValue.type,
      defaultSortField: defaultValue.fieldName,
      defaultSortDirection: defaultValue.direction,
    });

  const {
    currentPage,
    pageNumber,
    getDataPerPage,
    handlePrevPage,
    handleNextPage,
    resetPagination,
  } = usePagination({ list: sortedList });

  const list = getDataPerPage();
  const noSearchResults = !!inputValue && !list.length;
  const noFiles = !files.length;
  const showPagination = !(noFiles || noSearchResults || pageNumber === 1);

  const headerText = (
    <>
      {text}{' '}
      <Typography component="span" sx={classes.amount}>
        ({files.length})
      </Typography>
    </>
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event);
    resetPagination();
  };

  const handleClear = () => {
    handleInputClear();
    resetPagination();
  };

  const handleDownload = (url: string, mediaType: PoiMediaType) => {
    try {
      if (mediaType === PoiMediaType.IMAGE) {
        downloadImage(url);
      } else {
        downloadFile(url);
      }
    } catch (error) {
      throw Error(getCatchErrorMessage(error));
    }
  };

  const handleOpenInNewTab = (asset: string) => {
    window.open(asset, '_blank');
  };

  const handleDelete = (id: string) => {
    setUserFiles((files) => files.filter((file) => file.id !== id));
  };

  return (
    <Box>
      <ToolboxHeader
        icon={DescriptionIcon}
        text={headerText}
        onClose={onClose}
      />
      <FilesHeader
        value={inputValue}
        onChange={handleChange}
        onClear={handleClear}
        activeSortField={sortField as keyof IPointOfInterest}
        activeSortType={sortType as SortType}
        activeSortDirection={sortDirection}
        onSort={handleSort}
        currentPage={currentPage}
        pageNumber={pageNumber}
        onPrevPage={handlePrevPage}
        onNextPage={handleNextPage}
        showPagination={showPagination}
      />
      <FilesWrapper noFiles={noFiles} noSearchResults={noSearchResults}>
        {list.map((file) => (
          <FileItem
            key={file.id}
            file={file}
            onDownload={() => handleDownload(file.asset, file.mediaType)}
            onOpenInNewTab={() => handleOpenInNewTab(file.asset)}
            onDelete={() => handleDelete(file.id)}
          />
        ))}
      </FilesWrapper>
    </Box>
  );
};

export default Files;
