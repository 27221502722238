import { useTheme } from '@mui/material';

import { isTablet } from 'react-device-detect';

import { colors } from '../../../../constants/colors';

import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      backgroundColor: colors.white,
      width: '100vw',
      height: '100vh',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    content: {
      width: '84%',
      textAlign: 'center',
    },
    icon: {
      width: isTablet ? 118 : 72,
      height: isTablet ? 143 : 88,
      marginBottom: isTablet ? '53px' : '33px',
      '@keyframes rotate': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '45%': {
          transform: 'rotate(90deg)',
        },
        '100%': {
          transform: 'rotate(0deg)',
        },
      },
      animation: 'rotate 3s infinite',
    },
    title: {
      fontWeight: 500,
      fontSize: 28,
      lineHeight: '44px',
      color: colors.oxford,
      marginBottom: 2,
      [breakpoints.down(ScreenSizes.Pc)]: {
        fontSize: 20,
        lineHeight: '24px',
      },
    },
    description: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '28px',
      color: colors.oxford,
    },
    boldText: {
      inherit: 'all',
      fontWeight: 600,
    },
  };
};
