import React from 'react';

import { useTranslation } from 'react-i18next';

import { useMytaverse } from '../../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../../providers';
import { useConference } from '../../../DashboardContent/Dolby';
import DevicesSettingsGeneralView from './DevicesSettingsGeneralView';

const DevicesSettingsGeneral = () => {
  const { selectLanguage, selectedLanguage } = useMytaverse();
  const { streamQuality, setStreamQuality, isConferenceInitialized } =
    useConference();
  const {
    updateRoomScale,
    showActiveCameras,
    setShowActiveCameras,
    gameSound,
    setGameSound,
    participantsSound,
    setParticipantsSound,
    setBubblesWithNearbyParticipants,
    bubblesWithNearbyParticipants,
    videoBubbleSize,
    setVideoBubbleSize,
  } = useMytaverseEvent();
  const { t: translate } = useTranslation('common');

  const devices = [
    {
      label: translate('settingsModal.high'),
      deviceId: 'fhd',
    },
    {
      label: translate('settingsModal.medium'),
      deviceId: 'hdplus',
    },
    {
      label: translate('settingsModal.low'),
      deviceId: 'hd',
    },
  ];

  const handleChange = (event: Event, newValue: number | number[]) => {
    setGameSound((newValue as number) / 100);
  };

  const handleLoudness = (event: Event, newValue: number | number[]) => {
    setParticipantsSound((newValue as number) / 100);
  };

  const handleBubblesSize = (event: Event, newValue: number | number[]) => {
    setVideoBubbleSize(newValue as number);
  };

  return (
    <DevicesSettingsGeneralView
      selectLanguage={selectLanguage}
      selectedLanguage={selectedLanguage}
      setStreamQuality={setStreamQuality}
      streamQuality={streamQuality || 'High'}
      translate={translate}
      participantsSound={participantsSound}
      gameSound={gameSound}
      handleChange={handleChange}
      handleLoudness={handleLoudness}
      updateRoomScale={updateRoomScale}
      devices={devices}
      showActiveCameras={showActiveCameras}
      setShowActiveCameras={setShowActiveCameras}
      isConferenceInitialized={isConferenceInitialized}
      setBubblesWithNearbyParticipants={setBubblesWithNearbyParticipants}
      videoBubbleSize={videoBubbleSize}
      handleBubblesSize={handleBubblesSize}
      bubblesWithNearbyParticipants={bubblesWithNearbyParticipants}
    />
  );
};

export default DevicesSettingsGeneral;
