export const useStyles = () => ({
  iframeWrapper: {
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflowY: 'hidden',
    '& iframe': {
      width: '100%',
      height: 'calc(100vh - 64px)',
      margin: 0,
      fontSize: 14,
      border: 'none',
    },
  },

  back: {
    position: 'fixed',
    top: 180,
    left: 60,
  },
});
