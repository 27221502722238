import React from 'react';

import { AppBar } from '@mui/material';

import { IMytaverseAppBar } from './interfaces';

import { useStyles } from './styles';

const MytaverseAppBar = ({
  children,
  position = 'top',
  sx,
}: IMytaverseAppBar) => {
  const classes = useStyles({ position });
  const rootSx = { ...classes.root, ...sx };

  return (
    <AppBar
      position={position === 'top' ? 'static' : 'absolute'}
      color="transparent"
      sx={rootSx}
    >
      {children}
    </AppBar>
  );
};

export default MytaverseAppBar;
