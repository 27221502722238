import { RefObject } from 'react';
import { OptionsObject, SnackbarKey } from 'notistack';
import { MessageResponse } from 'stream-chat';
import { StreamChatGenerics } from 'stream-chat-react/dist/stories/utils';

import { NOTIFICATION_TYPES } from './constants';

import { SVGType } from '../../interfaces';

export interface INotification {
  ref?: RefObject<HTMLDivElement>;
  id: SnackbarKey;
  title?: string;
  message: string;
  type: NOTIFICATION_TYPES;
  withButton?: boolean;
}

export interface INotificationView {
  title?: string;
  message: string;
  color: string;
  background: string;
  icon: SVGType;
  reloadPage: () => void;
  onDismiss: () => void;
  withButton?: boolean;
  onClickClose: () => void;
}

export interface INotificationStyles {
  color: string;
  background: string;
  title: string;
}

export type GetMessageDataType = (type: NOTIFICATION_TYPES) => {
  icon: SVGType;
  color: string;
  background: string;
};

export interface IGetNotificationProps extends OptionsObject {
  title?: string;
  message: string;
  type: NOTIFICATION_TYPES;
  closeManually?: boolean;
  withButton?: boolean;
  logMessage?: string;
}

export type GetNotificationType = (
  props: IGetNotificationProps,
) => IGetNotificationProps;

export interface IGetSpecificNotificationProps extends OptionsObject {
  title?: string;
  message: string;
  type?: NOTIFICATION_TYPES;
  closeManually?: boolean;
  withButton?: boolean;
}

export type GetSpecificNotificationType = (
  props: IGetSpecificNotificationProps,
) => IGetNotificationProps;

export interface IGetDolbyNotificationProps
  extends Omit<IGetSpecificNotificationProps, 'message'> {
  message?: string;
}

export interface IGetMillicastNotificationProps
  extends Omit<IGetSpecificNotificationProps, 'message'> {
  message?: string;
}

export type GetDolbyNotificationType = (
  props?: IGetDolbyNotificationProps,
) => IGetNotificationProps;

export type GetMillicastNotificationType = (
  props?: IGetMillicastNotificationProps,
) => IGetNotificationProps;

export type ShowNotificationType = (
  props: IGetNotificationProps,
) => SnackbarKey;

export type UseNotificationType = () => {
  showNotification: ShowNotificationType;
  showChatNotification: ShowChatNotificationType;
  hideNotification: (id: SnackbarKey) => void;
  getSuccessNotification: GetSpecificNotificationType;
  getBadRequestNotification: GetSpecificNotificationType;
  getStreamingNotification: GetSpecificNotificationType;
  getDolbyNotification: GetDolbyNotificationType;
  getMillicastNotification: GetMillicastNotificationType;
  getWebsocketNotification: GetSpecificNotificationType;
};

export enum CloseReasonEnum {
  TIMEOUT = 'timeout',
}

export type ChatMessageType = MessageResponse<StreamChatGenerics> & {
  userImage: string;
};

export interface IChatNotificationProps extends OptionsObject {
  message: ChatMessageType;
  onReply: (userId: string) => void;
}

export type ShowChatNotificationType = (
  props: IChatNotificationProps,
) => SnackbarKey;
