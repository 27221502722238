import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

import {
  getSessionStorageValue,
  setSessionStorageValue,
} from '../../helpers/sessionStorage';

import { COLORS } from '../../constants/colors';
import { NOTIFICATION_TYPES } from './constants';

import {
  GetMessageDataType,
  GetNotificationType,
  GetSpecificNotificationType,
} from './interfaces';
import { SessionStorage } from '../../constants/storage';

export const getMessageData: GetMessageDataType = (type) => {
  const messageData = {
    [NOTIFICATION_TYPES.ERROR]: {
      icon: ErrorRoundedIcon,
      color: COLORS.ERROR,
      background:
        'radial-gradient(45.47% 122.24% at 97.04% -19.65%, rgba(72, 18, 18, 0.9) 0%, rgba(11, 11, 13, 0.9) 100%)',
    },
    [NOTIFICATION_TYPES.WARNING]: {
      icon: ErrorRoundedIcon,
      color: '#FFBB54',
      background:
        'radial-gradient(45.47% 122.24% at 97.04% -19.65%, rgba(197, 131, 34, 0.9) 0%, rgba(11, 11, 13, 0.9) 100%)',
    },
    [NOTIFICATION_TYPES.SUCCESS]: {
      icon: CheckCircleRoundedIcon,
      color: '#53CD3F',
      background:
        'radial-gradient(45.47% 122.24% at 97.04% -19.65%, rgba(35, 72, 18, 0.9) 0%, rgba(11, 11, 13, 0.9) 100%)',
    },
  };

  return messageData[type];
};

export const getNotification: GetNotificationType = ({
  title = '',
  message,
  ...props
}) => {
  return {
    title,
    message,
    ...props,
  };
};

export const getErrorNotification: GetSpecificNotificationType = ({
  title = '',
  message,
  ...props
}) => {
  return getNotification({
    title,
    message,
    type: NOTIFICATION_TYPES.ERROR,
    ...props,
  });
};

export const setDismissNotificationMessage = (message: string) => {
  const dismissMessages =
    getSessionStorageValue<string[]>(SessionStorage.DismissMessages) || [];

  const finalMessage = message.slice(0, 21);

  setSessionStorageValue(
    SessionStorage.DismissMessages,
    dismissMessages.concat(finalMessage),
  );
};

export const checkIsDismissNotification = (message: string): boolean => {
  const dismissMessages =
    getSessionStorageValue<string[]>(SessionStorage.DismissMessages) || [];

  const hasDismissMessage = dismissMessages.some((dismissMessage) =>
    message.toLowerCase().startsWith(dismissMessage.toLowerCase()),
  );

  return hasDismissMessage;
};
