import React from 'react';

import { Box } from '@mui/material';

import LoggedAppBar from '../../../../components/LoggedAppBar';
import BackButton from '../../components/BackButtonOld';

import { IReadyPlayerMeView } from './interfaces';

import { useStyles } from './styles';

const ReadyPlayerMeView = ({
  frameRef,
  onBackBtnClick,
}: IReadyPlayerMeView) => {
  const classes = useStyles();

  return (
    <>
      <LoggedAppBar />
      <Box component="div" sx={classes.iframeWrapper}>
        <Box component="div" sx={classes.back}>
          <BackButton onClick={onBackBtnClick} text="to avatars" />
        </Box>
        <iframe
          ref={frameRef}
          id="frame"
          src={process.env.REACT_APP_READY_PLAYER_ME}
          className="frame"
          allow="camera *; microphone *; clipboard-write"
        />
      </Box>
    </>
  );
};

export default ReadyPlayerMeView;
