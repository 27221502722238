import React from 'react';

import { Box, Typography } from '@mui/material';

import { IToolboxCard } from './interfaces';

import { classes } from './styles';

const ToolboxCard = ({ icon: Icon, text, onClick }: IToolboxCard) => {
  return (
    <Box sx={classes.root} onClick={onClick}>
      <Box sx={classes.iconBox}>
        <Icon />
      </Box>
      <Typography sx={classes.text}>{text}</Typography>
    </Box>
  );
};

export default ToolboxCard;
