import { GetFullUserNameType } from './interfaces';

export const getFullUserName: GetFullUserNameType = ({
  firstName,
  lastName,
}) => {
  if (!firstName && !lastName) return '';

  return `${firstName} ${lastName}`.replace(/\s+/g, ' ').trim();
};
