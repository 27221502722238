import $api from '../http/axios';
import {
  IUserCredentials,
  IAutoUserCredentials,
  ILoginUserResponse,
} from '../interfaces/user';

export default class LoginService {
  static loginUser(credo: IUserCredentials): Promise<ILoginUserResponse> {
    return $api.post('auth/signIn', credo).then((res) => res.data);
  }

  static autoLoginUser(
    credo: IAutoUserCredentials,
  ): Promise<ILoginUserResponse> {
    return $api.post('auth/autoSignIn', credo).then((res) => res.data);
  }

  static getUser(id: string) {
    return $api.get(`cms/users/get/${id}`, {}).then((res) => res.data);
  }

  static socialLogin(credo: any) {
    return $api.post('auth/socialSignIn', credo).then((res) => res.data);
  }

  static resetPassword(email: string, url: string) {
    return $api
      .post(`${process.env.REACT_APP_API_URL}auth/passwordReset`, {
        email,
        url,
      })
      .then((res) => res.data);
  }

  static confirmEmail(token: string) {
    return $api
      .post(`${process.env.REACT_APP_API_URL}auth/emailConfirm`, {
        token,
      })
      .then((res) => res.data.result);
  }
}
