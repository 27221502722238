import React, { BaseSyntheticEvent } from 'react';

import {
  useChannelStateContext,
  useMessageInputContext,
} from 'stream-chat-react';

import ChatInputView from './ChatInputView';

import { usePreventGameKeydownListening } from '../../../../../hooks';
import { useMytaverseEvent } from '../../../providers';

import { StreamChatGenerics } from 'stream-chat-react/dist/stories/utils';

const ChatInput = () => {
  const {
    textareaRef,
    text,
    handleChange,
    handleSubmit,
    emojiPickerIsOpen,
    openEmojiPicker,
    closeEmojiPicker,
    uploadNewFiles,
  } = useMessageInputContext();
  const { quotedMessage } = useChannelStateContext<StreamChatGenerics>();
  const { currentParticipant } = useMytaverseEvent();

  const preventKeydownCallback = (event: Event) => {
    if ((event as KeyboardEvent).key !== 'Enter') {
      event.stopPropagation();
    }
  };

  usePreventGameKeydownListening<HTMLTextAreaElement>(
    textareaRef,
    preventKeydownCallback,
  );

  const handleSendMessage = (event: BaseSyntheticEvent) => {
    handleSubmit(event, { userImage: currentParticipant?.avatarImage || '' });
  };

  return (
    <ChatInputView
      hasText={!!text}
      quotedMessage={quotedMessage}
      onClickEmoji={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
      onChange={handleChange}
      onSubmit={handleSendMessage}
      onSelectFiles={uploadNewFiles}
    />
  );
};

export default ChatInput;
