import React from 'react';

import MicrophoneTest from '../components/MicrophoneTest';
import ConnectionTest from '../components/ConnectionTest';
import SpeakerTest from '../components/SpeakerTest';
import WebcamTest from '../components/WebcamTest';

import { StepsContentType } from './interfaces';

export const STEPS_CONTENT: StepsContentType[] = [
  () => <ConnectionTest />,
  () => <MicrophoneTest />,
  () => <SpeakerTest />,
  () => <WebcamTest />,
];

export const DEVICES_TEST_STEPS_COUNT = STEPS_CONTENT.length;
