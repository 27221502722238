import { COLORS } from '../../constants/colors';

import { IStyles } from '../../interfaces/styles';

export const classes: IStyles = {
  onlineIcon: {
    display: 'flex',
    color: COLORS.GREEN,
    fontSize: 8,
  },
  offlineIcon: {
    color: COLORS.RED,
    fontSize: 8,
    opacity: 0.5,
  },
};
