import React from 'react';

import { Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

import { ISearchFieldView } from './interfaces';

import { useStyles } from './styles';

const SearchFieldView = React.forwardRef<
  HTMLInputElement | undefined,
  ISearchFieldView
>(({ sx = {}, value, onChange, onClear, placeholder }, ref) => {
  const classes = useStyles({ value });
  const inputSx = { ...classes.input, ...sx };

  return (
    <Input
      ref={ref}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      startAdornment={<SearchIcon sx={classes.searchIcon} />}
      endAdornment={
        value ? <CancelIcon sx={classes.clearIcon} onClick={onClear} /> : null
      }
      fullWidth
      sx={inputSx}
    />
  );
});

SearchFieldView.displayName = 'SearchFieldView';

export default SearchFieldView;
