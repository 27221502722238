import { COLORS } from '../../../../../constants/colors';

import { IStyles } from '../../../../../interfaces/styles';
import { IDashboardTopBarContentStyles } from './interfaces';

export const useStyles = ({
  isOpenChat,
  hasMediaStream,
}: IDashboardTopBarContentStyles): IStyles => ({
  root: {
    display: ['none', 'flex'],
    mt: 1,
    columnGap: 2,
  },
  rootLoading: {
    display: 'flex',
    mt: 1,

    '& svg': {
      width: 184,
      heigh: 32,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconButton: {
    display: ['block', 'none'],
    padding: 0,
  },
  stack: {
    mt: 1,
    mr: isOpenChat || hasMediaStream ? 50 : 'unset',
    columnGap: 2,
  },
  indicator: {
    color: `${COLORS.GREEN} !important`,
    width: 15,
    height: 15,
  },
  inviteUserBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  addPersonIcon: {
    color: COLORS.GREY,
  },
  logoutIcon: { color: 'red' },
});
