import React from 'react';

import EventDropdownItemView from './EventDropdownItemView';

import { useMytaverse } from '../../../providers/MytaverseProvider';

import { IEventDropdownItem } from './interfaces';

const EventDropdownItem = ({ event }: IEventDropdownItem) => {
  const { appTheme } = useMytaverse();

  return (
    <EventDropdownItemView
      event={event}
      fontColor={appTheme.eventDropdown.assets.fontColor}
    />
  );
};

export default EventDropdownItem;
