import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APIEnvironment, Mytaverse } from 'mytaverse-sdk-wip';

import { useNotification } from '../../../../components/Notification';
import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { setSentryNotAuthUserData } from '../../../../helpers/sentry';
import { getCatchErrorMessage } from '../../../../helpers/error';

import Firebase from '../../../../services/Firebase';
import ROUTES from '../../../../constants/routes';
import EventsService from '../../../../services/EventsService';

import { SocialProviders } from '../../../../interfaces';
import { IFormValues } from './interfaces';

export const useSignUp = () => {
  const [loading, setLoading] = useState(false);
  const { setLoading: setAppLoading, setIsAuthenticated } = useMytaverse();
  const { showNotification, getBadRequestNotification } = useNotification();
  const { t: translate } = useTranslation('common');
  const navigate = useNavigate();

  const mytaverseApi = new Mytaverse(
    process.env.REACT_APP_MYTAVERSE_ENV as APIEnvironment,
  );

  const signUp = async (credentials: IFormValues) => {
    try {
      setLoading(true);
      setAppLoading(true);

      const trimmedCredentials = {
        ...credentials,
        email: credentials.email.toLowerCase().trim(),
      };

      setSentryNotAuthUserData(trimmedCredentials.email);

      const inviteToken = sessionStorage.getItem('inviteToken');

      const response = await mytaverseApi.signUp({
        ...trimmedCredentials,
        emailConfirmationUrl: `${window.location.origin}${ROUTES.LOGIN}/${ROUTES.CONFIRMED_EMAIL}`,
      } as never);

      if (response.error) {
        throw Error(translate(`apiErrors.${response.error.message}`));
      }

      if (response.data && inviteToken) {
        await EventsService.confirmEvent({
          token: inviteToken,
          email: response.data.user.email,
        });
        sessionStorage.removeItem('inviteToken');
      }

      setIsAuthenticated(true);
      navigate(
        `${ROUTES.LOGIN}/${ROUTES.CONFIRM_EMAIL}?email=${encodeURIComponent(
          trimmedCredentials.email,
        )}`,
      );
    } catch (error: unknown) {
      const errorMessage = getCatchErrorMessage(error);

      showNotification(getBadRequestNotification({ message: errorMessage }));
      setIsAuthenticated(false);
      throw Error(getCatchErrorMessage(error));
    } finally {
      setLoading(false);
      setAppLoading(false);
    }
  };

  return { loading, signUp };
};

export const useSocialSignUp = () => {
  const [socialLoading, setSocialLoading] = useState<SocialProviders | string>(
    '',
  );
  const { showNotification, getBadRequestNotification } = useNotification();
  const { setLoading, socialLogin } = useMytaverse();

  const socialSignUp = async (
    provider: SocialProviders,
    validEmail: string,
  ) => {
    try {
      setSocialLoading(provider);

      const { success, user } = await Firebase.signInWithProvider(provider);

      if (!success) {
        throw new Error('Failed to sign in by google account');
      }

      if (user?.email !== validEmail) {
        showNotification(
          getBadRequestNotification({
            message: 'This is not correct email from invite link',
          }),
        );

        setLoading(false);
        return;
      }

      socialLogin(user);
    } catch (error: unknown) {
      throw Error(getCatchErrorMessage(error));
    } finally {
      setSocialLoading('');
    }
  };

  return { socialLoading, socialSignUp };
};
