import React from 'react';
import { Box, Typography } from '@mui/material';

import SearchField from '../../../SearchField';
import ScrollableContainer from '../../../../../../components/ScrollableContainer';
import ParticipantSearchItem from '../ParticipantSearchItem';
import ArrowButton from '../../ArrowButton';

import { checkIsStreamChannelUnread } from '../../../../helpers';

import { ISearchChatView } from './interfaces';

import { useStyles } from './styles';
import ParticipantStatusIcon from '../../../../../../components/ParticipantStatusIcon';

const SearchChatView = ({
  results,
  inputValue,
  onInputChange,
  onInputClear,
  onItemClick,
}: ISearchChatView) => {
  const classes = useStyles();

  const noSearchResult = !results.length && !!inputValue;

  return (
    <Box sx={classes.root}>
      <Box sx={classes.inputContainer}>
        <SearchField
          value={inputValue}
          onChange={onInputChange}
          onClear={onInputClear}
        />
      </Box>
      {noSearchResult ? (
        <Typography sx={classes.noSearchResult}>
          No results for “{inputValue}”
        </Typography>
      ) : (
        <ScrollableContainer
          sxProps={{
            scrollContent: classes.scrollContent,
            content: classes.content,
          }}
        >
          {results.map(({ participant, privateChannel }) => {
            const lastMessage =
              privateChannel?.channel?.lastMessage()?.text || '';
            const isUnreadChannel = checkIsStreamChannelUnread(
              privateChannel?.channel || null,
            );

            return (
              <ParticipantSearchItem
                key={participant.id}
                item={participant}
                lastMessage={lastMessage}
                isUnreadChannel={isUnreadChannel}
                onClick={() => onItemClick(participant.userId)}
                actions={
                  <>
                    <ParticipantStatusIcon
                      isOnline={!!participant.roomId}
                      className="participantStatusIcon"
                      sx={classes.statusIcon}
                    />
                    <ArrowButton sx={classes.resultItemAction} forwardIcon />
                  </>
                }
                classes={{
                  root: classes.resultItem(lastMessage),
                  name: classes.resultItemName(isUnreadChannel),
                }}
              />
            );
          })}
        </ScrollableContainer>
      )}
    </Box>
  );
};

export default SearchChatView;
