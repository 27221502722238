import { useEffect, useState } from 'react';

import { checkMuteModalHidden } from '../../../helpers';

import { UseShowInitialMuteModalType } from './interfaces';

export const useShowInitialMuteModal: UseShowInitialMuteModalType = ({
  eventId,
  initialMuteModalData,
}) => {
  const { openModal } = initialMuteModalData;

  useEffect(() => {
    const muteModalHidden = checkMuteModalHidden(eventId);

    if (!muteModalHidden) {
      openModal();
    }
  }, [eventId]);
};

export const useOpenDevicesSettingsModal = () => {
  const [open, setOpen] = useState(false);

  const switchOpenModal = () => setOpen((open) => !open);

  const closeModal = () => setOpen(false);

  return {
    open,
    switchOpenModal,
    closeModal,
  };
};
