import { SchemaOf, ValidationError } from 'yup';
import { FormikErrors } from 'formik';

export const validateForm = <T extends { password: string }>(
  schema: SchemaOf<T>,
  values: T,
): Promise<FormikErrors<T>> => {
  return schema
    .validate(values, { abortEarly: false })
    .then(() => ({}))
    .catch((error: ValidationError) => {
      const newErrors = error.inner.reduce<FormikErrors<T>>((acc, error) => {
        if (!error.path) return acc;

        const param = error.path;

        if (param.includes('password')) {
          const passwordErrors = (acc.password as string) || '';

          // map password error string to 'minLength.oneLowerCase' and etc.
          (acc.password as string) = passwordErrors.concat(
            `${passwordErrors ? ';' : ''}${error.message}`,
          );

          return acc;
        }

        if (!acc[param as keyof T]) {
          (acc[param as keyof T] as string) = error.message;
        }

        return acc;
      }, {});

      return newErrors;
    });
};
