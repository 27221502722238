/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { IParticipant } from '../../../../../interfaces/participants';
import { useStyles } from './styles';

const SharingWindowVideo = ({
  participant,
  mediaStream,
}: {
  participant: IParticipant;
  mediaStream: MediaStream;
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const classes = useStyles();
  useEffect(() => {
    //@ts-ignore
    navigator.attachMediaStream(
      videoRef.current,
      mediaStream,
    );
  }, [participant]);

  return (
    <>
      <Typography sx={classes.name}>{participant.fullName}</Typography>
      <video ref={videoRef} autoPlay playsInline muted={false} />
    </>
  );
};

export default SharingWindowVideo;
