import { SxProps } from '@mui/material';

import { getScrollbarStyles } from '../../../helpers';

import { COLORS } from '../../../../../constants/colors';

import { IChatInputStyles } from './types';

const iconStyles: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 32,
  height: 32,
  flexShrink: 0,
};

const fileUploadStyles: SxProps = {
  '& .rfu-dropzone': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .rfu-image-previewer': {
      padding: '0 8px',
    },
    '& .rfu-file-previewer': {
      display: 'flex',
      width: '100%',
      overflowX: 'scroll',
    },
    '& .rfu-file-previewer__file': {
      '&:hover': {
        background: 'transparent',
      },
    },
    '& .rfu-file-upload-button': {
      fontSize: 0,
      ...iconStyles,
      '& svg': {
        color: COLORS.WHITE,
        opacity: 0.6,
      },
      '&:hover svg': {
        color: COLORS.WHITE,
        opacity: 0.6,
      },
    },
  },
};

export const useStyles = ({ hasText }: IChatInputStyles) => ({
  root: {
    ...getScrollbarStyles({ width: 14, borderWidth: 4 }),
    ...fileUploadStyles,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#1c1c1c',
    padding: 2,
    columnGap: 1,
    '& .str-chat__input--emojipicker': {
      right: 'auto',
    },
  },
  emojiIcon: {
    ...iconStyles,
    fontSize: 0,
    '& svg': {
      color: COLORS.WHITE,
      opacity: 0.6,
      cursor: 'pointer',
    },
  },
  input: {
    flexGrow: 1,
    '& .str-chat__textarea': {
      display: 'flex',
      '& textarea': {
        flexGrow: 1,
        border: 'none',
        borderRadius: 0,
        backgroundColor: '#1c1c1c',
        color: COLORS.WHITE,
        fontWeight: 400,
        fontSize: 18,
        lineHeight: 1.33,
        letterSpacing: '0.025em',
        padding: 0,
        minHeight: 24,
        maxHeight: 590,
        boxShadow: 0,
        resize: 'none',
        '&::placeholder': {
          color: COLORS.WHITE,
          opacity: 0.6,
          fontWeight: 400,
          fontSize: 18,
          lineHeight: 1.33,
          letterSpacing: '0.025em',
        },
      },
    },
  },
  btnContainer: {
    '& .str-chat__send-button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: 0,
      height: 32,
      width: 32,
      margin: 0,
      '& svg': {
        height: 24,
        width: 24,
        '& path': {
          fill: COLORS.WHITE,
          opacity: hasText ? 1 : 0.6,
        },
        '& title': {
          display: 'none',
        },
      },
    },
  },
});
