import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import LoadingTutorial from './LoadingTutorial';
import Footer from '../../../../components/Footer';

import { IAppLoader } from './interfaces';

import { useStyles } from './styles';

const AppLoader = ({ title, children, t: translate }: IAppLoader) => {
  const classes = useStyles(!!children);

  return (
    <Box sx={classes.root}>
      <Box sx={classes.background} />
      <Box sx={classes.container}>
        {children}
        <Box sx={classes.content}>
          {!!title && <Typography sx={classes.title}>{title}</Typography>}
          <LoadingTutorial />
        </Box>
        <Footer sx={classes.footer} custom>
          <Typography sx={classes.footerText}>
            {translate('loading.footer')}
          </Typography>
        </Footer>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(AppLoader);
