import { saveAs } from 'file-saver';

import FilesService from '../services/FilesService';

const getImageBlob = async (url: string): Promise<Blob> => {
  const files = await FilesService.getS3Files([url]);
  const buffer = new Uint8Array(files[0].data).buffer;
  const blob = new Blob([buffer]);

  return blob;
};

const getBlob = async (url: string): Promise<Blob> => {
  return await fetch(url).then((res) => res.blob());
};

const getFileName = (url: string): string => {
  const fileNameIndex = url.lastIndexOf('/') + 1;

  return url.substring(fileNameIndex);
};

export const downloadFile = async (url: string) => {
  const fileBlob = await getBlob(url);
  const fileName = getFileName(url);

  saveAs(fileBlob, fileName);
};

export const downloadImage = async (url: string) => {
  const fileBlob = await getImageBlob(url);
  const fileName = getFileName(url);

  saveAs(fileBlob, fileName);
};
