import { SwiperOptions } from 'swiper';

export const getSelectEventsSwiperMaxWidth = (
  breakpoints: SwiperOptions,
  sliderWidth: number,
): number => {
  const slidesPerView = breakpoints.slidesPerView as number;
  const spaceBetween = breakpoints.spaceBetween as number;

  const spaceBetweenWidth =
    slidesPerView > 1 ? (slidesPerView - 1) * spaceBetween : 0;

  return sliderWidth * slidesPerView + spaceBetweenWidth;
};
