import {
  Backdrop,
  Box,
  Typography,
  Modal,
  IconButton,
  Slider,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';

import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './styles';
import { useMytaverseEvent } from '../../providers';
import { useConference } from '../DashboardContent/Dolby';

const AdminSettings = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation('common');
  const {
    setOpenAdminSettingsModal,
    currentRoomDolbySpatialAudioScale,
    updateRoomScale,
  } = useMytaverseEvent();

  const [currentRoomScale, setCurrentRoomScale] = React.useState(
    currentRoomDolbySpatialAudioScale,
  );

  const { isConferenceInitialized } = useConference();

  const handleClose = () => {
    setOpenAdminSettingsModal(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box sx={classes.modal} component="div">
        <Box sx={classes.title} component="div">
          <Box component="div" sx={classes.titleWrapper}>
            <SettingsIcon />
            <Typography sx={classes.titleName}>
              Admin {translate('settingsModal.settings')}
            </Typography>
          </Box>
          <IconButton onClick={handleClose} sx={classes.titleButton}>
            <CloseIcon />
          </IconButton>
        </Box>
        {isConferenceInitialized ? (
          <Box component="div" sx={classes.slider}>
            <Typography>Room Scale</Typography>
            <Slider
              step={10}
              aria-label="Volume"
              value={Number(currentRoomScale)}
              max={200}
              onChange={(_, value) => setCurrentRoomScale(value as number)}
              onChangeCommitted={(_, value) => updateRoomScale(value as number)}
              valueLabelDisplay="on"
              marks
            />
          </Box>
        ) : null}
      </Box>
    </Modal>
  );
};

export default AdminSettings;
