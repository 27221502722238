import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';

import { IParticipantStatusIcon } from './interfaces';

import { classes } from './styles';

const ParticipantStatusIcon = ({
  isOnline,
  className = '',
  sx = {},
}: IParticipantStatusIcon) => {
  const rootSx = {
    ...(isOnline ? classes.onlineIcon : classes.offlineIcon),
    ...sx,
  };

  return <CircleIcon className={className} sx={rootSx} />;
};

export default ParticipantStatusIcon;
