export const useStyles = () => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 56px)',
    gap: '24px 32px',
    justifyContent: 'center',
    padding: '16px 28px',
    '& img': {
      width: 56,
      height: 56,
      cursor: 'pointer',
    },
  },
});
