import React from 'react';

import { Card, CardContent, CardMedia } from '@mui/material';

import OverflowTitle from '../../../../../components/OverflowTitle';

import { ISelectEventItemView } from './interfaces';

import { useStyles } from './styles';

const SelectEventItemView = ({ event, onClick }: ISelectEventItemView) => {
  const classes = useStyles();

  return (
    <Card sx={classes.root} onClick={onClick}>
      <CardMedia
        component="img"
        height="216"
        image={event.logoImage || event.image}
        alt=""
        sx={classes.image}
      />
      <CardContent sx={classes.content}>
        <OverflowTitle
          title={event.name}
          component="div"
          sx={classes.eventName}
        />
      </CardContent>
    </Card>
  );
};

export default SelectEventItemView;
