import React from 'react';

import { TabPanel } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
  Typography,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import HighQualityIcon from '@mui/icons-material/HighQuality';

import HomeBottomButtonOptionsSettings from '../../components/HomeBottomButtonOptionsSettings/HomeBottomButtonOptionsSettings';
import { languages } from './constants';
import { useStyles } from './styles';
import { DevicesSettingsGeneralViewProps } from './interfaces';

const DevicesSettingsGeneralView = ({
  selectLanguage,
  selectedLanguage,
  streamQuality,
  setStreamQuality,
  translate,
  gameSound,
  handleChange,
  participantsSound,
  handleLoudness,
  devices,
  showActiveCameras,
  setShowActiveCameras,
  bubblesWithNearbyParticipants,
  setBubblesWithNearbyParticipants,
  videoBubbleSize,
  handleBubblesSize,
}: DevicesSettingsGeneralViewProps) => {
  const classes = useStyles();

  return (
    <TabPanel value="general" sx={classes.root}>
      <HomeBottomButtonOptionsSettings
        devices={languages}
        handleSelect={(e) => selectLanguage(e.target.value)}
        label={translate('settingsModal.language')}
        selectedValue={selectedLanguage}
        SelectIcon={LanguageIcon}
      />

      <HomeBottomButtonOptionsSettings
        devices={devices}
        handleSelect={(e) => setStreamQuality(e.target.value)}
        label={translate('settingsModal.quality')}
        selectedValue={streamQuality}
        SelectIcon={HighQualityIcon}
      />
      <Box sx={classes.slider}>
        <Typography>{translate('settingsModal.worldSound')}</Typography>
        <Slider
          aria-label="Volume"
          value={gameSound * 100}
          onChange={handleChange}
        />
      </Box>
      <Box sx={classes.slider}>
        <Typography>{translate('settingsModal.loudness')}</Typography>
        <Slider
          aria-label="Volume"
          value={participantsSound * 100}
          onChange={handleLoudness}
        />
      </Box>
      <Box sx={classes.slider}>
        <Typography>{translate('settingsModal.bubblesSize')}</Typography>
        <Slider
          aria-label="Bubble size"
          value={videoBubbleSize}
          min={60}
          max={140}
          onChange={handleBubblesSize}
        />
      </Box>
      <Box component="div" sx={classes.checkBox}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showActiveCameras}
                onChange={() => setShowActiveCameras(!showActiveCameras)}
              />
            }
            label="Show active cameras"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={bubblesWithNearbyParticipants}
                onChange={() =>
                  setBubblesWithNearbyParticipants(
                    !bubblesWithNearbyParticipants,
                  )
                }
              />
            }
            label="Nearby cams(experimental)"
          />
        </FormGroup>
      </Box>
    </TabPanel>
  );
};

export default DevicesSettingsGeneralView;
