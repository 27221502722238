import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';

import {
  Channel,
  VirtualizedMessageList,
  MessageInput,
} from 'stream-chat-react';

import ChatDateSeparator from '../ChatDateSeparator';
import ChatMessage from '../ChatMessage';
import ChatInput from '../ChatInput';
import ConfirmClearChatModal, {
  useOpenConfirmClearChatModal,
} from '../ConfirmClearChatModal';

import { IChatChannel } from './interfaces';

import { useStyles } from './styles';

const ChatChannel = ({ channel, showClearChat = true }: IChatChannel) => {
  const classes = useStyles();

  const { open, openModal, closeModal } = useOpenConfirmClearChatModal();
  const { t: translate } = useTranslation('common');

  return (
    <Box sx={classes.root}>
      <Channel channel={channel} DateSeparator={ChatDateSeparator}>
        {showClearChat && (
          <>
            <Button
              color="error"
              variant="contained"
              sx={classes.clearHistoryBtn}
              disableRipple
              onClick={openModal}
            >
              {translate('chatDrawer.clearChat')}
            </Button>
            <ConfirmClearChatModal
              open={open}
              onClose={closeModal}
              channel={channel}
            />
          </>
        )}
        <VirtualizedMessageList
          Message={ChatMessage}
          disableDateSeparator={false}
          hideNewMessageSeparator
          hideDeletedMessages
          shouldGroupByUser
          defaultItemHeight={67}
          stickToBottomScrollBehavior="auto"
          scrollToLatestMessageOnFocus={false}
        />
        <MessageInput Input={() => <ChatInput />} maxRows={5} grow />
      </Channel>
    </Box>
  );
};

export default React.memo(ChatChannel);
