import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SelectWrapper from '../../components/SelectWrapper';
import { FormLayout } from '../../components/Form';

import { useDevicesTestState } from './hooks/state';

import ROUTES from '../../../../constants/routes';
import { STEPS_CONTENT } from '../../constants/devicesSteps';

import { useStyles } from './styles';

const DevicesTestPage = () => {
  const classes = useStyles();

  const { step, resetState } = useDevicesTestState();
  const navigate = useNavigate();

  useEffect(() => resetState, [resetState]);

  const onBackButtonClick = () => navigate(ROUTES.SELECT_AVATAR_SKIN);

  return (
    <SelectWrapper showEventsDropdown onBackClick={onBackButtonClick}>
      <FormLayout classes={{ container: classes.formContainer }}>
        {STEPS_CONTENT[step - 1]()}
      </FormLayout>
    </SelectWrapper>
  );
};

export default DevicesTestPage;
