import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { IFilesWrapper } from './interfaces';

import { classes } from './styles';

const FilesWrapper = ({
  children,
  noFiles,
  noSearchResults,
}: IFilesWrapper) => {
  const { t: translate } = useTranslation('common');

  if (noFiles) {
    return (
      <Box sx={classes.emptyContent}>
        <Typography sx={classes.text}>
          {translate('toolbox.noFiles')}
        </Typography>
      </Box>
    );
  }

  if (noSearchResults) {
    return (
      <Box sx={classes.emptyContent}>
        <Typography sx={classes.text}>
          {translate('toolbox.noResults')}
        </Typography>
      </Box>
    );
  }

  return <Box sx={classes.content}>{children}</Box>;
};

export default FilesWrapper;
