import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const GlobalIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      d="M15.9062 15.3906C17.3125 13.8906 18.0156 12.0938 18.0156 10C18.0156 8.34375 17.5469 6.84375 16.6094 5.5C15.7031 4.15625 14.5 3.1875 13 2.59375V3.01562C13 3.54687 12.7969 4.01562 12.3906 4.42188C11.9844 4.79688 11.5156 4.98438 10.9844 4.98438H9.01562V7C9.01562 7.28125 8.90625 7.51562 8.6875 7.70312C8.5 7.89062 8.26562 7.98438 7.98438 7.98438H6.01562V10H12.0156C12.2969 10 12.5312 10.0938 12.7188 10.2812C12.9062 10.4688 13 10.7031 13 10.9844V13.9844H13.9844C14.9219 13.9844 15.5625 14.4531 15.9062 15.3906ZM9.01562 17.9219V16C8.48438 16 8.01562 15.7969 7.60938 15.3906C7.20312 14.9844 7 14.5156 7 13.9844V13L2.21875 8.21875C2.0625 8.84375 1.98438 9.4375 1.98438 10C1.98438 12.0312 2.65625 13.7969 4 15.2969C5.375 16.7969 7.04688 17.6719 9.01562 17.9219ZM2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875Z"
      fill="currentColor"
    />
  </SvgIcon>
);
