import React from 'react';

import UserProfilePreviewView from './UserProfilePreviewView';

import { getFullUserName } from '../../../helpers/user';
import { openInNewTab } from '../../../helpers';

import { IUserProfilePreview } from './interfaces';

const UserProfilePreview = ({
  user,
  enableEditing,
  showFollowMe,
  onEditClick,
  onFollow,
}: IUserProfilePreview) => {
  const fullUserName = getFullUserName({
    firstName: user.firstName,
    lastName: user.lastName,
  });

  const handleOpenLinkedIn = () => {
    openInNewTab(user.linkedIn);
  };

  return (
    <UserProfilePreviewView
      user={user}
      fullUserName={fullUserName}
      enableEditing={enableEditing}
      showFollowMe={showFollowMe}
      onOpenLinkedIn={handleOpenLinkedIn}
      onEditClick={onEditClick}
      onFollow={onFollow}
    />
  );
};

export default UserProfilePreview;
