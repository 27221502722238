import { useTheme } from '@mui/material';

import { DEVICES_LANDSCAPE_SIZES } from '../../../../../../constants/devicesSizes';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      width: 'inherit',
      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
        position: 'absolute',
        inset: 0,
        background: '#030303',
        backdropFilter: 'blur(12px)',
      },
    },
    chatHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px 16px 16px 24px',
      width: '100%',
    },
    arrowButton: {
      width: 24,
      height: 24,
      marginRight: 2,
    },
    avatar: {
      width: 32,
      height: 32,
    },
    participantName: {
      fontWeight: 400,
      fontSize: 22,
      lineHeight: 1.27,
    },
    statusIcon: {
      marginLeft: 2,
      marginRight: 'auto',
      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
        marginRight: 0,
      },
    },
    selectionBtn: {
      marginLeft: 'auto',
      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
        display: 'flex',
        marginLeft: 0,
      },
    },
    closeIcon: {
      display: 'none',
      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
        display: 'flex',
        marginLeft: 'auto',
      },
    },
  };
};
