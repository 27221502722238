import { colors } from '../../../../../constants/colors';

export const useStyles = () => ({
  tools: {
    height: 48,
    width: '100%',
    background: colors.gray,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingInline: 2,
    columnGap: 1,
    justifyContent: 'space-between',
    position: 'static',
    '& button': {
      p: 0,
    },
  },
  toolsLink: {
    width: '100%',
    height: 32,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      width: 32,
      height: 32,
      color: colors.white,
    },
    '& button': {
      mr: 1,
    },
    '& p': {
      color: colors.white,
    },
  },

  toolsButtonWrapper: {
    '& button': {
      padding: 0,
      '& p': {
        color: colors.white,
        textTransform: 'capitalize',
        p: 0,
        pl: 1,
        fontWeight: 500,
        fontSize: 16,
      },
    },
    minWidth: 'max-content',
  },

  toolsUrlControls: {
    columnGap: 2,
    display: 'flex',
  },
  toolsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    '& video': {
      height: 'calc(100% - 48px)',
    },
  },
  slider: {
    color: '#7B7B7B',
    '& span:not(.MuiSlider-thumb)': {
      height: 2,
    },

    '& .MuiSlider-thumb': {
      height: 10,
      color: '#D9D9D9',
      width: 10,
    },
  },
  sliderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    columnGap: 1,
    pl: 1,
    pr: 1,
  },
  sliderVolume: {
    color: '#7B7B7B',
    '& span:not(.MuiSlider-thumb)': {
      height: 2,
    },

    '& .MuiSlider-thumb': {
      height: 10,
      color: '#D9D9D9',
      width: 10,
    },
  },
  sliderVolumeWrapper: {
    width: 116,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
});
