import React from 'react';

import ConfirmationLoading from './ConfirmationLoading';
import EventConfirmationPageView from './EventConfirmationPageView';

import { useHandleConfirmEvent } from './hooks';

const EventConfirmationPage = () => {
  const { isLoading, isConfirmed, timer } = useHandleConfirmEvent();

  if (isLoading) {
    return <ConfirmationLoading />;
  }

  return <EventConfirmationPageView isConfirmed={isConfirmed} timer={timer} />;
};

export default EventConfirmationPage;
