import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMytaverse } from '../../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../../providers';

import { DolbyService } from '../../../DashboardContent/Dolby';

import { WebsocketAction } from '../../../../../../interfaces/webSocketConnectionInfo';

export const useAdminTools = () => {
  const [editing, setEditing] = useState(false);
  const [isDroneMode, setIsDroneMode] = useState(false);
  const { t: translate } = useTranslation('common');
  const { sendMessageToUnreal, sendMessageToEvent } = useMytaverse();
  const { setOpenAdminSettingsModal, currentEvent, currentParticipant } =
    useMytaverseEvent();
  const handleCreatePOIs = () => {
    sendMessageToUnreal({
      action: editing
        ? WebsocketAction.StopPoiCreatingMode
        : WebsocketAction.StartPoiCreatingMode,
    });
    setEditing(!editing);
  };

  const handleDroneMode = useCallback(() => {
    const unrealAction = isDroneMode
      ? WebsocketAction.DisableDroneCamera
      : WebsocketAction.EnableDroneCamera;

    setIsDroneMode(!isDroneMode);
    sendMessageToUnreal({
      action: unrealAction,
    });
  }, [isDroneMode, sendMessageToUnreal]);

  const handleMuteAll = useCallback(() => {
    if (!currentEvent || !currentParticipant) {
      return;
    }
    sendMessageToEvent(currentEvent.id, {
      action: WebsocketAction.MuteConference,
      mutedBy: currentParticipant.id,
      conferenceId: DolbyService.getConferenceId(),
    });
  }, [currentEvent, currentParticipant, sendMessageToEvent]);

  const handleOpenAdminSettings = () => {
    setOpenAdminSettingsModal(true);
  };

  return {
    editing,
    setEditing,
    translate,
    handleCreatePOIs,
    handleDroneMode,
    handleOpenAdminSettings,
    handleMuteAll,
  };
};
