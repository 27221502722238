import React, { useCallback, useEffect, useRef } from 'react';
import { Subject } from 'rxjs';
import { camelCase } from 'lodash';

import { useMytaverseEvent } from '../../../providers';
import { useConference } from '../Dolby';
import { useChatState } from '../../../../../hooks/context';

import {
  checkIsParticipantVideoPositionAction,
  checkIsPositionChangedAction,
} from './helpers';
import { setSessionStorageValue } from '../../../../../helpers/sessionStorage';

import { CHAT_DRAWER_WIDTH } from '../../../../../constants';

import { IPurewebStreamerMessage, IVideoPosition } from './interfaces';
import { ISubscription } from '../../../../../interfaces';
import { SpatialType } from '../../../../../interfaces/webSocketConnectionInfo';
import { SessionStorage } from '../../../../../constants/storage';

export const useWindowResolution = () => {
  const { open: isOpenChat } = useChatState();
  const [windowResolution, setWindowResolution] = React.useState({
    heigh: window.innerHeight,
    width: window.innerWidth,
  });

  const setSizes = useCallback(() => {
    setWindowResolution((prevState) => ({
      ...prevState,
      heigh: window.innerHeight,
      width: isOpenChat
        ? window.innerWidth - CHAT_DRAWER_WIDTH
        : window.innerWidth,
    }));
  }, [isOpenChat]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setSizes);

    return () => {
      window.removeEventListener('resize', setSizes);
    };
  }, [isOpenChat]);

  useEffect(() => {
    setWindowResolution((prevState) => ({
      ...prevState,
      heigh: window.innerHeight,
      width: isOpenChat
        ? window.innerWidth - CHAT_DRAWER_WIDTH
        : window.innerWidth,
    }));
  }, [isOpenChat]);

  return { windowResolution };
};

export const useNearbyParticipantsListener = (
  messageSubject: Subject<string>,
) => {
  const subscriptionRef = useRef<ISubscription>();
  const prevLastJsonMessageRef = useRef<string>('');

  const { setParticipantPosition } = useConference();
  const {
    nearbyParticipants,
    setNearbyParticipants,
    initMessageSended,
    setParticipantState,
    currentParticipant,
  } = useMytaverseEvent();

  const nearbyParticipantsStr = JSON.stringify(nearbyParticipants);

  const handleGameMessage = useCallback(
    async (message: IPurewebStreamerMessage) => {
      const {
        action,
        timestamp,
        playerId,
        playerPosition,
        usersinscreen,
        regionSpatialType,
      } = message;

      let { regionName } = message;

      if (regionName) {
        regionName = camelCase(regionName);
      }

      if (
        checkIsPositionChangedAction(action) &&
        playerId &&
        playerPosition &&
        initMessageSended &&
        currentParticipant
      ) {
        const { eventId, roomId, CurrentGameSessionId, ...position } =
          playerPosition;

        const currentRegionName = currentParticipant.region
          ? currentParticipant.region.region
          : '';

        const isNewParticipantPositionFlow = regionName !== undefined;

        if (
          isNewParticipantPositionFlow &&
          (!currentParticipant.roomId ||
            currentParticipant.roomId !== roomId ||
            currentRegionName !== regionName)
        ) {
          setParticipantState(currentParticipant.id, {
            eventId,
            roomId,
            region: regionName
              ? {
                  timestamp: Date.now(),
                  region: regionName,
                  state: 'joined',
                  regionSpatialType: regionSpatialType
                    ? regionSpatialType
                    : SpatialType.SpatialAudio,
                }
              : null,
            regions: [],
            gameSessionId: CurrentGameSessionId,
            isNewParticipantPositionFlow,
          });
        }

        await setParticipantPosition(
          playerId,
          timestamp ? parseInt(timestamp, 10) : 0,
          {
            x: Math.trunc(position.x),
            y: Math.trunc(position.y),
            z: Math.trunc(position.z),
            r: Math.trunc(position.r),
          },
        );
        setSessionStorageValue(SessionStorage.ParticipantPosition, position);
      }

      if (checkIsParticipantVideoPositionAction(action) && usersinscreen) {
        const currentNearbyParticipantIDs: string[] = usersinscreen
          .map((item: string) => {
            const { participantId }: IVideoPosition = JSON.parse(item);

            return participantId;
          })
          .sort();
        const currentNearbyParticipantIDsStr = JSON.stringify(
          currentNearbyParticipantIDs,
        );

        // conditions to skip change state if data is the same
        if (nearbyParticipantsStr === currentNearbyParticipantIDsStr) {
          return;
        }

        setNearbyParticipants(currentNearbyParticipantIDs);
      }
    },
    [
      initMessageSended,
      nearbyParticipantsStr,
      setNearbyParticipants,
      setParticipantPosition,
      setParticipantState,
      currentParticipant,
    ],
  );

  useEffect(() => {
    if (subscriptionRef.current) {
      return;
    }

    subscriptionRef.current = messageSubject.subscribe(
      async (value: string) => {
        if (prevLastJsonMessageRef.current !== value) {
          const message: IPurewebStreamerMessage = JSON.parse(value);
          prevLastJsonMessageRef.current = value;

          await handleGameMessage(message);
        }
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    );

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = undefined;
      }
    };
  }, [handleGameMessage, messageSubject]);
};

export const useCurrentParticipantSpeaking = () => {
  const { speakingParticipantIds } = useConference();
  const { currentParticipant } = useMytaverseEvent();
  const [currentParticipantSpeaking, setCurrentParticipantSpeaking] =
    React.useState(false);
  useEffect(() => {
    if (!currentParticipant) {
      return;
    }
    const isSpeaking = speakingParticipantIds.some(
      (id) => id === currentParticipant.id,
    );

    setCurrentParticipantSpeaking(isSpeaking);
  }, [speakingParticipantIds, currentParticipant]);

  return {
    currentParticipantSpeaking,
  };
};
