import { COLORS } from '../../../../../../constants/colors';

import { IStyles } from '../../../../../../interfaces/styles';

export const classes: IStyles = {
  root: {
    padding: 1,
    margin: 0,
    width: 42,
    height: 40,
  },
  popover: {
    top: 15,
  },
  icon: {
    color: '#FFB800',
  },
  container: {
    width: 400,
    height: 321,
  },
  tools: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    height: 64,
    pl: 1.3125,
    pr: 1.3125,
    mt: 3.8,
    rowGap: 3.8,
    '& svg': {
      width: 37,
      height: 37,
      color: COLORS.WHITE,
    },
  },
};
