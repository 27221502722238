import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingProgress from '../../../../components/LoadingProgress';
import SelectAvatarSkinPageView from './SelectAvatarSkinPageView';

import { useMytaverseEvent } from '../../../dashboard/providers';
import { useDetectSwiperLoop } from '../../hooks';

import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';

import ROUTES from '../../../../constants/routes';

import { IAvatarSkin } from '../../../../interfaces/avatarSkin';

const SelectAvatarSkinPage = () => {
  const {
    avatarSkins,
    selectSkin,
    loadingAvatars,
    loadingSkins,
    getSkins,
    setCustomAvatarUrl,
    currentAvatarId,
    setCurrentSkin,
  } = useMytaverseEvent();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loadingSkins && currentAvatarId && !avatarSkins.length) {
      getSkins(currentAvatarId);
    }
  }, [avatarSkins.length, currentAvatarId, getSkins, loadingSkins]);

  const handleBackButtonClick = () => navigate(ROUTES.AVATAR_CHOOSING);

  const handleAvatarSkinClick = (skin: IAvatarSkin) => {
    setCustomAvatarUrl(null);
    setCurrentSkin(skin);
    selectSkin(skin);
    navigate(ROUTES.DEVICES_TEST);
    sendCustomSentryBreadcrumb({ message: 'select avatar skin' });
  };

  const loop = useDetectSwiperLoop(avatarSkins.length);
  const loading = loadingAvatars || loadingSkins || loop === null;

  if (loading) {
    return <LoadingProgress fullHeight />;
  }

  return (
    <SelectAvatarSkinPageView
      avatarSkins={avatarSkins}
      loop={loop}
      onBackButtonClick={handleBackButtonClick}
      onAvatarSkinClick={handleAvatarSkinClick}
    />
  );
};

export default SelectAvatarSkinPage;
