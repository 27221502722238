import { COLORS } from '../../../../../constants/colors';

export const useStyles = () => ({
  name: {
    display: 'none',
    position: 'absolute',
    p: 1,
    color: COLORS.WHITE,
    background: COLORS.MYTAVERSE_BLUE,
  },
});
