import { CheckFileType, CheckToolboxOption, ToolboxOption } from './interfaces';
import { PoiMediaType } from '../../../DashboardContent/interfaces';

export const checkIsBriefcaseToolbox: CheckToolboxOption = (toolboxOption) =>
  toolboxOption === ToolboxOption.Briefcase;

export const checkIsAgendaToolbox: CheckToolboxOption = (toolboxOption) =>
  toolboxOption === ToolboxOption.Agenda;

export const checkIsCalendarToolbox: CheckToolboxOption = (toolboxOption) =>
  toolboxOption === ToolboxOption.Calendar;

export const checkIsFilesToolbox: CheckToolboxOption = (toolboxOption) =>
  toolboxOption === ToolboxOption.Files;

export const checkIsThreeDObject: CheckFileType = (type) =>
  type === PoiMediaType.THREE_D_OBJECT_VIEW ||
  type === PoiMediaType.THREE_D_PROP;
