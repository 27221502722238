import { ISelectDropdownData } from './FilesHeader/SortDropdown/SelectDropdown';
import { SortType } from '../../../../../../../constants/sort';
import { ISortDataValue } from './interfaces';

export const SORT_DATA: ISelectDropdownData<ISortDataValue>[] = [
  {
    label: 'sortOptions.alphabetical',
    value: {
      type: SortType.Alphabetical,
      fieldName: 'displayName',
      direction: false,
    },
  },
  {
    label: 'sortOptions.newestFirst',
    value: {
      type: SortType.Date,
      fieldName: 'createdAt',
      direction: true,
    },
  },
  {
    label: 'sortOptions.oldestFirst',
    value: {
      type: SortType.Date,
      fieldName: 'createdAt',
      direction: false,
    },
  },
];
