import React from 'react';

import { Badge, Tooltip } from '@mui/material';

import ChromeOperation from '../../../../public/images/ChromeOperation.jpg';

import { IWarningButtonBadge } from './interfaces';

const WarningButtonBadge = ({
  children,
  classes = {},
}: IWarningButtonBadge) => {
  const { root = {}, tooltip = {} } = classes;

  return (
    <Badge
      badgeContent={
        <Tooltip
          title={<img width="400" height="500" src={ChromeOperation} alt="" />}
          arrow
          placement="top"
          sx={tooltip}
        >
          <h2>!</h2>
        </Tooltip>
      }
      color="error"
      sx={root}
    >
      {children}
    </Badge>
  );
};

export default WarningButtonBadge;
