import { Breakpoints } from '@mui/system/createTheme/createBreakpoints';

import { colors } from '../../../../../constants/colors';

import { ScreenSizes } from '../../../../../interfaces';

export const useCommonTipStyles = (breakpoints: Breakpoints) => {
  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 2,
      padding: '24px 8px',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '8px',
        rowGap: 1,
      },
    },
    title: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '28px',
      color: colors.white,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 18,
      },
    },
    description: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: '0.001px',
      color: colors.white_80,
      [breakpoints.down(ScreenSizes.Pc)]: {
        fontSize: 16,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },
  };
};
