import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import { colors } from '../constants/colors';

interface IPoweredByMytaverseIcon extends SvgIconProps {
  custom: boolean;
}

export const PoweredByMytaverseIcon = ({
  sx = {},
  custom,
  ...props
}: IPoweredByMytaverseIcon) => {
  const color = custom ? colors.white : colors.oxford;

  return (
    <SvgIcon
      width="127"
      height="40"
      viewBox="0 0 127 40"
      sx={{ width: 'auto', height: 'auto', ...sx }}
      {...props}
    >
      <path
        d="M7.07443 3.67355C7.07443 4.18862 6.95312 4.67454 6.71052 5.1313C6.46791 5.58807 6.07974 5.96222 5.546 6.25378C5.01227 6.53561 4.32811 6.67653 3.49354 6.67653H1.65943V10.7874H0V0.65599H3.49354C4.26989 0.65599 4.92493 0.792047 5.45866 1.06416C6.0021 1.32656 6.40483 1.68614 6.66685 2.1429C6.93857 2.59967 7.07443 3.10988 7.07443 3.67355ZM3.49354 5.32081C4.12432 5.32081 4.59498 5.17989 4.90552 4.89806C5.21606 4.60651 5.37132 4.19834 5.37132 3.67355C5.37132 2.56565 4.7454 2.0117 3.49354 2.0117H1.65943V5.32081H3.49354Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M12.2223 10.9186C11.4653 10.9186 10.7812 10.7485 10.1698 10.4084C9.55844 10.0585 9.07807 9.5726 8.72872 8.95063C8.37936 8.31893 8.20469 7.59005 8.20469 6.76399C8.20469 5.94765 8.38422 5.22363 8.74328 4.59193C9.10233 3.96024 9.5924 3.47432 10.2135 3.13418C10.8345 2.79403 11.5284 2.62396 12.295 2.62396C13.0617 2.62396 13.7555 2.79403 14.3766 3.13418C14.9977 3.47432 15.4878 3.96024 15.8468 4.59193C16.2059 5.22363 16.3854 5.94765 16.3854 6.76399C16.3854 7.58033 16.201 8.30435 15.8323 8.93605C15.4635 9.56774 14.9589 10.0585 14.3184 10.4084C13.6876 10.7485 12.9889 10.9186 12.2223 10.9186ZM12.2223 9.47542C12.6493 9.47542 13.0471 9.37338 13.4159 9.16929C13.7944 8.9652 14.1 8.65907 14.3329 8.2509C14.5658 7.84273 14.6823 7.34709 14.6823 6.76399C14.6823 6.18089 14.5707 5.69011 14.3475 5.29166C14.1243 4.88348 13.8283 4.57736 13.4596 4.37327C13.0908 4.16918 12.6929 4.06714 12.2659 4.06714C11.8389 4.06714 11.4411 4.16918 11.0723 4.37327C10.7132 4.57736 10.427 4.88348 10.2135 5.29166C9.99998 5.69011 9.89323 6.18089 9.89323 6.76399C9.89323 7.62893 10.1116 8.29949 10.5483 8.77569C10.9947 9.24218 11.5527 9.47542 12.2223 9.47542Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M28.8838 2.75516L26.3946 10.7874H24.6479L23.0321 4.85433L21.4163 10.7874H19.6696L17.1659 2.75516H18.8544L20.5284 9.21302L22.2315 2.75516H23.9637L25.594 9.18387L27.2535 2.75516H28.8838Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M37.5611 6.57448C37.5611 6.87575 37.5417 7.14787 37.5029 7.39083H31.3746C31.4231 8.03224 31.6609 8.54731 32.0879 8.93605C32.5149 9.32478 33.0389 9.51915 33.66 9.51915C34.5528 9.51915 35.1835 9.14499 35.5523 8.39668H37.3428C37.1001 9.13528 36.6586 9.74267 36.0181 10.2189C35.3873 10.6854 34.6013 10.9186 33.66 10.9186C32.8933 10.9186 32.2043 10.7485 31.593 10.4084C30.9913 10.0585 30.5158 9.5726 30.1664 8.95063C29.8268 8.31893 29.657 7.59005 29.657 6.76399C29.657 5.93793 29.8219 5.21391 30.1519 4.59193C30.4915 3.96024 30.9622 3.47432 31.5639 3.13418C32.1752 2.79403 32.8739 2.62396 33.66 2.62396C34.4169 2.62396 35.0914 2.78917 35.6833 3.1196C36.2753 3.45002 36.7362 3.91651 37.0662 4.51905C37.3961 5.11187 37.5611 5.79701 37.5611 6.57448ZM35.8289 6.04969C35.8192 5.43743 35.6008 4.94665 35.1738 4.57736C34.7469 4.20806 34.218 4.02341 33.5872 4.02341C33.0146 4.02341 32.5246 4.20806 32.117 4.57736C31.7094 4.93694 31.4668 5.42771 31.3892 6.04969H35.8289Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M40.9398 3.92137C41.1824 3.51319 41.5026 3.19735 41.9005 2.97382C42.3081 2.74058 42.7884 2.62396 43.3416 2.62396V4.34411H42.9194C42.2692 4.34411 41.7743 4.50933 41.4347 4.83975C41.1047 5.17018 40.9398 5.74356 40.9398 6.5599V10.7874H39.2803V2.75516H40.9398V3.92137Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M52.3041 6.57448C52.3041 6.87575 52.2847 7.14787 52.2459 7.39083H46.1176C46.1661 8.03224 46.4039 8.54731 46.8309 8.93605C47.2579 9.32478 47.7819 9.51915 48.403 9.51915C49.2958 9.51915 49.9266 9.14499 50.2953 8.39668H52.0858C51.8432 9.13528 51.4016 9.74267 50.7611 10.2189C50.1303 10.6854 49.3443 10.9186 48.403 10.9186C47.6363 10.9186 46.9473 10.7485 46.336 10.4084C45.7343 10.0585 45.2588 9.5726 44.9094 8.95063C44.5698 8.31893 44.4 7.59005 44.4 6.76399C44.4 5.93793 44.5649 5.21391 44.8949 4.59193C45.2345 3.96024 45.7052 3.47432 46.3069 3.13418C46.9182 2.79403 47.6169 2.62396 48.403 2.62396C49.1599 2.62396 49.8344 2.78917 50.4263 3.1196C51.0183 3.45002 51.4792 3.91651 51.8092 4.51905C52.1391 5.11187 52.3041 5.79701 52.3041 6.57448ZM50.5719 6.04969C50.5622 5.43743 50.3438 4.94665 49.9169 4.57736C49.4899 4.20806 48.961 4.02341 48.3302 4.02341C47.7576 4.02341 47.2676 4.20806 46.86 4.57736C46.4524 4.93694 46.2098 5.42771 46.1322 6.04969H50.5719Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M53.4702 6.73484C53.4702 5.92821 53.6352 5.21391 53.9651 4.59193C54.3048 3.96996 54.7609 3.4889 55.3334 3.14875C55.9157 2.79889 56.561 2.62396 57.2694 2.62396C57.7935 2.62396 58.3078 2.74058 58.8124 2.97382C59.3267 3.19735 59.7343 3.49862 60.0351 3.87763V0H61.7091V10.7874H60.0351V9.57746C59.7634 9.9662 59.385 10.2869 58.8997 10.5396C58.4242 10.7923 57.8759 10.9186 57.2549 10.9186C56.5562 10.9186 55.9157 10.7437 55.3334 10.3938C54.7609 10.0342 54.3048 9.53859 53.9651 8.90689C53.6352 8.26548 53.4702 7.54146 53.4702 6.73484ZM60.0351 6.76399C60.0351 6.21004 59.9187 5.72898 59.6858 5.32081C59.4626 4.91264 59.1666 4.60165 58.7978 4.38785C58.4291 4.17404 58.0312 4.06714 57.6042 4.06714C57.1772 4.06714 56.7794 4.17404 56.4106 4.38785C56.0418 4.59193 55.741 4.89806 55.5081 5.30623C55.2849 5.70469 55.1733 6.18089 55.1733 6.73484C55.1733 7.28878 55.2849 7.7747 55.5081 8.19259C55.741 8.61048 56.0418 8.93119 56.4106 9.15471C56.7891 9.36852 57.1869 9.47542 57.6042 9.47542C58.0312 9.47542 58.4291 9.36852 58.7978 9.15471C59.1666 8.94091 59.4626 8.62992 59.6858 8.22175C59.9187 7.80386 60.0351 7.31794 60.0351 6.76399Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M69.5018 3.95052C69.7832 3.56179 70.1665 3.24594 70.6517 3.00298C71.1467 2.7503 71.6949 2.62396 72.2966 2.62396C73.005 2.62396 73.6455 2.79403 74.2181 3.13418C74.7906 3.47432 75.2419 3.96024 75.5718 4.59193C75.9018 5.21391 76.0667 5.92821 76.0667 6.73484C76.0667 7.54146 75.9018 8.26548 75.5718 8.90689C75.2419 9.53859 74.7858 10.0342 74.2035 10.3938C73.631 10.7437 72.9953 10.9186 72.2966 10.9186C71.6755 10.9186 71.1224 10.7971 70.6372 10.5542C70.1617 10.3112 69.7832 10.0002 69.5018 9.62119V10.7874H67.8423V0H69.5018V3.95052ZM74.3782 6.73484C74.3782 6.18089 74.2617 5.70469 74.0288 5.30623C73.8056 4.89806 73.5048 4.59193 73.1263 4.38785C72.7576 4.17404 72.3597 4.06714 71.9327 4.06714C71.5154 4.06714 71.1175 4.17404 70.7391 4.38785C70.3703 4.60165 70.0695 4.91264 69.8366 5.32081C69.6134 5.72898 69.5018 6.21004 69.5018 6.76399C69.5018 7.31794 69.6134 7.80386 69.8366 8.22175C70.0695 8.62992 70.3703 8.94091 70.7391 9.15471C71.1175 9.36852 71.5154 9.47542 71.9327 9.47542C72.3597 9.47542 72.7576 9.36852 73.1263 9.15471C73.5048 8.93119 73.8056 8.61048 74.0288 8.19259C74.2617 7.7747 74.3782 7.28878 74.3782 6.73484Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M85.0139 2.75516L80.0938 14.563H78.3762L80.0065 10.6562L76.8477 2.75516H78.6964L80.9527 8.87774L83.2962 2.75516H85.0139Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M0.311773 22.4178H2.26793C2.44074 22.4178 2.5797 22.5562 2.5797 22.7285V23.8346C3.6326 22.6024 5.10595 22.1035 6.31563 22.1035C8.07937 22.1035 9.47255 22.9433 10.2618 24.3584C11.2612 22.9167 13.025 22.1035 14.8671 22.1035C17.8405 22.1035 19.7094 24.1222 19.7094 27.3483V34.9048C19.7094 35.077 19.5704 35.2155 19.3976 35.2155H17.6C17.4272 35.2155 17.2882 35.077 17.2882 34.9048V27.8721C17.2882 25.6953 16.2086 24.3317 14.3148 24.3317C12.7364 24.3317 11.051 25.4077 11.051 27.8987V34.9048C11.051 35.077 10.912 35.2155 10.7392 35.2155H8.96836C8.79555 35.2155 8.65659 35.077 8.65659 34.9048V27.8721C8.65659 25.6687 7.49858 24.3317 5.55134 24.3317C3.9996 24.3317 2.39441 25.4077 2.39441 28.0549V34.903C2.39441 35.0752 2.25545 35.2137 2.08264 35.2137H0.311773C0.138962 35.2137 0 35.0752 0 34.903V22.7267C0 22.5562 0.140743 22.4178 0.311773 22.4178Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M23.7873 22.6108L27.5767 31.8842L31.2877 22.6126C31.3358 22.4954 31.4498 22.4173 31.5763 22.4173H33.495C33.7177 22.4173 33.8692 22.6428 33.7819 22.847L26.6538 39.81C26.6057 39.9254 26.4917 40 26.367 40H24.4714C24.247 40 24.0955 39.771 24.1864 39.565L25.861 35.7264C26.1497 35.0677 26.1532 34.3184 25.8735 33.6562L21.3109 22.847C21.2254 22.6428 21.3751 22.4155 21.5978 22.4155H23.4987C23.627 22.4173 23.741 22.4937 23.7873 22.6108Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M35.8342 19.5168C35.8342 19.3446 35.9732 19.2061 36.146 19.2061H37.9169C38.0897 19.2061 38.2286 19.3446 38.2286 19.5168V22.418H41.7276C41.9004 22.418 42.0394 22.5565 42.0394 22.7287V24.2059C42.0394 24.3781 41.9004 24.5166 41.7276 24.5166H38.2286V30.9421C38.2286 32.4104 38.9662 33.1188 40.3344 33.1188H42.0839C42.2567 33.1188 42.3957 33.2573 42.3957 33.4296V34.9068C42.3957 35.079 42.2567 35.2175 42.0839 35.2175H40.044C37.2541 35.2175 35.8342 33.9072 35.8342 31.1001V19.5168Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M43.8227 28.8433C43.8227 24.962 46.6375 22.1035 50.3218 22.1035C52.1907 22.1035 53.7691 22.8634 54.9004 24.0974V22.7302C54.9004 22.558 55.0394 22.4195 55.2122 22.4195H57.0098C57.1826 22.4195 57.3215 22.558 57.3215 22.7302V34.9066C57.3215 35.0788 57.1826 35.2173 57.0098 35.2173H55.2122C55.0394 35.2173 54.9004 35.0788 54.9004 34.9066V33.5377C53.7691 34.7698 52.1907 35.5315 50.3218 35.5315C46.6375 35.5298 43.8227 32.7245 43.8227 28.8433ZM50.532 33.2216C53.0583 33.2216 54.9004 31.439 54.9004 28.8166C54.9004 26.2723 53.0583 24.385 50.532 24.385C48.0842 24.385 46.2171 26.3256 46.2171 28.8166C46.2171 31.3343 48.0842 33.2216 50.532 33.2216Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M66.4005 35.2158H64.3214C64.1949 35.2158 64.0809 35.1394 64.0328 35.0222L59.0996 22.8459C59.0177 22.6417 59.1674 22.4198 59.3883 22.4198H61.4228C61.5511 22.4198 61.6669 22.4979 61.7132 22.6187L65.426 32.1495L69.1387 22.6187C69.1851 22.4997 69.3009 22.4198 69.4291 22.4198H71.3586C71.5795 22.4198 71.7309 22.6435 71.6472 22.8477L66.6891 35.024C66.641 35.1394 66.527 35.2158 66.4005 35.2158Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M72.6626 28.9214C72.6626 25.0135 75.7679 22.1035 79.3203 22.1035C82.846 22.1035 85.5308 24.804 85.5308 28.2662C85.5308 28.4704 85.5148 28.7403 85.4827 29.0599C85.4667 29.2179 85.3331 29.3404 85.1727 29.3404H75.0054C75.2156 31.8314 77.0577 33.4844 79.4005 33.4844C81.027 33.4844 82.4612 32.6552 82.9155 31.4017C82.9601 31.281 83.0758 31.2029 83.2059 31.2029H84.9607C85.171 31.2029 85.3242 31.4071 85.2582 31.6059C84.5082 33.9105 82.2278 35.5298 79.2419 35.5298C75.3724 35.5298 72.6626 32.5665 72.6626 28.9214ZM83.0562 27.3483C82.9244 25.5657 81.3727 24.1755 79.3203 24.1755C77.3998 24.1755 75.7412 25.4077 75.1889 27.3483H83.0562Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M90.9486 24.1492C91.6861 23.0733 92.764 22.4181 94.1589 22.4181H95.2154C95.3882 22.4181 95.5272 22.5566 95.5272 22.7289V24.2061C95.5272 24.3783 95.3882 24.5168 95.2154 24.5168H94.0538C92.1333 24.5168 90.9486 25.7756 90.9486 27.821V34.9052C90.9486 35.0774 90.8096 35.2159 90.6368 35.2159H88.8392C88.6664 35.2159 88.5274 35.0774 88.5274 34.9052V22.7289C88.5274 22.5566 88.6664 22.4181 88.8392 22.4181H90.6368C90.8096 22.4181 90.9486 22.5566 90.9486 22.7289V24.1492Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M97.7985 31.3613H99.4946C99.646 31.3613 99.7725 31.4696 99.801 31.6169C100.036 32.8562 101.103 33.6179 102.634 33.6179C104.239 33.6179 105.265 32.8047 105.265 31.6507C105.265 28.556 97.6863 30.6812 97.6863 25.9602C97.6863 23.5473 99.7387 22.1056 102.343 22.1056C104.916 22.1056 106.847 23.3343 107.285 25.5661C107.323 25.7578 107.173 25.9354 106.979 25.9354H105.224C105.085 25.9354 104.962 25.843 104.927 25.7099C104.65 24.6978 103.669 23.9699 102.345 23.9699C101.109 23.9699 99.9507 24.4937 99.9507 25.7276C99.9507 28.5862 107.608 26.3828 107.608 31.4181C107.608 33.831 105.555 35.5354 102.609 35.5354C99.8224 35.5354 97.8484 34.1506 97.4921 31.7235C97.4618 31.5299 97.6079 31.3613 97.7985 31.3613Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M110.052 28.9214C110.052 25.0135 113.157 22.1035 116.71 22.1035C120.236 22.1035 122.92 24.804 122.92 28.2662C122.92 28.4704 122.904 28.7403 122.872 29.0599C122.856 29.2179 122.723 29.3404 122.562 29.3404H112.395C112.605 31.8314 114.447 33.4844 116.79 33.4844C118.417 33.4844 119.851 32.6552 120.305 31.4017C120.35 31.281 120.465 31.2029 120.595 31.2029H122.35C122.56 31.2029 122.714 31.4071 122.648 31.6059C121.898 33.9105 119.617 35.5298 116.631 35.5298C112.762 35.5298 110.052 32.5665 110.052 28.9214ZM120.446 27.3483C120.314 25.5657 118.762 24.1755 116.71 24.1755C114.789 24.1755 113.131 25.4077 112.578 27.3483H120.446Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M122.983 20.8484H124.064V21.1396H123.687V22.4393H123.359V21.1396H122.985V20.8484H122.983ZM124.725 20.8484L125.172 21.9492L125.614 20.8484H126.047V22.4393H125.741V21.2799L125.276 22.4393H125.048L124.583 21.2657V22.4393H124.276V20.8484H124.725Z"
        fill={color}
        fillOpacity="0.8"
      />
    </SvgIcon>
  );
};
