import React from 'react';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import HomeBarButton from '../../HomeBarButton';
import ReadIconWrapper from '../../../../../components/ReadIconWrapper';

import { ChatButtonViewProp } from './interfaces';

const ChatButtonView = ({
  handleClick,
  showUnreadIcon = false,
}: ChatButtonViewProp) => (
  <HomeBarButton
    label="Chat"
    handleClick={handleClick}
    icon={
      <ReadIconWrapper showRedIcon={showUnreadIcon}>
        <ChatBubbleIcon />
      </ReadIconWrapper>
    }
  />
);

export default ChatButtonView;
