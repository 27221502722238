import { colors } from '../../../../../../../../../../constants/colors';

export const classes = {
  popper: {
    zIndex: 1300,
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    padding: '12px 16px',
    backgroundColor: colors.gray,
    border: `1px solid ${colors.white_10}`,
    borderRadius: '4px',
  },
  getMenuItem: (isSelected: boolean) => ({
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: colors.white,
    opacity: isSelected ? 1 : 0.6,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
  }),
};
