import { useEffect, useMemo, useState } from 'react';

import { useMytaverseEvent } from '../../../dashboard/providers';

import { getInternetSpeed } from '../../../../helpers/internet';
import { delay } from '../../../../helpers';
import { getAverageValue } from './helpers';

import StreamService from '../../../../services/StreamService';

export const useFastCheckInternetConnectionSpeed = () => {
  const [speeds, setSpeeds] = useState<number[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);

  const averageSpeed = useMemo(() => getAverageValue(speeds), [speeds]);

  useEffect(() => {
    const setSpeedValue = async () => {
      await delay(1000);
      const speed = await getInternetSpeed();

      setSpeeds((prev) => prev.concat(speed));
    };

    const startSpeedChecking = async () => {
      const checkSpeedList = [...new Array(10)];

      for (const [i] of checkSpeedList.entries()) {
        await setSpeedValue();

        if (i === checkSpeedList.length - 1) {
          setIsCompleted(true);
        }
      }
    };

    startSpeedChecking();
  }, []);

  return {
    speed: averageSpeed,
    isCompleted,
  };
};

export const useCheckServiceLatency = (isFirstTestCompleted: boolean) => {
  const [serviceLatencies, setServiceLatencies] = useState<number[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);

  const { gameCastStreamRegion, currentEvent } = useMytaverseEvent();

  const averageServiceLatency = useMemo(
    () => getAverageValue(serviceLatencies),
    [serviceLatencies],
  );

  useEffect(() => {
    const region = gameCastStreamRegion || currentEvent?.region;

    if (!isFirstTestCompleted || !region) return;

    const setServiceLatencyValue = async () => {
      await delay(1000);
      const serviceLatency = await StreamService.checkServiceLatency(region);

      setServiceLatencies((prev) => prev.concat(serviceLatency));
    };

    const startCheckServiceLatency = async () => {
      const checkServiceLatencyList = [...new Array(5)];

      for (const [i] of checkServiceLatencyList.entries()) {
        await setServiceLatencyValue();

        if (i === checkServiceLatencyList.length - 1) {
          setIsCompleted(true);
        }
      }
    };

    startCheckServiceLatency();
  }, [currentEvent?.region, gameCastStreamRegion, isFirstTestCompleted]);

  return {
    serviceLatency: averageServiceLatency,
    isCompleted,
  };
};
