import { COLORS } from '../../constants/colors';

import { IStyles } from '../../interfaces/styles';

export const classes: IStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  dropdown: {
    m: 0,
    minWidth: 80,
    border: 'none',
    '& fieldset': {
      border: 'none',
    },
  },
  select: {
    display: 'flex',
    border: 'none',
    '& .MuiSelect-select': {
      p: 0,
      display: 'flex',
    },
    '& svg': {
      color: COLORS.GREY,
    },
  },
};
