import React from 'react';

import ChatMessageView from './ChatMessageView';

import { useMessageContext } from 'stream-chat-react';

const ChatMessage = () => {
  const { message, isMyMessage, firstOfGroup } = useMessageContext();

  return (
    <ChatMessageView
      message={message}
      isMyMessage={isMyMessage()}
      firstMessageOfGroup={firstOfGroup}
    />
  );
};

export default ChatMessage;
