import React from 'react';

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';

import ConfirmModal, { ConfirmModalButton } from '../ConfirmModal';

import { IInitialMuteModalView } from './interfaces';

import { useStyles } from './styles';
import { withTranslation } from 'react-i18next';

const InitialMuteModalView = ({
  onClose,
  muted,
  isMuteUpdated,
  onMuteUpdate,
  onContinue,
  hideModalNextTime,
  onHideModalNextTime,
  t: translate,
}: IInitialMuteModalView) => {
  const classes = useStyles(hideModalNextTime);

  const Icon = muted ? MicOffIcon : MicIcon;
  const title = isMuteUpdated
    ? muted
      ? 'initialMuteModal.mutedTitle'
      : 'initialMuteModal.unmutedTitle'
    : 'initialMuteModal.title';
  const description = isMuteUpdated
    ? 'initialMuteModal.nextDescription'
    : 'initialMuteModal.description';

  return (
    <ConfirmModal open onClose={onClose}>
      <Box sx={classes.iconContainer}>
        <Icon />
      </Box>
      <Typography sx={classes.title}>{translate(title)}</Typography>
      <Typography sx={classes.description}>{translate(description)}</Typography>
      <Box sx={classes.buttonContainer}>
        {isMuteUpdated ? (
          <ConfirmModalButton onClick={onContinue} type="button">
            {translate('initialMuteModal.continue')}
          </ConfirmModalButton>
        ) : (
          <>
            <ConfirmModalButton
              onClick={() => onMuteUpdate(false)}
              type="button"
            >
              {translate('initialMuteModal.yes')}
            </ConfirmModalButton>
            <ConfirmModalButton
              onClick={() => onMuteUpdate(true)}
              type="submit"
            >
              {translate('initialMuteModal.no')}
            </ConfirmModalButton>
          </>
        )}
      </Box>
      {isMuteUpdated && (
        <Box sx={classes.checkboxContainer}>
          <FormControlLabel
            sx={classes.checkboxLabel}
            control={
              <Checkbox
                checked={hideModalNextTime}
                onChange={onHideModalNextTime}
                sx={classes.checkbox}
              />
            }
            label={translate('initialMuteModal.checkboxLabel')}
          />
        </Box>
      )}
    </ConfirmModal>
  );
};

export default withTranslation('common')(InitialMuteModalView);
