import React, { useEffect, useRef, useState } from 'react';

import { usePureweb } from './PurewebProvider';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import PurewebView from './PurewebView';
import { useMytaverseEvent } from '../../../providers';
import { useConference } from '../Dolby';
import useAsyncEffect from 'use-async-effect';
import { useCurrentParticipantSpeaking } from './hooks';

import { useChatState } from '../../../../../hooks/context';
import { WebsocketAction } from '../../../../../interfaces/webSocketConnectionInfo';

const Pureweb: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(
    document.getElementById('gameWindow') as HTMLVideoElement,
  );

  const { currentParticipantSpeaking } = useCurrentParticipantSpeaking();

  const { loading, streamerStatus, inputEmitter, videoStream, clientOptions } =
    usePureweb();

  const { conference } = useConference();

  const { sendJSONMessageToWebSocket } = useMytaverse();
  const {
    currentRoom,
    currentParticipant,
    setInitMessageSended,
    initMessageSended,
    trackAnalytics,
    initMessageHandler,
    muted,
  } = useMytaverseEvent();
  const { open: isOpenChat } = useChatState();

  const [enterPureweb, setEnterPureweb] = useState(loading);
  const purewebConnectionStartTimeRef = useRef<number>();

  React.useEffect(() => {
    if (streamerStatus === 'Connected' && inputEmitter && currentParticipant) {
      if (!initMessageHandler) {
        return;
      }

      const message = initMessageHandler();
      if (!message) {
        return;
      }

      inputEmitter.EmitUIInteraction(message);
      // eslint-disable-next-line no-console
      if (!initMessageSended) {
        setInitMessageSended(true);
      }
      // eslint-disable-next-line no-console
      console.log('INIT MESSAGE TO UE5', JSON.stringify(message));
    }
  }, [
    streamerStatus,
    inputEmitter,
    setInitMessageSended,
    currentParticipant,
    initMessageSended,
    initMessageHandler,
  ]);

  const sendParticipantState = React.useCallback(() => {
    if (!currentRoom) {
      return;
    }

    const message = {
      action: WebsocketAction.ParticipantState,
      speaking: currentParticipantSpeaking || false,
      muted,
      dolbyConferenceAlias: conference ? conference.alias : null,
      dolbySpatialAudioStyle:
        conference && conference.params.spatialAudioStyle
          ? conference.params.spatialAudioStyle
          : 'disabled',
      dolbyParticipantId: currentParticipant?.dolbyParticipantId,
    };

    sendJSONMessageToWebSocket(message);
  }, [
    currentRoom,
    currentParticipantSpeaking,
    currentParticipant,
    muted,
    sendJSONMessageToWebSocket,
    conference,
  ]);

  // Sync participants state
  useEffect(() => {
    const interval = setInterval(async () => {
      await sendParticipantState();
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [sendParticipantState]);

  useAsyncEffect(async () => {
    if (streamerStatus === 'Connected') {
      setEnterPureweb(false);

      if (purewebConnectionStartTimeRef.current) {
        await trackAnalytics('PUREWEB_CONNECTION_FINISH', {
          purewebClientOptions: clientOptions,
          duration:
            new Date().getTime() - purewebConnectionStartTimeRef.current,
        });
      }
    }
  }, [streamerStatus, clientOptions, trackAnalytics]);

  React.useEffect(() => {
    if (currentRoom) {
      sendJSONMessageToWebSocket({
        action: 'CHANGE_SCREEN_RESOLUTION',
        width: window.innerWidth >= 1980 ? 1980 : window.innerWidth,
        height: window.innerHeight >= 1080 ? 1080 : window.innerHeight,
      });
    }
  }, [currentRoom]);

  useAsyncEffect(async () => {
    purewebConnectionStartTimeRef.current = new Date().getTime();

    await trackAnalytics('PUREWEB_CONNECTION_START', {
      purewebClientOptions: clientOptions,
    });
  }, [trackAnalytics, clientOptions]);

  return (
    <PurewebView
      enterPureweb={enterPureweb}
      inputEmitter={inputEmitter}
      setEnterPureweb={setEnterPureweb}
      streamerStatus={streamerStatus}
      videoRef={videoRef}
      videoStream={videoStream}
      clientOptions={clientOptions}
      isOpenChat={isOpenChat}
    />
  );
};

export default Pureweb;
